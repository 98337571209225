import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SnowChart = ({ resortName, snowData }) => {
  const getSnowDataValue = (season) => {

    let dailySnowData = [];

    let dailyData = snowData.filter(function (currentElement) {
      return (currentElement.Season === season && currentElement.SnowDepth !== 999);
    });

    dailyData.sort(function (a, b) {
      var dateA = new Date(a.CreatedAt), dateB = new Date(b.CreatedAt)
      return dateA - dateB
    })

    dailyData.forEach(function (dataItem) {
      let valueCounter = 0;

      if (dataItem.WeatherMonth < 6) {
        valueCounter = 1
      }

      dailySnowData.push([Date.UTC(1969 + valueCounter, Number(dataItem.WeatherMonth) - 1, dataItem.WeatherDay), dataItem.SnowDepth])
    })

    return dailySnowData
  }

  let resortWeatherSnowDataSeries = [];

  let seasons = [...new Set(snowData.map(item => item.Season))].sort().reverse();

  seasons.forEach(function (item) {
    resortWeatherSnowDataSeries.push({
      'name': item,
      'data': getSnowDataValue(item)
    })
  })

  const options = {
    colors: ["#0000CC", "#0033CC", "#3366CC", "#3399FF", "#000066", "#990000", "#CC0000", "#FF0066", "#660033", "#8904B1", "#FE9A2E", "#DF7401"],
    chart: {
      type: 'spline',
      zoomType: 'x',
      backgroundColor: "#efefef",
      borderWidth: 1,
      borderColor: "#dedede",
      style: {
        fontFamily: 'Lato'
      }
    },
    title: {
      text: 'Official snow depth data',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat'
      }
    },
    subtitle: {
      text: 'Drag a section of the graph to zoom in',
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Lato'
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%b %e ', year: '%b'
      },
      title: {
        text: ''
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },
    yAxis: {
      title: {
        text: 'Official snow depth (cm)',
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat'
        }
      },
      min: 0,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat'
        }
      }
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%b %e}: {point.y}cm'
    },
    exporting:
    {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: true
        }
      }
    },
    series: resortWeatherSnowDataSeries,
    credits:
    {
      enabled: false
    }
  };

  return (
    <div>
      <div className="sj-box sj-box-dark-blue sj-round-half center-aligned mt-5">
        <h3>{resortName}</h3>
      </div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}

export default SnowChart;