import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import ResortAutocomplete from './ResortAutocomplete'
import Uploader from '../Common/Uploader'

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm
const MIN_FILE_SIZE = 50 // .25MB
const MAX_FILE_SIZE = 5120 // 5MB

const MemberUploadPhoto = ({ user, skiAreaList }) => {

    const params = useParams()
    const navigate = useNavigate()

    const [imageFiles, setImageFiles] = useState([])
    const [images, setImages] = useState([])

    /*const selectPhotoRef = useRef()
    const captionRef = useRef()
    const errRef = useRef()*/
    const [success, setSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [formerrors, setFormErrors] = useState({})
    const [uploadStarted, setUploadStarted] = useState(false)

    const [values, setValues] = useState({
        Caption: ""
    })

    const [resortUniqueNameFromParam, setResortUniqueNameFromParam] = useState('')
    const [thisResort, setThisResort] = useState(undefined)
    const [resortUniqueName, setResortUniqueName] = useState('')

    //const [selectPhoto, setSelectPhoto] = useState('')
    const [validSelectPhoto, setValidSelectPhoto] = useState(true)
    //const [selectPhotoFocus, setSelectPhotoFocus] = useState(false)

    //const [caption, setCaption] = useState('')
    //const [validCaption, setValidCaption] = useState(true)
    //const [captionFocus, setCaptionFocus] = useState(false)

    useEffect(() => {
        if (params !== undefined && params.resort_unique !== undefined) {
            setResortUniqueNameFromParam(params.resort_unique)
            setResortUniqueName(params.resort_unique)
        }
    }, [params.resort_unique]);

    useEffect(() => {
        let passedResort = skiAreaList.filter(function (currentElement) {
            return (currentElement.UniqueName === params.resort_unique);
        })

        if (passedResort !== undefined && passedResort[0] !== undefined) {
            setThisResort(passedResort[0])
        }
    }, [skiAreaList, params.resort_unique])

    /*useEffect(() => {
        captionRef.current.focus()
    }, [])

    useEffect(() => {
        setValidCaption(caption !== undefined && caption !== '')
    }, [caption])

    useEffect(() => {
        setErrMsg('')
    }, [caption])*/

    useEffect(() => {
        const images = [], fileReaders = []
        let isCancel = false
        if (imageFiles.length) {
            imageFiles.forEach((file) => {
                const fileReader = new FileReader()
                fileReaders.push(fileReader)
                fileReader.onload = (e) => {
                    const { result } = e.target
                    if (result) {
                        images.push(result)
                    }
                    if (images.length === imageFiles.length && !isCancel) {
                        setImages(images)
                    }
                }
                fileReader.readAsDataURL(file)
            })
        }
        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [imageFiles])

    const validate = () => {

        let errors = {}

        if (!values.Caption) {
            errors.Caption = "The caption field cannot be left blank"
        }

        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    const changeHandler = (e) => {
        const { files } = e.target

        const validImageFiles = []
        let hasErrorWithSelectedFiles = false
        for (let i = 0; i < files.length; i++) {
            const file = files[i]

            if (file.type.match(imageTypeRegex)) {

                let fileSizeKiloBytes = file.size / 1024

                if (fileSizeKiloBytes > MAX_FILE_SIZE || fileSizeKiloBytes < MIN_FILE_SIZE) {
                    hasErrorWithSelectedFiles = true
                    setValidSelectPhoto(false)
                }
                else {
                    validImageFiles.push(file)
                }
            }
            else {
                hasErrorWithSelectedFiles = true
                setValidSelectPhoto(false)
            }
        }

        if (validImageFiles.length === files.length) {
            setImageFiles(validImageFiles)
            return;
        }

        if (hasErrorWithSelectedFiles) {
            setImages([])
            alert("The selected image(s) are either not valid type or too large")
        }
    };

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate(values)) {
            try {
                setUploadStarted(true)
                const formData = new FormData()
                formData.append("ResortUniqueName", resortUniqueName);
                formData.append("Caption", values.Caption);
                formData.append("Email", user.Email);
                formData.append("Token", user.Token);
                for (let i = 0; i < imageFiles.length; i++) {
                    formData.append('files', imageFiles[i]);
                }

                //console.log(formData)

                axios({
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-rapidapi-host": "file-upload8.p.rapidapi.com",
                        "x-rapidapi-key": "your-rapidapi-key-here",
                    },
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/sjmember/UploadPhoto`,
                    data: formData
                }).then(photoUploadResult => {

                    //console.log(photoUploadResult?.data);
                    let photoUpload = photoUploadResult.data

                    if (photoUpload !== null) {
                        //console.log(photoUpload)

                        let path = window.$baseMemberUrl + '/photos';
                        navigate(path);
                    } else {
                        setUploadStarted(false)
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                    }
                })
            } catch (err) {
                setUploadStarted(false)
                //console.log(err)
                if (!err?.response) {
                    setErrMsg('No Server Response')
                }
                //errRef.current.focus()
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Upload photo(s) | Member area | SnowJapan</title>
                <meta name="description" content="Upload photo(s) | Member area | SnowJapan" />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Photos.svg" alt='upload-photo' /></div>
                <div>
                    <h3>Upload new photo(s)</h3>
                    <div className="sj-para">
                        Please note that all submissions will be reviewed before appearing on the site. We reserve the right to decline content that we feel is inappropriate. Before submitting content to SnowJapan and for full details, <Link to={`${window.$baseAppUrl}/about-members-area`}>please read this</Link>.
                    </div>
                    <div className='sj-row'>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3 sj-no-margin">
                                {uploadStarted ? (
                                    <>
                                        <Uploader />
                                    </>
                                ) : (
                                    <div>
                                        {resortUniqueNameFromParam === '' &&
                                            <ResortAutocomplete skiAreaList={skiAreaList} onResortSelect={data => {
                                                setResortUniqueName(data)
                                            }} />
                                        }
                                        {(resortUniqueNameFromParam !== '' && thisResort !== undefined) &&
                                            <Form.Group controlId="txtResortNameLabel" className="mt-2 mb-4">
                                                <Form.Label><h5>Ski area:</h5></Form.Label>
                                                <Form.Control name="txtResortNameLabel" type="text" placeholder="Caption" value={thisResort.Name} readOnly="true" />
                                            </Form.Group>
                                        }
                                        <Form.Group controlId="txtCaption" className="mt-2 mb-4">
                                            <Form.Label>
                                                <h5>Caption:</h5>
                                                A simple description to display with the photo.<br />
                                                Please limit to 100 words.
                                            </Form.Label>
                                            <Form.Control name="txtCaption" type="text" placeholder="Caption" value={values.Caption} onChange={handleChange} />
                                            {formerrors.Caption && (
                                                <div className="text-danger">{formerrors.Caption}</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group controlId="filePhotos" className="mt-2 mb-4">
                                            <Form.Label>
                                                <h5>Select photo(s):</h5>
                                                Max. size allowed 5MB.<br />
                                                After selecting image(s), click on Upload photos and wait.
                                            </Form.Label>
                                            <Form.Control name="filePhotos" type="file" placeholder="Caption" accept="image/png, image/jpg, image/jpeg" multiple required onChange={changeHandler} />
                                            {formerrors.Photos && (
                                                <div className="text-danger">{formerrors.Photos}</div>
                                            )}
                                        </Form.Group>
                                    </div>
                                )
                                }
                                {
                                    images.length > 0 ?
                                        <div className="row sj-row-no-margin-padding sj-no-bg mb-2">
                                            {
                                                images.map((image, idx) => {
                                                    return <div className="col-lg-2 col-md-4 col-sm-6" key={idx}> <img src={image} alt="" className="sj-full-width" /> </div>
                                                })
                                            }
                                        </div> : null
                                }
                                {!uploadStarted &&
                                    <div className='center-aligned'>
                                        <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Upload Photo(s)</Button>
                                    </div>
                                }
                            </Row>
                        </Form>

                    </div>
                </div>
            </div >
        </div >
    )
}

export default MemberUploadPhoto