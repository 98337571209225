import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'
import useSkiAreaPhotos from '../CustomHooks/useSkiAreaPhotos'
import SkiAreaPhotoDetail from './SkiAreaPhotoDetail'

const SkiAreaPhotos = () => {

    const queryPhoto = 0
    const userName = ''
    const [pageNumberPhoto, setPaegNumberPhoto] = useState(1)

    const {
        loadingPhoto,
        errorPhoto,
        photos,
        hasMorePhoto
    } = useSkiAreaPhotos(queryPhoto, userName, pageNumberPhoto)

    const observerPhoto = useRef()
    const lastPhotoElementRef = useCallback(nodePhoto => {
        if (loadingPhoto)
            return
        if (observerPhoto.current)
            observerPhoto.current.disconnect()
        observerPhoto.current = new IntersectionObserver(entriesPhoto => {
            if (entriesPhoto[0].isIntersecting && hasMorePhoto) {
                setPaegNumberPhoto(prevPageNumberPhoto => prevPageNumberPhoto + 1)
            }
        })

        if (nodePhoto)
            observerPhoto.current.observe(nodePhoto)
    }, [loadingPhoto, hasMorePhoto])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Photos from ski areas around Japan | SnowJapan</title>
                <meta name="description" content="Photos from ski and snowboard areas throughout Japan, submitted by SnowJapan readers." />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Photos.svg" alt='snowjapan-photo' /></div>
                <div>
                    <h3>Japanese ski area photos</h3>
                    <div className="sj-para">
                        Photos taken at ski areas throughout Japan, as posted by SnowJapan members. Photos for each ski area also appear within the relevant section on SnowJapan. If you are looking for photos of a specific ski area, you can find them there.
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Write a new ski area review</Link></h6>
                    </div>
                </div>
            </div>
            <div className='empty-row'>
                <div className="sj-para">
                    <h3>Recent photos</h3>
                </div>
                {photos.map((photo, index) => {
                    if (photos.length === index + 1) {
                        return (
                            <div ref={lastPhotoElementRef} key={photo.Id}>
                                <SkiAreaPhotoDetail photo={photo} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={photo.Id}>
                                <SkiAreaPhotoDetail photo={photo} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingPhoto &&
                        <Loader />
                    }
                </div>
                <div>{errorPhoto && 'Error'}</div>
            </div>
        </div>
    )
};

export default SkiAreaPhotos;