import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from "../../../Common/Loader"
import { GetTotalMonthDays, GetCurrentSeasonName } from '../../../../helpers/Helper'

const DaylySnowfallTable = ({ dailyReport }) => {

    const [dailySnowfallList, setDailySnowfallList] = useState([]);

    const fetchDailySnowfall = async (nowUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/${nowUniqueName}/All`
        }).then(resDailySnowfall => {
            setDailySnowfallList(resDailySnowfall.data)
        })
    };

    useEffect(() => {
        fetchDailySnowfall(dailyReport.UniqueName)
    }, [dailyReport.UniqueName]);

    const getMonthlySeasonSnowfallValue = (month) => {
        let monthlySeasonSnowfallData = []

        let seasons = [...new Set(dailySnowfallList.map(item => item.Season))].sort().reverse();

        seasons.forEach(function (season) {
            if (season > '2011-2012') {
                let dailySnowfallData = []

                let dailyReportData = dailySnowfallList.filter(function (currentElement) {
                    return (currentElement.NowReportMonthFull === month && currentElement.Season === season);
                });

                dailyReportData.forEach(function (dataItem) {
                    dailySnowfallData.push({ 'day': dataItem.NowReportDay, 'dailySnowfall': dataItem.NewSnowfallResortA, 'reportDate': dataItem.NowReportDayTh + '-' + dataItem.NowReportMonthFull + '-' + dataItem.NowReportYear })
                })

                monthlySeasonSnowfallData.push({ 'seasonName': season, 'seasonDailySnowfall': dailySnowfallData })
            }
        })

        return monthlySeasonSnowfallData;
    }

    let dailySnowfallDataList = [];
    let months = ['November', 'December', 'January', 'February', 'March', 'April'];

    months.forEach(function (month) {
        dailySnowfallDataList.push({
            'name': month,
            'seasonData': getMonthlySeasonSnowfallValue(month)
        })
    })

    let totalDays = 0

    const runCallback = (cb) => {
        return cb();
    };

    const GetDailyData = (monthlyData, day) => {
        let snowData = monthlyData.filter(function (elm) {
            return (elm.day === day);
        });

        if (snowData.length > 0) {
            return snowData[0].dailySnowfall;
        } else {
            return '';
        }
    };

    const GetDailyDataCell = (monthlyData, day, seasonName) => {
        let snowData = monthlyData.filter(function (elm) {
            return (elm.day === day);
        });

        if (snowData.length > 0) {
            return (<td key={day} className={`${seasonName === currentSeason ? "sj-box-light-red" : ""}`}><Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/archives/${snowData[0].reportDate}`}>{snowData[0].dailySnowfall}</Link></td>);
        } else {
            return (<td key={day} className={`${seasonName === currentSeason ? "sj-box-light-red" : ""}`}></td>);
        }
    };

    const GetDayTh = (day) => {
        let dayTh = ''

        switch (day) {
            case 1:
            case 21:
            case 31:
                dayTh = 'st'
                break;
            case 2:
            case 22:
                dayTh = 'nd'
                break;
            case 3:
            case 23:
                dayTh = 'rd'
                break;
            default:
                dayTh = 'th'
        }

        return day + dayTh
    };

    const currentSeason = GetCurrentSeasonName()

    if (dailySnowfallList.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='empty-row'>
                <div className='center-aligned mt-5'>
                    <h5>Click on the dates in the tables below to view each daily report</h5>
                </div>
                {dailySnowfallDataList.map((monthlyData, index) => (
                    <div key={index} className='empty-row' style={{ overflowX: 'auto' }}>
                        <div>
                            <h3 className='center-aligned'>{monthlyData.name}</h3>
                        </div>
                        <div className='table-container'>
                            <table className='table-container__table sj-data-table'>
                                <thead>
                                    <tr>
                                        <th>Season</th>
                                        {runCallback(() => {
                                            totalDays = GetTotalMonthDays(monthlyData.name)
                                            const row = [];
                                            for (var i = 1; i < (totalDays + 1); i++) {
                                                row.push(<th key={i}>{GetDayTh(i)}</th>);
                                            }
                                            return row;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {monthlyData.seasonData.map((seasonSnowData, key) => (
                                        <tr key={key} >
                                            <td className={`td-first-column ${seasonSnowData.seasonName === currentSeason ? "sj-box-red" : ""}`}><Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/season/${seasonSnowData.seasonName}`}>{seasonSnowData.seasonName}</Link></td>
                                            {runCallback(() => {
                                                const row = [];
                                                for (var i = 1; i < (totalDays + 1); i++) {
                                                    /*row.push(<td key={i} className={`${seasonSnowData.seasonName === currentSeason ? "sj-box-light-red" : ""}`}>{
                                                        GetDailyData(seasonSnowData.seasonDailySnowfall, i)
                                                    }</td>);*/
                                                    row.push(GetDailyDataCell(seasonSnowData.seasonDailySnowfall, i, seasonSnowData.seasonName));
                                                }
                                                return row;
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))
                }
            </div>
        )
    }
};

export default DaylySnowfallTable;