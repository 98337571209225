import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

const SkiAreaListTable = ({ tableData, sortColumn, sortOrder, headingColumns, dataMapping, title, breakOn = 'medium' }) => {

  let tableClass = 'table-container__table';

  if (breakOn === 'small') {
    tableClass += ' table-container__table--break-sm';
  } else if (breakOn === 'medium') {
    tableClass += ' table-container__table--break-md';
  } else if (breakOn === 'large') {
    tableClass += ' table-container__table--break-lg';
  }

  const data = tableData.map((row, index) => {
    let rowData = [];
    let i = 0;

    for (const key in row) {

      if (dataMapping[i] === undefined) {
        continue;
      }

      var objName = 'row.' + dataMapping[i]

      rowData.push({
        key: headingColumns[i],
        val: eval(objName),
        UniqueName: row.UniqueName,
        PrefectureName: row.PrefectureName,
        PrefectureUniqueName: row.PrefectureUniqueName,
        TownName: row.TownName,
        TownUniqueName: row.TownUniqueName,
        TownTypeStr: row.TownTypeStr
      });
      i++;
    }

    return <tr key={index}>
      {rowData.map((data, index) => {
        if (index === 0) {
          if (sortColumn === 'Ski area name') {
            return <td key={index} className='sorted-column' data-heading={data.key}>
              <Link to={`${window.$baseSkiAreaUrl}/${data.PrefectureUniqueName}/${data.TownUniqueName}/${data.UniqueName}`}><h5>{data.val}</h5></Link>
              (<Link to={`${window.$baseSkiAreaUrl}/town/${data.TownUniqueName}`}>{data.TownName} {data.TownTypeStr}</Link>,&nbsp;
              <Link to={`${window.$baseSkiAreaUrl}/prefecture/${data.PrefectureUniqueName}`}>{data.PrefectureName}</Link>)
            </td>
          } else {
            return <td key={index} data-heading={data.key}>
              <Link to={`${window.$baseSkiAreaUrl}/${data.PrefectureUniqueName}/${data.TownUniqueName}/${data.UniqueName}`}><h5>{data.val}</h5></Link>
              (<Link to={`${window.$baseSkiAreaUrl}/town/${data.TownUniqueName}`}>{data.TownName} {data.TownTypeStr}</Link>,&nbsp;
              <Link to={`${window.$baseSkiAreaUrl}/prefecture/${data.PrefectureUniqueName}`}>{data.PrefectureName}</Link>)
            </td>
          }
        }
        else {
          if (data.key === sortColumn) {
            return <td key={index} className='sorted-column' data-heading={data.key}>{data.val}</td>
          } else {
            return <td key={index} data-heading={data.key}>{data.val}</td>
          }
        }
      }
      )}
    </tr>
  });

  return (
    <div className="table-container">
      <div className="left-aligned">
        <h4>{title}</h4>
      </div>
      <table className={`ski-area-list ${tableClass}`}>
        <thead>
          <tr>
            {headingColumns.map((col, index) => {
              if (col === sortColumn) {
                if (sortOrder === 'ASC') {
                  return <th key={index} className='sorted-column-header'>{col}&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDoubleDown} /></th>
                } else if (sortOrder === 'DESC') {
                  return <th key={index} className='sorted-column-header'>{col}&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDoubleUp} /></th>
                } else {
                  return <th key={index} className='sorted-column-header'>{col}</th>
                }
              } else {
                return <th key={index}>{col}</th>
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data}
        </tbody>
      </table>
    </div>
  );
}

SkiAreaListTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  breakOn: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default SkiAreaListTable;