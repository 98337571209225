import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { GetCurrentSeasonName } from '../../../helpers/Helper'

const DetailTabs = () => {

  const params = useParams()

  const [buttonTitle, setButtonTitle] = useState('')
  const [subItemName, setSubItemName] = useState('')

  const currentSeason = GetCurrentSeasonName()

  const nowUniqueName = params.now

  useEffect(() => {
    setSubItemName(params.type)

    let thisButtonTitle
    switch (params.type) {
      case 'observed-snowfall-analysis':
        thisButtonTitle = 'Snowfall analysis'
        break
      case 'season':
        thisButtonTitle = 'Seasonal archives'
        break
      case 'snow-depth-analysis':
        thisButtonTitle = 'Snow depth data'
        break
      case 'weather':
        thisButtonTitle = 'Weather'
        break
      case 'about-daily-reports':
        thisButtonTitle = 'About'
        break
      default:
        thisButtonTitle = "Latest report"
    }

    setButtonTitle(thisButtonTitle)

  }, [params]);

  return (
    <div>
      <div className="d-none d-lg-block bg-color-report">
        <ul className="tabs">
          <li className={`${(subItemName === '' || subItemName === undefined) ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}`}>Latest report</Link>
          </li>
          <li className={`${subItemName === 'observed-snowfall-analysis' ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/observed-snowfall-analysis`}>Snowfall analysis</Link>
          </li>
          <li className={`${subItemName === 'season' ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/season/${currentSeason}`}>Seasonal archives</Link>
          </li>
          <li className={`${subItemName === 'snow-depth-analysis' ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/snow-depth-analysis`}>Snow depth data</Link>
          </li>
          <li className={`${subItemName === 'weather' ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/weather`}>Weather</Link>
          </li>
          <li className={`${subItemName === 'about-daily-reports' ? " active" : ""}`}>
            <Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/about-daily-reports`}>About</Link>
          </li>
        </ul>
      </div>
      <div className="d-lg-none sj-row-100">
        <DropdownButton align="end" title={buttonTitle} id="dropdown-menu-align-end" className='sj-large-font-button daily-report-sub-menu'>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}`}>Latest report</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}/observed-snowfall-analysis`}>Snowfall analysis</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}/season/${currentSeason}`}>Seasonal archives</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}/snow-depth-analysis`}>Snow depth data</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}/weather`}>Weather</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/${nowUniqueName}/about-daily-reports`}>About</Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  )
}

export default DetailTabs