import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'
import useSkiAreaReviews from '../CustomHooks/useSkiAreaReviews'
import SkiAreaReviewDetail from './SkiAreaReviewDetail'

const SkiAreaReviews = () => {
    const queryReview = 0
    const userName = ''
    const [pageNumberReview, setPaegNumberReview] = useState(1)

    const {
        loadingReview,
        errorReview,
        reviews,
        hasMoreReview
    } = useSkiAreaReviews(queryReview, userName, pageNumberReview)

    const observerReview = useRef()
    const lastReviewElementRef = useCallback(nodeReview => {
        if (loadingReview)
            return
        if (observerReview.current)
            observerReview.current.disconnect()
        observerReview.current = new IntersectionObserver(entriesReview => {
            if (entriesReview[0].isIntersecting && hasMoreReview) {
                setPaegNumberReview(prevPageNumberReview => prevPageNumberReview + 1)
            }
        })

        if (nodeReview)
            observerReview.current.observe(nodeReview)
    }, [loadingReview, hasMoreReview])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Reviews of ski areas around Japan | SnowJapan</title>
                <meta name="description" content="Reviews of ski and snowboard areas throughout Japan, submitted by SnowJapan readers." />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Reviews.svg" alt='review' /></div>
                <div>
                    <h3>Japanese ski area reviews</h3>
                    <div className="sj-para">
                        Reviews of ski areas throughout Japan, as posted by SnowJapan members. The views expressed in these reviews are not those of SnowJapan. Reviews for each ski area also appear within the relevant section on SnowJapan. If you are looking for reviews of a specific ski area, you can find them there.
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Write a new ski area review</Link></h6>
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                    </div>
                </div>
            </div>
            <div className='empty-row'>
                <div className="sj-para">
                    <h3>Recent reviews</h3>
                </div>
                {reviews.map((review, index) => {
                    if (reviews.length === index + 1) {
                        return (
                            <div ref={lastReviewElementRef} key={review.Id}>
                                <SkiAreaReviewDetail review={review} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={review.Id}>
                                <SkiAreaReviewDetail review={review} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingReview &&
                        <Loader />
                    }
                </div>
                <div>{errorReview && 'Error'}</div>
            </div>
        </div>
    )
};

export default SkiAreaReviews;