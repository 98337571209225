/* eslint-disable */
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row, Collapse } from 'react-bootstrap'
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const ForgotPassword = () => {
    //const errRef = useRef()
    const [success, setSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [formerrors, setFormErrors] = useState({})
    //const [isVisible, setIsVisible] = useState(false)

    const [values, setValues] = useState({
        Username: "",
        Email: ""
    })

    /*const invokeCollapse = () => {
        return setIsVisible(!isVisible)
    }*/

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const validate = () => {
        let errors = {}

        if (!values.Username) {
            errors.Username = "The username field cannot be blank."
        }

        if (!values.Email) {
            errors.Email = "The email field cannot be blank."
        } else if (!EMAIL_REGEX.test(values.Email)) {
            errors.Email = "The email address is invalid."
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate(values)) {
            try {
                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/Account/ForgotPassword`,
                    data: {
                        UserName: values.Username,
                        Email: values.Email
                    }
                }).then(forgotPasswordResult => {

                    console.log(forgotPasswordResult?.data);

                    let forgotPassword = forgotPasswordResult.data

                    if (forgotPassword !== null) {
                        console.log(forgotPassword)
                        if (forgotPassword.ResultType === 'SUCCESS') {
                            setValues({
                                Username: "",
                                Email: ""
                            })
                            setSuccess(true)
                        } else {
                            setErrMsg(registeredUser.Message)
                            errRef.current.focus()
                        }
                    } else {
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No server response')
                }
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Forgot password  | Member area | SnowJapan</title>
                <meta name="description" content="Register a new account to upload photos and submit reviews on SnowJapan." />
            </Helmet>
            <div>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Icons-Member.svg" alt='member-login' /></div>
                <div>
                    <h3>SnowJapan member area</h3>
                    <div className="sj-para">
                        <p>Please enter your username and email below and click Submit to reset your password.</p>
                    </div>
                </div>
                <div className="empty-row">
                    {success ? (
                        <section>
                            <h3>Success!</h3>
                            <p>Please check your registered email address.</p>
                            <p>Click on the link in the email to reset your password.</p>
                        </section>
                    ) : (
                        <>
                            {errMsg !== '' &&
                                <div>
                                    <h1 className="text-danger">Error!</h1>
                                    <div className="text-danger">{errMsg}</div>
                                </div>
                            }
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3 sj-no-margin">
                                    <Form.Group controlId="txtUsername" className="mt-2 mb-4">
                                        <Form.Label>
                                            <h5>Username:</h5>
                                        </Form.Label>
                                        <Form.Control name="txtUsername" type="text" placeholder="Username" value={values.Username} onChange={handleChange} />
                                        {formerrors.Username && (
                                            <div className="text-danger">{formerrors.Username}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtEmail" className="mb-4">
                                        <Form.Label><h5>Email:</h5>
                                        </Form.Label>
                                        <Form.Control name="txtEmail" type="email" placeholder="Email" aria-describedby="igEmail" value={values.Email} onChange={handleChange} />
                                        {formerrors.Email && (
                                            <div className="text-danger">{formerrors.Email}</div>
                                        )}
                                    </Form.Group>
                                    <div className='center-aligned'>
                                        <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Submit</Button>
                                    </div>
                                </Row>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword