import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AccommodationDetailTabs = () => {

  const params = useParams()

  const [buttonTitle, setButtonTitle] = useState('Introduction')
  const [subItemName, setSubItemName] = useState('')
  const baseUrlPath = window.$baseAccommodationUrl + '/' + params.prefecture + '/' + params.town + '/' + params.hotel

  useEffect(() => {

    setSubItemName(params.type)

    let thisButtonTitle
    switch (params.type) {
      case 'photographs':
        thisButtonTitle = 'Photographs';
        break;
      case 'route-finder':
        thisButtonTitle = 'Route Finder';
        break;
      default:
        thisButtonTitle = "Introduction";
    }

    setButtonTitle(thisButtonTitle)

  }, [params]);

  return (
    <div>
      <div className="d-none d-lg-block bg-color-accommodation">
        <ul className="tabs">
          <li className={`${subItemName === undefined ? " active" : ""}`}>
            <Link to={`${baseUrlPath}`}>Introduction</Link>
          </li>
          <li className={`${subItemName === 'photographs' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/photographs`}>Photographs</Link>
          </li>
          {/*<li className={`${subItemName === 'route-finder' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/route-finder`}>Route Finder</Link>
  </li>*/}
        </ul>
      </div>
      <div className="d-lg-none sj-row-100">
        <DropdownButton align="end" title={buttonTitle} id="dropdown-menu-align-end" variant="danger" className='sj-large-font-button accommodation-sub-menu'>
          <Dropdown.Item as={Link} to={`${baseUrlPath}`}>Introduction</Dropdown.Item>
          <Dropdown.Item as={Link} to={`${baseUrlPath}/photographs`}>Photographs</Dropdown.Item>
          {/*<Dropdown.Item as={Link} to={`${baseUrlPath}/route-finder`}>Route Finder</Dropdown.Item>*/}
        </DropdownButton>
      </div>
    </div>
  );
}

export default AccommodationDetailTabs;