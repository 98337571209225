import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../CustomHooks/useAuth'
import { Form, Button, Row } from 'react-bootstrap'

const Login = () => {

    const { setAuth, setUser } = useAuth()
    const navigate = useNavigate();
    const [errMsg, setErrMsg] = useState('')
    const [formerrors, setFormErrors] = useState({})

    const [values, setValues] = useState({
        Username: "",
        Password: ""
    })

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const validate = () => {

        let errors = {};

        if (!values.Username) {
            errors.Username = "The username field cannot be blank."
        }

        if (!values.Password) {
            errors.Password = "The password field cannot be blank."
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate(values)) {
            //console.log(values)
            try {
                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/Account/InternalLogin`,
                    data: {
                        UserName: values.Username,
                        Password: values.Password,
                        RememberMe: false
                    }
                }).then(loginResult => {

                    let loggedInUserResult = loginResult.data

                    if (loggedInUserResult !== null) {
                        //console.log(loggedInUserResult)
                        if (loggedInUserResult.ResultType === 'SUCCESS') {
                            setUser(loggedInUserResult)
                            setAuth(true)
                            setValues({
                                Username: "",
                                Password: ""
                            })
                            sessionStorage.setItem('sessionUser', JSON.stringify(loggedInUserResult))
                            //let path = window.$baseMemberUrl;
                            navigate(window.$baseMemberUrl)
                        } else {
                            setErrMsg(loggedInUserResult.Message)
                        }
                    } else {
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                }
                //errRef.current.focus()
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Login  | Member area | SnowJapan</title>
                <meta name="description" content="Login  | Member area | SnowJapan" />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Icons-Member.svg" alt='member-login' /></div>
                <div>
                    <h3>SnowJapan member area</h3>
                    <div className="sj-para">
                        Your reviews and photographs of Japanese ski areas can be submitted and shared with other people using the SnowJapan member area. All content you submit will be publicly displayed within the relevant sections of SnowJapan and your member page. Before using our member area, <Link to={`${window.$baseAppUrl}/about-members-area`}>please read this</Link>.
                    </div>
                </div>
                <div>
                    <div className='empty-row'>
                        {errMsg !== '' &&
                            <div>
                                <h4 className="text-danger">Error!</h4>
                                <div className="text-danger">{errMsg}</div>
                            </div>
                        }
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3 sj-no-margin">
                                <Form.Group controlId="txtUsername" className="mt-2 mb-4">
                                    <Form.Label><h5>Username:</h5></Form.Label>
                                    <Form.Control name="txtUsername" type="text" placeholder="Username" value={values.Username} onChange={handleChange} />
                                    {formerrors.Username && (
                                        <div className="text-danger">{formerrors.Username}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtPassword" className="mb-4">
                                    <Form.Label><h5>Password:</h5></Form.Label>
                                    <Form.Control name="txtPassword" type="password" placeholder="Password" value={values.Password} onChange={handleChange} />
                                    {formerrors.Password && (
                                        <div className="text-danger">{formerrors.Password}</div>
                                    )}
                                </Form.Group>
                                <div className='center-aligned'>
                                    <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Login</Button>
                                </div>
                                <div className='center-aligned'>
                                    <Link to={`${window.$baseAppUrl}/forgot-password`} className='btn btn-primary w-100 sj-box-dark-blue mt-2 mb-3'><h6 className='mt-1 mb-1'>Forgot password</h6></Link>
                                </div>
                            </Row>
                        </Form>
                    </div>
                    <div>
                        <div className='sj-row'>
                            <h3>Want to join?</h3>
                            <div className="sj-para">
                                <p>Registration is free and very simple.
                                </p>
                                <p>
                                    What is needed:
                                </p>
                                <p>
                                    - a username (does not need to be your real name)<br />
                                    - a valid email address<br />
                                    - a password<br />
                                </p>
                                <p>
                                    Your email address is not shared with anyone or added to any mailing list, and you will not receive any emails from us. Be assured that it is strictly only used for registration purposes.
                                </p>
                            </div>
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-6 sj-row-no-margin-padding">
                                    <Link to={`${window.$baseAppUrl}/register`} className='btn btn-primary w-100 sj-box-dark-blue mt-2'><h6 className='mt-1 mb-1'>Register here</h6></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;