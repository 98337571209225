import { Image } from 'react-bootstrap'
import { GetPCIconImageName, GetWindCIconImageName, GetWthConditionIconImageName, GetVConditionIconImageName } from '../../../../helpers/Helper'

const DailyReportInfo = ({ dailyReport, isArchive }) => {

  let reportType = '(latest report)'
  if (isArchive) {
    reportType = '(Archive report)'
  }

  //console.log(dailyReport)

  return (
    <div>
      <h3 className={`mt-4 mb-4 ${isArchive ? 'sj-red-text' : ''}`}>{dailyReport.NowReportWeekDay} {dailyReport.NowReportDayTh} {dailyReport.NowReportMonthFull} {dailyReport.NowReportYear} {dailyReport.NowReportHourMinute} {reportType}</h3>
      {dailyReport.Season > '2011-2012' &&
        <>
          <div className='row sj-no-margin'>
            <div className='col-md-6 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Observed snowfall at base (24 hrs)</h5></div>
              <div className='center-aligned mt-3'>
                <h2 className='sj-light-blue-text'>{dailyReport.NewSnowfallResortA}cm</h2>
              </div>
            </div>
            <div className='col-md-6 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Temperature (base / top)</h5></div>
              <div className='center-aligned mt-3'>
                <h2 className='sj-light-blue-text'>{dailyReport.TemperatureBottom}°C / {dailyReport.TemperatureTop}°C</h2>
              </div>
            </div>
          </div>
          <div className='row sj-no-margin'>
            <div className='col-3 sj-no-margin'>
              <div className='center-aligned'>
                <Image src={`https://www.snowjapan.com/UploadedFiles/NowImages/NowIconsNew/${GetWthConditionIconImageName(dailyReport.WeatherConditionIcon)}`} fluid='true' alt='Weather Condition' />
              </div>
              <div className='center-aligned'>
                <h6>[Overview]</h6>
                {dailyReport.WeatherCondition}
              </div>
            </div>
            <div className='col-3 sj-no-margin'>
              <div className='center-aligned'>
                <Image src={`https://www.snowjapan.com/UploadedFiles/NowImages/NowIconsNew/${GetPCIconImageName(dailyReport.PrecipitationConditionIcon)}`} fluid='true' alt='Precipitation Condition' />
              </div>
              <div className='center-aligned'>
                <h6>[Precipitation]</h6>
                {dailyReport.PrecipitationCondition}
              </div>
            </div>
            <div className='col-3 sj-no-margin'>
              <div className='center-aligned'>
                <Image src={`https://www.snowjapan.com/UploadedFiles/NowImages/NowIconsNew/${GetWindCIconImageName(dailyReport.WindConditionIcon)}`} fluid='true' alt='Wind Condition' />
              </div>
              <div className='center-aligned'>
                <h6>[Wind]</h6>
                {dailyReport.WindCondition}
              </div>
            </div>
            <div className='col-3 sj-no-margin'>
              <div className='center-aligned'>
                <Image src={`https://www.snowjapan.com/UploadedFiles/NowImages/NowIconsNew/${GetVConditionIconImageName(dailyReport.VisibilityConditionIcon)}`} fluid='true' alt='Visibility Condition' />
              </div>
              <div className='center-aligned'>
                <h6>[Visibility]</h6>
                {dailyReport.VisibilityCondition}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default DailyReportInfo