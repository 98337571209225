import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SkiAreaInfo from '../SkiAreaInfo'
import Loader from "../../../Common/Loader"
import { RenderBreak } from '../../../../helpers/Helper'

const DailyReportContainer = ({ resort, skiAreaDailyReport }) => {

    //console.log(skiAreaDailyReport)

    if (skiAreaDailyReport === undefined) {
        return (<Loader />)
    }
    else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>{skiAreaDailyReport.NowName} daily snow & weather report for {resort.Name} region | SnowJapan</title>
                    <meta name="description" content={`Daily snow and weather report from close to ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
                </Helmet>
                <div className='empty-row'>
                    <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Daily-Reports.svg" alt={`${resort.Name} daily report`} /></div>
                    <div>
                        <SkiAreaInfo resort={resort} />
                        {(skiAreaDailyReport !== undefined && Object.keys(skiAreaDailyReport).length > 0) &&
                            <>
                                <div className="sj-para">
                                    <h3 className='mb-4'>{skiAreaDailyReport.NowName} latest report</h3>
                                    <h4>Visit <Link to={`${window.$baseDailyReportUrl}/${skiAreaDailyReport.NowUniqueName}`}>{skiAreaDailyReport.NowName}</Link> for detailed daily reports from the area</h4>
                                </div>
                                <div className="sj-para">
                                    <h5 className='sj-red-text'>The following report was posted on {skiAreaDailyReport.NowReportWeekDay} {skiAreaDailyReport.NowReportDayTh} {skiAreaDailyReport.NowReportMonthFull} {skiAreaDailyReport.NowReportYear} {skiAreaDailyReport.NowReportHourMinute}</h5>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {(skiAreaDailyReport !== undefined && Object.keys(skiAreaDailyReport).length > 0) &&
                    <div className="sj-row">
                        <pre>
                            <div dangerouslySetInnerHTML={{ __html: RenderBreak(skiAreaDailyReport.MainReport) }} />
                        </pre>
                        <div className='sj-para'>
                            <h4>Opening, lift & other notes</h4>
                        </div>
                        <pre>
                            <div dangerouslySetInnerHTML={{ __html: RenderBreak(skiAreaDailyReport.LiftOperation) }} />
                        </pre>
                        <div>
                            <Link to={`${window.$baseDailyReportUrl}/${skiAreaDailyReport.NowUniqueName}`} className='btn btn-primary sj-large-button-blue sj-full-width'>{skiAreaDailyReport.NowName} report section</Link>
                        </div>
                        <div className='empty-row'>
                            {(skiAreaDailyReport.Photo1 !== null && skiAreaDailyReport.Photo1 !== undefined) &&
                                <div className='sj-image-holder center-aligned mt-4'>
                                    <Image src={`https://www.snowjapan.com${skiAreaDailyReport.Photo1.replace('~', '')}`} fluid='true' alt='Report 1' />
                                </div>
                            }
                            {(skiAreaDailyReport.Photo2 !== null && skiAreaDailyReport.Photo2 !== undefined) &&
                                <div className='sj-image-holder center-aligned mt-4'>
                                    <Image src={`https://www.snowjapan.com${skiAreaDailyReport.Photo2.replace('~', '')}`} fluid='true' alt='Report 2' />
                                </div>
                            }
                            {(skiAreaDailyReport.Photo3 !== null && skiAreaDailyReport.Photo3 !== undefined) &&
                                <div className='sj-image-holder center-aligned mt-4'>
                                    <Image src={`https://www.snowjapan.com${skiAreaDailyReport.Photo3.replace('~', '')}`} fluid='true' alt='Report 3' />
                                </div>
                            }
                        </div>
                        <div>
                            <Link to={`${window.$baseDailyReportUrl}/${skiAreaDailyReport.NowUniqueName}`} className='btn btn-primary sj-large-button-blue sj-full-width'>{skiAreaDailyReport.NowName} report section</Link>
                        </div>
                    </div>
                }
            </div>
        )
    };
}

export default DailyReportContainer;