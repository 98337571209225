import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Loader from "../../Common/Loader"
import AccommodationDetailTabs from './AccommodationDetailTabs'
import BasicContainer from './BasicInfo/BasicInfoContainer'
import PhotographsContainer from './Photographs/PhotographsContainer'
import RouteFinderContainer from './RouteFinder/RouteFinderContainer'

const AccommodationDetailContainer = () => {

    const params = useParams()

    const [accommodation, setAccommodation] = useState([]);
    const [accommodationPhotos, setAccommodationPhotos] = useState([]);
    const [nearbySkyAras, setNearbySkyAras] = useState([]);

    const fetchAccommodationPhotosAndNearbySkyAras = async (hotelUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/accommodation/${hotelUniqueName}`
        }).then(dataAccommodation => {
            setAccommodation(dataAccommodation.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/photos/${hotelUniqueName}`
            })
        }).then(dataAccommodationPhotos => {
            setAccommodationPhotos(dataAccommodationPhotos.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/nearby-resorts/skiarea/${hotelUniqueName}`
            })
        }).then(dataNearbySkyAreas => {
            setNearbySkyAras(dataNearbySkyAreas.data)
        })
    };

    useEffect(() => {
        fetchAccommodationPhotosAndNearbySkyAras(params.hotel);
    }, [params.hotel]);

    if (accommodation.length <= 0) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <Fragment>
                <Helmet>
                    <title>{accommodation.Name}, an accommodation {accommodation.TownName} {accommodation.TownTypeStr}, {accommodation.PrefectureName}, Japan  |  {accommodation.NameJapanese} | SnowJapan</title>
                    <meta name="description" content={`Introducing ${accommodation.Name}, an accommodation located close to ski areas in ${accommodation.TownName} ${accommodation.TownTypeStr}, ${accommodation.PrefectureName}, Japan.`} />
                </Helmet>
                <h2>{accommodation.Name}</h2>
                <div className='sj-para-large mt-1 mb-3'>{accommodation.NameJapanese}</div>
                <AccommodationDetailTabs />
                <>
                    {
                        {
                            undefined: <BasicContainer accommodation={accommodation} nearbySkyAraList={nearbySkyAras} accommodationPhotos={accommodationPhotos} />,
                            'photographs': <PhotographsContainer accommodation={accommodation} accommodationPhotos={accommodationPhotos} />,
                            'route-finder': <RouteFinderContainer accommodation={accommodation} />
                        }[params.type]
                    }
                </>
            </Fragment>
        )
    }
};

export default AccommodationDetailContainer;