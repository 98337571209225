import PropTypes from 'prop-types'
import { Button, Modal, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useState } from 'react'

const SkiAreaPhotoDetail = ({ photo }) => {
    /*const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
    }
    const showModal = () => {
        setShow(true)
    }*/
    return (
        <div>
            <div className="sj-box sj-box-blue sj-round-half left-aligned mt-3">
                <h4>{photo.Caption}</h4>
            </div>
            {(photo.ResortUniqueName !== undefined && photo.ResortUniqueName !== null) &&
                <div className="row sj-row-small-margin-padding sj-no-bg">
                    <div>
                        <Link to={`${window.$baseSkiAreaUrl}/${photo.PrefectureUniqueName}/${photo.TownUniqueName}/${photo.ResortUniqueName}`}><h4>{photo.ResortName}</h4></Link>
                    </div>
                    <div className='sj-large-font'>
                        <Link to={`${window.$baseSkiAreaUrl}/town/${photo.TownUniqueName}`}>{photo.TownName} {photo.TownTypeStr}</Link>,&nbsp;
                        <Link to={`${window.$baseSkiAreaUrl}/prefecture/${photo.PrefectureUniqueName}`}>{photo.PrefectureName}</Link>
                    </div>
                </div>
            }
            <div className='sj-row-small-margin-padding center-aligned'>
                <Image src={`${window.$baseAppUrl}/photo-galleries/${photo.CreatedByUserName}/${photo.PhotoFileName}`} alt={`thumb-${photo.Id}`} fluid='true' />
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Uploaded by:</strong>
                </div>
                <div className="col-lg-10">
                    {photo.CreatedByUserName}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Uploaded on:</strong>
                </div>
                <div className="col-lg-10">
                    {photo.CreatedAtDayTh} {photo.CreatedAtMonthFull} {photo.CreatedAtYear}
                </div>
            </div>
            <hr className='mt-5' />
            {/*<Modal
                show={show}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{photo.Caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='center-aligned'>
                        <img src={`${window.$baseAppUrl}/photo-galleries/${photo.CreatedByUserName}/${photo.PhotoFileName}`} alt={`full-${photo.Id}`} />
                    </div>
                    {(photo.ResortUniqueName !== undefined && photo.ResortUniqueName !== null) &&
                        <div className="row sj-row-small-margin-padding">
                            <div>
                                <Link to={`${window.$baseSkiAreaUrl}/${photo.PrefectureUniqueName}/${photo.TownUniqueName}/${photo.ResortUniqueName}`}><h5>{photo.ResortName}</h5></Link>
                                (<Link to={`${window.$baseSkiAreaUrl}/town/${photo.TownUniqueName}`}>{photo.TownName} {photo.TownTypeStr}</Link>,&nbsp;
                                <Link to={`${window.$baseSkiAreaUrl}/prefecture/${photo.PrefectureUniqueName}`}>{photo.PrefectureName}</Link>)
                            </div>
                        </div>
                    }
                    <div className="row sj-row-small-margin-padding">
                        <div className="col-lg-3">
                            <strong>Uploaded by:</strong>
                        </div>
                        <div className="col-lg-9">
                            {photo.CreatedByUserName}
                        </div>
                    </div>
                    <div className="row sj-row-small-margin-padding">
                        <div className="col-lg-3">
                            <strong>Uploaded on:</strong>
                        </div>
                        <div className="col-lg-9">
                            {photo.CreatedAtDayTh} {photo.CreatedAtMonthFull} {photo.CreatedAtYear}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
                </Modal>*/}
        </div>
    )
};

SkiAreaPhotoDetail.propTypes = {
    photo: PropTypes.object.isRequired
}

export default SkiAreaPhotoDetail;