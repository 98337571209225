import AccommodationInfo from '../AccommodationInfo'

const RouteFinderContainer = ({ accommodation }) => {
    return (
        <div>
            <h1>Route Finder</h1>
            <AccommodationInfo accommodation={accommodation} />
            <div>
            </div>
        </div>
    )
};

export default RouteFinderContainer;