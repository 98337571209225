import { useState, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { DropdownButton, Dropdown, Button, Collapse } from 'react-bootstrap'
import axios from 'axios'
import Loader from '../Common/Loader'
import SkiAreaListTable from './SkiAreaListTable'
import SkiAreaListMap from './SkiAreaListMap'
import SkiAreaMapContainer from '../Common/SkiAreaMapContainer'
import JapanMap from '../SkiArea/JapanMap'

const SkiAreaList = () => {

    const params = useParams()
    const location = useLocation()

    const [allSkiAreaList, setAllSkiAreaList] = useState([])
    const [prefecture, setPrefecture] = useState(undefined)
    const [area, setArea] = useState(undefined)
    const [town, setTown] = useState(undefined)
    //const [tableTitleName, setTableTitleName] = useState('');

    const [buttonTitle, setButtonTitle] = useState('Sort By: Ski area name [A-Z]')
    const [sortColumn, setSortColumn] = useState('Name')
    const [sortOrder, setSortOrder] = useState('')

    const [open, setOpen] = useState(false)

    let requestType = ''

    const fetchPrefecture = async (prefectureUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/prefecture/${prefectureUniqueName}`
        }).then(resPrefecture => {
            setPrefecture(resPrefecture.data)
        })
    };

    const fetchArea = async (areaUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/area/${areaUniqueName}`
        }).then(resArea => {
            setArea(resArea.data)
        })
    };

    const fetchTown = async (townUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/town/${townUniqueName}`
        }).then(resTown => {
            setTown(resTown.data)
        })
    };

    if (location.pathname.includes('prefecture')) {
        requestType = 'prefecture'
    }
    else if (location.pathname.includes('area')) {
        requestType = 'area'
    }
    else if (location.pathname.includes('town')) {
        requestType = 'town'
    }

    const fetchSkiAreaList = async (requestType, resortUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/list/${requestType}/${resortUniqueName}`
        }).then(dataResortList => {
            setAllSkiAreaList(dataResortList.data)
        })
    }

    useEffect(() => {
        if (location.pathname.includes('prefecture')) {
            fetchPrefecture(params.key)
        }
        else if (location.pathname.includes('area')) {
            fetchArea(params.key)
        }
        else if (location.pathname.includes('town')) {
            fetchTown(params.key)
        }

        fetchSkiAreaList(requestType, params.key)

    }, [requestType, location.pathname, params.key])

    const SortResult = (evtKey, evt) => {

        var sortedResult = []
        var sortColumn = ''
        var sortOrder = ''

        if (evtKey === '1' || evtKey === '2') {
            sortedResult = allSkiAreaList.sort(function (a, b) {
                let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })
            sortOrder = "ASC"

            if (evtKey === '2') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            sortColumn = ""

        } else if (evtKey === '3' || evtKey === '4') {
            if (evtKey === '3') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.MaximumElevation < b.MaximumElevation) return 1
                    if (a.MaximumElevation > b.MaximumElevation) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '4') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.MaximumElevation > b.MaximumElevation) return 1
                    if (a.MaximumElevation < b.MaximumElevation) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Top (m)"

        } else if (evtKey === '5' || evtKey === '6') {
            if (evtKey === '5') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.MinimumElevation < b.MinimumElevation) return 1
                    if (a.MinimumElevation > b.MinimumElevation) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '6') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.MinimumElevation > b.MinimumElevation) return 1
                    if (a.MinimumElevation < b.MinimumElevation) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Bottom (m)"

        } else if (evtKey === '7' || evtKey === '8') {
            if (evtKey === '7') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.VerticalDifference < b.VerticalDifference) return 1
                    if (a.VerticalDifference > b.VerticalDifference) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '8') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.VerticalDifference > b.VerticalDifference) return 1
                    if (a.VerticalDifference < b.VerticalDifference) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Vertical (m)"

        } else if (evtKey === '9' || evtKey === '10') {
            if (evtKey === '9') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiLifts < b.TotalSkiLifts) return 1
                    if (a.TotalSkiLifts > b.TotalSkiLifts) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '10') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiLifts > b.TotalSkiLifts) return 1
                    if (a.TotalSkiLifts < b.TotalSkiLifts) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Lifts"

        } else if (evtKey === '11' || evtKey === '12') {
            if (evtKey === '11') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiCourses < b.TotalSkiCourses) return 1
                    if (a.TotalSkiCourses > b.TotalSkiCourses) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '12') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiCourses > b.TotalSkiCourses) return 1
                    if (a.TotalSkiCourses < b.TotalSkiCourses) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Courses"

        } else if (evtKey === '13' || evtKey === '14') {
            if (evtKey === '13') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.LongestCourse < b.LongestCourse) return 1
                    if (a.LongestCourse > b.LongestCourse) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '14') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.LongestCourse > b.LongestCourse) return 1
                    if (a.LongestCourse < b.LongestCourse) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Longest (m)"

        } else if (evtKey === '15' || evtKey === '16') {
            if (evtKey === '15') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.SteepestSlope < b.SteepestSlope) return 1
                    if (a.SteepestSlope > b.SteepestSlope) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '16') {
                sortedResult = allSkiAreaList.sort(function (a, b) {
                    if (a.SteepestSlope > b.SteepestSlope) return 1
                    if (a.SteepestSlope < b.SteepestSlope) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            sortColumn = "Steepest (º)"
        }

        setAllSkiAreaList(sortedResult)
        setButtonTitle('Sort by: ' + evt.target.innerText)
        setSortColumn(sortColumn)
        setSortOrder(sortOrder)
    }

    if (allSkiAreaList.length > 0 && (prefecture !== undefined || area !== undefined || town !== undefined)) {
        let mapCenter = {
            lat: 36.2048, lng: 138.2529
        }

        let mapZoomLevel = 13

        let tableTitle = ''
        let pageTitle = ''
        if (requestType === 'prefecture' && prefecture !== undefined) {
            tableTitle = prefecture.Name + ' prefecture'
            pageTitle = 'Ski areas in ' + prefecture.Name + ' | Introducing the ski areas of Japan | SnowJapan'
            mapCenter = {
                lat: parseFloat(prefecture.Location_Latitude), lng: parseFloat(prefecture.Location_Longitude)
            }
            mapZoomLevel = parseInt(prefecture.Location_MapZoomLevel)
        }

        if (requestType === 'area' && area !== undefined) {
            tableTitle = ' the ' + area.Name
            pageTitle = 'Ski areas in the ' + area.Name + ' | Introducing the ski areas of Japan | SnowJapan'

            mapCenter = {
                lat: parseFloat(area.Location_Latitude), lng: parseFloat(area.Location_Longitude)
            }
            mapZoomLevel = parseInt(area.Location_MapZoomLevel)
        }

        if (requestType === 'town' && town !== undefined) {

            tableTitle = town.Name + ' ' + town.TownTypeStr + ' (' + town.PrefectureName + ')'
            pageTitle = 'Ski areas in ' + town.Name + ' ' + town.TownTypeStr + ' (' + town.PrefectureName + ') | Introducing the ski areas of Japan | SnowJapan'

            mapCenter = {
                lat: parseFloat(town.Location_Latitude), lng: parseFloat(town.Location_Longitude)
            }
            mapZoomLevel = parseInt(town.Location_MapZoomLevel)
        }

        return (
            <div className='empty-row'>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <h3>Ski areas in {tableTitle}</h3>
                <div>
                    <div className="empty-row">
                        Introducing the {allSkiAreaList.length} ski areas in {tableTitle}.
                    </div>
                    <div className="empty-row">
                        Read our introduction to the each of the prefectures of Japan <Link to='/guides/the-prefectures-of-japan'>here</Link>.
                    </div>
                </div>
                <div className='empty-row'>
                    <div className='right-aligned'>
                        <DropdownButton align="end" title={buttonTitle} onSelect={SortResult} id="dropdown-menu-align-end" className='sj-large-font-button'>
                            <Dropdown.Item eventKey="1">Ski area name [A-Z]</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Ski area name [Z-A]</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Maximum elevation [highest{'>'}lowest]</Dropdown.Item>
                            <Dropdown.Item eventKey="4">Maximum elevation [lowest{'>'}highest]</Dropdown.Item>
                            <Dropdown.Item eventKey="5">Minimum elevation [highest{'>'}lowest]</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Minimum elevation [lowest{'>'}highest]</Dropdown.Item>
                            <Dropdown.Item eventKey="7">Vertical [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="8">Vertical [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="9">Lifts [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="10">Lifts [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="11">Courses [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="12">Courses [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="13">Longest [longest{'>'}shortest]</Dropdown.Item>
                            <Dropdown.Item eventKey="14">Longest [shortest{'>'}longest]</Dropdown.Item>
                            <Dropdown.Item eventKey="15">Steepest [steepest{'>'}gentlest]</Dropdown.Item>
                            <Dropdown.Item eventKey="16">Steepest [gentlest{'>'}steepest]</Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <SkiAreaListTable
                        tableData={allSkiAreaList}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        headingColumns={['', 'Top (m)', 'Bottom (m)', 'Vertical (m)', 'Lifts', 'Courses', 'Longest (m)', 'Steepest (º)']}
                        dataMapping={['Name', 'MaximumElevation', 'MinimumElevation', 'VerticalDifference', 'TotalSkiLifts', 'TotalSkiCourses', 'LongestCourse', 'SteepestSlope']}
                        title=''
                    />
                </div>
                <div className='mt-5'>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-expanded={open} className='w-100 mb-1'><h5 className='mt-1'>{open ? 'Click to hide map -' : 'Click to view map +'}</h5></Button>
                    <Collapse in={open}>
                        <div>
                            {(open) &&
                                <SkiAreaMapContainer mapCenter={mapCenter} mapZoom={mapZoomLevel} skiAreaList={allSkiAreaList} />
                            }
                        </div>
                    </Collapse>
                </div>
                <SkiAreaListMap skiAreaList={allSkiAreaList} prefecture={prefecture} area={area} town={town} />
                {prefecture !== undefined &&
                    <div className='mt-5'>
                        <JapanMap />
                    </div>
                }
            </div>
        )
    } else {
        return (
            <Loader />
        )
    }
}

export default SkiAreaList