import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'

const SkiSnowBoard = ({ resort, nearbySkiAreaList }) => {

    const [nearbySkiAreas, setNearbySkiAreas] = useState(nearbySkiAreaList);

    useEffect(() => {

        let thisNearbySkiAreas = []

        if (nearbySkiAreaList.length > 0) {
            thisNearbySkiAreas = nearbySkiAreaList.filter(function (currentElement) {
                return (currentElement.IsConnected);
            });
        }

        setNearbySkiAreas(thisNearbySkiAreas)
    }, [resort.UniqueName, nearbySkiAreaList]);

    console.log("a " + resort.SkiersAllowed)
    console.log("b " + resort.SnowboardersAllowed)

    return (
        <div className='sj-row'>
            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                <div className="col-md-4 sj-row-no-margin-padding sj-bia-frame-first">
                    <div className='center-aligned sj-box sj-box-green sj-round-full pt-4 pb-4 mt-4 mb-4'>
                        <h4>{resort.BeginnersCourses}%</h4>
                        <h5>Beginner</h5>
                    </div>
                </div>
                <div className="col-md-4 sj-row-no-margin-padding sj-bia-frame-middle">
                    <div className='center-aligned sj-box sj-box-red sj-round-full pt-4 pb-4 mt-4 mb-4'>
                        <h4>{resort.IntermediateCourses}%</h4>
                        <h5>Intermediate</h5>
                    </div>
                </div>
                <div className="col-md-4 sj-row-no-margin-padding sj-bia-frame-last">
                    <div className='center-aligned sj-box sj-box-dark-blue sj-round-full pt-4 pb-4 mt-4 mb-4'>
                        <h4>{resort.AdvancedCourses}%</h4>
                        <h5>Advanced</h5>
                    </div>
                </div>
            </div>
            <div className="row sj-row sj-no-bg skier-snowboarder">
                <div className="col-md-3">
                    <div className={`center-aligned pt-5 ${resort.TotalSkiLifts > 0 ? "" : "gray-font"}`}>
                        <h1 className='pt-5'>{resort.TotalSkiLifts}</h1>
                        <h3>Total ski lift{resort.TotalSkiLifts === 1 ? "" : "s"}</h3>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.RopeWays > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Ropeway.svg' fluid='true' alt='ropeways' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Ropeway-bw.svg' fluid='true' alt='ropeways 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.RopeWays > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.RopeWays}</h2>
                        <h4>Ropeway{resort.RopeWays === 1 ? "" : "s"}</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.Gondolas > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Gondola.svg' fluid='true' alt='gondolas' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Gondola-bw.svg' fluid='true' alt='gondolas 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.Gondolas > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.Gondolas}</h2>
                        <h4>Gondola{resort.Gondolas === 1 ? "" : "s"}</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.QuadLifts > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Quad.svg' fluid='true' alt='quad lifts' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Quad-bw.svg' fluid='true' alt='quad lifts 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.QuadLifts > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.QuadLifts}</h2>
                        <h4>Quad lift{resort.QuadLifts === 1 ? "" : "s"}</h4>
                    </div>
                </div>
            </div>
            <div className="row sj-row sj-no-bg skier-snowboarder">
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.TripleLifts > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Triple.svg' fluid='true' alt='triple lifts' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Triple-bw.svg' fluid='true' alt='triple lifts 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.TripleLifts > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.TripleLifts}</h2>
                        <h4>Triple lift{resort.TripleLifts === 1 ? "" : "s"}</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.PairLifts > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Pair.svg' fluid='true' alt='pair lifts' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Pair-bw.svg' fluid='true' alt='pair lifts' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.PairLifts > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.PairLifts}</h2>
                        <h4>Pair lift{resort.PairLifts === 1 ? "" : "s"}</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.SingleLifts > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Single.svg' fluid='true' alt='single lifts' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Single-bw.svg' fluid='true' alt='single lifts 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.SingleLifts > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.SingleLifts}</h2>
                        <h4>Single lift{resort.SingleLifts === 1 ? "" : "s"}</h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className='center-aligned'>
                        {resort.OtherLifts > 0
                            ? <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Other.svg' fluid='true' alt='other lifts' />
                            : <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLift-Other-bw.svg' fluid='true' alt='other lifts 0' />
                        }
                    </div>
                    <div className={`center-aligned ${resort.OtherLifts > 0 ? "" : "gray-font"}`}>
                        <h2>{resort.OtherLifts}</h2>
                        <h4>Other lift{resort.OtherLifts === 1 ? "" : "s"}</h4>
                    </div>
                </div>
            </div>
            {(resort.SkiersAllowed > 0 || resort.SnowboardersAllowed > 0) &&
                <>
                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-6 sj-row-no-margin-padding">
                            <div className='sj-skier-snowboarder-left'>
                                <div className='center-aligned sj-box sj-box-dark-gray sj-round-half'>
                                    <h4>Skiers</h4>
                                </div>
                                <div className='center-aligned sj-box-lighter-gray pt-2 pb-2'>
                                    <h4>{resort.SkiersPercent}%</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 sj-row-no-margin-padding">
                            <div className='sj-skier-snowboarder-right'>
                                <div className='center-aligned sj-box sj-box-dark-gray sj-round-half'>
                                    <h4>Snowboarders</h4>
                                </div>
                                <div className='center-aligned sj-box-lighter-gray pt-2 pb-2'>
                                    <h4>{resort.SnowboardersPercent}%</h4>
                                </div>
                            </div>
                        </div>
                        {(resort.SkiersAllowed > 0 && resort.SnowboardersAllowed > 0) &&
                            <div className='sj-row-small-margin-padding center-aligned'>
                                {(resort.SkiersAllowed === 1 && resort.SnowboardersAllowed === 1) &&
                                    <>Skiing and snowboarding are both allowed at {resort.Name}</>
                                }
                                {(resort.SkiersAllowed === 1 && resort.SnowboardersAllowed === 2) &&
                                    <>{resort.Name} is a skiing-only resort (snowboarding is not allowed)</>
                                }
                                {(resort.SkiersAllowed === 2 && resort.SnowboardersAllowed === 1) &&
                                    <>{resort.Name} is a snowboarding-only resort (skiing is not allowed)</>
                                }
                                {(resort.SnowboardersAllowed === 3) &&
                                    <>There are some snowboarding restrictions at {resort.Name}</>
                                }
                                {(resort.SkiersAllowed === 3) &&
                                    <>There are some skiing restrictions at {resort.Name}</>
                                }
                            </div>
                        }
                    </div>
                </>
            }
            {nearbySkiAreas.length > 0 &&
                <div className='sj-row center-aligned'>
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                        <h4>{resort.Name} is lift-connected with</h4>
                    </div>
                    <div className='sj-link-button-container center-aligned'>
                        {nearbySkiAreas.map(nearbySkiArea => (
                            <Link key={nearbySkiArea.SkiAreaUniqueName} to={`${window.$baseSkiAreaUrl}/${nearbySkiArea.SkiAreaPrefectureName}/${nearbySkiArea.SkiAreaTownUniqueName}/${nearbySkiArea.SkiAreaUniqueName}`}>{nearbySkiArea.SkiAreaName} ({nearbySkiArea.Distance}km)</Link>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

export default SkiSnowBoard;