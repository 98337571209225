export function RenderBreak(text) {
    let alteredText = ''
    String(text).split('\r\n\r\n').forEach(function (item) {
        alteredText = alteredText + '<p>' + item + '</p>'
    })

    return alteredText;
}

// Get Precipitation Condition Icon Name
export function GetPCIconImageName(iconId) {
    let iconImageName = ''

    switch (iconId) {
        case 1:
            iconImageName = 'LightSnow.svg'
            break;
        case 2:
            iconImageName = 'ModerateSnow.svg'
            break;
        case 3:
            iconImageName = 'HeavySnow.svg'
            break;
        case 4:
            iconImageName = 'Sleet.svg'
            break;
        case 5:
            iconImageName = 'LightRain.svg'
            break;
        case 6:
            iconImageName = 'ModerateRain.svg'
            break;
        case 7:
            iconImageName = 'HeavyRain.svg'
            break;
        case 8:
            iconImageName = 'NoPrecipitation.svg'
            break;
        default:
        // code block
    }

    return iconImageName;
}

// Get Wind Condition Icon Name
export function GetWindCIconImageName(iconId) {
    let iconImageName = ''

    switch (iconId) {
        case 1:
            iconImageName = 'NoWind.svg'
            break;
        case 2:
            iconImageName = 'LightWind.svg'
            break;
        case 3:
            iconImageName = 'ModerateWind.svg'
            break;
        case 4:
            iconImageName = 'StrongWind.svg'
            break;
        default:
        // code block
    }

    return iconImageName;
}

// Get Weather Condition Icon Name
export function GetWthConditionIconImageName(iconId) {
    let iconImageName = ''

    switch (iconId) {
        case 1:
            iconImageName = 'Bluebird.svg'
            break;
        case 2:
            iconImageName = 'PartlyCloudy.svg'
            break;
        case 3:
            iconImageName = 'MostlyCloudy.svg'
            break;
        case 4:
            iconImageName = 'CloudCover.svg'
            break;
        case 5:
            iconImageName = 'Foggy.svg'
            break;
        case 6:
            iconImageName = 'Thunderstorm.svg'
            break;
        case 7:
            iconImageName = 'Hazy.svg'
            break;
        case 8:
            iconImageName = 'Rain.svg'
            break;
        case 9:
            iconImageName = 'WeatherSleet.svg'
            break;
        case 10:
            iconImageName = 'Snow.svg'
            break;
        default:
        // code block
    }

    return iconImageName;
}

// Get Visibility Condition Icon Name
export function GetVConditionIconImageName(iconId) {
    let iconImageName = ''

    switch (iconId) {
        case 1:
            iconImageName = 'Excellent.svg'
            break;
        case 2:
            iconImageName = 'VeryGood.svg'
            break;
        case 3:
            iconImageName = 'FairDecent.svg'
            break;
        case 4:
            iconImageName = 'VariedLimited.svg'
            break;
        case 5:
            iconImageName = 'Poor.svg'
            break;
        case 6:
            iconImageName = 'VeryPoor.svg'
            break;
        default:
        // code block
    }

    return iconImageName;
}

export function GetCurrentSeasonName() {
    let seasonName = ''

    const thisDate = new Date();
    let thisMonth = thisDate.getMonth();
    let thisYear = thisDate.getFullYear();

    if (thisMonth < 10) {
        seasonName = (thisYear - 1) + '-' + thisYear
    }
    else {
        seasonName = thisYear + '-' + (thisYear + 1)
    }

    return seasonName;
}

export function GetPreviousSeasonName() {
    let seasonName = ''

    const thisDate = new Date();
    let thisMonth = thisDate.getMonth();
    let thisYear = thisDate.getFullYear();

    if (thisMonth < 10) {
        seasonName = (thisYear - 2) + '-' + (thisYear - 1)
    }
    else {
        seasonName = (thisYear - 1) + '-' + thisYear
    }

    return seasonName;
}

export function GetTotalMonthDays(monthName) {
    let todayDays = 0

    switch (monthName) {
        case 'November':
        case 'April':
            todayDays = 30
            break;
        case 'December':
        case 'January':
        case 'March':
            todayDays = 31
            break;
        case 'February':
            todayDays = 29
            break;
        default:
    }

    return todayDays;
}