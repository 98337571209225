import { Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Autocomplete from "./Autocomplete";
import Loader from "./Loader"

const Home = () => {
    const [skiAreaList, setSkiAreaList] = useState([])
    const [slidingImageList, setSlidingImageList] = useState([]);
    const [dailyReportList, setDailyReportList] = useState([]);
    const [homeLinkList, setHomeLinkList] = useState([]);
    const [weatherImportResult, setWeatherImportResult] = useState('');
    const [mainHomePageSeason, setMainHomePageSeason] = useState('');

    const fetchSkiAreaSlidingImagesDailyReportsAndHomeLinks = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/home/sliding-images`
        }).then(resSlidingImage => {
            setSlidingImageList(resSlidingImage.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/dailyreport/latest/all`
            })
        }).then(resDailyReportLatest => {
            setDailyReportList(resDailyReportLatest.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/home/home-inks`
            })
        }).then(resSlidingLinks => {
            setHomeLinkList(resSlidingLinks.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/skiarea/home/list`
            })
        }).then(dataResortList => {
            setSkiAreaList(dataResortList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/generalmessage/HomePageSeason`
            })
        }).then(resGeneralMessages => {
            console.log(resGeneralMessages)
            setMainHomePageSeason(resGeneralMessages.data.Title)
            return axios({
                method: 'POST',
                url: `${window.$baseSJAPIUrl}/weatherdata`
            })
        }).then(resWeatherData => {
            setWeatherImportResult(resWeatherData.data)
        })
    };

    useEffect(() => {
        fetchSkiAreaSlidingImagesDailyReportsAndHomeLinks()
    }, []);

    if (slidingImageList.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className="sj-row-no-margin-padding">
                <div className="row sj-row-no-margin-padding sj-no-bg">
                    <div className="col-lg-6 sj-row-no-margin-padding">
                        <div>
                            <div className='sj-box sj-box-dark-blue sj-round-full Montserrat-font'>
                                Let SnowJapan introduce you to the wonderful world of skiing and snowboarding in Japan. Before starting, <Link to='/about-snowjapan'>learn more about SnowJapan here</Link>.
                            </div>
                            <div className='sj-row'>
                                <Link to='/about-snowjapan-japanese'>日本語による紹介はこちらから</Link>
                            </div>
                        </div>
                        <div>
                            {homeLinkList.map((homeLink, index) => (
                                <div key={index}>
                                    <div className="row sj-row sj-box sj-box-gray sj-round-half sj-row-small-margin-padding">
                                        <div className="col-6">
                                            <h5>{homeLink.Title}</h5>
                                        </div>
                                        <div className="col-6 right-aligned">
                                            <h5>{homeLink.LinkTimestamp}</h5>
                                        </div>
                                    </div>
                                    <div className='sj-padding-left-15'>
                                        {homeLink.LinkType === 'Internal' &&
                                            <Link to={{ pathname: `${homeLink.URLLink}` }} rel='noreferrer'>{homeLink.HeadlineText}</Link>
                                        }
                                        {homeLink.LinkType === 'External' &&
                                            <a href={`${homeLink.URLLink}`} target='_blank' rel='noreferrer'>{homeLink.HeadlineText}</a>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-6 sj-row-no-margin-padding sj-padding-left-10">
                        <Carousel controls={false} indicators={false} fade={true} pause='hover' interval='3000'>
                            {slidingImageList.map(slidingImage => (
                                <Carousel.Item key={slidingImage.Id}>
                                    <Image src={`${window.$baseAppUrl}/UploadedFiles/SlidingImages/${slidingImage.Name}`} fluid='true' alt={`sliding ${slidingImage.Name}`} className="sj-round-full d-block w-100" />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className="sj-row">
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2 mb-3">
                        <Link to={window.$baseSkiAreaUrl}><h4>Discover Japan's ski areas</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to={window.$baseSkiAreaUrl}>
                                <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiArea.svg' fluid='true' alt='Ski Area' />
                            </Link>
                        </div>
                        <div className="col-md-9 sj-list-text-holder">
                            <div>
                                There are over 450 operating ski areas in Japan. They can be found across the country's mountainous regions, from Hokkaido in the north to Kyushu in the south. About half are located in three prefectures - Hokkaido, Nagano and Niigata. Japan's ski areas range from huge interconnected resorts with modern facilities to very small local ski hills. SnowJapan introduces them in detail and offers various ways to discover them.
                            </div>
                            <div className='mt-3'>
                                <Link to={window.$baseSkiAreaUrl}><h5>Ski Areas index</h5></Link>
                            </div>
                            <div className='mt-3'>
                                <Link to={`${window.$baseSkiAreaUrl}/discover`}><h5>Discovery search</h5></Link>
                            </div>
                            <div className='mt-3'>
                                <Link to={`${window.$baseGuideUrl}/the-japan-skiing-experience/the-ski-season-in-japan`}><h5>The Japanese ski season</h5></Link>
                            </div>
                            <div className='mt-3'>
                                <Link to={`${window.$baseRouteFinderUrl}`}><h5>Route Finder</h5></Link>
                            </div>
                            <div className="mt-3">
                                <h5>Quick search (ski area, town, area)</h5>
                                <Autocomplete skiAreaList={skiAreaList} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-row">
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-3">
                        <Link to={window.$baseDailyReportUrl}><h4>Exclusive daily weather and snow reports</h4></Link>
                    </div>
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                            <Link to={window.$baseDailyReportUrl}>
                                <Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Daily-Reports.svg' fluid='true' alt='Daily Report' />
                            </Link>
                            <br />
                            <div className="sj-box sj-box-red sj-round-full mt-2 center-aligned">
                                <h5 className='p-1'>{mainHomePageSeason}</h5>
                            </div>
                        </div>
                        <div className="col-md-9 sj-list-text-holder">
                            <div>
                                SnowJapan publishes exclusive daily snow and weather reports from popular snow regions of Japan in the winter season. Our reports are 100% independent and hype-free. Each section includes detailed data, snowfall analysis and archives.
                            </div>
                            <div className='mt-4'>
                                <table className='table-container__table sj-table-home'>
                                    <tbody>
                                        {dailyReportList.map(dailyReport => (
                                            <tr key={dailyReport.Id}>
                                                <td>
                                                    <h6><Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}`}>{dailyReport.Name}</Link></h6>
                                                </td>
                                                <td>
                                                    {dailyReport.NowReportWeekDay} {dailyReport.NowReportDayTh} {dailyReport.NowReportMonthFull} {dailyReport.NowReportYear}, {dailyReport.NowReportHourMinute}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-row">
                    <div className="sj-row">
                        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                            <Link to={window.$baseGuideUrl}><h4>Guides</h4></Link>
                        </div>
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-right-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/introduction-to-skiing-in-japan'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SnowWalls.svg' fluid='true' alt='Introduction to skiing in Japan' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/introduction-to-skiing-in-japan'><h5>Introduction to skiing in Japan</h5></Link>
                                        </div>
                                        <div>
                                            This guide looks back at how skiing was introduced to Japan, ski resort development over the years, various boom periods, the introduction of snowboarding and the growth of inbound tourism.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-left-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/the-japan-skiing-experience'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-GuidedTour.svg' fluid='true' alt='The Japan ski experience' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/the-japan-skiing-experience'><h5>The Japan skiing experience</h5></Link>
                                        </div>
                                        <div>
                                            Some parts of Japan's ski experience are somewhat different from the experience in other countries. This guide introduces aspects of what to expect when skiing and snowboarding in Japan.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-right-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/travel-guides'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Inbound.svg' fluid='true' alt='Travel guides' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/travel-guides'><h5>Travel guides</h5></Link>
                                        </div>
                                        <div>
                                            An introduction to getting around Japan by public transport and car, as well as various region-specific travel guide information.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-left-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/the-prefectures-of-japan'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiLocations.svg' fluid='true' alt='Introducing the prefectures' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/the-prefectures-of-japan'><h5>Introducing the prefectures</h5></Link>
                                        </div>
                                        <div>
                                            There are ski areas in 31 of the 47 prefectures of Japan. This section looks at each prefecture, including introductions to the popular skiing regions, basic statistics and data.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-right-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/graphs'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Graphs-Data.svg' fluid='true' alt='Japanese ski areas by graphs' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/graphs'><h5>Japanese ski areas by graphs</h5></Link>
                                        </div>
                                        <div>
                                            In this section, we offer a visual way to discover the ski areas of Japan through interactive graphs covering the whole of Japan and individual prefectures.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 sj-row-no-margin-padding home-guide-section sj-padding-left-15">
                                <div className="row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-4 sj-list-icon-holder">
                                        <Link to='/guides/old-ski-areas-from-japans-past'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiArea-History.svg' fluid='true' alt='Ski areas from Japan past' /></Link>
                                    </div>
                                    <div className="col-md-8 sj-row-no-margin-padding sj-list-text-holder">
                                        <div className='sj-box sj-box-dark-gray sj-round-half'>
                                            <Link to='/guides/old-ski-areas-from-japans-past'><h5>Ski areas from Japan's past</h5></Link>
                                        </div>
                                        <div>
                                            Over the decades, there have been over 800 individual ski areas in Japan. Many old ski areas are no longer with us, but we believe they should be remembered.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-row mb-5">
                    <div className="sj-row mb-5">
                        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-4">
                            <Link to={window.$baseListingUrl}><h4>Listings</h4></Link>
                        </div>
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-left'>
                                    <Link to={window.$baseAccommodationUrl}><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Accommodations.svg' fluid='true' alt='Accommodation' /></Link>
                                    <div className='mt-1 margin-bottom-5'>
                                        <Link to={window.$baseAccommodationUrl} className='btn btn-primary sj-large-button-blue sj-full-width pt-3'><h5>Accommodation</h5></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center1'>
                                    <Link to='/listings/resort-spotlights'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiArea.svg' fluid='true' alt='Ski Areas' /></Link>
                                    <div className='mt-1 margin-bottom-5'>
                                        <Link to='/listings/resort-spotlights' className='btn btn-primary sj-large-button-blue sj-full-width pt-3'><h5>Ski Areas</h5></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center2'>
                                    <Link to='/listings/lessons'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Ski-Lessons.svg' fluid='true' alt='Ski lessons' /></Link>
                                    <div className='mt-1 margin-bottom-5'>
                                        <Link to='/listings/lessons' className='btn btn-primary sj-large-button-blue sj-full-width pt-3'><h5>Ski lessons</h5></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-right'>
                                    <Link to='/listings/job-listings'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Jobs-Available.svg' fluid='true' alt='Jobs available' /></Link>
                                    <div className='mt-1 margin-bottom-5'>
                                        <Link to='/listings/job-listings' className='btn btn-primary sj-large-button-blue sj-full-width pt-3'><h5>Jobs available</h5></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-holder">
                    <div className="sj-row mb-0">
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-left mt-3'>
                                    <Link to='/listings/resort-spotlights/geto-kogen'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-geto-kogen.jpg' fluid='true' alt='geto-kogen' /></Link>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center1 mt-3'>
                                    <a href={`https://en.nozawaski.com/`} target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-nozawa-onsen.jpg' fluid='true' alt='listing-nozawa-onsen' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center2 mt-3'>
                                    <a href='https://www.appi-japan.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-appi.jpg' fluid='true' alt='appi-listing' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-right mt-3'>
                                    {/*<a href='https://www.snowjapan.com/listings/travel/yuzawa-minamiuonuma-eleven-plus' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-eleven-plus.jpg' alt='listing-naspa' /></a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default Home;