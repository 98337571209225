import { RenderBreak } from '../../../../helpers/Helper'

const ContactInformation = ({ resort }) => {

    return (
        <div className='empty-row'>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                <h4>{resort.Name} address & further information</h4>
            </div>

            {resort.Address !== '' &&
                <div className="sj-row">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-5 p-0 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Address.svg" alt='address' />
                        </div>
                        <div className="col-md-9 sj-list-text-holder">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.Address) }} />
                                {resort.Telephone}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/*resort.WebUrl !== '' &&
                <div className="sj-row">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 m-0 mt-5 p-0 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Website.svg" alt='website' />
                        </div>
                        <div className="col-md-9 sj-list-text-holder">
                            <div>
                                <h5><a href={resort.WebUrl} className='btn btn-primary sj-large-button-blue sj-full-width' target='_blank' rel='noreferrer' >Website</a></h5>
                                The above link, where available, opens in a new window. All linked content is outside the control of SnowJapan and may change without notice. Links are checked at least once a year.
                            </div>
                        </div>
                    </div>
                </div>
            */}
        </div>
    );
}

export default ContactInformation;