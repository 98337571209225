import { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from "../../../Common/Loader"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SeasonSnowfall = ({ dailyReport }) => {

    const [seasonSnowfallList, setSeasonSnowfallList] = useState([]);

    const fetchSeasonSnowfall = async (nowId) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/season-snowfall/All/${nowId}`
        }).then(resSeasonSnowfall => {
            setSeasonSnowfallList(resSeasonSnowfall.data)
        })
    };

    useEffect(() => {
        fetchSeasonSnowfall(dailyReport.Now_Id)
    }, [dailyReport.Now_Id]);

    const getSnowfallValue = (season) => {

        let seasonSnowfallData = [];

        let seasonData = seasonSnowfallList.filter(function (currentElement) {
            return (currentElement.Season === season);
        });

        seasonData.forEach(function (dataItem) {

            seasonSnowfallData.push(dataItem.TotalSnow)
        })

        return seasonSnowfallData;
    }

    let seasonSnowfallDataSeries = [];
    let seasons = [...new Set(seasonSnowfallList.map(item => item.Season))].sort().reverse();

    seasons.forEach(function (item) {
        if (item > '2011-2012') {
            seasonSnowfallDataSeries.push({
                'name': item,
                'data': getSnowfallValue(item),
                'visible': true
            })
        }
    })

    const options = {
        colors: ["#0000CC", "#0033CC", "#3366CC", "#3399FF", "#000066", "#990000", "#CC0000", "#FF0066", "#660033", "#8904B1", "#FE9A2E", "#DF7401"],
        chart: {
            type: 'column',
            backgroundColor: "#efefef",
            style: {
                fontFamily: 'Lato'
            },
            borderWidth: 1,
            borderColor: "#dedede"
        },
        title: {
            text: 'Season total observed snowfall at base',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat'
            }
        },
        legend: {
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        },
        xAxis: {
            categories: [''
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Season total observed snowfall (cm)',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            },
            minorTickInterval: 'auto',
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold'
                }
            }
        },
        tooltip: {
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            candlestick: {
                lineColor: '#404048'
            }
        },
        // General
        background2: '#F0F0EA',
        exporting:
        {
            enabled: false
        },
        series: seasonSnowfallDataSeries,
        credits:
        {
            enabled: false
        }
    };

    if (seasonSnowfallList.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='sj-para'>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <div className='center-aligned sj-box-lighter-gray p-2'>
                    <h6>Click on each season to add or remove data from other seasons</h6>
                </div>
            </div>
        )
    }
};

export default SeasonSnowfall;