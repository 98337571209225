import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api'
import { useState, useCallback, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import WayPointAutocomplete from "./WayPointAutocomplete"
import { Helmet } from "react-helmet"

const containerStyle = {
    width: '100%',
    height: '100vh'
}

const center = {
    lat: 36.2048,
    lng: 138.2529
}

const RouteFinder = () => {

    const params = useParams()

    const [formerrors, setFormErrors] = useState({})
    const [resort, setResort] = useState(undefined)
    const [skiAreaList, setSkiAreaList] = useState([])
    const [generalMessageRouteFinder, setGeneralMessageRouteFinder] = useState(undefined);
    const [originPoint, setOriginPoint] = useState({ name: '', location_latitude: '', location_longitude: '' })
    const [destinationPoint, setDestinationPoint] = useState({ name: '', location_latitude: '', location_longitude: '' })
    const [wayPoints, setWayPoints] = useState([])
    const [showMap, setShowMap] = useState(false)
    const summaryPanel = document.getElementById("directions-panel")
    //const history = useHistory()
    const navigate = useNavigate()

    const alphabetArray = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAmyWUwcoNW504tXuRlQ_mZssrKEEhGn20'
    })

    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const [directionsResponse, setDirectionsResponse] = useState(null)

    const onLoad = useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const fetchResortData = async (resortUniqueName) => {

        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/${resortUniqueName}`
        }).then(dataResort => {
            if (Object.keys(dataResort.data).length <= 0) {
                window.location.href = "https://www.snowjapan.com/404.html"
            }

            if (dataResort.data !== undefined) {
                setResort(dataResort.data)

                let destinationResortName = dataResort.data.Name + ' (' + dataResort.data.PrefectureName + ')'

                let destinationLocation = { name: destinationResortName, location_latitude: dataResort.data.Location_Latitude, location_longitude: dataResort.data.Location_Longitude }
                setDestinationPoint(destinationLocation)
            }
        })
    };

    const fetchData = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/route-finder/list`
        }).then(dataResortList => {
            setSkiAreaList(dataResortList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/generalmessage/RouteFinder`
            })
        }).then(resGeneralMessages => {
            setGeneralMessageRouteFinder(resGeneralMessages.data)
        })
    };

    useEffect(() => {
        // setTimeout(() => myRef.current.scrollIntoView({ behavior: 'smooth' }), 1000);
    }, [originPoint.name, destinationPoint.name]);

    useEffect(() => {

        if (params.resort !== undefined) {
            fetchResortData(params.resort)
        }

        fetchData();

    }, [params.resort]);

    const useCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords.latitude >= 26.075 && position.coords.latitude <= 45.52722222222222 && position.coords.longitude >= 127.6363888888889 && position.coords.longitude <= 145.8175) {
                let currentLocation = { name: 'Current Location', location_latitude: position.coords.latitude, location_longitude: position.coords.longitude }

                if (originPoint.name !== 'Current Location') {
                    setOriginPoint(currentLocation)
                }
            }
            else {
                alert('You need to be within Japan to use current location')
            }
        })
    }

    const setStartPoint = (newValue) => {
        if (newValue.name !== 'Current Location') {
            setOriginPoint(newValue)
        }
    }

    const setEndPoint = (newValue) => {
        if (newValue.location_latitude !== undefined && newValue.location_longitude !== undefined) {
            setDestinationPoint(newValue)
        }
    }

    const addWayPoint = () => {
        let newWayPoints = [...wayPoints, { name: '', location_latitude: '', location_longitude: '' }]
        setWayPoints(newWayPoints)
    }

    const removeWayPoint = (index) => {
        let newWayPoints = [...wayPoints]
        newWayPoints.splice(index, 1)
        setWayPoints(newWayPoints)
    }

    const setWayPoint = (newValue) => {
        let newWayPoints = [...wayPoints]
        newWayPoints[newValue.index] = { name: newValue.name, location_latitude: newValue.location_latitude, location_longitude: newValue.location_longitude }
        setWayPoints(newWayPoints)
    }

    async function calculateRoute() {
        setDirectionsResponse(null)
        setShowMap(false)

        const directionsService = new window.google.maps.DirectionsService()

        const waypts = [];

        for (let i = 0; i < wayPoints.length; i++) {
            let routeWayPoint = ''

            if (wayPoints[i].name !== '') {

                if (wayPoints[i].location_latitude !== '' && wayPoints[i].location_longitude !== '') {
                    let wayPointCoords = [wayPoints[i].location_latitude, wayPoints[i].location_longitude]
                    routeWayPoint = wayPointCoords.join(',')
                } else {
                    routeWayPoint = wayPoints[i].name
                }
            }

            waypts.push({
                location: routeWayPoint,
                stopover: true,
            });
        }

        // console.log(originPoint)
        // console.log(destinationPoint)

        let routeOriginPoint = ''

        if (originPoint.name !== '') {

            if (originPoint.location_latitude !== '' && originPoint.location_longitude !== '') {
                let originCoords = [originPoint.location_latitude, originPoint.location_longitude]
                // let currentLocation = { name: 'Current Location', location: coords.join(',') }
                routeOriginPoint = originCoords.join(',')
            } else {
                routeOriginPoint = originPoint.name
            }
        }

        let routeDestinationPoint = ''

        if (destinationPoint.name !== '') {

            if (destinationPoint.location_latitude !== '' && destinationPoint.location_longitude !== '') {
                let destinationCoords = [destinationPoint.location_latitude, destinationPoint.location_longitude]
                routeDestinationPoint = destinationCoords.join(',')
            } else {
                routeDestinationPoint = destinationPoint.name
            }
        }

        if (originPoint.name !== '' && destinationPoint.name !== '') {
            const results = await directionsService.route({
                origin: routeOriginPoint,
                destination: routeDestinationPoint,
                waypoints: waypts,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING
            })

            setDirectionsResponse(results)
            setShowMap(true)
        }
    }

    const clearRoute = () => {

        setDirectionsResponse(null)
        setWayPoints([])
        setOriginPoint({ name: '', location_latitude: '', location_longitude: '' })
        if (params.resort === undefined) {
            setDestinationPoint({ name: '', location_latitude: '', location_longitude: '' })
        }
        setShowMap(false)
    }

    const handleGoToMainRouteFinder = () => {
        clearRoute()

        setResort(undefined)
        setDestinationPoint({ name: '', location_latitude: '', location_longitude: '' })
        let path = `${window.$baseRouteFinderUrl}`;
        //history.push(path);
        navigate(path);
        return false
    }

    return isLoaded ? (
        <div className="empty-row">
            <Helmet>
                <title>{resort !== undefined ? resort.Name + ' Route Finder | Discover the route to ' + resort.Name + ' (' + resort.TownName + ', ' + resort.PrefectureName + ') | SnowJapan' : 'Route Finder | Discover routes between ski areas throughout Japan | SnowJapan'}</title>
                <meta name="description" content='SnowJapan Route Finder allows you to find road routes between ski areas throughout Japan.' />
            </Helmet>
            <h3>Route Finder{resort !== undefined ? ': ' + resort.Name : ''}</h3>
            <div className="sj-para">
                {generalMessageRouteFinder !== undefined && generalMessageRouteFinder.Title === 'Y' &&
                    <>
                        <strong>SnowJapan Route Finder</strong> allows you to discover routes between ski areas throughout Japan. Type ski area names, town or popular area names, and then select ski areas to choose your route. Add <strong>Waypoints</strong> if required. If you are in Japan and have location settings enabled, click on <strong>Use current location</strong> to use it as your <strong>Starting point</strong>. Learn more about Route Finder below.
                        {resort !== undefined &&
                            <a onClick={handleGoToMainRouteFinder} className='normal-link'> Click here for main Route Finder.</a>
                        }
                    </>
                }
                {generalMessageRouteFinder !== undefined && generalMessageRouteFinder.Title !== 'Y' &&
                    <>
                        <div className="sj-para">
                            <strong>SnowJapan Route Finder</strong> allows you to discover routes between ski areas throughout Japan.
                        </div>
                        <div className="sj-para sj-red-text">
                            Sorry, Route Finder is currently temporarily unavailable. Please try again later.
                        </div>
                    </>
                }
            </div>
            {generalMessageRouteFinder !== undefined && generalMessageRouteFinder.Title === 'Y' &&
                <>
                    <div className="sj-para">
                        <div className="sj-row padding-top">
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-3 p-0">
                                    <h5>Starting point [A]</h5>
                                </div>
                                <div className="col-md-6 sj-list-text-holder">
                                    <WayPointAutocomplete controlName='txtStartingPoint' skiAreaList={skiAreaList} initialValue={originPoint.name} readOnly={false} onRoutePointSelect={data => {
                                        setStartPoint(data);
                                    }} />
                                    {formerrors.currentLocation && (
                                        <div className="text-danger">{formerrors.currentLocation}</div>
                                    )}
                                </div>
                                <div className="col-md-3 sj-list-text-holder">
                                    <Button className='w-100' onClick={useCurrentLocation}><h6 className='mt-1 mb-1'>Use current location</h6></Button>
                                </div>
                            </div>
                        </div>
                        {wayPoints.map((wayPoint, index) => (
                            <div className="sj-row" key={index}>
                                <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                    <div className="col-md-3 p-0">
                                        <h5>Waypoint [{alphabetArray[index]}]</h5>
                                    </div>
                                    <div className="col-md-6 sj-list-text-holder">
                                        <WayPointAutocomplete controlName='txtRoutePoint' skiAreaList={skiAreaList} slNo={index} initialValue='' readOnly={false} onRoutePointSelect={data => {
                                            setWayPoint(data);
                                        }} />
                                    </div>
                                    <div className="col-md-3 sj-list-text-holder">
                                        <button type="button" class="btn-close mt-1" aria-label="Close" onClick={() => removeWayPoint(index)}></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="sj-row">
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-3 p-0">
                                    <h5>Destination point [{alphabetArray[wayPoints.length]}]</h5>
                                </div>
                                <div className="col-md-6 sj-list-text-holder">
                                    <WayPointAutocomplete controlName='txtDestinationPoint' skiAreaList={skiAreaList} readOnly={params.resort !== undefined} initialValue={destinationPoint.name} onRoutePointSelect={data => {
                                        setEndPoint(data);
                                    }} />
                                </div>
                                <div className="col-md-3">

                                </div>
                            </div>
                        </div>
                        <div className="sj-row">
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-3 p-0">

                                </div>
                                <div className="col-md-6 sj-list-text-holder">
                                    <Button type="button" className='w-100 calculate-route' onClick={calculateRoute} disabled={(originPoint.name != '' && destinationPoint.name != '') ? false : true}><h6 className='mt-1 mb-1'>Calculate route</h6></Button>
                                </div>
                                <div className="col-md-3">

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-3 p-0">

                                </div>
                                <div className="col-md-6 sj-row-no-margin-padding">
                                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                        <div className="col-md-6 sj-list-text-holder">
                                            <Button onClick={addWayPoint} className='w-100 sj-box-dark-blue'><h6 className='mt-1 mb-1'>Add waypoint</h6></Button>
                                        </div>
                                        <div className="col-md-6 sj-list-text-holder">
                                            <Button variant="primary" onClick={clearRoute} className='sj-large-font-button sj-red-bg w-100'><h6 className='mt-1 mb-1'>Clear route</h6></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">

                                </div>
                            </div>
                        </div>
                    </div>
                    {showMap &&
                        <div className='map-container'>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={12}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false
                                }}
                            >

                                {directionsResponse && <DirectionsRenderer directions={directionsResponse} panel={summaryPanel} />}
                            </GoogleMap>
                            <div id='directions-panel'></div>
                        </div>
                    }
                </>
            }
            <div className='sj-row pt-4'>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned">
                    <h4>About Route Finder</h4>
                </div>
                <div className='sj-row'>
                    <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Route-finder.svg" alt="Japan Ski Areas Route Finder" /></div>

                    <ul className='mt-2'>
                        <li>
                            SnowJapan Route Finder uses Google Maps technology.
                        </li>
                        <li>
                            SnowJapan does not control the maps, routes and information displayed on this page.
                        </li>
                        <li>
                            This functionality and resulting maps and data are provided for general planning and reference purposes.
                        </li>
                        <li>
                            Please research further if you intend to make a road trip.
                        </li>
                        <li>
                            In times of heavy usage, Route Finder functionality might be temporarily unavailable.
                        </li>
                        <li>
                            In such cases, please try again later.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    ) : <></>
}

export default RouteFinder;