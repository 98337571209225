import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import SkiAreaListTable from '../SkiArea/SkiAreaListTable'
import AccommodationListTable from '../Accommodation/AccommodationListTable'
import ListingListTable from '../Listing/ListingListTable'
import Loader from "../Common/Loader"

const GeneralSearch = () => {

    const params = useParams()

    const [skiAreaList, setSkiAreaList] = useState([])
    const [accommodationList, setAccommodationList] = useState([])
    const [listingsList, setListingsList] = useState([])
    const [guideList, setGuideList] = useState([])

    const fetchSearchedSkiArea = async (keyword) => {

        let areaIds = []

        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/search/${keyword}`
        }).then(dataSkiAreaList => {
            setSkiAreaList(dataSkiAreaList.data)
            const allAreaIds = [...new Set(dataSkiAreaList.data.map(item => item.MainArea_Id))]

            areaIds = allAreaIds.filter(function (currentElement) {
                return (currentElement);
            })
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/search/${keyword}`,
                data: { areaIds: areaIds }
            })
        }).then(dataAccommodationList => {
            setAccommodationList(dataAccommodationList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/listingGuideSection/listings/search/${keyword}`,
                data: { areaIds: areaIds }
            })
        }).then(dataListingsList => {
            setListingsList(dataListingsList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/listingGuideSection/guides/search/${keyword}`
            })
        }).then(dataGuidesList => {
            setGuideList(dataGuidesList.data)
        })
    };

    useEffect(() => {
        if (params.keyword !== undefined) {
            fetchSearchedSkiArea(params.keyword)
        }
    }, [params.keyword]);

    if (params.keyword !== undefined) {
        if (skiAreaList.length <= 0 && accommodationList.length <= 0) {
            return (
                <>
                    <div>
                        <h3>General Search</h3>
                    </div>
                    <div>
                        <Loader />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div>
                        <h3>General Search</h3>
                    </div>
                    {skiAreaList.length > 0 &&
                        <div className='mt-5'>
                            <div className="sj-box sj-box-blue sj-round-full center-aligned mt-3">
                                <h4>Ski Area Search Result for {params.keyword}</h4>
                            </div>
                            <SkiAreaListTable
                                tableData={skiAreaList}
                                headingColumns={['', 'Top', 'Bottom', 'Vertical', 'Steepest Slope', 'Ski Course', 'Ski Lifts', 'Reviews', 'Photos']}
                                dataMapping={['Name', 'MaximumElevation', 'MinimumElevation', 'VerticalDifference', 'SteepestSlope', 'TotalSkiCourses', 'TotalSkiLifts', 'NumberOfReviews', 'NumberOfPhotos']}
                                title=''
                            />
                        </div>
                    }
                    {accommodationList.length > 0 &&
                        <div className='mt-5'>
                            <div className="sj-box sj-box-blue sj-round-full center-aligned mt-3">
                                <h4>Accommodation Search Result for {params.keyword}</h4>
                            </div>
                            <AccommodationListTable
                                tableData={accommodationList}
                                headingColumns={['', '', 'Town', 'Prefecture']}
                                dataMapping={['AreaName', 'Name', 'TownName', 'PrefectureName']}
                                title=''
                            />
                        </div>
                    }
                    {listingsList.length > 0 &&
                        <div className='mt-5'>
                            <div className="sj-box sj-box-blue sj-round-full center-aligned mt-3">
                                <h4>Listing Search Result for {params.keyword}</h4>
                            </div>
                            <ListingListTable
                                tableData={listingsList}
                                headingColumns={['', '', 'Area', 'Prefecture']}
                                dataMapping={['a', 'Name', 'AreaName', 'PrefectureName']}
                                title=''
                            />
                        </div>
                    }
                    {guideList.length > 0 &&
                        <div className='mt-5'>
                            <h3>Guides</h3>
                            <div className='table-container'>
                                <table className='table-container__table sj-list-table sj-snow-table'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Section</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {guideList.map(guide => (
                                            <tr key={guide.Id}>
                                                <td>
                                                    <Link to={`${window.$baseGuideUrl}/${guide.SectionUniqueName}/${guide.UniqueName}`}>{guide.Name}</Link>
                                                </td>
                                                <td>{guide.SectionName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </>
            )
        }
    }
    else {
        return (
            <>
                <div>
                    <h1>General Search</h1>
                </div>
                <div>
                    <h1>Keyword cannot be blank</h1>
                </div>
            </>
        )
    }
}

export default GeneralSearch