import axios from 'axios'
import { useEffect, useState } from 'react'

const useSkiAreaPhotos = (queryPhoto, userName, pageNumberPhoto) => {

    const [loadingPhoto, setLoadingPhoto] = useState(false)
    const [errorPhoto, setErrorPhoto] = useState(false)
    const [photos, setPhotos] = useState([])
    const [hasMorePhoto, setHasMorePhoto] = useState(false)

    useEffect(() => {
        setPhotos([])
    }, [queryPhoto, userName])

    useEffect(() => {
        if (queryPhoto === '' && userName === '')
            return null

        setLoadingPhoto(true)
        setErrorPhoto(false)

        let cancelPhoto
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/photos/${pageNumberPhoto}`,
            data: {
                resortid: queryPhoto,
                username: userName
            },
            cancelToken: new axios.CancelToken(c => cancelPhoto = c)
        }).then(resPhoto => {
            if (resPhoto.data === 'No Data Found') {
                setHasMorePhoto(false)
                setLoadingPhoto(false)
                return () => cancelPhoto()
            }

            setPhotos(prevPhotos => {
                return [...new Set([...prevPhotos, ...resPhoto.data.map(p => p)])]
            })
            setHasMorePhoto(resPhoto.data.length > 0)
            setLoadingPhoto(false)
        }).catch(e => {
            if (axios.isCancel(e))
                return
            setErrorPhoto(true)
        })

        return () => cancelPhoto()

    }, [queryPhoto, pageNumberPhoto])

    return { loadingPhoto, errorPhoto, photos, hasMorePhoto }
};

export default useSkiAreaPhotos;