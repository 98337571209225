import { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import Home from './Home'
import Calendar from './Calendar'
import OfficialSnowDepth from './OfficialSnowDepth'
import Webcams from './Webcams'
import { GetCurrentSeasonName } from '../../helpers/Helper'


const DailyReportContainer = () => {

    const location = useLocation()

    console.log(location)

    const currentSeasonName = GetCurrentSeasonName();

    let requestType = undefined

    if (location.pathname.includes('calendar')) {
        requestType = 'calendar'
    }
    else if (location.pathname.includes('official-snow-depth')) {
        requestType = 'officialsnowdepth'
    }
    else if (location.pathname.includes('webcams')) {
        requestType = 'webcams'
    }

    return (
        <Fragment>
            <>
                {
                    {
                        undefined: <Home seasonName={currentSeasonName} />,
                        'calendar': <Calendar seasonName={currentSeasonName} />,
                        'officialsnowdepth': <OfficialSnowDepth />,
                        'webcams': <Webcams />
                    }[requestType]
                }
            </>
        </Fragment>
    )
}

export default DailyReportContainer