import axios from 'axios'
import { useState, useEffect } from 'react'
import Loader from '../../../Common/Loader'
import { RenderBreak } from '../../../../helpers/Helper'

const AboutDailyReport = ({ params }) => {

    const [guide, setGuide] = useState(undefined)

    const fetchGuides = async (guideUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/listingGuideSection/guides/${guideUniqueName}`
        }).then(resGuide => {
            setGuide(resGuide.data)
        })
    };

    useEffect(() => {
        fetchGuides('about-daily-reports')
    }, [params])

    if (guide !== undefined && guide !== null) {
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(guide.HtmlContent) }} />
            </>
        )
    } else {
        return (
            <Loader />
        )
    }
}

export default AboutDailyReport