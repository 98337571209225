import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Loader from "../../../Common/Loader"

const MonthlySnowfall = ({ monthlySnowfall }) => {

    const getSnowfallValue = (season) => {

        let monthlySnowfallData = [];

        let monthlyData = monthlySnowfall.filter(function (currentElement) {
            return (currentElement.Season === season);
        });

        let novemberData = monthlyData.filter(function (currentElement) {
            return (currentElement.NowReportMonthFull === 'November');
        });

        if (novemberData.length <= 0) {
            monthlySnowfallData.push(null)
        }

        monthlyData.forEach(function (dataItem) {
            monthlySnowfallData.push(dataItem.TotalSnow)
        })

        return monthlySnowfallData;
    }

    let monthlySnowfallDataSeries = [];
    let seasons = [...new Set(monthlySnowfall.map(item => item.Season))].sort().reverse();

    seasons.forEach(function (item) {
        if (item > '2011-2012') {
            monthlySnowfallDataSeries.push({
                'name': item,
                'data': getSnowfallValue(item),
                'visible': true
            })
        }
    })

    const options = {
        colors: ["#0000CC", "#0033CC", "#3366CC", "#3399FF", "#000066", "#990000", "#CC0000", "#FF0066", "#660033", "#8904B1", "#FE9A2E", "#DF7401"],
        chart: {
            /*renderTo: 'newsnowfall-season',*/
            type: 'column',
            backgroundColor: "#efefef",
            style: {
                fontFamily: 'Lato'
            },
            borderWidth: 1,
            borderColor: "#dedede"
        },
        title: {
            text: 'Monthly observed snowfall at base',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat'
            }
        },
        legend: {
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        },
        xAxis: {
            categories: [
                'Nov',
                'Dec',
                'Jan',
                'Feb',
                'Mar',
                'Apr'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Daily observed snowfall (cm)',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            },
            minorTickInterval: 'auto',
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:12px;font-weight:bold;">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0;text-align:right;"><b>{point.y} cm</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            backgroundColor: 'rgba(255,255,255,0.8)',
            shadow: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            candlestick: {
                lineColor: '#404048'
            }
        },
        // General
        background2: '#F0F0EA',
        exporting:
        {
            enabled: false
        },
        series: monthlySnowfallDataSeries,
        credits:
        {
            enabled: false
        }
    };

    if (monthlySnowfall.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='sj-para'>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <div className='center-aligned sj-box-lighter-gray p-2'>
                    <h6>Click on each season to add or remove data from other seasons</h6>
                </div>
            </div>
        )
    }
};

export default MonthlySnowfall;