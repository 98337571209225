import { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SeasonSnowChart = ({ resortName, snowData, season, chartOptions, index }) => {

  const [resortWeatherSnowDataSeries, setResortWeatherSnowDataSeries] = useState([])
  const chartColors = ["#0000CC", "#0033CC", "#3366CC", "#3399FF", "#000066", "#990000", "#CC0000", "#FF0066", "#660033", "#8904B1", "#FE9A2E", "#DF7401"]

  const getSnowDataValue = (thisSeason) => {

    let dailySnowData = [];

    let dailyData = snowData.filter(function (currentElement) {
      return (currentElement.Season === thisSeason && currentElement.SnowDepth !== 999);
    });

    dailyData.sort(function (a, b) {
      var dateA = new Date(a.CreatedAt), dateB = new Date(b.CreatedAt)
      return dateA - dateB
    })

    dailyData.forEach(function (dataItem) {
      let valueCounter = 0;

      if (dataItem.WeatherMonth < 6) {
        valueCounter = 1
      }

      dailySnowData.push([Date.UTC(1969 + valueCounter, Number(dataItem.WeatherMonth) - 1, dataItem.WeatherDay), dataItem.SnowDepth])
    })

    return dailySnowData
  }

  const GetResortWeatherSnowDataSeries = (thisSeason) => {

    let thisResortWeatherSnowDataSeries = [];

    thisResortWeatherSnowDataSeries.push({
      'name': thisSeason,
      'data': getSnowDataValue(thisSeason)
    })

    setResortWeatherSnowDataSeries(thisResortWeatherSnowDataSeries)
  }

  useEffect(() => {
    GetResortWeatherSnowDataSeries(season)
  }, [season, snowData]);

  const thisOptions = { ...chartOptions, series: resortWeatherSnowDataSeries, colors: [chartColors[index]] };

  return (
    <div>
      <div className="sj-box sj-box-dark-gray sj-round-half center-aligned mt-5">
        <h4>{season} Season snow depth data ({resortName})</h4>
      </div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={thisOptions} />
      </div>
    </div>
  );
}

export default SeasonSnowChart;