import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import Loader from "../Common/Loader"
import ListingListTable from './ListingListTable';

const ListingList = ({ listingList }) => {

  const [listings, setListings] = useState([])
  const [buttonTitle, setButtonTitle] = useState('Sort By: Listing name [A-Z]')
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('')

  useEffect(() => {
    setListings(listingList)
  }, [listingList])

  const SortResult = (evtKey, evt) => {

    var sortedResult = []
    var sortColumn = ''
    var sortOrder = ''

    if (evtKey === '1' || evtKey === '2') {
      sortedResult = listings.sort(function (a, b) {
        let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();

        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
      })
      sortOrder = "ASC"

      if (evtKey === '2') {
        sortedResult = sortedResult.reverse()
        sortOrder = "DESC"
      }

      sortColumn = "Listing name"

    } else if (evtKey === '3' || evtKey === '4') {
      sortedResult = listings.sort(function (a, b) {
        let nameA = a.AreaName.toLowerCase(), nameB = b.AreaName.toLowerCase();

        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }

        if (a.Name > b.Name) return 1;
        if (a.Name < b.Name) return -1;

        return 0;
      })
      sortOrder = "ASC"

      if (evtKey === '4') {
        sortedResult = sortedResult.reverse()
        sortOrder = "DESC"
      }

      sortColumn = "Area"

    } else if (evtKey === '5' || evtKey === '6') {
      sortedResult = listings.sort(function (a, b) {
        let nameA = a.PrefectureName.toLowerCase(), nameB = b.PrefectureName.toLowerCase();

        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }

        if (a.Name > b.Name) return 1;
        if (a.Name < b.Name) return -1;

        return 0;
      })
      sortOrder = "ASC"

      if (evtKey === '6') {
        sortedResult = sortedResult.reverse()
        sortOrder = "DESC"
      }

      sortColumn = "Prefecture"
    }

    setListings(sortedResult)
    setButtonTitle('Sort by: ' + evt.target.innerText)
    setSortColumn(sortColumn)
    setSortOrder(sortOrder)
  }

  if (listings.length > 0) {
    return (
      <>
        <div className="empty-row right-aligned">
          <DropdownButton align="end" title={buttonTitle} onSelect={SortResult} id="dropdown-menu-align-end" className='sj-large-font-button'>
            <Dropdown.Item eventKey="1">Listing name [A-Z]</Dropdown.Item>
            <Dropdown.Item eventKey="2">Listing name [Z-A]</Dropdown.Item>
            <Dropdown.Item eventKey="3">Area name [A-Z]</Dropdown.Item>
            <Dropdown.Item eventKey="4">Area name [Z-A]</Dropdown.Item>
            <Dropdown.Item eventKey="5">Prefecture name [A-Z]</Dropdown.Item>
            <Dropdown.Item eventKey="6">Prefecture name [Z-A]</Dropdown.Item>
          </DropdownButton>
        </div>
        <ListingListTable
          tableData={listings}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          headingColumns={['', 'Listing name', 'Area', 'Prefecture']}
          dataMapping={['a', 'Name', 'AreaName', 'PrefectureName']}
          title=''
        />
      </>
    )
  } else {
    return (
      <Loader />
    )
  }
};

export default ListingList;