import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import MemberHome from './Home'
import MemberReviews from './MemberReviews'
import MemberSubmitReview from './MemberSubmitReview'
import MemberPhotos from './MemberPhotos'
import MemberUploadPhoto from './MemberUploadPhoto'
import { useAuth } from '../CustomHooks/useAuth'

const MemberContainer = () => {

    const params = useParams()
    const { setAuth, auth, setUser, user } = useAuth()
    const navigate = useNavigate()

    if (!sessionStorage.getItem('sessionUser') || sessionStorage.getItem('sessionUser') === null || sessionStorage.getItem('sessionUser') === 'null') {
        setAuth(false)
        navigate('/login')
        console.log("b")
    }

    console.log(auth)

    if (auth === null) {
        navigate(window.$baseAppUrl)
        console.log("a")
        navigate('/')
    }
    const thisSessionUser = JSON.parse(sessionStorage.getItem('sessionUser'))
    console.log(thisSessionUser)

    const [skiAreaList, setSkiAreaList] = useState([])

    /*const checkLogin = async () => {
        if (auth === null || !auth) {
            const thisSessionUser = JSON.parse(sessionStorage.getItem('sessionUser'))

            try {
                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/Account/CheckLoginStatus`,
                    data: {
                        FullName: thisSessionUser.FullName,
                        Email: thisSessionUser.Email,
                        Token: thisSessionUser.Token
                    }
                }).then(loginStatusResult => {
                    let loggedInUser = loginStatusResult.data
                    if (loggedInUser.UserName !== null && loggedInUser.UserName !== '') {
                        sessionStorage.setItem('sessionUser', JSON.stringify(loggedInUser))
                        setAuth(true)
                        setUser(loggedInUser)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect((thisAuth, thisUser, localSessionUser) => {

    }, [auth, user, thisSessionUser]);*/

    const fetchSkiAreas = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/home/list`
        }).then(dataResortList => {
            setSkiAreaList(dataResortList.data)
        })
    };

    useEffect(() => {
        fetchSkiAreas()
        /*if (auth && user == null) {
            if (thisSessionUser == null) {
                checkLogin()
            }
            else {
                setUser(thisSessionUser)
            }
        }*/
    }, []);

    return (
        <Fragment>
            <>
                {
                    {
                        undefined: <MemberHome user={thisSessionUser} />,
                        'reviews': <MemberReviews user={thisSessionUser} />,
                        'submit-review': <MemberSubmitReview user={thisSessionUser} skiAreaList={skiAreaList} />,
                        'photos': <MemberPhotos user={thisSessionUser} />,
                        'upload-photo': <MemberUploadPhoto user={thisSessionUser} skiAreaList={skiAreaList} />
                    }[params.type]
                }
            </>
        </Fragment>
    )
}

export default MemberContainer