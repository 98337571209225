import MonthlyReportsListingContainer from './MonthlyReportsListingContainer'
import DetailDailyReport from '../Common/DetailDailyReport'

const ArchivesContainer = ({ params, dailyReport, nowSeasons }) => {
    return (
        <div>
            {params.season === undefined
                ? <MonthlyReportsListingContainer dailyReport={dailyReport} />
                : <DetailDailyReport dailyReport={dailyReport} params={params} nowSeasons={nowSeasons} isArchive={true} />
            }
        </div>
    )
}

export default ArchivesContainer