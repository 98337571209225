import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

const SeasonReportCalendar = ({ dailyReport, nowYear, nowMonth, dailySnowfallMonthlyList }) => {

  //const history = useHistory();
  const navigate = useNavigate();

  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [date, setDate] = useState(new Date(nowYear, nowMonth, 1));
  const [month, setMonth] = useState(date.getMonth());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    setMonth(date.getMonth());
    setStartDay(getStartDayOfMonth(date));
  }, [nowYear, nowMonth, date]);

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const getDayOrdinal = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  const getDailySnowfall = (calDay) => {

    let dailySnowfall = undefined

    let dailySnowfallData = dailySnowfallMonthlyList.filter(function (currentElement) {
      return (currentElement.NowReportDay === calDay);
    });

    if (dailySnowfallData[0] !== undefined) {
      dailySnowfall = dailySnowfallData[0].NewSnowfallResortA
    }

    return dailySnowfall
  }

  const getReportCSS = (snowfall) => {

    let snowfallCSS = ' with-report-individual-0'

    if (snowfall > 0 && snowfall < 10) {
      snowfallCSS = ' with-report-individual-9'
    } else if (snowfall > 9 && snowfall < 20) {
      snowfallCSS = ' with-report-individual-19'
    } else if (snowfall > 19 && snowfall < 50) {
      snowfallCSS = ' with-report-individual-49'
    } else if (snowfall > 49) {
      snowfallCSS = ' with-report-individual-50'
    }

    return snowfallCSS
  }

  const goToDetail = (d) => {
    const archiveUrl = `${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/archives/${d}${getDayOrdinal(d)}-${MONTHS[month]}-${nowYear}`

    //history.push(archiveUrl)
    navigate(archiveUrl);
  }

  const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;

  return (
    <div className='now-calendar-frame'>
      <div className='now-calendar-frame-header'>
        <div className='center-aligned'>
          <h4>{MONTHS[month]} {nowYear}</h4>
        </div>
      </div>
      <div className='now-calendar-frame-body'>
        {DAYS_OF_THE_WEEK.map((d) => (
          <div key={`${d}-${nowMonth}-${nowYear}`} className='now-calendar-day-header'>
            <strong>{d}</strong>
          </div>
        ))}
        {Array(days[month] + (startDay - 1)).fill(null).map((_, index) => {
          const d = index - (startDay - 2);
          var dayClassName = ''
          var dailySnowfall = ''


          if (d <= 0) {
            dayClassName = ' empty-date'
          } else {
            dayClassName = ' with-date-individual'
            dailySnowfall = getDailySnowfall(d)
            if (dailySnowfall !== undefined) {
              dayClassName = dayClassName + getReportCSS(dailySnowfall)
            }
          }

          return (
            <>
              {d <= 0 &&
                <div className={dayClassName} key={index}></div>
              }
              {(d > 0 && dailySnowfall !== undefined) &&
                <div className={dayClassName} key={index} onClick={() => goToDetail(d)}>
                  <div className='calendar-date-container'>{d}</div>
                  <div className='calendar-snow-container'>{dailySnowfall}cm</div>
                </div>
              }
              {(d > 0 && dailySnowfall === undefined) &&
                <div className={dayClassName} key={index}>
                  {d}
                </div>
              }
            </>
          );
        })}
      </div>
    </div>
  );
}

export default SeasonReportCalendar;