import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RenderBreak } from '../../helpers/Helper'
import Loader from '../Common/Loader'

const CommonPage = () => {

    const params = useParams()

    const [guide, setGuide] = useState(undefined);

    const fetchGuides = async (guideUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/listingGuideSection/guides/${guideUniqueName}`
        }).then(resGuide => {
            setGuide(resGuide.data)
        })
    };

    useEffect(() => {
        if (params !== undefined && params.type !== undefined) {
            let commonPageNmae = params.type

            if (commonPageNmae === 'about-snowjapan' || commonPageNmae === 'about-snowjapan-japanese' || commonPageNmae === 'about-daily-reports' || commonPageNmae === 'about-information-and-updates' || commonPageNmae === 'about-members-area') {
                fetchGuides(commonPageNmae)
            } else {
                window.location.href = "https://www.snowjapan.com/404.html";
            }
        }
    }, [params.type]);

    if (guide !== undefined && guide !== null) {
        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(guide.HtmlContent) }} />
            </>
        )
    } else {
        return (
            <Loader />
        )
    }
}

export default CommonPage