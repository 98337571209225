import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios'

const AuthContext = createContext({
    auth: null,
    setAuth: () => { },
    user: null,
    setUser: () => { }
});


export function useAuth() {
    return useContext(AuthContext);
}

//const AuthProvider = (children) => {
export function AuthProvider({ children }) {
    const [auth, setAuth] = useState(null);
    const [user, setUser] = useState(null);

    const checkLogin = async () => {
        if (auth === null || !auth) {
            const thisSessionUser = JSON.parse(sessionStorage.getItem('sessionUser'))
            if (thisSessionUser !== null) {
                try {
                    axios({
                        method: 'POST',
                        url: `${window.$baseSJAPIUrl}/Account/CheckLoginStatus`,
                        data: {
                            FullName: thisSessionUser.FullName,
                            Email: thisSessionUser.Email,
                            Token: thisSessionUser.Token
                        }
                    }).then(loginStatusResult => {
                        let loggedInUser = loginStatusResult.data
                        if (loggedInUser.UserName !== null && loggedInUser.UserName !== '') {
                            sessionStorage.setItem('sessionUser', JSON.stringify(loggedInUser))
                            setAuth(true)
                            setUser(loggedInUser)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }

    useEffect(() => {
        checkLogin()
    }, [auth, user]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

//export default AuthProvider;