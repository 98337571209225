import { useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Loader from "../../../Common/Loader"

const DailyObservedSnowfall = ({ season, dailySnowfallList }) => {

  const chartRef = useRef()

  const getSnowDataValue = () => {

    let dailySnowData = {}
    let dailySnowfallData = []
    let accumulatedSnowData = []
    let dailyAccumulatedSnow = 0

    let sortedDailySnowfallList = dailySnowfallList.sort(function (a, b) {
      if (a.Id < b.Id) { return -1; }
      if (a.Id > b.Id) { return 1; }

      return 0;
    })


    sortedDailySnowfallList.forEach(function (dataItem) {
      let valueCounter = 0;

      if (dataItem.NowReportMonth < 6) {
        valueCounter = 1
      }

      dailySnowfallData.push([Date.UTC(1969 + valueCounter, Number(dataItem.NowReportMonth) - 1, dataItem.NowReportDay), dataItem.NewSnowfallResortA])

      dailyAccumulatedSnow = dailyAccumulatedSnow + dataItem.NewSnowfallResortA

      accumulatedSnowData.push([Date.UTC(1969 + valueCounter, Number(dataItem.NowReportMonth) - 1, dataItem.NowReportDay), dailyAccumulatedSnow])
    })

    dailySnowData = { ...dailySnowData, 'dailySnowfallData': dailySnowfallData }
    dailySnowData = { ...dailySnowData, 'accumulatedSnowData': accumulatedSnowData }

    return dailySnowData
  }

  var thisDailySnowfallDataSeries = [];

  thisDailySnowfallDataSeries.push({
    'name': season,
    'data': getSnowDataValue().dailySnowfallData
  })

  const options = {
    //colors: "#0000CC".split(","),
    colors: ["#0000CC"],
    chart: {
      type: 'column',
      zoomType: 'x',
      backgroundColor: "#efefef",
      borderWidth: 1,
      borderColor: "#dedede",
      style: {
        fontFamily: 'Lato'
      }
    },
    title: {
      text: 'Daily observed snowfall at base',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat'
      }
    },
    subtitle: {
      text: 'Drag a section of the graph to zoom in',
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'Lato'
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
        month: '%b %e ', year: '%b'
      },
      title: {
        text: '',
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat'
        }
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },
    yAxis: {
      title: {
        text: 'Observed snowfall at base (cm)',
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
          fontFamily: 'Montserrat'
        }
      },
      min: 0
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%b %e}: {point.y}cm'
    },
    exporting:
    {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: true
        }
      }
    },
    series: thisDailySnowfallDataSeries,
    credits:
    {
      enabled: false
    }
  };

  const ShowAccumSnowChart = () => {
    if (chartRef.current.chart !== undefined) {
      var accumulatedSnowDataSeries = [];

      accumulatedSnowDataSeries.push({
        'name': season,
        'data': getSnowDataValue().accumulatedSnowData
      })

      chartRef.current.chart.update({
        title: {
          text: 'Observed accumulated snowfall'
        },
        yAxis: {
          title: {
            text: 'Observed accumulated snowfall (cm)'
          },
          min: 0
        },
        series: accumulatedSnowDataSeries
      });
    }
  }

  const ShowSnowfallChart = () => {
    if (chartRef.current.chart !== undefined) {

      var dailySnowfallDataSeries = [];

      dailySnowfallDataSeries.push({
        'name': season,
        'data': getSnowDataValue().dailySnowfallData
      })

      chartRef.current.chart.update({
        title: {
          text: 'Daily observed snowfall at base'
        },
        yAxis: {
          title: {
            text: 'Observed snowfall at base (cm)'
          },
          min: 0
        },
        series: dailySnowfallDataSeries
      });
    }
  }

  if (dailySnowfallList.length <= 0) {
    return (
      <Loader />
    )
  }
  else {
    return (
      <div>
        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-4">
          <h4>Daily observed snowfall at base</h4>
        </div>
        <h3 className='center-aligned space-above'>{season} season</h3>
        <div className='row sj-row-no-margin-padding sj-no-bg center-aligned'>
          <div className='col-md-6 sj-row-no-margin-padding sj-padding-right-10'>
            <button className='btn btn-primary sj-large-button-blue sj-full-width' onClick={ShowSnowfallChart}>Show snowfall</button>
          </div>
          <div className='col-md-6 sj-row-no-margin-padding sj-padding-left-10'>
            <button className='btn btn-primary sj-large-button-blue sj-full-width' onClick={ShowAccumSnowChart}>Show accumulated snowfall</button>
          </div>
        </div>
        <div className='mt-4'>
          <HighchartsReact highcharts={Highcharts} options={options} allowChartUpdate={true} ref={chartRef} />
        </div>
      </div>
    );
  }
}

export default DailyObservedSnowfall;