import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './components/CustomHooks/useAuth'
import 'bootstrap/dist/css/bootstrap.css'
import './App.scss'

//Misc Section
import ScrollToTop from './ScrollToTop'
import Home from './components/Common/Home'
import Login from './components/Common/Login'
import Register from './components/Common/Register'
import ConfirmEmail from './components/Common/ConfirmEmail'
import ForgotPassword from './components/Common/ForgotPassword'
import ResetPassword from './components/Common/ResetPassword'
import Contact from './components/Common/Contact'
import RouteFinder from './components/Common/RouteFinder'
import CommonPage from './components/Common/CommonPage'
import GeneralSearch from './components/Common/GeneralSearch'
import Header from './components/Common/Header'
import MainNav from './components/Common/MainNav'
import SearchMemberBar from './components/Common/SearchMemberBar'
import Footer from './components/Common/Footer'

// Ski Area Section
import SkiAreaContainer from './components/SkiArea/SkiAreaContainer'
import SkiAreaList from './components/SkiArea/SkiAreaList'
import SkiAreaDetailContainer from './components/SkiArea/SkiAreaDetail/SkiAreaDetailContainer'

// Daily Reports Section
import DailyReportContainer from './components/DailyReport/DailyReportContainer'
import DetailContainer from './components/DailyReport/Detail/DetailContainer'

// Accommodation Section
import AccommodationContainer from './components/Accommodation/AccommodationContainer'
import AccommodationDetailContainer from './components/Accommodation/AccommodationDetail/AccommodationDetailContainer'

// Listing Section
import ListingContainer from './components/Listing/ListingContainer'

// Guide Section
import GuideContainer from './components/Guide/GuideContainer'

// Member Section
import MemberContainer from './components/Member/MemberContainer'

const App = () => {
  return (
    <Router>
      <div className='App'>

        <ScrollToTop />
        <React.StrictMode>
          <AuthProvider>
            <Header />
            <MainNav />
            <SearchMemberBar />
            <div className='sj-content-holder'>
              <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/register' exact element={<Register />} />
                <Route path='/confirm-email' exact element={<ConfirmEmail />} />
                <Route path='/login' exact element={<Login />} />
                <Route path='/forgot-password' exact element={<ForgotPassword />} />
                <Route path='/reset-password' exact element={<ResetPassword />} />
                <Route path='/contact' exact element={<Contact />} />
                <Route path={`${window.$baseRouteFinderUrl}/:resort?`} element={<RouteFinder />} />

                <Route path={`${window.$baseSkiAreaUrl}/prefecture/:key`} element={<SkiAreaList />} />
                <Route path={`${window.$baseSkiAreaUrl}/town/:key`} element={<SkiAreaList />} />
                <Route path={`${window.$baseSkiAreaUrl}/area/:key`} element={<SkiAreaList />} />
                <Route path={`${window.$baseSkiAreaUrl}/:prefecture/:town/:resort/:type?`} element={<SkiAreaDetailContainer />} />
                <Route path={`${window.$baseSkiAreaUrl}/:type?`} element={<SkiAreaContainer />} />

                <Route path={`${window.$baseDailyReportUrl}`} exact element={<DailyReportContainer />} />
                <Route path={`${window.$baseDailyReportUrl}/calendar`} exact element={<DailyReportContainer />} />
                <Route path={`${window.$baseDailyReportUrl}/official-snow-depth`} exact element={<DailyReportContainer />} />
                {/*<Route path={`${window.$baseDailyReportUrl}/about-daily-reports`} exact component={DailyReportCommonPage} />*/}
                <Route path={`${window.$baseDailyReportUrl}/webcams/:type?`} exact element={<DailyReportContainer />} />
                <Route path={`${window.$baseDailyReportUrl}/:now/:type?/:season?`} element={<DetailContainer />} />

                <Route path={`${window.$baseAccommodationUrl}/:prefecture/:town/:hotel/:type?`} element={<AccommodationDetailContainer />} />
                <Route path={`${window.$baseAccommodationUrl}`} element={<AccommodationContainer />} />

                <Route path={`${window.$baseListingUrl}/:section_name/:listing_name`} element={<ListingContainer />} />
                <Route path={`${window.$baseListingUrl}/:section_name`} element={<ListingContainer />} />
                <Route path={`${window.$baseListingUrl}/`} element={<ListingContainer />} />

                <Route path={`${window.$baseGuideUrl}/`} exact element={<GuideContainer />} />
                <Route path={`${window.$baseGuideUrl}/:section_name/:guide_name?`} element={<GuideContainer />} />

                <Route path={`${window.$baseMemberUrl}/:type?/:resort_unique?`} element={<MemberContainer />} />

                <Route path='/search/:keyword?' exact element={<GeneralSearch />} />

                <Route path='/:type' element={<CommonPage />} />
              </Routes>
            </div>
            <Footer />
          </AuthProvider>
        </React.StrictMode>
      </div>
    </Router >
  );
}

export default App;
