import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import ResortAutocomplete from './ResortAutocomplete'

const MemberSubmitReview = ({ user, skiAreaList }) => {

    const params = useParams()
    const navigate = useNavigate()

    /*const headlineRef = useRef()
    const dateVisitedRef = useRef()
    const snowConditionOnVisitRef = useRef()
    const reviewTextRef = useRef()
    const thingsILikedRef = useRef()
    const thingsIDidnotLikeRef = useRef()
    const errRef = useRef()*/

    const [resortUniqueNameFromParam, setResortUniqueNameFromParam] = useState('')
    const [thisResort, setThisResort] = useState(undefined)
    const [resortUniqueName, setResortUniqueName] = useState('')
    /*const [validResortUniqueName, setValidResortUniqueName] = useState(true)
    //const [resortUniqueNameFocus, setResortUniqueNameFocus] = useState(false)

    const [headline, setHeadline] = useState('')
    const [validHeadline, setValidHeadline] = useState(true)
    const [headlineFocus, setHeadlineFocus] = useState(false)

    const [snowConditionOnVisit, setSnowConditionOnVisit] = useState('')
    const [validSnowConditionOnVisit, setValidSnowConditionOnVisit] = useState(false)
    const [snowConditionOnVisitFocus, setSnowConditionOnVisitFocus] = useState(false)

    const [reviewText, setReviewText] = useState('')
    const [validReviewText, setValidReviewText] = useState(false)
    const [reviewTextFocus, setReviewTextFocus] = useState(false)

    const [thingsILiked, setThingsILiked] = useState('')
    const [validThingsILiked, setValidThingsILiked] = useState(false)
    const [thingsILikedFocus, setThingsILikedFocus] = useState(false)

    const [thingsIDidnotLike, setThingsIDidnotLike] = useState('')
    const [validThingsIDidnotLike, setValidThingsIDidnotLike] = useState(false)
    const [thingsIDidnotLikeFocus, setThingsIDidnotLikeFocus] = useState(false)

    const [dateVisited, setDateVisited] = useState('')
    const [validDateVisited, setValidDateVisited] = useState(true)
    const [dateVisitedFocus, setDateVisitedFocus] = useState(false)

    const [success, setSuccess] = useState(false)*/
    const [errMsg, setErrMsg] = useState('')
    const [formerrors, setFormErrors] = useState({})

    const [values, setValues] = useState({
        Headline: "",
        SnowConditionOnVisit: "",
        ReviewText: "",
        ThingsILiked: "",
        ThingsIDidnotLike: "",
        DateVisited: ""
    });

    useEffect(() => {
        if (params !== undefined && params.resort_unique !== undefined) {
            setResortUniqueNameFromParam(params.resort_unique)
            setResortUniqueName(params.resort_unique)
        }
    }, [params.resort_unique]);

    useEffect(() => {
        let passedResort = skiAreaList.filter(function (currentElement) {
            return (currentElement.UniqueName === params.resort_unique);
        });

        if (passedResort !== undefined && passedResort[0] !== undefined) {
            setThisResort(passedResort[0])
        }
    }, [skiAreaList, params.resort_unique])

    /*useEffect(() => {
        headlineRef.current.focus()
    }, [])

    useEffect(() => {
        setValidResortUniqueName(resortUniqueName !== undefined && resortUniqueName !== '')
    }, [resortUniqueName])

    useEffect(() => {
        setValidHeadline(headline !== undefined && headline !== '')
    }, [headline])

    useEffect(() => {
        setValidDateVisited(dateVisited !== undefined && dateVisited !== '')
    }, [dateVisited])

    useEffect(() => {
        setValidSnowConditionOnVisit(snowConditionOnVisit !== undefined && snowConditionOnVisit !== '')
    }, [snowConditionOnVisit])

    useEffect(() => {
        setValidReviewText(reviewText !== undefined && reviewText !== '')
    }, [reviewText])

    useEffect(() => {
        setValidThingsILiked(thingsILiked !== undefined && thingsILiked !== '')
    }, [thingsILiked])

    useEffect(() => {
        setValidThingsIDidnotLike(thingsIDidnotLike !== undefined && thingsIDidnotLike !== '')
    }, [thingsIDidnotLike])

    useEffect(() => {
        setErrMsg('')
    }, [headline, snowConditionOnVisit, reviewText, thingsILiked, thingsIDidnotLike])

    const handleChange = (value, selectOptionSetter) => {
        selectOptionSetter(value)
    }*/

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const validate = () => {
        let errors = {}

        if (!values.Headline) {
            errors.Headline = "The headline field cannot be blank."
        }

        if (!values.SnowConditionOnVisit) {
            errors.SnowConditionOnVisit = "The snow condition on visit field cannot be blank."
        }

        if (!values.ReviewText) {
            errors.ReviewText = "The review text field cannot be blank."
        }

        if (!values.ThingsILiked) {
            errors.ThingsILiked = "The headline field cannot be blank."
        }

        if (!values.ThingsIDidnotLike) {
            errors.ThingsIDidnotLike = "The things I liked field cannot be blank."
        }

        /*if (!values.DateVisited) {
            errors.DateVisited = "The cate of visit field cannot be blank."
        }*/

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate(values)) {
            try {
                var inputData = {
                    Headline: values.Headline,
                    SnowConditionOnVisit: values.SnowConditionOnVisit,
                    ReviewText: values.ReviewText,
                    ThingsILiked: values.ThingsILiked,
                    ThingsIDidnotLike: values.ThingsIDidnotLike,
                    ResortRanking: 0,
                    UserType: "User Type",
                    ResortUniqueName: resortUniqueName,
                    DateVisited: values.DateVisited,
                    Email: user.Email,
                    Token: user.Token
                }
                console.log(inputData)

                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/sjmember/SubmitReview`,
                    data: inputData
                }).then(reviewSubmissionResult => {

                    console.log(reviewSubmissionResult?.data);

                    let reviewSubmission = reviewSubmissionResult.data

                    if (reviewSubmission !== null) {
                        console.log(reviewSubmission)

                        let path = window.$baseMemberUrl + '/reviews';
                        navigate(path);
                    } else {
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                }
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Write a ski area review | Member area | SnowJapan</title>
                <meta name="description" content="Write a ski area review | Member area | SnowJapan" />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Reviews.svg" alt='submit-review' /></div>
                <div>
                    <h3>Write a new ski area review</h3>
                    <div className="sj-para">
                        Please note that all submissions will be reviewed before appearing on the site. We reserve the right to decline content that we feel is inappropriate. Before submitting content to SnowJapan and for full details, <Link to={`${window.$baseAppUrl}/about-members-area`}>please read this</Link>.
                    </div>
                    <div className='sj-row'>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3 sj-no-margin">
                                {resortUniqueNameFromParam === '' &&
                                    <ResortAutocomplete skiAreaList={skiAreaList} onResortSelect={data => {
                                        setResortUniqueName(data)
                                    }} />
                                }
                                {(resortUniqueNameFromParam !== '' && thisResort !== undefined) &&
                                    <Form.Group controlId="txtResortNameLabel" className="mt-2 mb-4">
                                        <Form.Label><h5>Ski area:</h5></Form.Label>
                                        <Form.Control name="txtResortNameLabel" type="text" placeholder="Caption" value={thisResort.Name} readOnly="true" />
                                    </Form.Group>
                                }
                                <Form.Group controlId="txtCaption" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Headline:</h5>
                                        A simple description/summary to display above the review.<br />
                                        Please limit to 100 words.
                                    </Form.Label>
                                    <Form.Control name="txtHeadline" type="text" placeholder="Headline" value={values.Headline} onChange={handleChange} />
                                    {formerrors.Headline && (
                                        <div className="text-danger">{formerrors.Headline}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtDateVisited" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Date of visit:</h5>
                                        Enter a specific date or a more general description of when you visited the ski area.
                                    </Form.Label>
                                    <Form.Control name="txtDateVisited" type="text" placeholder="Date of visit" value={values.DateVisited} onChange={handleChange} />
                                    {formerrors.DateVisited && (
                                        <div className="text-danger">{formerrors.DateVisited}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtSnowConditionOnVisit" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Snow conditions:</h5>
                                        Summarise the snow conditions when you visited the ski area.
                                    </Form.Label>
                                    <Form.Control name="txtSnowConditionOnVisit" as="textarea" rows={3} placeholder="Snow conditions" value={values.SnowConditionOnVisit} onChange={handleChange} />
                                    {formerrors.SnowConditionOnVisit && (
                                        <div className="text-danger">{formerrors.SnowConditionOnVisit}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtReviewText" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Review:</h5>
                                        Write your main review of the ski area here.
                                    </Form.Label>
                                    <Form.Control name="txtReviewText" as="textarea" rows={12} placeholder="Review" value={values.ReviewText} onChange={handleChange} />
                                    {formerrors.ReviewText && (
                                        <div className="text-danger">{formerrors.ReviewText}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtThingsILiked" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Things I liked:</h5>
                                        Summarise what you liked about your visit to the ski area.
                                    </Form.Label>
                                    <Form.Control name="txtThingsILiked" as="textarea" rows={3} placeholder="Things I liked" value={values.ThingsILiked} onChange={handleChange} />
                                    {formerrors.ThingsILiked && (
                                        <div className="text-danger">{formerrors.ThingsILiked}</div>
                                    )}
                                </Form.Group>
                                <Form.Group controlId="txtThingsIDidnotLike" className="mt-2 mb-4">
                                    <Form.Label>
                                        <h5>Things I didn’t like:</h5>
                                        Summarise what you did not like about your visit to the ski area.
                                    </Form.Label>
                                    <Form.Control name="txtThingsIDidnotLike" as="textarea" rows={3} placeholder="Things I didn’t like" value={values.ThingsIDidnotLike} onChange={handleChange} />
                                    {formerrors.ThingsIDidnotLike && (
                                        <div className="text-danger">{formerrors.ThingsIDidnotLike}</div>
                                    )}
                                </Form.Group>
                                <div className='center-aligned'>
                                    <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Submit review</Button>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberSubmitReview