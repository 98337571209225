/* eslint-disable */
import './../../AuthenticationForm.css'
import { useState, useEffect } from "react"
import { useSearchParams, useParams } from 'react-router-dom'
import axios from 'axios'

//`${window.$baseSJAPIUrl}/api/Account/Register`

const ConfirmEmail = () => {

    const params = useParams()

    const [errMsg, setErrMsg] = useState('')
    const [statusCode, setStatusCode] = useState('')
    const [success, setSuccess] = useState(false)
    const [nonSuccessMessage, setNonSuccessMessage] = useState('Please wait while we verify your email.')
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token') || ''

    const HandleConfirmEmail = (thisToken) => {
        console.log(thisToken)
        try {
            axios({
                method: 'POST',
                url: `${window.$baseSJAPIUrl}/Account/ConfirmEmail`,
                data: {
                    Token: thisToken
                }
            }).then(confirmEmailResult => {
                console.log(confirmEmailResult);
                if (confirmEmailResult.data.ResultType === 'SUCCESS') {
                    setSuccess(true)
                    setErrMsg('')
                } else {
                    setErrMsg(confirmEmailResult.data.Message)
                    setStatusCode(confirmEmailResult.data.StatusCode)
                }
            })
        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('No server response');
            }
        }
    }

    useEffect(() => {
        HandleConfirmEmail(token);
    }, [token])

    return (
        <>
            {success ? (
                <section>
                    <p>Email conifirmation successful. Please use the link below to sign-in.</p>
                    <p>
                        <a href="/login">Sign In</a>
                    </p>
                </section>
            ) : (
                errMsg !== '' ? (
                    <div className='className="sj-row-no-margin-padding"'>
                        {statusCode === '409' ? (
                            <>
                                <p>{errMsg} Please use the link below to sign-in.</p>
                                <p><a href="/login">Sign In</a></p>
                            </>
                        ) : (
                            <>
                                <p>{errMsg}</p>
                            </>
                        )}
                    </div>
                ) : (
                    <div className='className="sj-row-no-margin-padding"'>
                        {nonSuccessMessage}
                    </div>
                )
            )}
        </>
    )
}

export default ConfirmEmail