import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import textlogo from '../../static-contents/Snow_Japan_Color_Logo.svg'

const Header = () => {
  return (
    <div className="center-aligned">
      <div className="d-none d-lg-block sj-top-logo-holder">
        <Link to='/'><Image src={textlogo} fluid /></Link>
      </div>
    </div>
  );
}

export default Header;