import styled from 'styled-components';
import { Link } from 'react-router-dom'

const Frame = styled.div`
width: 400px;
min-height: 525px;
border: 1px solid lightgrey;
box-shadow: 2px 2px 2px #eee;
float:left;
`;

const Header = styled.div`
font-size: 18px;
font-weight: bold;
padding: 10px 10px 5px 10px;
display: flex;
justify-content: space-between;
background-color: #f5f6fa;
`;

const Body = styled.div`
width: 100%;
`;

const MonthlyReportsListing = ({ dailyReport, monthName, nowYear, dailySnowfallList }) => {

    var dailyDataList = dailySnowfallList.filter(function (currentElement) {
        return (currentElement.NowReportMonthFull === monthName && currentElement.NowReportYear === parseInt(nowYear));
    });

    dailyDataList.sort(function (a, b) {
        var dateA = new Date(a.Id), dateB = new Date(b.Id)
        return dateA - dateB
    })

    return (
        <Frame>
            <Header>
                {/*<Button onClick={() => setDate(new Date(year, month - 1, day))}>Prev</Button>*/}
                <div>
                    {monthName} {nowYear}
                </div>
                {/*<Button onClick={() => setDate(new Date(year, month + 1, day))}>Next</Button>*/}
            </Header>
            <Body>
                {dailyDataList.map((dailyData, index) => (
                    <div key={index}>
                        <Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/archives/${dailyData.NowReportDayTh}-${dailyData.NowReportMonthFull}-${dailyData.NowReportYear}`}>{dailyData.NowReportDayTh} {dailyData.NowReportMonthFull} {dailyData.NowReportYear}</Link>
                    </div>
                ))}
            </Body>
        </Frame>
    )
};

export default MonthlyReportsListing;