import { Helmet } from "react-helmet";
import Areas from './Areas'
import Prefectures from './Prefectures'
import Towns from './Towns'

const Listings = () => {
    return (
        <div className='empty-row'>
            <Helmet>
                <title>Listings of ski areas around Japan | SnowJapan</title>
                <meta name="description" content="Listings of all the ski areas in Japan by popular area, town and prefecture." />
            </Helmet>
            <h3>Ski areas by prefecture, town and region</h3>
            <div className='sj-para'>
                Discover all of the ski areas of Japan by prefecture, town and region. The number in brackets is the number of ski areas in each list. SnowJapan has assigned the regions lists as an additional way to discover well-known ski areas. It is not an official or geographical classification. We may add to or adjust those lists as we feel appropriate.
            </div>
            <div className='row sj-no-bg sj-row-no-margin-padding'>
                <div className='col-lg-4 col-sm-6 sj-row-no-margin-padding sj-guide-frame-first'>
                    <div className='sj-guide-frame'>
                        <div className="sj-box sj-box-dark-gray sj-round-half center-aligned">
                            <h4>Prefectures</h4>
                        </div>
                        <div className='sj-guide-frame-body'>
                            <div>
                                <img
                                    src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/pref.svg" alt='prefecture' />
                            </div>
                            <div className='left-aligned mt-3 mb-2'>
                                <h5>Discover ski areas by prefecture</h5>
                            </div>
                            <Prefectures />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-6 sj-row-no-margin-padding sj-guide-frame-middle'>
                    <div className='sj-guide-frame'>
                        <div className="sj-box sj-box-dark-gray sj-round-half center-aligned">
                            <h4>Towns</h4>
                        </div>
                        <div className='sj-guide-frame-body'>
                            <div>
                                <img
                                    src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/town.svg" alt='town' />
                            </div>
                            <div className='left-aligned mt-3 mb-2'>
                                <h5>Discover ski areas by town</h5>
                            </div>
                            <Towns />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-6 sj-row-no-margin-padding sj-guide-frame-last'>
                    <div className='sj-guide-frame'>
                        <div className="sj-box sj-box-dark-gray sj-round-half center-aligned">
                            <h4>Popular regions</h4>
                        </div>
                        <div className='sj-guide-frame-body'>
                            <div>
                                <img
                                    src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/area.svg" alt='area' />
                            </div>
                            <div className='left-aligned mt-3 mb-2'>
                                <h5>Discover ski areas by popular region</h5>
                            </div>
                            <Areas />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Listings;