import { useState, useEffect } from 'react'
import { Image } from 'react-bootstrap'

const SkiAreaListMap = ({ skiAreaList, prefecture, area, town }) => {

    const [mapPath, setMapPath] = useState('');

    const getMapPath = (thisPrefecture, thisArea, thisTown) => {
        if (thisPrefecture !== undefined) {
            setMapPath('https://www.snowjapan.com//UploadedFiles/MapImages/Prefectures/' + thisPrefecture.MapImage)
        } else if (thisArea !== undefined) {
            setMapPath('https://www.snowjapan.com//UploadedFiles/MapImages/Areas/' + thisArea.MapImage)
        } else if (thisTown !== undefined) {
            setMapPath('https://www.snowjapan.com//UploadedFiles/MapImages/Towns/' + thisTown.MapImage)
        }
    }

    useEffect(() => {
        getMapPath(prefecture, area, town);
    }, [skiAreaList, prefecture, area, town]);

    return (
        <>
            {mapPath !== '' &&
                <div className='mt-5'>
                    <div className='empty-row center-aligned sj-map-andrew'>
                        <Image src={mapPath} fluid='true' alt='ski area map' />
                    </div>
                </div>
            }
        </>
    )
};

export default SkiAreaListMap;