import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import Loader from '../Common/Loader'
import { Link } from 'react-router-dom'
import useSkiAreaPhotos from '../CustomHooks/useSkiAreaPhotos'
import MemberPhotoDetail from './MemberPhotoDetail'

const MemberPhotos = ({ user }) => {
    const queryPhoto = 0
    /*if (user === null) {
        return null
    }*/
    const userName = user.UserName
    const [pageNumberPhoto, setPaegNumberPhoto] = useState(1)

    const {
        loadingPhoto,
        errorPhoto,
        photos,
        hasMorePhoto
    } = useSkiAreaPhotos(queryPhoto, userName, pageNumberPhoto)

    const observerPhoto = useRef()
    const lastPhotoElementRef = useCallback(nodePhoto => {
        if (loadingPhoto)
            return
        if (observerPhoto.current)
            observerPhoto.current.disconnect()
        observerPhoto.current = new IntersectionObserver(entriesPhoto => {
            if (entriesPhoto[0].isIntersecting && hasMorePhoto) {
                setPaegNumberPhoto(prevPageNumberPhoto => prevPageNumberPhoto + 1)
            }
        })

        if (nodePhoto)
            observerPhoto.current.observe(nodePhoto)
    }, [loadingPhoto, hasMorePhoto])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>{user.FullName}’s photos | Member area | SnowJapan</title>
                <meta name="description" content="Member photos | Member area | SnowJapan" />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Photos.svg" alt='snowjapan-photo' /></div>
                <div>
                    <h3>My photos</h3>
                    <div className="sj-para">
                        Below are the photos you have posted on SnowJapan. Please note that photos need to be manually approved before appearing on the site, so please allow some time after posting new photos.
                    </div>
                    {/*<div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                    </div>*/}
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Write a new ski area review</Link></h6>
                    </div>
                </div>
            </div>
            <div className='empty-row'>
                <div className="sj-para">
                    <h3>Recent photos</h3>
                </div>
                {photos.map((photo, index) => {
                    if (photos.length === index + 1) {
                        return (
                            <div ref={lastPhotoElementRef} key={photo.Id}>
                                <MemberPhotoDetail photo={photo} user={user} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={photo.Id}>
                                <MemberPhotoDetail photo={photo} user={user} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingPhoto &&
                        <Loader />
                    }
                </div>
                <div>{errorPhoto && 'Error'}</div>
            </div>
        </div>
    )
}

export default MemberPhotos