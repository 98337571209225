import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from "react-helmet"
import AccommodationHome from './Home'

const AccommodationContainer = () => {
    const params = useParams()
    const [accommodationList, setAccommodationList] = useState([])
    const [allNearbySkyAreaList, setAllNearbySkyAreaList] = useState([])
    const [uniqueSkiAreas, setuniqueSkiAreas] = useState([])

    const fetchAccommodationList = async (thisParam) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/accommodation/list/all/all`
        }).then(dataAccommodationList => {
            setAccommodationList(dataAccommodationList.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/nearby-resorts/list/all`
            })
        }).then(dataNearbySkyAras => {
            setAllNearbySkyAreaList(dataNearbySkyAras.data)
            getAllAccommodationNearbySkiAreas(dataNearbySkyAras.data)
        })
    };

    const getAllAccommodationNearbySkiAreas = (nearbySkyAreas) => {

        if (nearbySkyAreas.length > 0) {
            const nearbySkiAreas = [...new Map(nearbySkyAreas.map(item => [item['SkiAreaUniqueName'], item])).values()];

            const allUniqueSkiAreas = nearbySkiAreas.map(sa => ({ name: sa.SkiAreaName, uniqueName: sa.SkiAreaUniqueName, prefectureUniqueName: sa.SkiAreaPrefectureUniqueName, accommodationUniqueName: sa.AccommodationUniqueName, checked: false, disabled: false }));

            allUniqueSkiAreas.sort(function (a, b) {
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;

            })

            setuniqueSkiAreas(allUniqueSkiAreas)
        }
    }

    useEffect(() => {
        fetchAccommodationList(params)
    }, [params]);

    return (
        <>
            <Helmet>
                <title>Accommodation Title</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
            <AccommodationHome accommodationList={accommodationList} uniqueSkiAreas={uniqueSkiAreas} allNearbySkyAreaList={allNearbySkyAreaList} />
        </>
    )
};

export default AccommodationContainer;