import { Image } from 'react-bootstrap'

const SkiMountain = ({ resort }) => {

  return (
    <div className='sj-row'>
      <div className="sj-box sj-box-dark-gray sj-round-full center-aligned">
        <h4>The Mountain</h4>
      </div>
      <div className="sj-mountain">
        <div className="row sj-row sj-no-bg">
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgMaxElevation' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Summit.svg' fluid='true' alt='Max Elevation' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.MaximumElevation}m</h2>
              <h4>Top elevation</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgVertical' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Vertical.svg' fluid='true' alt='Vertical Difference' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.VerticalDifference}m</h2>
              <h4>Vertical</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgMinElevation' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Base.svg' fluid='true' alt='Min Elevation' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.MinimumElevation}m</h2>
              <h4>Base elevation</h4>
            </div>
          </div>
        </div>
        <div className="row sj-row sj-no-bg">
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgTotalSkiCourses' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Number-of-Courses.svg' fluid='true' alt='Total Ski Courses' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.TotalSkiCourses}</h2>
              <h4>Number of courses</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgLongestCourse' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Longest-Course.svg' fluid='true' alt='Longest Course' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.LongestCourse}m</h2>
              <h4>Longest course</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className='center-aligned'>
              <Image id='imgSteepestSlope' src='https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Mountain-Steepest-Course.svg' fluid='true' alt='Steepest Slope' />
            </div>
            <div className='center-aligned'>
              <h2>{resort.SteepestSlope}°</h2>
              <h4>Steepest course</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkiMountain;