import axios from 'axios'
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../Common/Loader'
import { RenderBreak } from '../../helpers/Helper'

const Webcams = () => {

    const params = useParams()

    const [generalMessageWebCam, setGeneralMessageWebCam] = useState(undefined)
    const [guide, setGuide] = useState(undefined)

    const fetchGeneralMessage = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/NowWebCam`
        }).then(resGeneralMessages => {
            setGeneralMessageWebCam(resGeneralMessages.data)
        })
    }

    const fetchGuides = async (guideUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/listingGuideSection/guides/${guideUniqueName}`
        }).then(resGuide => {
            setGuide(resGuide.data)
        })
    }

    useEffect(() => {
        if (params !== undefined && params.type !== undefined) {
            fetchGuides(params.type)
        } else {
            fetchGeneralMessage()
        }
    }, [params])

    if (generalMessageWebCam === undefined && guide === undefined) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='empty-row'>
                {generalMessageWebCam !== undefined &&
                    <>
                        <Helmet>
                            <title>{generalMessageWebCam.Title}</title>
                            <meta name="description" content={`${generalMessageWebCam.Description}`} />
                        </Helmet>
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(generalMessageWebCam.Message) }} />
                    </>
                }
                {guide !== undefined &&
                    <>
                        <Helmet>
                            <title>{guide.Title}</title>
                            <meta name="description" content={`${guide.Description}`} />
                        </Helmet>
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(guide.HtmlContent) }} />
                    </>
                }
            </div>
        )
    }
}

export default Webcams