import { RenderBreak } from '../../../../helpers/Helper'

const SeasonOpeningTime = ({ resort }) => {

    return (
        <div className='empty-row'>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-3">
                <h4>{resort.Name} opening information</h4>
            </div>
            <div className="row sj-no-bg sj-row-no-margin-padding">
                <div className="col-md-3 m-0 mt-5 p-0 sj-list-icon-holder">
                    <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Opening-Times.svg" alt='Opening time' />
                </div>
                <div className="col-md-9 sj-list-text-holder">
                    <div className='table-container'>
                        <div className='center-aligned sj-box sj-box-dark-gray sj-round-half pt-2 pb-2 mt-0 mb-0'>
                            <h4>Typical season</h4>
                        </div>
                        <div>
                            <table className='table-container__table opening-time'>
                                <thead>
                                    <tr>
                                        <th colSpan='3'>NOV</th>
                                        <th colSpan='3'>DEC</th>
                                        <th colSpan='3'>JAN</th>
                                        <th colSpan='3'>FEB</th>
                                        <th colSpan='3'>MAR</th>
                                        <th colSpan='3'>APR</th>
                                        <th colSpan='3'>MAY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {[...Array(21)].map((x, i) =>
                                            <td key={i} className={i > resort.OpeningDate - 3 && i < resort.ClosingDate + 8 ? `resort-open` : `normal`}>&nbsp;</td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {resort.InformationOnChildren !== '' &&
                            <div className='pt-4'>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.OpeningTimeComments) }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default SeasonOpeningTime;