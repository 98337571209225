import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DailyObservedSnowfall from './DailyObservedSnowfall'
import RecentDailyReports from './RecentDailyReports'

const SnowfallStatistics = ({ dailyReport, isArchive }) => {

  const [snowfallTotalList, setSnowfallTotalList] = useState([]);
  const [seasonDailyReportList, setSeasonDailyReportList] = useState([]);

  const fetchSeasonDailyReportAndSnowfallTotal = async (nowId) => {
    axios({
      method: 'POST',
      url: `${window.$baseAPIUrl}/dailyreport/snowfalltotal/${nowId}`
    }).then(resSnowfallTotal => {
      setSnowfallTotalList(resSnowfallTotal.data)
      return axios({
        method: 'POST',
        url: `${window.$baseAPIUrl}/dailyreport/reports/${dailyReport.UniqueName}/${dailyReport.Season}/${dailyReport.CreatedAt}`
      })
    }).then(resSeasonDailyReport => {
      setSeasonDailyReportList(resSeasonDailyReport.data)
      console.log(seasonDailyReportList)
    })
  };

  useEffect(() => {
    fetchSeasonDailyReportAndSnowfallTotal(dailyReport.Now_Id)
  }, [dailyReport.Now_Id]);

  let TotalSnow7Days = '-'
  let TotalSnow14Days = '-'
  let TotalSnowCurrentMonth = '0cm'
  let TotalSnowCurrentSeason = '0cm'

  if (snowfallTotalList !== undefined) {
    let TotalSnow7DaysData = snowfallTotalList.find(item => item.TotalType === '7')
    if (TotalSnow7DaysData !== undefined) {
      TotalSnow7Days = TotalSnow7DaysData['TotalSnow'] + 'cm'
    }

    let TotalSnow14DaysData = snowfallTotalList.find(item => item.TotalType === '14')
    if (TotalSnow14DaysData !== undefined) {
      TotalSnow14Days = TotalSnow14DaysData['TotalSnow'] + 'cm'
    }

    let TotalSnowCurrentMonthData = snowfallTotalList.find(item => item.TotalType === 'CurrentMonth')
    if (TotalSnowCurrentMonthData !== undefined) {
      TotalSnowCurrentMonth = TotalSnowCurrentMonthData['TotalSnow'] + 'cm'
    }

    let TotalSnowCurrentSeasonData = snowfallTotalList.find(item => item.TotalType === 'CurrentSeason')
    if (TotalSnowCurrentSeasonData !== undefined) {
      TotalSnowCurrentSeason = TotalSnowCurrentSeasonData['TotalSnow'] + 'cm'
    }
  }

  return (
    <div>
      {(!isArchive) &&
        <>
          <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2 mb-4">
            <h4>Recent observed snowfall data at base</h4>
          </div>
          <div className='row sj-no-margin'>
            <div className='col-md-3 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Lastest 7 reports</h5></div>
              <div className='center-aligned mt-3'>
                <h3 className='sj-light-blue-text'>{TotalSnow7Days}</h3>
              </div>
            </div>
            <div className='col-md-3 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Lastest 14 reports</h5></div>
              <div className='center-aligned mt-3'>
                <h3 className='sj-light-blue-text'>{TotalSnow14Days}</h3>
              </div>
            </div>
            <div className='col-md-3 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Current month</h5></div>
              <div className='center-aligned mt-3'>
                <h3 className='sj-light-blue-text'>{TotalSnowCurrentMonth}</h3>
              </div>
            </div>
            <div className='col-md-3 sj-no-margin'>
              <div className='sj-box sj-box-lighter-gray center-aligned white-border'><h5>Season so far</h5></div>
              <div className='center-aligned mt-3'>
                <h3 className='sj-light-blue-text'>{TotalSnowCurrentSeason}</h3>
              </div>
            </div>
          </div>
        </>
      }
      {(isArchive) &&
        <div className="sj-box sj-box-red sj-round-full center-aligned mt-5">
          <h5>This is an archive report from {dailyReport.NowReportWeekDay} {dailyReport.NowReportDayTh} {dailyReport.NowReportMonthFull} {dailyReport.NowReportYear}</h5>
        </div>
      }
      {(seasonDailyReportList.length > 1) &&
        <div>
          <RecentDailyReports dailySnowfallList={seasonDailyReportList} />
        </div>
      }
      <div>
        <DailyObservedSnowfall season={dailyReport.Season} dailySnowfallList={seasonDailyReportList} />
      </div>
      <div className="sj-para">
        <Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/observed-snowfall-analysis`} className="btn btn-primary sj-large-button-blue sj-full-width">Full analysis here</Link>
      </div>
    </div>
  );
}

export default SnowfallStatistics;