import { useState, useEffect } from 'react'
import styled from 'styled-components';

const CheckboxText = styled.span`
  ${props => props.Disabled && `
         color: #b7b7b7;
    `}
`;

const AreaCheckList = ({ selectedPrefectures, selectedAreas, options, onChange }) => {
    const [areaData, setAreaData] = useState(options);

    const toggle = index => {
        const newData = [...areaData];
        newData.splice(index, 1, {
            name: areaData[index].name,
            uniqueName: areaData[index].uniqueName,
            prefectureUniqueName: areaData[index].prefectureUniqueName,
            checked: !areaData[index].checked,
            disabled: areaData[index].disabled
        });
        setAreaData(newData);
        onChange(newData);
    };

    const refreshAreaOpetions = (prefectures, allAreas, selAreas) => {
        let selectedAreaList = selAreas.filter(function (currentElement) {
            return (currentElement.checked);
        });

        var selectedAreaUniqueNames = selectedAreaList.map(function (obj) { return obj.uniqueName; });

        allAreas = allAreas.map(item => {
            item.disabled = false;
            item.checked = selectedAreaUniqueNames.includes(item.uniqueName);
            return item;
        })

        setAreaData(allAreas);
    }

    useEffect(() => {
        refreshAreaOpetions(selectedPrefectures, options, selectedAreas);
    }, [selectedPrefectures, options, selectedAreas]);

    return (
        <>
            <h3>Areas</h3>
            {areaData.map((item, index) => (
                <div key={item.uniqueName}>
                    <label>
                        <input
                            readOnly
                            type="checkbox"
                            checked={item.checked || false}
                            onClick={() => toggle(index)}
                            disabled={item.disabled || false}
                        /> <CheckboxText Disabled={item.disabled}>{item.name}</CheckboxText>
                    </label><br />
                </div>
            ))}
        </>
    );
};

export default AreaCheckList;