import { Link } from 'react-router-dom'
import Loader from "../../../Common/Loader"

const RecentDailyReports = ({ dailySnowfallList }) => {

    let sortedDailySnowfallList = dailySnowfallList.sort(function (a, b) {
        if (a.Id < b.Id) { return 1; }
        if (a.Id > b.Id) { return -1; }

        return 0;
    })

    let last7daysDailyReports = sortedDailySnowfallList.slice(1, 8)

    if (last7daysDailyReports.length <= 0) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <div>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-4">
                    <h4>Previous 7 reports</h4>
                </div>
                <div className='mt-4'>
                    {last7daysDailyReports.map(dailyReport => (
                        <div key={dailyReport.Id}>
                            <h5><Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/archives/${dailyReport.NowReportDayTh}-${dailyReport.NowReportMonthFull}-${dailyReport.NowReportYear}`}>{dailyReport.NowReportWeekDay} {dailyReport.NowReportDayTh} {dailyReport.NowReportMonthFull} {dailyReport.NowReportYear}, {dailyReport.NowReportHourMinute} ({dailyReport.NewSnowfallResortA}cm)</Link></h5>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
};

export default RecentDailyReports;