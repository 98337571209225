import { Link } from 'react-router-dom'

const YearlyAnalysisTab = ({ nowUniqueName, nowSeasons }) => {

  return (
    <div className='sj-link-button-container'>
      {nowSeasons.map((season, index) => (
        <div key={index} className='sj-weather-season-button center-aligned'><Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/season/${season.Season}`}>{season.Season}</Link></div>
      ))}
    </div >
  );
}

export default YearlyAnalysisTab;