import { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from "../../../Common/Loader"
import { RenderBreak } from '../../../../helpers/Helper'

const SpotlightContainer = ({ resort }) => {

    const [listingDetail, setListingDetail] = useState({});

    const fetchListing = async (thisResort) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/listingGuideSection/listings/${thisResort.ResortSpotlightUrl}`
        }).then(dataListingDetail => {
            setListingDetail(dataListingDetail.data)
        })
    };

    useEffect(() => {
        fetchListing(resort);
    }, [resort]);

    if (listingDetail === undefined) {
        <Loader />
    } else {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(listingDetail.HtmlContent) }} />
            </div>
        )
    }
};

export default SpotlightContainer;