import '../../Uploader.scss'

const Uploader = () => {
    return (
        <div className="uploader-container">
            <div className="uploader">
                <div className="uploader--dot"></div>
                <div className="uploader--dot"></div>
                <div className="uploader--dot"></div>
                <div className="uploader--dot"></div>
                <div className="uploader--dot"></div>
                <div className="uploader--dot"></div>
                <div className="uploader--text"></div>
            </div>
        </div>
    );
}

export default Uploader;