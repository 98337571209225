import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Winter Sports Japan
//window.$baseAPIUrl = 'https://www.wintersportsjapan.net/rest-api'
//window.$baseSJAPIUrl = 'https://www.wintersportsjapan.net/api'
//window.$baseAppUrl = 'https://www.wintersportsjapan.net'

// Snow Japan
window.$baseAPIUrl = 'https://www.snowjapan.com/rest-api'
window.$baseSJAPIUrl = 'https://www.snowjapan.com/api'
window.$baseAppUrl = 'https://www.snowjapan.com'

// Localhost
//window.$baseAPIUrl = 'http://localhost/rest-api'
//window.$baseSJAPIUrl = 'http://localhost/api'
//window.$baseAppUrl = 'http://localhost'

window.$baseSkiAreaUrl = '/japan-ski-resorts'
window.$baseDailyReportUrl = '/japan-daily-snow-weather-reports'
window.$baseAccommodationUrl = '/japan-places-to-stay'
window.$baseListingUrl = '/listings'
window.$baseGuideUrl = '/guides'
window.$baseRouteFinderUrl = '/route-finder'
window.$baseMemberUrl = '/members'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
