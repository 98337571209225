import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Common/Loader'
import SkiAreaDetailTabs from './SkiAreaDetailTabs'
import BasicContainer from './BasicInfo/BasicInfoContainer'
import DailyReportContainer from './DailyReport/DailyReportContainer'
import HistoryContainer from './History/HistoryContainer'
import ListingsContainer from './Listings/ListingsContainer'
import LocationContainer from './Location/LocationContainer'
import PhotosContainer from './Photos/PhotosContainer'
import ReviewsContainer from './Reviews/ReviewsContainer'
import RouteFinderContainer from './RouteFinder/RouteFinderContainer'
import SnowDataContainer from './SnowData/SnowDataContainer'
import WeatherContainer from './Weather/WeatherContainer'
import SpotlightContainer from './Spotlight/SpotlightContainer'

const SkiAreaDetailContainer = () => {

    const params = useParams()

    const [showSnowData, setShowSnowData] = useState('')

    const [resort, setResort] = useState([])
    const [nearbySkiAreas, setNearbySkiAreas] = useState([])
    const [skiAreaDailyReport, setSkiAreaDailyReport] = useState(undefined)
    const [skiAreaLatestWeather, setSkiAreaLatestWeather] = useState(undefined)

    const [nearbyAccommodations, setNearbyAccommodations] = useState([])
    const [areaSectionListings, setAreaSectionListings] = useState([])

    const [hasListings, setHasListings] = useState(false)

    const getAreaListingValue = (sectionUniqueName, listingList) => {

        let listingSectionData = []

        let listingData = listingList.filter(function (currentElement) {
            return (currentElement.SectionUniqueName === sectionUniqueName)
        })

        listingData.forEach(function (dataItem) {

            listingSectionData.push(dataItem)
        })

        return listingSectionData;
    }

    const getListingSectionName = (sectionUniqueName, listingList) => {

        let sectionName = ''

        let listingData = listingList.filter(function (currentElement) {
            return (currentElement.SectionUniqueName === sectionUniqueName)
        })

        listingData.forEach(function (dataItem) {

            sectionName = dataItem.SectionName
        })

        return sectionName
    }

    const fetchData = async (resortUniqueName) => {

        let areaUniqueName = ''

        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/${resortUniqueName}`
        }).then(dataResort => {

            if (Object.keys(dataResort.data).length <= 0) {
                window.location.href = "https://www.snowjapan.com/404.html"
            }

            setResort(dataResort.data)

            areaUniqueName = dataResort.data.AreaUniqueName

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/skiarea/latestweather/latest`,
                data: {
                    resortid: dataResort.data.Id
                }
            })
        }).then(dataResortLatestWeather => {
            setSkiAreaLatestWeather(dataResortLatestWeather.data)

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/skiarea/nearby-resorts/${resortUniqueName}`
            })
        }).then(dataNearbySkiAreas => {
            let thisNearbySkiAreaList = []

            if (dataNearbySkiAreas.data.length > 0) {
                dataNearbySkiAreas.data.forEach(nr => {
                    if (nr.FromSkiAreaUniqueName === resortUniqueName) {
                        thisNearbySkiAreaList.push({ Id: nr.Id, Distance: nr.Distance, IsConnected: nr.IsConnected, SkiAreaName: nr.ToSkiAreaName, SkiAreaUniqueName: nr.ToSkiAreaUniqueName, SkiAreaPrefectureName: nr.ToSkiAreaPrefectureName, SkiAreaPrefectureUniqueName: nr.ToSkiAreaPrefectureUniqueName, SkiAreaTownName: nr.ToSkiAreaTownName, SkiAreaTownUniqueName: nr.ToSkiAreaTownUniqueName, SkiAreaTownTypeStr: nr.ToSkiAreaTownTypeStr, SkiAreaAreaName: nr.ToSkiAreaAreaName, SkiAreaAreaUniqueName: nr.ToSkiAreaAreaUniqueName })
                    } else if (nr.ToSkiAreaUniqueName === resortUniqueName) {
                        thisNearbySkiAreaList.push({ Id: nr.Id, Distance: nr.Distance, IsConnected: nr.IsConnected, SkiAreaName: nr.FromSkiAreaName, SkiAreaUniqueName: nr.FromSkiAreaUniqueName, SkiAreaPrefectureName: nr.FromSkiAreaPrefectureName, SkiAreaPrefectureUniqueName: nr.FromSkiAreaPrefectureUniqueName, SkiAreaTownName: nr.FromSkiAreaTownName, SkiAreaTownUniqueName: nr.FromSkiAreaTownUniqueName, SkiAreaTownTypeStr: nr.FromSkiAreaTownTypeStr, SkiAreaAreaName: nr.FromSkiAreaAreaName, SkiAreaAreaUniqueName: nr.FromSkiAreaAreaUniqueName })
                    }
                })

                thisNearbySkiAreaList = thisNearbySkiAreaList.sort(function (a, b) {
                    if (a.Distance > b.Distance) return 1;
                    if (a.Distance < b.Distance) return -1;

                    if (a.SkiAreaName > b.SkiAreaName) return 1;
                    if (a.SkiAreaName < b.SkiAreaName) return -1;

                    return 0;
                })
            }

            setNearbySkiAreas(thisNearbySkiAreaList)

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/dailyreport/dailyreportskiareas/resort/${resortUniqueName}`
            })
        }).then(dataSkiAreaDailyReport => {
            setSkiAreaDailyReport(dataSkiAreaDailyReport.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/nearby-hotels/${resortUniqueName}`
            })
        }).then(dataNearbyAccommodations => {
            setNearbyAccommodations(dataNearbyAccommodations.data)

            setHasListings(dataNearbyAccommodations.data.length > 0)

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/generalmessage/ShowSnowData`
            })
        }).then(resGeneralMessages => {
            setShowSnowData(resGeneralMessages.data.Title)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/listingGuideSection/listings/list/area/${areaUniqueName}`
            })
        }).then(dataAreaListings => {
            if (dataAreaListings.data.length > 0) {
                let listingDataSeries = [];
                let sections = [...new Set(dataAreaListings.data.map(item => item.SectionUniqueName))].sort().reverse();

                sections.forEach(function (item) {
                    listingDataSeries.push({
                        'sectionName': getListingSectionName(item, dataAreaListings.data),
                        'sectionUniqueName': item,
                        'listings': getAreaListingValue(item, dataAreaListings.data)
                    })
                })

                setAreaSectionListings(listingDataSeries)
                setHasListings(dataAreaListings.data.length > 0)
            }
        })
    };

    useEffect(() => {
        fetchData(params.resort)
    }, [params.resort]);

    if (Object.keys(resort).length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <Fragment>
                <h2>{resort.Name}</h2>
                <div className='sj-para-large mt-1 mb-3'>{resort.NameJapanese}</div>
                <SkiAreaDetailTabs params={params} resort={resort} skiAreaDailyReport={skiAreaDailyReport} skiAreaLatestWeather={skiAreaLatestWeather} hasListings={hasListings} />
                <>
                    {
                        {
                            undefined: <BasicContainer resort={resort} nearbySkiAreaList={nearbySkiAreas} skiAreaLatestWeather={skiAreaLatestWeather} showSnowData={showSnowData} />,
                            'daily-report': <DailyReportContainer resort={resort} skiAreaDailyReport={skiAreaDailyReport} />,
                            'spotlight': <SpotlightContainer resort={resort} />,
                            'location': <LocationContainer resort={resort} nearbySkiAreaList={nearbySkiAreas} />,
                            'snow-data': <SnowDataContainer resort={resort} skiAreaDailyReport={skiAreaDailyReport} />,
                            'weather': <WeatherContainer resort={resort} />,
                            'history': <HistoryContainer resort={resort} />,
                            'reviews': <ReviewsContainer resort={resort} />,
                            'photos': <PhotosContainer resort={resort} />,
                            'route-finder': <RouteFinderContainer resort={resort} />,
                            'listing': <ListingsContainer resort={resort} nearbyAccommodations={nearbyAccommodations} areaSectionListings={areaSectionListings} />
                        }[params.type]
                    }
                </>
            </Fragment>
        )
    }
}

export default SkiAreaDetailContainer