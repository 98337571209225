import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Loader from "../Common/Loader"
import { Helmet } from 'react-helmet'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

const OfficialSnowDepth = () => {

    const params = useParams()

    const [allSkiAreaWeatherList, setAllSkiAreaWeatherList] = useState([])
    const [lastUpdateDate, setLastUpdateDate] = useState('')
    const [showSnowData, setShowSnowData] = useState('')

    const [buttonTitle, setButtonTitle] = useState('Current Snow Depth [highest>lowest]')
    const [nameSorted, setNameSorted] = useState('')
    const [townSorted, setTownSorted] = useState('')
    const [prefectureSorted, setPrefectureSorted] = useState('')
    const [snowDepthSorted, setSnowDepthSorted] = useState('sorted-column')
    const [changeSorted, setChangeSorted] = useState('')
    const [sortOrder, setSortOrder] = useState('ASC')

    const fetchSkiAreaWeatherList = async (thisMatch) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/latestweather/list`,
            data: { filter_type: 'all' }
        }).then(resSkiAreaWeather => {
            if (resSkiAreaWeather.data) {
                const skiAreaLatestWeather = resSkiAreaWeather.data

                if (skiAreaLatestWeather.length > 0) {
                    var latestReport = skiAreaLatestWeather.sort((a, b) => b.CreatedAt - a.CreatedAt)[0]

                    setLastUpdateDate(latestReport.WeatherDayTh + ' ' + latestReport.WeatherMonthFull + ' ' + latestReport.WeatherYear)

                    var maxCreated = latestReport.CreatedAt;

                    const latestSkiAreaWeather = skiAreaLatestWeather.filter(function (currentElement) {
                        return (currentElement.CreatedAt === maxCreated);
                    });

                    if (latestSkiAreaWeather.length > 0) {
                        latestSkiAreaWeather.sort(function (a, b) {
                            return b.SnowDepth - a.SnowDepth
                        })

                        setAllSkiAreaWeatherList(latestSkiAreaWeather)
                    }
                }
            }

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/generalmessage/ShowSnowData`
            })
        }).then(resGeneralMessages => {
            setShowSnowData(resGeneralMessages.data.Title)
        })
    };

    useEffect((params) => {
        fetchSkiAreaWeatherList()
    }, [params]);

    const SortResult = (evtKey, evt) => {

        var sortedResult = []

        var sortOrder = ''

        setNameSorted('')
        setTownSorted('')
        setPrefectureSorted('')
        setSnowDepthSorted('')
        setChangeSorted('')

        if (evtKey === '1' || evtKey === '2') {
            sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase()

                if (nameA < nameB) { return -1 }
                if (nameA > nameB) { return 1 }
                return 0
            })
            sortOrder = "ASC"

            if (evtKey === '2') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            setNameSorted('sorted-column')

        } else if (evtKey === '3' || evtKey === '4') {
            if (evtKey === '3') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                    let townNameA = a.TownName.toLowerCase(), townNameB = b.TownName.toLowerCase();

                    if (townNameA < townNameB) { return -1 }
                    if (townNameA > townNameB) { return 1 }

                    if (nameA < nameB) { return -1 }
                    if (nameA > nameB) { return 1 }

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '4') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();
                    let townNameA = a.TownName.toLowerCase(), townNameB = b.TownName.toLowerCase();

                    if (townNameA < townNameB) { return 1 }
                    if (townNameA > townNameB) { return -1 }

                    if (nameA < nameB) { return -1 }
                    if (nameA > nameB) { return 1 }

                    return 0;
                })

                sortOrder = "DESC"
            }

            setTownSorted('sorted-column')

        } else if (evtKey === '5' || evtKey === '6') {
            if (evtKey === '5') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase()
                    let townNameA = a.TownName.toLowerCase(), townNameB = b.TownName.toLowerCase()
                    let prefectureNameA = a.PrefectureName.toLowerCase(), prefectureNameB = b.PrefectureName.toLowerCase()

                    if (prefectureNameA < prefectureNameB) { return -1 }
                    if (prefectureNameA > prefectureNameB) { return 1 }

                    if (townNameA < townNameB) { return -1 }
                    if (townNameA > townNameB) { return 1 }

                    if (nameA < nameB) { return -1 }
                    if (nameA > nameB) { return 1 }

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '6') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase()
                    let townNameA = a.TownName.toLowerCase(), townNameB = b.TownName.toLowerCase()
                    let prefectureNameA = a.PrefectureName.toLowerCase(), prefectureNameB = b.PrefectureName.toLowerCase()

                    if (prefectureNameA < prefectureNameB) { return 1 }
                    if (prefectureNameA > prefectureNameB) { return -1 }

                    if (townNameA < townNameB) { return -1 }
                    if (townNameA > townNameB) { return 1 }

                    if (nameA < nameB) { return -1 }
                    if (nameA > nameB) { return 1 }

                    return 0
                })
                sortOrder = "DESC"
            }

            setPrefectureSorted('sorted-column')

        } else if (evtKey === '7' || evtKey === '8') {
            if (evtKey === '7') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    if (a.SnowDepth < b.SnowDepth) return 1
                    if (a.SnowDepth > b.SnowDepth) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '8') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    if (a.SnowDepth > b.SnowDepth) return 1
                    if (a.SnowDepth < b.SnowDepth) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0;
                })

                sortOrder = "DESC"
            }

            setSnowDepthSorted('sorted-column')
        } else if (evtKey === '9' || evtKey === '10') {
            if (evtKey === '9') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    if (a.SnowDepthCompareToYesterday < b.SnowDepthCompareToYesterday) return 1
                    if (a.SnowDepthCompareToYesterday > b.SnowDepthCompareToYesterday) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })
                sortOrder = "ASC"
            } else if (evtKey === '10') {
                sortedResult = allSkiAreaWeatherList.sort(function (a, b) {
                    if (a.SnowDepthCompareToYesterday > b.SnowDepthCompareToYesterday) return 1
                    if (a.SnowDepthCompareToYesterday < b.SnowDepthCompareToYesterday) return -1

                    if (a.Name > b.Name) return 1
                    if (a.Name < b.Name) return -1

                    return 0
                })

                sortOrder = "DESC"
            }

            setChangeSorted('sorted-column')
        }

        setButtonTitle('Sort by: ' + evt.target.innerText)
        setSortOrder(sortOrder)
    }

    if (allSkiAreaWeatherList.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Official snow depth data of ski areas in Japan | SnowJapan</title>
                    <meta name="description" content="Official snow depth data chart for ski areas throughout Japan. Updated daily in winter." />
                </Helmet>
                <div className='mb-4'>
                    <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Snow-Depth.svg" alt="Official ski area snow depth data" /></div>
                    <div>
                        <h3>Official ski area snow depth data</h3>
                        <div className="sj-para">
                            {(showSnowData !== undefined && showSnowData === "Y") &&
                                <h3 className='sj-red-header'>Latest update: {lastUpdateDate}</h3>
                            }
                            {(showSnowData !== undefined && showSnowData === "Y") &&
                                <>The information presented in this table is official snow depth data originating from ski areas and is updated once a day through the main winter season. Please keep in mind that every ski area has its own policy and methods of measuring snow depth.</>
                            }
                            {(showSnowData !== undefined && showSnowData === "N") &&
                                <>Official snow depth data for ski areas throughout Japan is displayed here during the winter season</>
                            }
                        </div>
                    </div>
                </div>
                {(showSnowData !== undefined && showSnowData === "Y") &&
                    <div className='empty-row'>
                        <div className='right-aligned'>
                            <DropdownButton align="end" title={buttonTitle} onSelect={SortResult} id="dropdown-menu-align-end">
                                <Dropdown.Item eventKey="1">Ski area name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Ski area name [Z-A]</Dropdown.Item>
                                <Dropdown.Item eventKey="3">Town name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="4">Town name [Z-A]</Dropdown.Item>
                                <Dropdown.Item eventKey="5">Prefecture name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="6">Prefecture name [Z-A]</Dropdown.Item>
                                <Dropdown.Item eventKey="7">Current Snow Depth [highest{'>'}lowest]</Dropdown.Item>
                                <Dropdown.Item eventKey="8">Current Snow Depth [lowest{'>'}highest]</Dropdown.Item>
                                <Dropdown.Item eventKey="9">Change [highest{'>'}lowest]</Dropdown.Item>
                                <Dropdown.Item eventKey="10">Change [lowest{'>'}highest]</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className='table-container overflow-x'>
                            <table className='table-container__table sj-list-table'>
                                <thead>
                                    <tr>
                                        <th className={`${nameSorted !== '' ? "sorted-column-header" : ""}`}>
                                            <h5>
                                                {(nameSorted !== '' && sortOrder === 'ASC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                }
                                                {(nameSorted !== '' && sortOrder === 'DESC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                }
                                            </h5>
                                        </th>
                                        <th className={`${townSorted !== '' ? "sorted-column-header" : ""}`}>
                                            <h5>
                                                Town&nbsp;&nbsp;
                                                {(townSorted !== '' && sortOrder === 'ASC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                }
                                                {(townSorted !== '' && sortOrder === 'DESC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                }
                                            </h5>
                                        </th>
                                        <th className={`${prefectureSorted !== '' ? "sorted-column-header" : ""}`}>
                                            <h5>
                                                Prefecture&nbsp;&nbsp;
                                                {(prefectureSorted !== '' && sortOrder === 'ASC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                }
                                                {(prefectureSorted !== '' && sortOrder === 'DESC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                }
                                            </h5>
                                        </th>
                                        <th className={`${snowDepthSorted !== '' ? "sorted-column-header" : ""}`}>
                                            <h5>
                                                Current Snow Depth&nbsp;&nbsp;
                                                {(snowDepthSorted !== '' && sortOrder === 'ASC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                }
                                                {(snowDepthSorted !== '' && sortOrder === 'DESC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                }
                                            </h5>
                                        </th>
                                        <th className={`${changeSorted !== '' ? "sorted-column-header" : ""}`}>
                                            <h5>
                                                Change&nbsp;&nbsp;
                                                {(changeSorted !== '' && sortOrder === 'ASC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                                                }
                                                {(changeSorted !== '' && sortOrder === 'DESC') &&
                                                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                                                }
                                            </h5>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allSkiAreaWeatherList.map(skiAreaWeather => (
                                        <tr key={skiAreaWeather.Id}>
                                            <td className={nameSorted}>
                                                <Link to={`${window.$baseSkiAreaUrl}/${skiAreaWeather.PrefectureUniqueName}/${skiAreaWeather.TownUniqueName}/${skiAreaWeather.UniqueName}`}>{skiAreaWeather.Name}</Link>
                                            </td>
                                            <td className={townSorted}>
                                                <Link to={`${window.$baseSkiAreaUrl}/town/${skiAreaWeather.TownUniqueName}`}>{skiAreaWeather.TownName} {skiAreaWeather.TownTypeStr}</Link>
                                            </td>
                                            <td className={prefectureSorted}>
                                                <Link to={`${window.$baseSkiAreaUrl}/prefecture/${skiAreaWeather.PrefectureUniqueName}`}>{skiAreaWeather.PrefectureName}</Link>
                                            </td>
                                            <td className={snowDepthSorted}>
                                                {skiAreaWeather.SnowDepth !== null &&
                                                    <>{skiAreaWeather.SnowDepth} cm</>
                                                }
                                                {skiAreaWeather.SnowDepth === null &&
                                                    <>--</>
                                                }
                                            </td>
                                            <td className={changeSorted}>
                                                {skiAreaWeather.SnowDepthCompareToYesterday !== null &&
                                                    <>{skiAreaWeather.SnowDepthCompareToYesterday} cm</>
                                                }
                                                {skiAreaWeather.SnowDepthCompareToYesterday === null &&
                                                    <>--</>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OfficialSnowDepth