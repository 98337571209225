import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Loader from "../../../Common/Loader"
import DailyReportInfo from './DailyReportInfo'
import SnowfallStatistics from './SnowfallStatistics'
import { RenderBreak } from '../../../../helpers/Helper'

const DetailDailyReport = ({ dailyReport, params, isArchive }) => {

    const [thisReport, setThisReport] = useState(undefined)

    const fetchDailyReport = async (reportUniqueName, seasonName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/archived/${reportUniqueName}/${seasonName}`
        }).then(resDailyReport => {
            setThisReport(resDailyReport.data)
        })
    };

    useEffect(() => {
        if (params.season !== undefined) {
            fetchDailyReport(dailyReport.UniqueName, params.season)
        } else {
            setThisReport(dailyReport)
        }
    }, [dailyReport, params.season])

    const getDayOrdinal = (day) => {
        if (day > 3 && day < 21) return 'th'
        switch (day % 10) {
            case 1: return "st"
            case 2: return "nd"
            case 3: return "rd"
            default: return "th"
        }
    }

    const getFormattedDate = (reportDate) => {
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let thisReportDate = new Date(reportDate)
        let formattedDate = ''

        formattedDate = [
            thisReportDate.getDate() + getDayOrdinal(thisReportDate.getDate()),
            MONTHS[thisReportDate.getMonth()],
            thisReportDate.getFullYear()
        ].join('-')

        return formattedDate
    }

    if (thisReport === undefined) {
        return (
            <Loader />
        )
    } else {
        return (
            <div>
                <Helmet>
                    <title>{`${thisReport.Name} | ${thisReport.NowReportDayTh} ${thisReport.NowReportMonthFull} ${thisReport.NowReportYear} | ${isArchive ? "Archive" : "Latest"} snow and weather report from ${thisReport.AreaName} | SnowJapan`}</title>
                    <meta name="description" content={`${isArchive ? "Archive" : "Exclusive independent daily"} snow and weather reports from the ${thisReport.AreaName} of Japan. ${isArchive ? "All SnowJapan reports are exclusive and 100% independent" : "Snowfall is observed from base areas"}.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <DailyReportInfo dailyReport={thisReport} isArchive={isArchive} />
                {(isArchive) &&
                    <div className="sj-box sj-box-red sj-round-full center-aligned mt-5">
                        <h5>This is an archive report from {thisReport.NowReportWeekDay} {thisReport.NowReportDayTh} {thisReport.NowReportMonthFull} {thisReport.NowReportYear}</h5>
                    </div>
                }
                <hr className='mt-5' />
                <div className='empty-row'>
                    <pre>
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(thisReport.MainReport) }} />
                    </pre>
                    <div className='sj-para'>
                        <h4>Opening, lift & other notes</h4>
                    </div>
                    <pre>
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(thisReport.LiftOperation) }} />
                    </pre>
                </div>
                {thisReport.Season > '2011-2012' &&
                    <SnowfallStatistics dailyReport={thisReport} isArchive={isArchive} />
                }
                <div className='empty-row'>
                    {thisReport.Photo1 !== null &&
                        <div className='sj-image-holder-now center-aligned mt-5'>
                            <Image src={`https://www.snowjapan.com${thisReport.Photo1.replace('~', '')}`} fluid='true' alt='Report 1' />
                        </div>
                    }
                    {thisReport.Photo2 !== null &&
                        <div className='sj-image-holder-now center-aligned mt-4'>
                            <Image src={`https://www.snowjapan.com${thisReport.Photo2.replace('~', '')}`} fluid='true' alt='Report 2' />
                        </div>
                    }
                    {thisReport.Photo3 !== null &&
                        <div className='sj-image-holder-now center-aligned mt-4'>
                            <Image src={`https://www.snowjapan.com${thisReport.Photo3.replace('~', '')}`} fluid='true' alt='Report 3' />
                        </div>
                    }
                </div>
                {
                    (isArchive) &&
                    <div className="sj-box sj-box-red sj-round-full center-aligned mt-5">
                        <h5>This is an archive report from {thisReport.NowReportWeekDay} {thisReport.NowReportDayTh} {thisReport.NowReportMonthFull} {thisReport.NowReportYear}</h5>
                    </div>
                }
                {
                    (isArchive) &&
                    <div className='mt-5'>
                        <Link to={`${window.$baseDailyReportUrl}/${thisReport.UniqueName}`} className='btn btn-primary sj-large-button-blue sj-full-width'>View latest Yuzawa Now report</Link>
                    </div>
                }
            </div>
        )
    }
}

export default DetailDailyReport