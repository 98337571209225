import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SeasonSummary from './SeasonSummary'
import DailyObservedSnowfall from '../Common/DailyObservedSnowfall'
import SeasonReportCalendar from './SeasonReportCalendar';

const SeasonContainer = ({ dailyReport, seasonName }) => {
    const [years, setYears] = useState(seasonName.split('-'));
    const [dailySnowfallList, setDailySnowfallList] = useState([]);

    const fetchDailyObservedSnowfall = async (nowUniqueName, seasonName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/${nowUniqueName}/${seasonName}`
        }).then(resDailySnowfall => {
            setDailySnowfallList(resDailySnowfall.data)
        })
    };

    const getDailySnowfallMonthlyList = (month) => {
        let dailyData = dailySnowfallList.filter(function (currentElement) {
            return (currentElement.NowReportMonth === month);
        });

        return dailyData
    }

    useEffect(() => {
        fetchDailyObservedSnowfall(dailyReport.UniqueName, seasonName)
        setYears(seasonName.split('-'))
    }, [dailyReport.UniqueName, seasonName]);

    return (
        <div className='empty-row'>
            <Helmet>
                <title>{dailyReport.Name} | {seasonName} season snowfall data, analysis and archives from {dailyReport.AreaName} | SnowJapan</title>
                <meta name="description" content={`Annual observed snowfall data and analysis with links to archive reports from the ${dailyReport.AreaName} of Japan. Snowfall is observed from base areas.`} />
            </Helmet>
            <h3>{seasonName} season statistics & archives</h3>
            <div>
                All data is the snowfall observed by our reporters around base areas. Please read <Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/about-daily-reports`}>this</Link> to learn more about our reporting.
            </div>
            {seasonName > '2011-2012' &&
                <SeasonSummary dailyReport={dailyReport} seasonName={seasonName} />
            }
            {seasonName > '2011-2012' &&
                <DailyObservedSnowfall dailyReport={dailyReport} season={seasonName} dailySnowfallList={dailySnowfallList} />
            }
            <div className='mt-5'>
                <div className="sj-box sj-box-dark-gray sj-round-half center-aligned mt-2">
                    <h4>{seasonName} season calendar</h4>
                </div>
                <div className='center-aligned mt-3'>
                    Click on the date to view each individual archive report
                </div>
            </div>
            <div className='center-aligned sj-row' style={{ overflow: 'none' }}>
                <div className="row sj-row-no-margin-padding sj-no-bg">
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[0]} nowMonth={10} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(11)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[0]} nowMonth={11} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(12)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[1]} nowMonth={0} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(1)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[1]} nowMonth={1} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(2)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[1]} nowMonth={2} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(3)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SeasonReportCalendar dailyReport={dailyReport} nowYear={years[1]} nowMonth={3} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(4)} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SeasonContainer;