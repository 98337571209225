import { Link } from 'react-router-dom'

const SkiAreaInfo = ({ resort }) => {

  //const baseUrlPath = "/japan-ski-areas"

  return (
    <div className='sj-para'>
      <h3><Link to={`${window.$baseSkiAreaUrl}/town/${resort.TownUniqueName}`}>{resort.TownName} {resort.TownTypeStr}</Link>, <Link to={`${window.$baseSkiAreaUrl}/prefecture/${resort.PrefectureUniqueName}`}>{resort.PrefectureName}</Link></h3>
      <div className='sj-para-large mt-1 mb-3'>{resort.PrefectureNameJapanese}{resort.TownNameJapanese}</div>
    </div>
  );
}

export default SkiAreaInfo;