import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'

const ResortAutocomplete = ({ skiAreaList, onResortSelect }) => {

    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const [filteredSkiAreas, setFilteredSkiAreas] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [userInput, setUserInput] = useState('')

    //const history = useHistory()
    const navigate = useNavigate();

    const onChange = (e) => {
        let selectedSkiAreaNameParts = e.currentTarget.value.split('(')

        const userInput = selectedSkiAreaNameParts[0].trim();

        const thisFilteredSkiAreas = skiAreaList.filter(
            //skiArea => (skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.NameJapanese.indexOf(userInput) === 0 || skiArea.TownName.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.TownNameJapanese.indexOf(userInput) === 0 || (skiArea.AreaName !== null && skiArea.AreaName !== undefined && skiArea.AreaName.toLowerCase().indexOf(userInput.toLowerCase()) === 0) || (skiArea.AreaNameJapanese !== null && skiArea.AreaNameJapanese !== undefined && skiArea.AreaNameJapanese.indexOf(userInput) === 0))
            skiArea => (skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) >= 0 || skiArea.NameJapanese.indexOf(userInput) >= 0 || skiArea.TownName.toLowerCase().indexOf(userInput.toLowerCase()) >= 0 || skiArea.TownNameJapanese.indexOf(userInput) >= 0 || (skiArea.AreaName !== null && skiArea.AreaName !== undefined && skiArea.AreaName.toLowerCase().indexOf(userInput.toLowerCase()) >= 0) || (skiArea.AreaNameJapanese !== null && skiArea.AreaNameJapanese !== undefined && skiArea.AreaNameJapanese.indexOf(userInput) >= 0))
        );

        setActiveSuggestion(0)
        setFilteredSkiAreas(thisFilteredSkiAreas)
        setShowSuggestions(true)
        setUserInput(e.currentTarget.value)
    };

    const onClick = e => {
        setActiveSuggestion(0)
        setFilteredSkiAreas([])
        setShowSuggestions(false)
        setUserInput(e.currentTarget.innerText)

        let resortUniqueName = ''
        let selectedSkiAreaNameParts = e.currentTarget.innerText.split('(')

        const selectedSkiAreaList = skiAreaList.filter(
            skiArea => (skiArea.Name === selectedSkiAreaNameParts[0].trim())
        );

        if (selectedSkiAreaList.length > 0) {

            let selectedSkiArea = selectedSkiAreaList[0];

            //let path = `${window.$baseSkiAreaUrl}/${selectedSkiArea.PrefectureUniqueName}/${selectedSkiArea.TownUniqueName}/${selectedSkiArea.UniqueName}`;
            //history.push(path);
            //navigate(path);
            //console.log(selectedSkiArea)
            resortUniqueName = selectedSkiArea.UniqueName
        }

        onResortSelect(resortUniqueName)
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            setActiveSuggestion(0)
            setShowSuggestions(false)
            let selectedSkiArea = filteredSkiAreas[activeSuggestion]
            if (selectedSkiArea !== null && selectedSkiArea !== undefined) {
                setUserInput(selectedSkiArea.Name)
                let path = `${window.$baseSkiAreaUrl}/${selectedSkiArea.PrefectureUniqueName}/${selectedSkiArea.TownUniqueName}/${selectedSkiArea.UniqueName}`;
                //history.push(path);
                navigate(path);
            }
        }
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setActiveSuggestion(activeSuggestion - 1)
        }
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSkiAreas.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    };

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
        if (filteredSkiAreas.length) {
            suggestionsListComponent = (
                <ul className="suggestions">
                    {filteredSkiAreas.map((skiArea, index) => {
                        let className;

                        if (index === activeSuggestion) {
                            className = "suggestion-active";
                        }

                        return (
                            <li className={className} key={index} onClick={onClick}>
                                {skiArea.Name} ({skiArea.PrefectureName})
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            suggestionsListComponent = (
                <div className="no-suggestions">
                    <em>No ski area found</em>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <Form.Group controlId="txtResort" className="mt-2 mb-4">
                <Form.Label>
                    <h5>Choose ski area:</h5>
                    Type a ski area name, and available options will appear.
                </Form.Label>
                <Form.Control name="txtResort" type="text" onChange={onChange} onKeyDown={onKeyDown} value={userInput} />
                {suggestionsListComponent}
            </Form.Group>
        </Fragment>
    );
};

export default ResortAutocomplete;