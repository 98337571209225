import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from "../../../Common/Loader"
import SkiAreaInfo from '../SkiAreaInfo'
import ListingListTable from './ListingListTable'
import AccommodationListTable from './AccommodationListTable'

const ListingsContainer = ({ resort, nearbyAccommodations, areaSectionListings }) => {

    if (nearbyAccommodations.length <= 0 && areaSectionListings.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Listings of services near {resort.Name} ({resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan) | SnowJapan</title>
                    <meta name="description" content={`Listings of services near ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
                </Helmet>
                <div className='empty-row'>
                    <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Listings.svg" alt={`${resort.Name} listing`} /></div>
                    <div>
                        <SkiAreaInfo resort={resort} />
                        <div className="sj-para">
                            <h3>Listings</h3>
                            <div className="sj-para">
                                Introducing various services and organizations close to NASPA Ski Garden that are keen to be known by English-speaking visitors. All listings are also displayed within the main <Link to='/listings'>Listing</Link> section of SnowJapan.
                            </div>
                            <div className="sj-para">
                                SnowJapan does not own or operate any of the services or organizations introduced in this section. We do not take any reservations, and we cannot answer any specific queries about individual services. If you own a business or service and would like to be listed here, <Link to='/contact'>please contact us</Link>.
                            </div>
                        </div>
                    </div>
                </div>
                {nearbyAccommodations.length > 0 &&
                    <div className='sj-row'>
                        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2 mb-2">
                            <h4>Nearby Accommodations</h4>
                        </div>
                        <AccommodationListTable
                            tableData={nearbyAccommodations}
                            headingColumns={['', '', 'Area', 'Prefecture']}
                            dataMapping={['a', 'AccommodationName', 'AccommodationAreaName', 'AccommodationPrefectureName']}
                            title={``}
                        />
                    </div>
                }
                {
                    areaSectionListings.length > 0 &&
                    <>
                        {areaSectionListings.map((areaSectionListing, index) => (
                            <div key={index} className='mt-5'>
                                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2 mb-2">
                                    <h4>{areaSectionListing.sectionName}</h4>
                                </div>
                                <ListingListTable
                                    tableData={areaSectionListing.listings}
                                    headingColumns={['', '', 'Area', 'Prefecture']}
                                    dataMapping={['a', 'Name', 'AreaName', 'PrefectureName']}
                                    title=''
                                />
                            </div>
                        ))}
                    </>
                }
            </div>
        )
    }
};

export default ListingsContainer;