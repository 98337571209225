import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import SkiAreaHome from './Home'
import SkiAreaSearchContainer from './SkiAreaSearchContainer'
import SkiAreaPhotos from './SkiAreaPhotos'
import SkiAreaReviews from './SkiAreaReviews'
import SkiAreaListings from './Listings'

const SkiAreaContainer = () => {

    const params = useParams()

    return (
        <Fragment>
            <>
                {
                    {
                        undefined: <SkiAreaHome />,
                        'discover': <SkiAreaSearchContainer />,
                        'reviews': <SkiAreaReviews />,
                        'photos': <SkiAreaPhotos />,
                        'listings': <SkiAreaListings />
                    }[params.type]
                }
            </>
        </Fragment>
    )
}

export default SkiAreaContainer