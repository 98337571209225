import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import AccommodationInfo from '../AccommodationInfo'
import { RenderBreak } from '../../../../helpers/Helper'

const BasicContainer = ({ accommodation, nearbySkyAraList, accommodationPhotos }) => {

    let sortedAccommodationPhotos = []
    let firstAccommodationPhoto = undefined

    if (accommodationPhotos.length > 0) {
        sortedAccommodationPhotos = accommodationPhotos.filter(function (currentElement) {
            return (currentElement.SortOrder !== null);
        });

        sortedAccommodationPhotos.sort(function (a, b) {
            return a.SortOrder - b.SortOrder
        })

        if (sortedAccommodationPhotos.length > 0) {
            firstAccommodationPhoto = sortedAccommodationPhotos[0]
        }
    }

    return (
        <div className='empty-row'>
            <div className='empty-row'>
                {firstAccommodationPhoto !== undefined &&
                    <div className="accommodation-first-photo right-aligned">
                        <Image src={`https://www.snowjapan.com/UploadedFiles/HotelImages/Photos/${firstAccommodationPhoto.FileName}`} className='img-fluid' alt='Hotel 1' />
                    </div>
                }
                <div>
                    <AccommodationInfo accommodation={accommodation} firstAccommodationPhoto={firstAccommodationPhoto} />
                    <div className="sj-para">
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(accommodation.PageContent) }} />
                    </div>
                </div>
            </div>
            <div>
                <div className='ratio ratio-16x9'>
                    <iframe title='Location Map' width='930' height='600' src={`https://www.google.com/maps/embed/v1/place?q=${accommodation.Location_Latitude},${accommodation.Location_Longitude}&key=AIzaSyAmyWUwcoNW504tXuRlQ_mZssrKEEhGn20&zoom=${accommodation.Location_MapZoomLevel}`} allowFullScreen></iframe>
                </div>
            </div>
            <div className='empty-row'>
                <div className='row sj-no-bg sj-row-no-margin-padding'>
                    {sortedAccommodationPhotos.length > 1 &&
                        <div className='col-md-6 center-aligned mt-3'>
                            <Image src={`https://www.snowjapan.com/UploadedFiles/HotelImages/Photos/${sortedAccommodationPhotos[1].FileName}`} fluid='true' alt='Hotel 2' />
                        </div>
                    }
                    {sortedAccommodationPhotos.length > 2 &&
                        <div className='col-md-6 center-aligned mt-3'>
                            <Image src={`https://www.snowjapan.com/UploadedFiles/HotelImages/Photos/${sortedAccommodationPhotos[2].FileName}`} fluid='true' alt='Hotel 3' />
                        </div>
                    }
                </div>
            </div>
            <div className='empty-row padding-top'>
                <h2 className='mt-3'>Nearby Ski Areas</h2>
                <div>
                    <div className='row sj-frame-header sj-row-no-margin-padding'>
                        <div className="col-8">
                            <h5>Ski Area</h5>
                        </div>
                        <div className="col-4">
                            <h5>Distance</h5>
                        </div>
                    </div>
                    <div className='sj-data-table-holder'>
                        {nearbySkyAraList.map(nearbySkyAra => (
                            <div className='row sj-frame-body sj-row-no-margin-padding' key={nearbySkyAra.Id}>
                                <div className="col-8">
                                    <Link to={`${window.$baseSkiAreaUrl}/${nearbySkyAra.SkiAreaPrefectureName}/${nearbySkyAra.SkiAreaTownUniqueName}/${nearbySkyAra.SkiAreaUniqueName}`}>{nearbySkyAra.SkiAreaName}</Link>
                                </div>
                                <div className="col-4">
                                    {nearbySkyAra.Distance}km
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BasicContainer;