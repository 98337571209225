import axios from 'axios'
import { useEffect, useState } from 'react'

const useSkiAreaReviews = (queryReview, userName, pageNumberReview) => {

    const [loadingReview, setLoadingReview] = useState(false)
    const [errorReview, setErrorReview] = useState(false)
    const [reviews, setReviews] = useState([])
    const [hasMoreReview, setHasMoreReview] = useState(false)

    useEffect(() => {
        setReviews([])
    }, [queryReview, userName])

    useEffect(() => {
        if (queryReview === '' && userName === '')
            return null

        setLoadingReview(true)
        setErrorReview(false)

        let cancelReview
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/reviews/${pageNumberReview}`,
            data: {
                resortid: queryReview,
                username: userName
            },
            cancelToken: new axios.CancelToken(c => cancelReview = c)
        }).then(resReview => {
            if (resReview.data === 'No Data Found') {
                setHasMoreReview(false)
                setLoadingReview(false)
                return () => cancelReview()
            }
            setReviews(prevReviews => {
                return [...new Set([...prevReviews, ...resReview.data.map(p => p)])]
            })
            setHasMoreReview(resReview.data.length > 0)
            setLoadingReview(false)
        }).catch(e => {
            if (axios.isCancel(e))
                return
            setErrorReview(true)
        })

        return () => cancelReview()

    }, [queryReview, pageNumberReview])

    return { loadingReview, errorReview, reviews, hasMoreReview }
};

export default useSkiAreaReviews;