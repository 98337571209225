import Loader from "../Common/Loader"
import { Helmet } from "react-helmet";
import { RenderBreak } from '../../helpers/Helper'
import ListingList from './ListingList';

const ListingListContainer = ({ sectionUniqueName, listingSectionList, listingList }) => {

  //const [listingSection, setListingSection] = useState({})
  //const [listings, setListings] = useState([])

  const listingSection = listingSectionList.filter(function (currentElement) {
    return (currentElement.UniqueName === sectionUniqueName);
  });

  const listings = listingList.filter(function (currentElement) {
    return (currentElement.SectionUniqueName === sectionUniqueName);
  });

  if (listingSectionList.length > 0 && listingList.length > 0) {
    return (
      <>
        {(sectionUniqueName !== 'accommodation') &&
          <div className='empty-row'>
            <Helmet>
              <title>{listingSection[0].Name} near ski areas in Japan | SnowJapan</title>
              <meta name="description" content={`${listingSection[0].Name} near popular ski areas throughout Japan`} />
            </Helmet>
            <h3>{listingSection[0].Name}</h3>
            <div className="sj-para-image">
              {listingSection[0].UniqueName === 'resort-spotlights' &&
                <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-SkiArea.svg" alt={listingSection[0].Name} />
              }
              {listingSection[0].UniqueName === 'lessons' &&
                <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Facilities-Lessons.svg" alt={listingSection[0].Name} />
              }
              {listingSection[0].UniqueName === 'job-listings' &&
                <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Jobs-Available.svg" alt={listingSection[0].Name} />
              }
            </div>
            <div>
              <div className="sj-para">
                {(listingSection[0].IntroductionText !== null && listingSection[0].IntroductionText !== undefined && listingSection[0].IntroductionText !== '') &&
                  <div dangerouslySetInnerHTML={{ __html: RenderBreak(listingSection[0].IntroductionText) }} />
                }
              </div>
            </div>
            <ListingList listingList={listings} />
          </div>
        }
      </>
    )
  } else {
    return (
      <Loader />
    )
  }
};

export default ListingListContainer;