import { RenderBreak } from '../../../../helpers/Helper'

const Facilities = ({ resort }) => {

    return (
        <div className='empty-row'>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                <h4>{resort.Name} facilities</h4>
            </div>

            {resort.SnowParkFacilities !== '' &&
                <div className="sj-row">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Facilities-Snow-Park.svg" alt='park facilities' />
                        </div>
                        <div className="col-md-9 sj-row-no-margin-padding sj-list-text-holder">
                            <div className='sj-box sj-box-dark-gray sj-round-half'>
                                <h4>Park, off-piste & other activities</h4>
                            </div>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.SnowParkFacilities) }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {resort.InformationOnChildren !== '' &&
                <div className="mt-5">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Facilities-Children.svg" alt='children facilities' />
                        </div>
                        <div className="col-md-9 sj-row-no-margin-padding sj-list-text-holder">
                            <div className='sj-box sj-box-dark-gray sj-round-half'>
                                <h4>Families & children</h4>
                            </div>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.InformationOnChildren) }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {resort.SchoolInformation !== '' &&
                <div className="mt-5">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Facilities-Lessons.svg" alt='Lessons' />
                        </div>
                        <div className="col-md-9 sj-row-no-margin-padding sj-list-text-holder">
                            <div className='sj-box sj-box-dark-gray sj-round-half'>
                                <h4>Lessons</h4>
                            </div>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.SchoolInformation) }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {resort.EquipmentRentalInformation !== '' &&
                <div className="mt-5">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-md-3 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Facilities-Rental.svg" alt='Rental' />
                        </div>
                        <div className="col-md-9 sj-row-no-margin-padding sj-list-text-holder">
                            <div className='sj-box sj-box-dark-gray sj-round-half'>
                                <h4>Rental & other facilities</h4>
                            </div>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.EquipmentRentalInformation) }} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    );
}

export default Facilities;