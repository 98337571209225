import { useState } from 'react'
import { Link } from 'react-router-dom'
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const SkiAreaMapContainer = ({ mapCenter, mapZoom, skiAreaList }) => {

    const [selected, setSelected] = useState({});

    const onSelect = (item) => {
        const selectedItem = {
            name: item.Name,
            location: {
                lat: parseFloat(item.Location_Latitude),
                lng: parseFloat(item.Location_Longitude)
            },
            maximumElevation: item.MaximumElevation,
            minimumElevation: item.MinimumElevation,
            verticalDifference: item.VerticalDifference,
            totalSkiLifts: item.TotalSkiLifts,
            totalSkiCourses: item.TotalSkiCourses,
            longestCourse: item.LongestCourse,
            steepestSlope: item.SteepestSlope,
            uniqueName: item.UniqueName,
            prefectureUniqueName: item.PrefectureUniqueName,
            townUniqueName: item.TownUniqueName
        }

        setSelected(selectedItem);
    }

    const mapStyles = {
        height: "100vh",
        width: "100%"
    };

    return (
        <LoadScript
            googleMapsApiKey='AIzaSyAmyWUwcoNW504tXuRlQ_mZssrKEEhGn20'>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={mapZoom}
                center={mapCenter}
            >
                {
                    skiAreaList.map(item => {
                        return (
                            <Marker key={item.UniqueName} position={{ lat: parseFloat(item.Location_Latitude), lng: parseFloat(item.Location_Longitude) }} onClick={() => onSelect(item)} />
                        )
                    })
                }
                {
                    selected.location &&
                    (
                        <InfoWindow
                            position={selected.location}
                            clickable={true}
                            onCloseClick={() => setSelected({})}
                        >
                            <>
                                <Link to={`${window.$baseSkiAreaUrl}/${selected.prefectureUniqueName}/${selected.townUniqueName}/${selected.uniqueName}`}><h5>{selected.name}</h5></Link>
                                <p className='info-box-text'><strong>Top:</strong> {selected.maximumElevation}m</p>
                                <p className='info-box-text'><strong>Bottom:</strong> {selected.minimumElevation}m</p>
                                <p className='info-box-text'><strong>Vertical:</strong> {selected.verticalDifference}m</p>
                                <p className='info-box-text'><strong>Number of lifts:</strong> {selected.totalSkiLifts}</p>
                                <p className='info-box-text'><strong>Number of courses:</strong> {selected.totalSkiCourses}</p>
                                <p className='info-box-text'><strong>Longest course:</strong> {selected.longestCourse}m</p>
                                <p className='info-box-text'><strong>Steepest run:</strong> {selected.steepestSlope}º</p>
                            </>
                        </InfoWindow>
                    )
                }
            </GoogleMap>
        </LoadScript>
    )
}

export default SkiAreaMapContainer;