import { useState, useEffect } from 'react'

const PrefectureCheckList = ({ selectedPrefectures, options, onChange }) => {
    const [prefectureData, setPrefectureData] = useState(options);

    const toggle = index => {
        const newData = [...prefectureData];
        newData.splice(index, 1, {
            name: prefectureData[index].name,
            uniqueName: prefectureData[index].uniqueName,
            checked: !prefectureData[index].checked
        });
        setPrefectureData(newData);
        onChange(newData);
    };

    useEffect(() => {
        if (selectedPrefectures.length > 0) {
            setPrefectureData(selectedPrefectures)
        }
    }, [selectedPrefectures]);

    return (
        <>
            <h3>Prefectures</h3>
            {prefectureData.map((item, index) => (
                <div key={item.uniqueName}>
                    <label>
                        <input
                            readOnly
                            type="checkbox"
                            checked={item.checked || false}
                            onClick={() => toggle(index)}
                        /> {item.name}
                    </label><br />
                </div>
            ))}
        </>
    );
};

export default PrefectureCheckList;