import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Areas = () => {
    const [areaList, setAreaList] = useState([]);

    const fetchAreas = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/area/list`
        }).then(resArea => {
            setAreaList(resArea.data)
        })
    };

    useEffect(() => {
        fetchAreas();
    }, []);

    return (

        <div className='ski-area-listing-holder'>
            <ul className="sj-no-bullets-thin">
                {areaList.map(area => (
                    <li key={area.Id}><Link to={`${window.$baseSkiAreaUrl}/area/${area.UniqueName}`}>{area.Name} ({area.TotalResorts})</Link></li>
                ))}
            </ul>
        </div>
    )
};

export default Areas;