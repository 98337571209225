import { useState, useEffect } from 'react'
import styled from 'styled-components';

const CheckboxText = styled.span`
  ${props => props.Disabled && `
         color: #b7b7b7;
    `}
`;

const TownCheckList = ({ selectedPrefectures, selectedTowns, options, onChange }) => {
    const [townData, setTownData] = useState(options);

    const toggle = index => {
        const newData = [...townData];
        newData.splice(index, 1, {
            name: townData[index].name,
            uniqueName: townData[index].uniqueName,
            townType: townData[index].townType,
            townTypeStr: townData[index].townTypeStr,
            prefectureUniqueName: townData[index].prefectureUniqueName,
            checked: !townData[index].checked,
            disabled: townData[index].disabled
        });
        setTownData(newData);
        onChange(newData);
    };

    const refreshTownOpetions = (prefectures, allTowns, selTowns) => {
        let selectedTownList = selTowns.filter(function (currentElement) {
            return (currentElement.checked);
        });

        var selectedTownUniqueNames = selectedTownList.map(function (obj) { return obj.uniqueName; });

        allTowns = allTowns.map(item => {
            item.disabled = false;
            item.checked = selectedTownUniqueNames.includes(item.uniqueName);
            return item;
        })

        setTownData(allTowns);
    }

    useEffect(() => {
        refreshTownOpetions(selectedPrefectures, options, selectedTowns);
    }, [selectedPrefectures, options, selectedTowns]);

    return (
        <>
            <h3>Towns</h3>
            {townData.map((item, index) => (
                <div key={item.uniqueName}>
                    <label>
                        <input
                            readOnly
                            type="checkbox"
                            checked={item.checked || false}
                            onClick={() => toggle(index)}
                            disabled={item.disabled}
                        /> <CheckboxText Disabled={item.disabled}>{item.name} {item.townTypeStr}</CheckboxText>
                    </label><br />
                </div>
            ))}
        </>
    );
};

export default TownCheckList;