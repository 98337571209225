/* eslint-disable */
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Row, Collapse } from 'react-bootstrap'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const USER_NAME_REGEX = /^[A-z][A-z0-9-_]{3,23}$/
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/

const Register = () => {
    //const errRef = useRef()
    const [success, setSuccess] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [formerrors, setFormErrors] = useState({})
    const [isVisible, setIsVisible] = useState(false)

    const [values, setValues] = useState({
        FullName: "",
        Email: "",
        Username: "",
        Password: "",
        ConfirmPassword: ""
    })

    const invokeCollapse = () => {
        return setIsVisible(!isVisible)
    }

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const validate = () => {
        let errors = {}

        if (!values.FullName) {
            errors.FullName = "The full name field cannot be blank."
        }

        if (!values.Email) {
            errors.Email = "The email field cannot be blank."
        } else if (!EMAIL_REGEX.test(values.Email)) {
            errors.Email = "The email address is invalid."
        }

        if (!values.Username) {
            errors.Username = "The username field cannot be blank."
        } else if (!USER_NAME_REGEX.test(values.Username)) {
            errors.Username = "The username is not valid."
        }

        if (!values.Password) {
            errors.Password = "The password field cannot be blank."
        }
        else if (!PWD_REGEX.test(values.Password)) {
            errors.Password = "The password field is not valid."
        }

        if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "The confirm password field cannot be blank."
        } else if (values.Password !== values.ConfirmPassword) {
            errors.ConfirmPassword = "The confirm password did not match with the password."
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate(values)) {
            try {
                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/Account/Register`,
                    data: {
                        FullName: values.FullName,
                        Email: values.Email,
                        UserName: values.Username,
                        Password: values.Password,
                        ConfirmPassword: values.ConfirmPassword
                    }
                }).then(registrationResult => {

                    console.log(registrationResult?.data);

                    let registeredUser = registrationResult.data

                    if (registeredUser !== null) {
                        console.log(registeredUser)
                        if (registeredUser.ResultType === 'SUCCESS') {
                            setValues({
                                FullName: "",
                                Email: "",
                                Username: "",
                                Password: "",
                                ConfirmPassword: ""
                            })
                            setSuccess(true)
                        } else {
                            //setSuccess(false)
                            setErrMsg(registeredUser.Message)
                            errRef.current.focus()
                        }
                    } else {
                        //setSuccess(false)
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                        //errRef.current.focus()
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                }
                //errRef.current.focus()
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Register  | Member area | SnowJapan</title>
                <meta name="description" content="Register a new account to upload photos and submit reviews on SnowJapan." />
            </Helmet>
            <div>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Icons-Member.svg" alt='member-login' /></div>
                <div>
                    <h3>SnowJapan member area</h3>
                    <div className="sj-para">
                        <p>Thank you in advance for sharing your thoughts and/or photographs of Japanese ski areas with other SnowJapan readers.</p>
                        <p>Be assured that your email address is strictly only used for registration purposes. It is not shared with anyone or added to any mailing list, and you will not receive any emails from us.</p>
                        <p>Before submitting content to SnowJapan, <Link to={`${window.$baseAppUrl}/about-members-area`}>please read this</Link>.</p>
                    </div>
                </div>
                <div className="empty-row">
                    {success ? (
                        <section>
                            <h1>Success!</h1>
                            <p>
                                <div>A confirmation email has been sent to you. Please click the link on the email to activate your account.</div>
                            </p>
                        </section>
                    ) : (
                        <>
                            {errMsg !== '' &&
                                <div>
                                    <h1 className="text-danger">Error!</h1>
                                    <div className="text-danger">{errMsg}</div>
                                </div>
                            }
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3 sj-no-margin">
                                    <Form.Group controlId="txtFullName" className="mt-2 mb-4">
                                        <Form.Label>
                                            <h5>Name:</h5>
                                            Your name will never be displayed on the site or shared.
                                        </Form.Label>
                                        <Form.Control name="txtFullName" type="text" placeholder="Full name" value={values.FullName} onChange={handleChange} />
                                        {formerrors.FullName && (
                                            <div className="text-danger">{formerrors.FullName}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtEmail" className="mb-4">
                                        <Form.Label><h5>Email:</h5>
                                            Your email address will never be displayed on the site or shared.
                                        </Form.Label>
                                        <Form.Control name="txtEmail" type="email" placeholder="Email" aria-describedby="igEmail" value={values.Email} onChange={handleChange} />
                                        {formerrors.Email && (
                                            <div className="text-danger">{formerrors.Email}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtUsername" className="mt-2 mb-4">
                                        <Form.Label>
                                            <h5>Username:</h5>
                                            Your username will be displayed on the site alongside your content.
                                        </Form.Label>
                                        <Form.Control name="txtUsername" type="text" placeholder="Username" value={values.Username} onChange={handleChange} />
                                        {formerrors.Username && (
                                            <div className="text-danger">{formerrors.Username}</div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtPassword" className="mb-4">
                                        <Form.Label>
                                            <h5>Password:</h5>
                                        </Form.Label>
                                        <Form.Control name="txtPassword" type="password" placeholder="Password" value={values.Password} onChange={handleChange} />
                                        {formerrors.Password && (
                                            <div className="text-danger">{formerrors.Password}
                                                {formerrors.Password === 'The password field is not valid.' &&
                                                    <>
                                                        <FontAwesomeIcon icon={faQuestionCircle} onClick={invokeCollapse} />
                                                        <Collapse in={isVisible}>
                                                            <div id="collapsePanel">
                                                                <div>
                                                                    8 to 24 characters.<br />
                                                                    Must include uppercase and lowercase letters, a number and a special character.<br />
                                                                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtConfirmPassword" className="mb-4">
                                        <Form.Label><h5>Confirm password:</h5></Form.Label>
                                        <Form.Control name="txtConfirmPassword" type="password" placeholder="Confirm password" value={values.ConfirmPassword} onChange={handleChange} />
                                        {formerrors.ConfirmPassword && (

                                            <div className="text-danger">{formerrors.ConfirmPassword}</div>
                                        )}
                                    </Form.Group>
                                    <div className='center-aligned'>
                                        <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Register</Button>
                                    </div>
                                </Row>
                            </Form>
                        </>
                    )}
                    <div>
                        <div className='sj-row mt-3 pt-3'>
                            <h3>Already registered?</h3>
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-md-6 sj-row-no-margin-padding">
                                    <Link to={`${window.$baseAppUrl}/login`} className='btn btn-primary w-100 sj-box-dark-blue mt-2'><h6 className='mt-1 mb-1'>Login here</h6></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Register