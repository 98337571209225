import axios from 'axios'
import { useState, useEffect } from 'react'
import AllReportCalendar from './AllReportCalendar';

const Calendar = ({ seasonName }) => {
    const [years, setYears] = useState(seasonName.split('-'));
    const [dailySnowfallList, setDailySnowfallList] = useState([]);

    const fetchDailyObservedSnowfall = async (thisSeasonName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/All/${thisSeasonName}`
            //url: `${window.$baseAPIUrl}/dailyreport/reports/All/2020-2021`
        }).then(resDailySnowfall => {
            setDailySnowfallList(resDailySnowfall.data)
        })
    };

    const getDailySnowfallMonthlyList = (month) => {
        let dailyData = dailySnowfallList.filter(function (currentElement) {
            return (currentElement.NowReportMonth === month);
        });

        return dailyData
    }

    useEffect(() => {
        fetchDailyObservedSnowfall(seasonName)
        setYears(seasonName.split('-'))
    }, [seasonName]);

    return (
        <div className='empty-row'>
            <div>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Calendar.svg" alt="Japan as 'Snow Country'" /></div>
                <div>
                    <h3>Daily snowfall calendar</h3>
                    <div className="sj-para">
                        <h3 className='sj-red-header'>{seasonName} season</h3>
                        View any of our Now daily reports from across the Japan using the links on the calendar below. Detailed snowfall analysis and archives are available within each report section.
                    </div>
                </div>
            </div>
            <div className='center-aligned sj-row' style={{ overflow: 'none' }}>
                <div className="row sj-row-no-margin-padding sj-no-bg">
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[0]} nowMonth={10} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(11)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[0]} nowMonth={11} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(12)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[1]} nowMonth={0} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(1)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[1]} nowMonth={1} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(2)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[1]} nowMonth={2} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(3)} />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <AllReportCalendar nowYear={years[1]} nowMonth={3} dailySnowfallMonthlyList={getDailySnowfallMonthlyList(4)} />
                    </div>
                </div>
            </div>
        </div >
    )
};

export default Calendar;