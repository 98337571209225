import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from "../../../Common/Loader"
import SkiAreaInfo from '../SkiAreaInfo'
import useSkiAreaReviews from '../../../CustomHooks/useSkiAreaReviews'
import ReviewDetail from './ReviewDetail'

const ReviewsContainer = ({ resort }) => {
    const queryReview = resort.Id
    const userName = ''
    const [pageNumberReview, setPaegNumberReview] = useState(1)

    const {
        loadingReview,
        errorReview,
        reviews,
        hasMoreReview
    } = useSkiAreaReviews(queryReview, userName, pageNumberReview)

    const observerReview = useRef()
    const lastReviewElementRef = useCallback(nodeReview => {
        if (loadingReview)
            return
        if (observerReview.current)
            observerReview.current.disconnect()
        observerReview.current = new IntersectionObserver(entriesReview => {
            if (entriesReview[0].isIntersecting && hasMoreReview) {
                setPaegNumberReview(prevPageNumberReview => prevPageNumberReview + 1)
            }
        })

        if (nodeReview)
            observerReview.current.observe(nodeReview)
    }, [loadingReview, hasMoreReview])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Reviews of {resort.Name} ({resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan) | SnowJapan</title>
                <meta name="description" content={`Reader reviews of ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
            </Helmet>
            <div className='empty-row'>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Reviews.svg" alt={`${resort.Name} review`} /></div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        <h3>Reader reviews of {resort.Name}</h3>
                        <div className="sj-para">
                            Reviews of <strong>{resort.Name}</strong> that have been posted by SnowJapan members. The views expressed in the reviews are not those of SnowJapan.
                        </div>
                        <div className="sj-para">
                            <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/submit-review/${resort.UniqueName}`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Write a new ski area review</Link></h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='empty-row'>
                <div className="sj-para">
                    <h3>Recent reviews</h3>
                </div>
                {reviews.map((review, index) => {
                    if (reviews.length === index + 1) {
                        return (
                            <div ref={lastReviewElementRef} key={review.Id}>
                                <ReviewDetail resort={resort} review={review} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={review.Id}>
                                <ReviewDetail resort={resort} review={review} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingReview &&
                        <Loader />
                    }
                </div>
                <div>{errorReview && 'Error'}</div>
            </div>
        </div>
    )
};

export default ReviewsContainer;