import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import AccommodationListTable from './AccommodationListTable';

const SearchResult = ({ allAccommodationList, ClearSort, prefectures, areas, towns, nearbySkiAreas, noFilter }) => {

    const [searchedAccommodationList, setSearchedAccommodationList] = useState(allAccommodationList)

    const [buttonTitle, setButtonTitle] = useState('Sort By: Accommodation name [A-Z]')
    const [sortColumn, setSortColumn] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [searchFilterPrefecture, setSearchFilterPrefecture] = useState('')
    const [searchFilterArea, setSearchFilterArea] = useState('')
    const [searchFilterTown, setSearchFilterTown] = useState('')
    const [searchFilterNearbySkiArea, setSearchFilterNearbySkiArea] = useState('')


    const ClearSortValues = () => {
        setButtonTitle('Sort By: Accommodation name [A-Z]')
        setSortColumn('')
        setSortOrder('')
    }

    const PerpareSearchFilter = () => {
        if (prefectures.length > 0) {

            let selectedPrefectures = prefectures.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let prefectureList = [...new Set(selectedPrefectures.map(item => item.name))].sort();

            if (prefectureList.length > 0) {
                setSearchFilterPrefecture(prefectureList.join(', '))
            }
        }

        if (areas.length > 0) {

            let selectedAreas = areas.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let areaList = [...new Set(selectedAreas.map(item => item.name))].sort();

            if (areaList.length > 0) {
                setSearchFilterArea(areaList.join(', '))
            }
        }

        if (towns.length > 0) {

            let selectedTowns = towns.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let townList = [...new Set(selectedTowns.map(item => item.name))].sort();

            if (townList.length > 0) {
                setSearchFilterTown(townList.join(', '))
            }
        }

        if (nearbySkiAreas.length > 0) {

            let selectedNearbySkiAreas = nearbySkiAreas.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let nearbySkiAreaList = [...new Set(selectedNearbySkiAreas.map(item => item.name))].sort();

            if (nearbySkiAreaList.length > 0) {
                setSearchFilterNearbySkiArea(nearbySkiAreaList.join(', '))
            }
        }
    }

    useEffect(() => {
        PerpareSearchFilter()
        setSearchedAccommodationList(allAccommodationList)
        ClearSortValues()
        if (noFilter) {
            setSearchFilterPrefecture('')
            setSearchFilterArea('')
            setSearchFilterTown('')
            setSearchFilterNearbySkiArea('')
        }
    }, [allAccommodationList, noFilter])

    useEffect(() => {
        ClearSort.current = ClearSortValues
    }, [ClearSort])

    const SortResult = (evtKey, evt) => {

        var sortedResult = []
        var sortColumn = ''
        var sortOrder = ''

        if (evtKey === '1' || evtKey === '2') {
            sortedResult = searchedAccommodationList.sort(function (a, b) {
                let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();

                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }
                return 0;
            })
            sortOrder = "ASC"

            if (evtKey === '2') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            sortColumn = "Accommodation name"

        } else if (evtKey === '3' || evtKey === '4') {
            sortedResult = searchedAccommodationList.sort(function (a, b) {
                let nameA = a.AreaName.toLowerCase(), nameB = b.AreaName.toLowerCase();

                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }

                if (a.Name > b.Name) return 1;
                if (a.Name < b.Name) return -1;

                return 0;
            })
            sortOrder = "ASC"

            if (evtKey === '4') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            sortColumn = "Area"

        } else if (evtKey === '5' || evtKey === '6') {
            sortedResult = searchedAccommodationList.sort(function (a, b) {
                let nameA = a.PrefectureName.toLowerCase(), nameB = b.PrefectureName.toLowerCase();

                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }

                if (a.Name > b.Name) return 1;
                if (a.Name < b.Name) return -1;

                return 0;
            })
            sortOrder = "ASC"

            if (evtKey === '6') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            sortColumn = "Prefecture"

        }

        setSearchedAccommodationList(sortedResult)
        setButtonTitle('Sort by: ' + evt.target.innerText)
        setSortColumn(sortColumn)
        setSortOrder(sortOrder)
    }


    if (searchedAccommodationList.length > 0) {
        return (
            <>
                <div className="empty-row">
                    <div className='row sj-no-bg sj-row-no-margin-padding'>
                        <div className='col-md-6 sj-row-no-margin-padding'>
                            {searchFilterPrefecture !== '' &&
                                <div><strong>[Prefecture] </strong>{searchFilterPrefecture}</div>
                            }
                            {searchFilterArea !== '' &&
                                <div><strong>[Area] </strong>{searchFilterArea}</div>
                            }
                            {searchFilterTown !== '' &&
                                <div><strong>[Town] </strong>{searchFilterTown}</div>
                            }
                            {searchFilterNearbySkiArea !== '' &&
                                <div><strong>[Nearby Ski areas] </strong>{searchFilterNearbySkiArea}</div>
                            }
                            {(searchFilterPrefecture === '' && searchFilterArea === '' && searchFilterTown === '' && searchFilterNearbySkiArea === '') &&
                                <div>No filters selected</div>
                            }
                        </div>
                        <div className='col-md-6 right-aligned sj-row-no-margin-padding'>
                            <DropdownButton align="end" title={buttonTitle} onSelect={SortResult} id="dropdown-menu-align-end" className='sj-large-font-button'>
                                <Dropdown.Item eventKey="1">Accommodation name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Accommodation name [Z-A]</Dropdown.Item>
                                <Dropdown.Item eventKey="3">Area name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="4">Area name [Z-A]</Dropdown.Item>
                                <Dropdown.Item eventKey="5">Prefecture name [A-Z]</Dropdown.Item>
                                <Dropdown.Item eventKey="6">Prefecture name [Z-A]</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                    <AccommodationListTable
                        tableData={searchedAccommodationList}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        headingColumns={['', 'Accommodation name', 'Area', 'Prefecture']}
                        dataMapping={['a', 'Name', 'AreaName', 'PrefectureName']}
                        title={`Selected accommodations`}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <h3>No Result Found</h3>
            </>
        )
    }
};

export default SearchResult;