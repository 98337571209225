import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import DailySnowfall from './DailySnowfall'
import MonthlySnowfall from './MonthlySnowfall'
import MonthlySnowfallTable from './MonthlySnowfallTable'
import SeasonSnowfall from './SeasonSnowfall'
import DaylySnowfallTable from './DaylySnowfallTable'
import { GetCurrentSeasonName } from '../../../../helpers/Helper'

const ObservedSnowfallAnalysisContainer = ({ dailyReport }) => {

    const currentSeason = GetCurrentSeasonName()
    const [monthlySnowfall, setMonthlySnowfall] = useState([]);

    const fetchMonthlySnowfallWithPrevMonth = async (nowId) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/monthly-snowfall-with-prev-month/${nowId}`
        }).then(resMonthlySnowfallWithPrevMonth => {
            setMonthlySnowfall(resMonthlySnowfallWithPrevMonth.data)
        })
    };

    useEffect(() => {
        fetchMonthlySnowfallWithPrevMonth(dailyReport.Now_Id)
    }, [dailyReport.Now_Id]);

    return (
        <div>
            <Helmet>
                <title>{dailyReport.Name} | Snowfall data and analysis from from {dailyReport.AreaName} | SnowJapan</title>
                <meta name="description" content={`Daily and monthly observed snowfall data and analysis from the ${dailyReport.AreaName} of Japan. Snowfall is observed from base areas.`} />
            </Helmet>
            <h3>Snowfall analysis</h3>
            <div className='sj-para'>
                All data is the snowfall observed by our reporters around base areas. Please read <Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}/about-daily-reports`}>this</Link> to learn more about our reporting.
            </div>
            <DailySnowfall dailyReport={dailyReport} />
            <MonthlySnowfall monthlySnowfall={monthlySnowfall} />
            <MonthlySnowfallTable monthlySnowfall={monthlySnowfall} nowUniqueName={dailyReport.UniqueName} />
            <SeasonSnowfall dailyReport={dailyReport} />
            <DaylySnowfallTable dailyReport={dailyReport} season={currentSeason} />
        </div>
    )
};

export default ObservedSnowfallAnalysisContainer;