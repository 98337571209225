import PropTypes from 'prop-types'
import { Button, Modal, Image } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'

const MemberPhotoDetail = ({ photo, user }) => {
    //console.log(photo)
    //const [show, setShow] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()

    /*const handleClose = () => {
        setShow(false)
    }
    const showModal = () => {
        setShow(true)
    }*/

    const handleDeletePhoto = (e) => {
        //console.log('agreed to delete')
        //console.log(e)
        if (e > 0) {
            try {
                var inputData = {
                    Id: e,
                    Caption: "a",
                    ResortUniqueName: "a",
                    Email: user.Email,
                    Token: user.Token
                }
                console.log(inputData)

                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/sjmember/DeletePhoto`,
                    data: inputData
                }).then(photoDeleteResult => {

                    console.log(photoDeleteResult?.data);

                    let photoDelete = photoDeleteResult.data

                    if (photoDelete !== null) {
                        console.log(photoDelete)

                        let path = window.$baseMemberUrl;
                        navigate(path);
                    } else {
                        setErrMsg('An error occured. Please contact SnowJapan administrator')
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                }
            }
        }
    }

    return (
        <div>
            <div className="sj-box sj-box-blue sj-round-half left-aligned mt-3">
                <h4>{photo.Caption}</h4>
            </div>
            {(photo.ResortUniqueName !== undefined && photo.ResortUniqueName !== null) &&
                <div className="row sj-row-small-margin-padding sj-no-bg">
                    <div>
                        <Link to={`${window.$baseSkiAreaUrl}/${photo.PrefectureUniqueName}/${photo.TownUniqueName}/${photo.ResortUniqueName}`}><h4>{photo.ResortName}</h4></Link>
                    </div>
                    <div className='sj-large-font'>
                        <Link to={`${window.$baseSkiAreaUrl}/town/${photo.TownUniqueName}`}>{photo.TownName} {photo.TownTypeStr}</Link>,&nbsp;
                        <Link to={`${window.$baseSkiAreaUrl}/prefecture/${photo.PrefectureUniqueName}`}>{photo.PrefectureName}</Link>
                    </div>
                </div>
            }
            <div className='sj-row-small-margin-padding center-aligned'>
                <Image src={`${window.$baseAppUrl}/photo-galleries/${photo.CreatedByUserName}/${photo.PhotoFileName}`} alt={`thumb-${photo.Id}`} fluid='true' />
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Uploaded by:</strong>
                </div>
                <div className="col-lg-10">
                    {photo.CreatedByUserName}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Uploaded on:</strong>
                </div>
                <div className="col-lg-10">
                    {photo.CreatedAtDayTh} {photo.CreatedAtMonthFull} {photo.CreatedAtYear}
                </div>
            </div>
            <div className="sj-row-no-margin-padding sj-no-bg">
                <Button variant="primary" onClick={() => {
                    const confirmBox = window.confirm(
                        "Do you really want to delete this photo?"
                    )
                    if (confirmBox === true) {
                        handleDeletePhoto(photo.Id)
                    }
                }} className='sj-large-font-button sj-red-bg w-100'>{'Delete Photo'}</Button>
            </div>
            <hr className='mt-5' />
            {/*<Modal
                show={show}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{photo.Caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='center-aligned'>
                        <img src={`${window.$baseAppUrl}/photo-galleries/${photo.CreatedByUserName}/${photo.PhotoFileName}`} alt={`full-${photo.Id}`} />
                    </div>
                    {(photo.ResortUniqueName !== undefined && photo.ResortUniqueName !== null) &&
                        <div className="row sj-row-small-margin-padding">
                            <div>
                                <Link to={`${window.$baseSkiAreaUrl}/${photo.PrefectureUniqueName}/${photo.TownUniqueName}/${photo.ResortUniqueName}`}><h5>{photo.ResortName}</h5></Link>
                                (<Link to={`${window.$baseSkiAreaUrl}/town/${photo.TownUniqueName}`}>{photo.TownName} {photo.TownTypeStr}</Link>,&nbsp;
                                <Link to={`${window.$baseSkiAreaUrl}/prefecture/${photo.PrefectureUniqueName}`}>{photo.PrefectureName}</Link>)
                            </div>
                        </div>
                    }
                    <div className="row sj-row-small-margin-padding">
                        <div className="col-lg-3">
                            <strong>Uploaded by:</strong>
                        </div>
                        <div className="col-lg-9">
                            {photo.CreatedByUserName}
                        </div>
                    </div>
                    <div className="row sj-row-small-margin-padding">
                        <div className="col-lg-3">
                            <strong>Uploaded on:</strong>
                        </div>
                        <div className="col-lg-9">
                            {photo.CreatedAtDayTh} {photo.CreatedAtMonthFull} {photo.CreatedAtYear}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
                </Modal>*/}
        </div>
    )
};

MemberPhotoDetail.propTypes = {
    photo: PropTypes.object.isRequired
}

export default MemberPhotoDetail;