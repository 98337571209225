import { useState } from 'react'
import { Button, Collapse } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const WeatherContainer = ({ dailyReport }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <Helmet>
                <title>Yuzawa Now | Real-time snowfall resources, snow forecasts and weather maps from {dailyReport.AreaName} | SnowJapan</title>
                <meta name="description" content={`Real time snowfall maps, snow and weather forecasts and weather maps for ${dailyReport.AreaName} of Japan.`} />
            </Helmet>
            <h3>Weather</h3>
            <div>
                The <strong>Japan Meteorological Agency (JMA)</strong> publishes various real-time snowfall, weather and forecast related resources. We introduce various resources for the region covered by {dailyReport.Name} located on this page. All links open in a new window. All maps are centred on the region.
            </div>
            <div className='sj-para sj-red-text'>
                Important: The below information is provided to give a general insight into the weather and snowfall conditions in the region. It is not ski area specific. Actual snowfall is often more than shown, especially at higher elevations.
            </div>
            <div className="sj-row">
                <div>
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2">
                        <h4>{dailyReport.Name} region</h4>
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Town information</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:normal/elements:snowd`} target='_blank' rel='noreferrer'>Snow depth</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`${dailyReport.HotelBookingLink1}`} target='_blank' rel='noreferrer'>Weather Advisory</a></h5>
                            </div>
                        </div>
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Precipitation analysis</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_nowc/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:deep/elements:hrpns&slmcs&amds_rain10m`} target='_blank' rel='noreferrer'>Now</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_kaikotan/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:normal/#zoom:6/elements:slmcs&rasrf24h`} target='_blank' rel='noreferrer'>24-hour</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:deep/elements:snowd`} target='_blank' rel='noreferrer'>Snow depth</a></h5>
                            </div>
                        </div>
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Snowfall</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:deep/elements:snowf24h`} target='_blank' rel='noreferrer'>24-hour snowfall</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:deep/elements:snowf48h`} target='_blank' rel='noreferrer'>48-hour snowfall</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${dailyReport.Location_Latitude}/lon:${dailyReport.Location_Longitude}/colordepth:deep/elements:snowf72h`} target='_blank' rel='noreferrer'>72-hour snowfall</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sj-row">
                The JMA is an agency of the Ministry of Land, Infrastructure, Transport and Tourism.
            </div>
            <div className="sj-row">
                <h3 className='mt-5'>About weather forecasts</h3>
                We do not specifically recommend any detailed weather forecasting service for all regions of Japan. However, many forecasts, both in Japanese and English, are free to use and widely available. Such services offer varying accuracy, with some seemingly more accurate for some areas of Japan than others.
            </div>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                <h4>Weather map</h4>
            </div>
            <div className="sj-row">
                <div className="row sj-row">
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-expanded={open} className='w-100 mb-1'><h5 className='mt-1'>{open ? 'Click to hide map -' : 'Click to view map +'}</h5></Button>
                    <Collapse in={open}>
                        <div className='ratio ratio-16x9'>
                            {(open) &&
                                <iframe title='Various animated forecasts' src={`${dailyReport.WeatherMapUrl}`} allowFullScreen></iframe>
                            }
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )
};

export default WeatherContainer;