import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Offcanvas } from 'react-bootstrap'
import Loader from '../Common/Loader'
import PrefectureCheckList from '../Common/PrefectureCheckList'
import TownCheckList from '../Common/TownCheckList'
import AreaCheckList from '../Common/AreaCheckList'
import SearchResult from './SearchResult'

const SkiAreaSearch = ({ skiAreaList }) => {

    const params = useParams()

    const [allSkiAreaList, setAllSkiAreaList] = useState([])

    // Prefectures
    const [prefectures, setPrefectures] = useState([])
    const [selectedPrefectures, setSelectedPrefectures] = useState([])

    // Areas
    const [allAreas, setAllAreas] = useState([])
    const [selectedAreas, setSelectedAreas] = useState([])

    // Towns
    const [allTowns, setAllTowns] = useState([])
    const [selectedTowns, setSelectedTowns] = useState([])

    const ClearSort = useRef(null)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [noFilter, setNoFilter] = useState(true)

    // Pre-Searched Prefecture, Area and Town List
    const getAllSkiAreaPrefectures = (resultSkiAreaList) => {
        if (resultSkiAreaList.length > 0) {
            const prefectures = [...new Map(resultSkiAreaList.map(item => [item['PrefectureUniqueName'], item])).values()]

            const uniquePrefectures = prefectures.map(p => ({ name: p.PrefectureName, uniqueName: p.PrefectureUniqueName, checked: false }))

            uniquePrefectures.sort(function (a, b) {
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0

            })

            setPrefectures(uniquePrefectures)
        }
    }

    const getAllSkiAreaAreas = (allSkiAreas) => {

        if (allSkiAreas.length > 0) {
            const areas = [...new Map(allSkiAreas.map(item => [item['AreaUniqueName'], item])).values()]

            let filteredAreas = areas.filter(function (currentElement) {
                return (currentElement.AreaUniqueName != null)
            })

            const uniqueAreas = filteredAreas.map(a => ({ name: a.AreaName, uniqueName: a.AreaUniqueName, prefectureUniqueName: a.PrefectureUniqueName, checked: false, disabled: false }));

            uniqueAreas.sort(function (a, b) {
                if (a.name === null || b.name === null) {
                    return 0
                }
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })

            setAllAreas(uniqueAreas)
        }
    }

    const getAllSkiAreaTowns = (allSkiAreas) => {
        if (allSkiAreas.length > 0) {
            const towns = [...new Map(allSkiAreas.map(item => [item['TownUniqueName'], item])).values()];

            const uniqueTowns = towns.map(t => ({ name: t.TownName, uniqueName: t.TownUniqueName, townTypeStr: t.TownTypeStr, prefectureUniqueName: t.PrefectureUniqueName, checked: false, disabled: false }));

            uniqueTowns.sort(function (a, b) {
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0

            })

            setAllTowns(uniqueTowns)
        }
    }

    const getSearchResult = (thisSkiAreaList) => {
        let searchedSkiAreas = thisSkiAreaList

        // Prefecture Search
        let selectedPrefectureList = selectedPrefectures.filter(function (currentElement) {
            return (currentElement.checked)
        });

        var searchedSkiAreasPrefecture = []
        var searchedSkiAreasArea = []
        var searchedSkiAreasTown = []

        if (selectedPrefectureList.length > 0) {

            var prefectureUniqueNames = selectedPrefectureList.map(function (obj) { return obj.uniqueName; })

            searchedSkiAreasPrefecture = thisSkiAreaList.filter(({ PrefectureUniqueName }) => prefectureUniqueNames.includes(PrefectureUniqueName))
        }

        // Area Search
        let selectedAreaList = selectedAreas.filter(function (currentElement) {
            return (currentElement.checked)
        });

        if (selectedAreaList.length > 0) {

            var areaUniqueNames = selectedAreaList.map(function (obj) { return obj.uniqueName; })

            searchedSkiAreasArea = thisSkiAreaList.filter(({ AreaUniqueName }) => areaUniqueNames.includes(AreaUniqueName))
        }

        // Town Search
        let selectedTownList = selectedTowns.filter(function (currentElement) {
            return (currentElement.checked);
        });

        if (selectedTownList.length > 0) {

            var townUniqueNames = selectedTownList.map(function (obj) { return obj.uniqueName; })

            searchedSkiAreasTown = thisSkiAreaList.filter(({ TownUniqueName }) => townUniqueNames.includes(TownUniqueName))
        }

        if (selectedPrefectureList.length > 0 || selectedAreaList.length > 0 || selectedTownList.length > 0) {
            searchedSkiAreas = [...new Set([...searchedSkiAreasPrefecture, ...searchedSkiAreasArea, ...searchedSkiAreasTown])]

            setNoFilter(false)
        }

        searchedSkiAreas = searchedSkiAreas.sort(function (a, b) {
            let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase()

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })

        setAllSkiAreaList(searchedSkiAreas)
        getAllSkiAreaPrefectures(searchedSkiAreas)
        getAllSkiAreaAreas(skiAreaList)
        getAllSkiAreaTowns(skiAreaList)
    }

    useEffect(() => {
        getSearchResult(skiAreaList)

    }, [params, skiAreaList, selectedPrefectures, selectedTowns, selectedAreas])

    const ClearSearch = () => {

        if (selectedPrefectures.length > 0) {
            const selPrefectures = selectedPrefectures.map(p => ({ name: p.name, uniqueName: p.uniqueName, checked: false }));
            setSelectedPrefectures(selPrefectures)
        }

        if (selectedAreas.length > 0) {
            const selAreas = selectedAreas.map(a => ({ name: a.AreaName, uniqueName: a.AreaUniqueName, prefectureUniqueName: a.PrefectureUniqueName, checked: false, disabled: false }));
            setSelectedAreas(selAreas)
        }

        if (selectedTowns.length > 0) {
            const selTowns = selectedTowns.map(t => ({ name: t.TownName, uniqueName: t.TownUniqueName, prefectureUniqueName: t.PrefectureUniqueName, checked: false, disabled: false }));
            setSelectedTowns(selTowns)
        }

        getSearchResult(skiAreaList)
        setNoFilter(true)

        ClearSort.current()
    }

    if (prefectures.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <>
                <Button variant="primary" onClick={handleShow} className='sj-large-font-button sj-red-bg'>{'< Select filters'}</Button>&nbsp;
                <Button variant="primary" onClick={ClearSearch} className='sj-large-font-button'>Clear all filters</Button>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <h3>Choose filter(s)</h3>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div>Choose any combination of filters from this list of Prefectures, Areas and Towns.</div>
                        <div className='search-bar-frame'>
                            <div className='search-bar-frame-body'>
                                <PrefectureCheckList
                                    options={prefectures}
                                    selectedPrefectures={selectedPrefectures}
                                    onChange={data => {
                                        setSelectedPrefectures(data);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='search-bar-frame'>
                            <div className='search-bar-frame-body'>
                                <AreaCheckList
                                    options={allAreas}
                                    selectedPrefectures={selectedPrefectures}
                                    selectedAreas={selectedAreas}
                                    onChange={data => {
                                        setSelectedAreas(data);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='search-bar-frame'>
                            <div className='search-bar-frame-body'>
                                <TownCheckList
                                    options={allTowns}
                                    selectedPrefectures={selectedPrefectures}
                                    selectedTowns={selectedTowns}
                                    onChange={data => {
                                        setSelectedTowns(data);
                                    }}
                                />
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <div>
                    <SearchResult allSkiAreaList={allSkiAreaList} ClearSort={ClearSort} prefectures={selectedPrefectures} areas={selectedAreas} towns={selectedTowns} noFilter={noFilter} />
                </div>
            </>
        )
    }
}

export default SkiAreaSearch