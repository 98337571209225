import { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import DetailTabs from './DetailTabs'
import Loader from "../../Common/Loader"
import DetailDailyReport from './Common/DetailDailyReport'
import ObservedSnowfallAnalysisContainer from './ObservedSnowfallAnalysis/ObservedSnowfallAnalysisContainer'
import SnowDepthAnalysisContainer from './SnowDepthAnalysis/SnowDepthAnalysisContainer'
import WeatherContainer from './Weather/WeatherContainer'
import SeasonContainer from './Season/SeasonContainer'
import ArchivesContainer from './Archives/ArchivesContainer'
import YearlyAnalysisTab from './YearlyAnalysisTab'
import AboutDailyReport from './About/AboutDailyReport'


const DetailContainer = () => {

    const params = useParams()

    const [latestReport, setLatestReport] = useState([]);
    const [nowSeasons, setNowSeasons] = useState([]);

    const fetchLatestReportAndNowSeasons = async (nowUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/latest/${nowUniqueName}`
        }).then(resLatestReport => {

            if (Object.keys(resLatestReport.data).length <= 0) {
                window.location.href = "https://www.snowjapan.com/404.html";
            }

            setLatestReport(resLatestReport.data)

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/dailyreport/reports/snowfall-seasons/${resLatestReport.data.Now_Id}`
            })
        }).then(resNowSeasons => {
            setNowSeasons(resNowSeasons.data)
        })
    };

    useEffect(() => {
        fetchLatestReportAndNowSeasons(params.now);
    }, [params.now]);

    if (Object.keys(latestReport).length <= 0) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <Fragment>
                <h2>{latestReport.Name}</h2>
                <div className='sj-para mt-1 mb-3'>
                    Independent snow and weather reports from the <Link to={`${window.$baseSkiAreaUrl}/area/${latestReport.AreaUniqueName}`}>{latestReport.AreaName}</Link>, Japan
                </div>
                <DetailTabs />
                <div>
                    {
                        {
                            undefined: <DetailDailyReport dailyReport={latestReport} params={params} nowSeasons={nowSeasons} isActive={false} />,
                            'observed-snowfall-analysis': <ObservedSnowfallAnalysisContainer dailyReport={latestReport} />,
                            'snow-depth-analysis': <SnowDepthAnalysisContainer dailyReport={latestReport} />,
                            'weather': <WeatherContainer dailyReport={latestReport} />,
                            'archives': <ArchivesContainer params={params} dailyReport={latestReport} nowSeasons={nowSeasons} />,
                            'season': <SeasonContainer dailyReport={latestReport} seasonName={params.season} />,
                            'about-daily-reports': <AboutDailyReport params={params} />
                        }[params.type]
                    }
                </div>
                <div>
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-3">
                        <h4>Annual archives</h4>
                    </div>
                    <YearlyAnalysisTab nowUniqueName={params.now} nowSeasons={nowSeasons} />
                </div>
                {/*params.now === 'Niseko-Now' &&
                    <div className="banner-holder">
                        <div className="sj-row mb-0">
                            <div className="row sj-row-no-margin-padding sj-no-bg">
                                <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                    <div className='home-listing-holder-left mt-3'>
                                        <a href='https://www.yukiroro.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-yu-kiroro.jpg' alt='listing-yu-kiroro' /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                */}
                {params.now === 'Yuzawa-Now' &&
                    <div className="banner-holder">
                        <div className="sj-row mb-0">
                            <div className="row sj-row-no-margin-padding sj-no-bg">
                                <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                    <div className='home-listing-holder-left mt-3'>
                                        <a href='https://gala.co.jp/winter/english/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-gala-yuzawa.jpg' alt='listing-gala-yuzawa' /></a>
                                    </div>
                                </div>
                                {/*<div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                    <div className='home-listing-holder-center1 mt-3'>
                                        <a href='https://www.naspanewotani.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-naspa.jpg' alt='listing-naspa' /></a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                    <div className='home-listing-holder-center2 mt-3'>
                                        <a href='https://www.snowjapan.com/listings/travel/yuzawa-minamiuonuma-eleven-plus' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-eleven-plus.jpg' alt='listing-naspa' /></a>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                }
                {params.now === 'Nozawa-Onsen-Now' &&
                    <div className="banner-holder">
                        <div className="sj-row mb-0">
                            <div className="row sj-row-no-margin-padding sj-no-bg">
                                <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                    <div className='home-listing-holder-left mt-3'>
                                        <a href='https://en.nozawaski.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-nozawa-onsen.jpg' alt='listing-nozawa-onsen' /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
};

export default DetailContainer;