//import { Popover, OverlayTrigger } from 'react-bootstrap'
//import { useHistory, Link } from 'react-router-dom'
import Popup from "reactjs-popup";

const MapPath = ({ path }) => {

    //const [mapConfig, setMapConfig] = useState({});
    //const [pathStyle, setPathStyle] = useState({});

    const jpjsconfig = {
        "jpjs1": {
            "name": "HOKKAIDO",
            "intro": "103 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/hokkaido", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099FF",
            "active": true
        },
        "jpjs2": {
            "name": "AOMORI PREFECTURE",
            "intro": "12 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/aomori", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099FF",
            "active": true
        },
        "jpjs3": {
            "name": "IWATE PREFECTURE",
            "intro": "19 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/iwate", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs4": {
            "name": "MIYAGI PREFECTURE",
            "intro": "10 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/miyagi", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs5": {
            "name": "AKITA PREFECTURE",
            "intro": "18 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/akita", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs6": {
            "name": "YAMAGATA PREFECTURE",
            "intro": "20 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/yamagata", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs7": {
            "name": "FUKUSHIMA PREFECTURE",
            "intro": "23 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/fukushima", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs8": {
            "name": "IBARAKI PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs9": {
            "name": "TOCHIGI PREFECTURE",
            "intro": "5 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/tochigi", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs10": {
            "name": "GUNMA PREFECTURE",
            "intro": "21 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/gunma", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs11": {
            "name": "SAITAMA PREFECTURE",
            "intro": "1 ski area (sort of!)",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/saitama", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs12": {
            "name": "CHIBA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs13": {
            "name": "TOKYO",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs14": {
            "name": "KANAGAWA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs15": {
            "name": "NIIGATA PREFECTURE",
            "intro": "51 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/niigata", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs16": {
            "name": "TOYAMA PREFECTURE",
            "intro": "8 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/toyama", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs17": {
            "name": "ISHIKAWA PREFECTURE",
            "intro": "6 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/ishikawa", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs18": {
            "name": "FUKUI PREFECTURE",
            "intro": "6 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/fukui", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs19": {
            "name": "YAMANASHI PREFECTURE",
            "intro": "3 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/yamanashi", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs20": {
            "name": "NAGANO PREFECTURE",
            "intro": "78 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/nagano", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs21": {
            "name": "GIFU PREFECTURE",
            "intro": "19 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/gifu", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs22": {
            "name": "SHIZUOKA PREFECTURE",
            "intro": "2 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/shizuoka", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs23": {
            "name": "AICHI PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/aichi", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs24": {
            "name": "MIE PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs25": {
            "name": "SHIGA PREFECTURE",
            "intro": "7 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/shiga", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs26": {
            "name": "KYOTO PREFECTURE",
            "intro": "2 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/kyoto", "target": "same_window",
            "upColor": "#C0C0C0", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": false
        },
        "jpjs27": {
            "name": "OSAKA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs28": {
            "name": "HYOGO PREFECTURE",
            "intro": "16 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/hyogo", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs29": {
            "name": "NARA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs30": {
            "name": "WAKAYAMA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs31": {
            "name": "TOTTORI PREFECTURE",
            "intro": "6 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/tottori", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs32": {
            "name": "SHIMANE PREFECTURE",
            "intro": "4 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/shimane", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs33": {
            "name": "OKAYAMA PREFECTURE",
            "intro": "7 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/okayama", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs34": {
            "name": "HIROSHIMA PREFECTURE",
            "intro": "11 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/hiroshima", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs35": {
            "name": "YAMAGUCHI PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs36": {
            "name": "TOKUSHIMA PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/tokushima", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs37": {
            "name": "KAGAWA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": false
        },
        "jpjs38": {
            "name": "EHIME PREFECTURE",
            "intro": "3 ski areas",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/ehime", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs39": {
            "name": "KOCHI PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/kochi", "target": "same_window",
            "upColor": "#C0C0C0", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": false
        },
        "jpjs40": {
            "name": "FUKUOKA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs41": {
            "name": "SAGA PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/saga", "target": "same_window",
            "upColor": "#C0C0C0", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": false
        },
        "jpjs42": {
            "name": "NAGASAKI PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs43": {
            "name": "KUMAMOTO PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs44": {
            "name": "OITA PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/oita", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs45": {
            "name": "MIYAZAKI PREFECTURE",
            "intro": "1 ski area",
            "url": "https://www.snowjapan.com/japan-ski-resorts/prefecture/miyazaki", "target": "same_window",
            "upColor": "#0000CC", "overColor": "#CC0000", "downColor": "#0099ff",
            "active": true
        },
        "jpjs46": {
            "name": "KAGOSHIMA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "jpjs47": {
            "name": "OKINAWA PREFECTURE",
            "intro": "No ski areas",
            "upColor": "#C0C0C0", "overColor": "#C0C0C0", "downColor": "#0099ff",
            "active": false
        },
        "general": {
            "borderColor": "#9CA8B6",
            "visibleNames": "#666666",
        }
    };

    /*const GetMapConfiguration = (thisPath) => {

        const thisJPJSConfig = jpjsconfig[thisPath.Id]
        const generalJPJSConfig = jpjsconfig["general"]

        var thisConfig = thisJPJSConfig

        let thisPathStyle = { "cursor": "default" }

        thisConfig.Stroke = generalJPJSConfig.borderColor

        if (thisJPJSConfig !== undefined && thisJPJSConfig.active === true) {
            thisConfig.Fill = thisJPJSConfig.upColor
            thisPathStyle.cursor = "pointer"
        } else {
            thisConfig.Fill = thisPath.Fill
            thisPathStyle.cursor = "default"
        }

        setMapConfig(thisConfig)
        setPathStyle(thisPathStyle)
    }

    useEffect(() => {
        GetMapConfiguration(path)
    }, [path]);*/

    const thisJPJSConfig = jpjsconfig[path.Id]
    const generalJPJSConfig = jpjsconfig["general"]

    var thisConfig = thisJPJSConfig

    var thisPathStyle = { "cursor": "default" }
    var className = ''

    thisConfig.Stroke = generalJPJSConfig.borderColor

    if (thisJPJSConfig !== undefined && thisJPJSConfig.active === true) {
        thisConfig.Fill = thisJPJSConfig.upColor
        thisPathStyle.cursor = "pointer"
        className = "map-active"
    } else {
        thisConfig.Fill = path.Fill
        thisPathStyle.cursor = "default"
    }

    const containerIdSelector = "#keepinside";

    return (

        <Popup
            trigger={open => (
                <path id={path.Id} fill={thisConfig.Fill} stroke={thisConfig.Stroke} vectorEffect={path.VectorEffect} d={path.Data} style={thisPathStyle} className={className} />
            )}
            position="right center"
            closeOnDocumentClick="true"
            keepTooltipInside={containerIdSelector}
        >
            <div className='popup-content-header'><h5>{thisJPJSConfig.name}</h5></div>
            <div className='popup-content-body'>
                <div>
                    {/*{thisJPJSConfig.intro}<br />*/}
                    <a href={thisJPJSConfig.url} target='_blank' rel="noreferrer">Click Here</a>
                </div>
            </div>
        </Popup>
    )
}

export default MapPath;