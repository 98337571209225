import { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import Loader from "../../../Common/Loader"
import SnowChart from '../../../Common/SnowChart'
import SeasonSnowChart from '../../../Common/SeasonSnowChart'

const SnowDepthAnalysisContainer = ({ dailyReport }) => {
    const [snowData, setSnowData] = useState([])
    const [skiAreaName, setSkiAreaName] = useState(dailyReport.ResortAName)
    const [dailyReportSkiAreas, setDailyReportSkiAreas] = useState([])
    const [showChart, setShowChart] = useState(true)

    const fetchSnowData = async (resortId) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/snowfall/${resortId}`
        }).then(dataSnowData => {

            if (dataSnowData.data.length > 0) {

                let thisAllSnowData = dataSnowData.data

                let thisSnowData = thisAllSnowData.filter(function (currentElement) {
                    return (currentElement.SnowDepth !== 999 && currentElement.Season !== '-');
                });

                setSnowData(thisSnowData)
                setShowChart(true)
            }
        })
    };

    const fetchDailyReportSkiAreas = async (dailyReportId) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/dailyreportskiareas/${dailyReportId}`
        }).then(dailyReportSkiAreaData => {
            setDailyReportSkiAreas(dailyReportSkiAreaData.data)
        })
    };

    useEffect(() => {
        fetchSnowData(dailyReport.ResortAId)
        fetchDailyReportSkiAreas(dailyReport.Now_Id)
    }, [dailyReport]);

    const ResortSelected = (evtKey, evt) => {

        setShowChart(false)
        let selectedSkiAreaId = evtKey;

        let selectedSkiAreaList = dailyReportSkiAreas.filter(function (currentElement) {
            return (currentElement.Resort_Id === parseInt(selectedSkiAreaId));
        });

        setSkiAreaName(selectedSkiAreaList[0].ResortName)

        fetchSnowData(selectedSkiAreaId)
    }

    const chartOptions = {
        chart: {
            type: 'area',
            zoomType: 'x',
            backgroundColor: "#efefef",
            borderWidth: 1,
            borderColor: "#dedede",
            style: {
                fontFamily: 'Lato'
            }
        },
        title: {
            text: 'Official snow depth data',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat'
            }
        },
        subtitle: {
            text: 'Drag a section of the graph to zoom in',
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: 'Lato'
            }
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: { // don't display the dummy year
                month: '%b %e ', year: '%b'
            },
            title: {
                text: '',
                enabled: false
            }
        },
        legend: {
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        },
        yAxis: {
            title: {
                text: 'Official snow depth (cm)',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            },
            min: 0,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %e}: {point.y}cm'
        },
        exporting:
        {
            enabled: false
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 3,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                threshold: null
            }
        },
        credits:
        {
            enabled: false
        }
    };

    if (snowData.length <= 0) {
        return (<Loader />)
    }
    else {
        let seasons = [...new Set(snowData.map(item => item.Season))].sort().reverse();

        return (
            <>
                <Helmet>
                    <title>{dailyReport.Name} | Individual ski area official snow depth data from {dailyReport.AreaName} | SnowJapan</title>
                    <meta name="description" content={`Official snow depth data for ski areas in the ${dailyReport.AreaName} of Japan. Snowfall is observed from base areas.`} />
                </Helmet>
                {(showChart) &&
                    <div className='empty-row'>
                        <h3>Ski area snow depth data</h3>
                        <div>
                            The data published on this page is official snow depth data, as originally reported by the ski areas. Please note that each ski area has its own method of measuring snow. SnowJapan does not control this data.
                        </div>
                        <div className='mt-3 mb-4'>
                            On the top graph click on each season name to toggle on/off and compare seasons.
                        </div>
                        <div className='sj-row-100'>
                            <DropdownButton align="start" title={skiAreaName} onSelect={ResortSelected} id="snow-depth-resort" className='sj-large-font-button'>
                                {dailyReportSkiAreas.map((item, index) => (
                                    <Dropdown.Item eventKey={item.Resort_Id} key={index}>{item.ResortName}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        <SnowChart resortName={skiAreaName} snowData={snowData} />
                        {seasons.map((season, index) => (
                            <div key={index}>
                                <SeasonSnowChart resortName={skiAreaName} snowData={snowData} season={season} chartOptions={chartOptions} index={index} />
                            </div>
                        ))}
                    </div>
                }
            </>
        )
    }
};

export default SnowDepthAnalysisContainer;