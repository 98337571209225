import AccommodationInfo from '../AccommodationInfo'
import { useState, useEffect, useCallback } from 'react'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Loader from "../../../Common/Loader"

const PhotographsContainer = ({ accommodation, accommodationPhotos }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photoList, setPhotoList] = useState([]);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const fetchAccommodationPhotos = (thisAccommodationPhotos) => {
        var accommodationPhotoList = thisAccommodationPhotos.map(p => ({ src: 'https://www.snowjapan.com/UploadedFiles/HotelImages/Photos/' + p.FileName, width: p.ImageWidth, height: p.ImageHeight }));
        setPhotoList(accommodationPhotoList);
    }

    useEffect(() => {
        fetchAccommodationPhotos(accommodationPhotos);
    }, [accommodationPhotos]);

    if (photoList.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='empty-row'>
                <div className='empty-row'>
                    <AccommodationInfo accommodation={accommodation} />
                </div>
                <div className='empty-row'>
                    <h1>Photographs</h1>
                </div>
                <div className='empty-row'>
                    <Gallery photos={photoList} onClick={openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photoList.map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </div >
        )
    }
};

export default PhotographsContainer;