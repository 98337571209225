import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Loader from '../Common/Loader'
import SkiAreaSearch from './SkiAreaSearch'

const SkiAreaSearchContainer = () => {

    const params = useParams()

    const [skiAreaList, setSkiAreaList] = useState([])

    const fetchSkiAreaList = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/list/All`
        }).then(dataResortList => {
            setSkiAreaList(dataResortList.data)
        })
    };

    useEffect(() => {
        fetchSkiAreaList()
    }, [params])

    if (skiAreaList.length > 0) {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Discover the ski areas of Japan | SnowJapan</title>
                    <meta name="description" content="Discover the ski areas of Japan using various filters and sorting criteria." />
                </Helmet>
                <h3>Discover Japan’s ski areas</h3>
                <SkiAreaSearch params={params} skiAreaList={skiAreaList} />
            </div>
        )
    } else {
        return (
            <Loader />
        )
    }
}

export default SkiAreaSearchContainer