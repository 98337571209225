import { RenderBreak } from '../../helpers/Helper'
import Loader from "../Common/Loader"
import { Helmet } from 'react-helmet'

const ListingDetail = ({ listingUniqueName, listingList }) => {

  const listings = listingList.filter(function (currentElement) {
    return (currentElement.UniqueName === listingUniqueName);
  });

  const listingDetail = listings[0]

  if (listingDetail !== undefined) {
    return (
      <>
        <Helmet>
          <title>{listingDetail.Name}</title>
          <meta name="description" content={listingDetail.Description} />
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: RenderBreak(listingDetail.HtmlContent) }} />
      </>
    )
  } else {
    return (
      <Loader />
    )
  }


};

export default ListingDetail;