import SkiAreaInfo from '../SkiAreaInfo'

const HistoryContainer = ({ resort }) => {
    return (
        <div className='empty-row'>
            <div className='empty-row'>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Snow-Depth.svg" alt={`${resort.Name} history`} /></div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        <h2>History</h2>
                        <div className="sj-para">
                            Text Goes here
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HistoryContainer;