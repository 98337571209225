import { useState, useEffect } from 'react'
import axios from 'axios'
import Highcharts from 'highcharts'
import Loader from "../../../Common/Loader"
import HighchartsReact from 'highcharts-react-official'
import { GetCurrentSeasonName, GetPreviousSeasonName } from '../../../../helpers/Helper'

const DailySnowfall = ({ dailyReport }) => {

    const [dailySnowfallList, setDailySnowfallList] = useState([]);

    const fetchDailySnowfall = async (nowUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/${nowUniqueName}/All`
        }).then(resDailySnowfall => {
            setDailySnowfallList(resDailySnowfall.data)
        })
    };

    useEffect(() => {
        fetchDailySnowfall(dailyReport.UniqueName)
    }, [dailyReport.UniqueName]);

    const getSnowfallValue = (season) => {

        let dailySnowfallData = [];

        let dailyData = dailySnowfallList.filter(function (currentElement) {
            return (currentElement.Season === season);
        });

        dailyData.sort(function (a, b) {
            var dateA = new Date(a.Id), dateB = new Date(b.Id)
            return dateA - dateB
        })

        dailyData.forEach(function (dataItem) {
            let valueCounter = 0;

            if (dataItem.NowReportMonth < 6) {
                valueCounter = 1
            }

            dailySnowfallData.push([Date.UTC(1969 + valueCounter, Number(dataItem.NowReportMonth) - 1, dataItem.NowReportDay), dataItem.NewSnowfallResortA])
        })

        return dailySnowfallData
    }

    let dailySnowfallDataSeries = [];
    let seasons = [...new Set(dailySnowfallList.map(item => item.Season))].sort().reverse();
    let currentSesson = GetCurrentSeasonName()
    let previousSeason = GetPreviousSeasonName()

    seasons.forEach(function (item) {

        if (item > '2011-2012') {

            let seriesVisible = false

            if (item === currentSesson || item === previousSeason) {
                seriesVisible = true;
            }

            dailySnowfallDataSeries.push({
                'name': item,
                'data': getSnowfallValue(item),
                'visible': seriesVisible
            })
        }
    })

    const options = {
        colors: ["#DF7401", "#0033CC", "#3366CC", "#3399FF", "#000066", "#990000", "#CC0000", "#FF0066", "#660033", "#8904B1", "#FE9A2E", "#DF7401"],
        chart: {
            type: 'spline',
            zoomType: 'x',
            backgroundColor: "#efefef",
            borderWidth: 1,
            borderColor: "#dedede",
            style: {
                fontFamily: 'Lato'
            }
        },
        title: {
            text: 'Daily observed snowfall at base',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat'
            }
        },
        subtitle: {
            text: 'Drag a section of the graph to zoom in',
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: 'Lato'
            }
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: { // don't display the dummy year
                month: '%b %e ', year: '%b'
            },
            title: {
                text: '',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            }
        },
        legend: {
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Daily observed snowfall (cm)',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            },
            minorTickInterval: 'auto',
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %e}: {point.y}cm'
        },
        exporting:
        {
            enabled: false
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: dailySnowfallDataSeries,
        credits:
        {
            enabled: false
        }
    };

    if (dailySnowfallList.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='sj-para'>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <div className='center-aligned sj-box-lighter-gray p-2'>
                    <h6>Click on each season to add or remove data from other seasons</h6>
                </div>
            </div>
        )
    }
};

export default DailySnowfall;