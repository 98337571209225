import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import axios from 'axios'
import { useState, useEffect } from 'react'
import roundlogo from '../../static-contents/sj-logo-round.png'

const Footer = () => {

  const [todayYear, setTodayYear] = useState('2023')

  const fetchTodayDate = async () => {
    axios({
      method: 'GET',
      url: `${window.$baseAPIUrl}/home/todaydate`
    }).then(resTodayDate => {

      if (resTodayDate != undefined && resTodayDate.data != undefined) {
        //setTodayDateStr(resTodayDate.data)

        console.log("today date is: " + resTodayDate.data)
        let strTodayDate = resTodayDate.data
        let todayDateParts = strTodayDate.split('-')
        if (todayDateParts.length === 3) {
          setTodayYear(todayDateParts[0])
        }
      }
    })
  };

  useEffect(() => {
    fetchTodayDate()
    //console.log(todayYear)
  }, []);

  return (
    <div className="sj-row-no-margin-padding sj-footer">
      <footer className="py-5">
        <div className="sj-box-dark-gray pt-2 pb-1">
          <div className="row sj-row-small-margin-padding">
            <div className="col-md-2">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to='/' className='nav-link p-0 text-muted'>Home</Link></li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to={`${window.$baseSkiAreaUrl}`} className='nav-link p-0 text-muted'>Ski Areas</Link></li>
                <li className="nav-item mb-2"><Link to={`${window.$baseDailyReportUrl}`} className='nav-link p-0 text-muted'>Daily Reports</Link></li>
                <li className="nav-item mb-2"><Link to={`${window.$baseGuideUrl}`} className='nav-link p-0 text-muted'>Guides</Link></li>
                <li className="nav-item mb-2"><Link to={`${window.$baseListingUrl}`} className='nav-link p-0 text-muted'>Listings</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to='/about-snowjapan' className='nav-link p-0 text-muted'>About SnowJapan</Link></li>
                <li className="nav-item mb-2"><Link to='/about-snowjapan-japanese' className='nav-link p-0 text-muted'>このサイトについて</Link></li>
                <li className="nav-item mb-2"><Link to='/about-daily-reports' className='nav-link p-0 text-muted'>About daily reports</Link></li>
                <li className="nav-item mb-2"><Link to='/about-information-and-updates' className='nav-link p-0 text-muted'>About our information</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to='https://www.snowjapan.com/community' className='nav-link p-0 text-muted'>News & updates</Link></li>
                <li className="nav-item mb-2"><Link to='/about-members-area' className='nav-link p-0 text-muted'>About member area</Link></li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><Link to='/contact' className='nav-link p-0 text-muted'>Contact us</Link></li>
                <li className="nav-item mb-2"><Link to='/about-links-and-privacy' className='nav-link p-0 text-muted'>Links & privacy</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="py-2 my-2 border-top">
          <div className='sj-row-no-margin-padding'>
            <div className='sj-footer-text-holder center-aligned'>
              <strong>SnowJapan</strong> is the independent guide to winter sports in Japan.<br />
              © Copyright 1999-{todayYear} SnowJapan.com. All rights reserved.
            </div>
            <div className='sj-footer-logo-holder center-aligned mt-2'>
              <Link to='/'><Image src={roundlogo} /></Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;