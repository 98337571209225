import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Prefectures = () => {
    const [prefectureList, setPrefectureList] = useState([]);

    const fetchPrefectures = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/prefecture/list`
        }).then(resPrefecture => {
            setPrefectureList(resPrefecture.data)
        })
    };

    useEffect(() => {
        fetchPrefectures()
    }, []);

    return (
        <div className='ski-area-listing-holder'>
            <ul className="sj-no-bullets-thin">
                {prefectureList.map(prefecture => (
                    <li key={prefecture.Id}><Link to={`${window.$baseSkiAreaUrl}/prefecture/${prefecture.UniqueName}`}>{prefecture.Name} ({prefecture.TotalResorts})</Link></li>
                ))}
            </ul>
        </div>
    )
};

export default Prefectures;