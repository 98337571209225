import { useState } from 'react'
import { Button, Collapse } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import SkiAreaInfo from '../SkiAreaInfo'

const WeatherContainer = ({ resort }) => {

    const [openPrecipitationMap, setOpenPrecipitationMap] = useState(false);
    const [openAnimateForecasts, setOpenAnimateForecasts] = useState(false);

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Weather & snow forecasts for {resort.Name} ({resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan) | SnowJapan</title>
                <meta name="description" content={`Weather and snow forecast resources for ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
            </Helmet>
            <div className='empty-row'>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Weather-HeavySnow.svg" alt={`${resort.Name} weather forecast`} /></div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        <h3>Weather resources</h3>
                        <div className='sj-para'>
                            The <strong>Japan Meteorological Agency (JMA)</strong> publishes various real-time snowfall, weather and forecast related resources. We introduce various resources for the region where <strong>{resort.Name}</strong> is located on this page. All links open in a new window. All maps are centred on the exact location of <strong>{resort.Name}</strong>.
                        </div>
                        <div className='sj-para sj-red-text'>
                            Important: The below information is provided to give a general insight into the weather and snowfall conditions in the region. It is not ski area specific. Actual snowfall is often more than shown, especially at higher elevations.
                        </div>
                    </div>
                </div>
            </div>
            <div className="sj-row">
                <div>
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2">
                        <h4>{resort.Name} region</h4>
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Town information</h5>
                            </div>
                        </div>
                        {(resort.HotelBookingLink1 !== null && resort.HotelBookingLink1 !== undefined && resort.HotelBookingLink1 !== '') &&
                            <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                                <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                    <h5><a href={resort.HotelBookingLink1} target='_blank' rel='noreferrer'>Local forecast</a></h5>
                                </div>
                            </div>
                        }
                        {(resort.HotelBookingLink2Text !== null && resort.HotelBookingLink2Text !== undefined && resort.HotelBookingLink2Text !== '') &&
                            <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                                <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                    <h5><a href={resort.HotelBookingLink2Text} target='_blank' rel='noreferrer'>Observations</a></h5>
                                </div>
                            </div>
                        }
                        {(resort.HotelBookingLink1Text !== null && resort.HotelBookingLink1Text !== undefined && resort.HotelBookingLink1Text !== '') &&
                            <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                                <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                    <h5><a href={resort.HotelBookingLink1Text} target='_blank' rel='noreferrer'>Weather Advisory</a></h5>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Precipitation analysis</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_nowc/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:deep/elements:hrpns&slmcs&amds_rain10m`} target='_blank' rel='noreferrer'>Now</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_kaikotan/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:normal/#zoom:6/elements:slmcs&rasrf24h`} target='_blank' rel='noreferrer'>24-hour</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:deep/elements:snowd`} target='_blank' rel='noreferrer'>Snow depth</a></h5>
                            </div>
                        </div>
                    </div>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        <div className="col-lg-3 left-aligned-jma sj-row-no-margin-padding">
                            <div className='sj-box sj-box-dark-blue sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5>Snowfall</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:deep/elements:snowf24h`} target='_blank' rel='noreferrer'>24-hour snowfall</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:deep/elements:snowf48h`} target='_blank' rel='noreferrer'>48-hour snowfall</a></h5>
                            </div>
                        </div>
                        <div className="col-lg-3 left-aligned sj-row-no-margin-padding">
                            <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                <h5><a href={`https://www.jma.go.jp/bosai/en_snow/#zoom:12/lat:${resort.Location_Latitude}/lon:${resort.Location_Longitude}/colordepth:deep/elements:snowf72h`} target='_blank' rel='noreferrer'>72-hour snowfall</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sj-row">
                The JMA is an agency of the Ministry of Land, Infrastructure, Transport and Tourism.
            </div>
            <div className="sj-row">
                <h3 className='mt-5'>About weather forecasts</h3>
                We do not specifically recommend any detailed weather forecasting service for all regions of Japan. However, many forecasts, both in Japanese and English, are free to use and widely available. Such services offer varying accuracy, with some seemingly more accurate for some areas of Japan than others.
            </div>
            {resort.WeatherFeedUrl !== '' &&
                <>
                    <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                        <h4>Weather map</h4>
                    </div>
                    <div className="sj-row">
                        <h4>Precipitation & 9-day forecast</h4>
                        <div className="mt-2">
                            <div className="mt-2">
                                <Button
                                    onClick={() => setOpenPrecipitationMap(!openPrecipitationMap)}
                                    aria-expanded={openPrecipitationMap} className='w-100 mb-1'><h5 className='mt-1'>{openPrecipitationMap ? 'Click to hide map -' : 'Click to view map +'}</h5></Button>
                                <Collapse in={openPrecipitationMap}>
                                    <>
                                        {(openPrecipitationMap) &&
                                            <>
                                                <div className='ratio ratio-16x9'>

                                                    <iframe title='Precipitation & 9 Days Forecast' src={`https://embed.windy.com/embed2.html?lat=${resort.Location_Latitude}&lon=${resort.Location_Longitude}&detailLat=${resort.Location_Latitude}&detailLon=${resort.Location_Longitude}&zoom=${resort.Location_MapZoomLevel}&level=surface&overlay=rain&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1`} allowFullScreen></iframe>

                                                </div>
                                                <div className='center-aligned'>
                                                    Use the bar above the forecast to advance the timeline
                                                </div>
                                            </>
                                        }
                                    </>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className="sj-row">
                        <h4>Animated forecasts</h4>
                        <div className="mt-2">
                            <div className="mt-2">
                                <Button
                                    onClick={() => setOpenAnimateForecasts(!openAnimateForecasts)}
                                    aria-expanded={openAnimateForecasts} className='w-100 mb-1'><h5 className='mt-1'>{openAnimateForecasts ? 'Click to hide map -' : 'Click to view map +'}</h5></Button>
                                <Collapse in={openAnimateForecasts}>
                                    <>
                                        {(openAnimateForecasts) &&
                                            <>
                                                <div className='ratio ratio-16x9'>
                                                    <iframe title='Various animated forecasts' src={`https://embed.windy.com/embed2.html?lat=${resort.Location_Latitude}&lon=${resort.Location_Longitude}&detailLat=${resort.Location_Latitude}&detailLon=${resort.Location_Longitude}&width=920&height=750&zoom=${resort.Location_MapZoomLevel}&level=surface&overlay=temp&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1`} allowFullScreen></iframe>
                                                </div>
                                                <div className='center-aligned'>
                                                    Use the play button (bottom left) to start a forecast animation
                                                </div>
                                            </>
                                        }
                                    </>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </>
            }
            {resort.WeatherFeedUrl === '' &&
                <>
                    <h4>Currently there is no weather map available for {resort.Name}</h4>
                </>
            }
        </div>
    )
};

export default WeatherContainer;