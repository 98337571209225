import axios from 'axios'
import { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import Home from './Home'
import ListingListContainer from './ListingListContainer'
import ListingDetail from './ListingDetail'

const ListingContainer = () => {

    const params = useParams()

    let requestType = undefined

    if (params.section_name !== undefined && params.section_name !== '' && (params.listing_name === '' || params.listing_name === undefined)) {
        requestType = 'section_listing'
    } else if (params.section_name !== undefined && params.section_name !== '' && params.listing_name !== undefined && params.listing_name !== '') {
        requestType = 'listing_detail'
    }

    const [listingList, setListingList] = useState([]);
    const [listingSectionList, setListingSectionList] = useState([]);
    const [accommodationList, setAccommodationList] = useState([]);

    const fetchListingSectionsAndListings = async (thisMatch) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/listingGuideSection/sections/list/Listing`
        }).then(resListingSections => {
            setListingSectionList(resListingSections.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/listingGuideSection/listings/list/All`
            })
        }).then(resListings => {
            setListingList(resListings.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/accommodation/list/all/all`
            })
        }).then(dataAccommodationList => {

            if (dataAccommodationList.data.length > 0) {
                let thisAccommodationList = dataAccommodationList.data
                thisAccommodationList.sort(function (a, b) {
                    let nameA = a.Name.toLowerCase(),
                        nameB = b.Name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;

                })
                setAccommodationList(thisAccommodationList)
            }
        })
    };

    useEffect(() => {
        fetchListingSectionsAndListings(params)
    }, [params]);

    return (
        <Fragment>
            <>
                {
                    {
                        undefined: <Home listingSectionList={listingSectionList} listingList={listingList} accommodationList={accommodationList} />,
                        'section_listing': <ListingListContainer sectionUniqueName={params.section_name} listingSectionList={listingSectionList} listingList={listingList} />,
                        'listing_detail': <ListingDetail listingUniqueName={params.listing_name} listingList={listingList} />
                    }[requestType]
                }
            </>
        </Fragment>
    )
};

export default ListingContainer;