import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import SkiAreaListTable from './SkiAreaListTable';

const SearchResult = ({ allSkiAreaList, ClearSort, prefectures, areas, towns, noFilter }) => {

    const [searchedSkiAreaList, setSearchedSkiAreaList] = useState(allSkiAreaList)

    const [buttonTitle, setButtonTitle] = useState('Sort By: Ski area name [A-Z]')
    const [sortColumn, setSortColumn] = useState('Name')
    const [sortOrder, setSortOrder] = useState('')
    const [searchFilterPrefecture, setSearchFilterPrefecture] = useState('')
    const [searchFilterArea, setSearchFilterArea] = useState('')
    const [searchFilterTown, setSearchFilterTown] = useState('')

    const ClearSortValues = () => {
        setButtonTitle('Sort By: Ski area name [A-Z]')
        setSortColumn('Name')
        setSortOrder('')
    }

    const PerpareSearchFilter = () => {
        if (prefectures.length > 0) {

            let selectedPrefectures = prefectures.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let prefectureList = [...new Set(selectedPrefectures.map(item => item.name))].sort();
            if (prefectureList.length > 0) {
                setSearchFilterPrefecture(prefectureList.join(', '))
            }
        }

        if (areas.length > 0) {

            let selectedAreas = areas.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let areaList = [...new Set(selectedAreas.map(item => item.name))].sort();

            if (areaList.length > 0) {
                setSearchFilterArea(areaList.join(', '))
            }
        }

        if (towns.length > 0) {

            let selectedTowns = towns.filter(function (currentElement) {
                return (currentElement.checked === true);
            });

            let townList = [...new Set(selectedTowns.map(item => item.name))].sort();

            if (townList.length > 0) {
                setSearchFilterTown(townList.join(', '))
            }
        }
    }

    useEffect(() => {
        PerpareSearchFilter()
        setSearchedSkiAreaList(allSkiAreaList)
        ClearSortValues()
        if (noFilter) {
            setSearchFilterPrefecture('')
            setSearchFilterArea('')
            setSearchFilterTown('')
        }
    }, [allSkiAreaList, noFilter])

    useEffect(() => {
        ClearSort.current = ClearSortValues
    }, [ClearSort])

    const SortResult = (evtKey, evt) => {

        var sortedResult = []
        var sortColumn = ''
        var sortOrder = ''

        if (evtKey === '1' || evtKey === '2') {
            sortedResult = searchedSkiAreaList.sort(function (a, b) {
                let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
            sortOrder = "ASC"

            if (evtKey === '2') {
                sortedResult = sortedResult.reverse()
                sortOrder = "DESC"
            }

            sortColumn = ""

        } else if (evtKey === '3' || evtKey === '4') {
            if (evtKey === '3') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.MaximumElevation < b.MaximumElevation) return 1;
                    if (a.MaximumElevation > b.MaximumElevation) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '4') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.MaximumElevation > b.MaximumElevation) return 1;
                    if (a.MaximumElevation < b.MaximumElevation) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Top (m)"

        } else if (evtKey === '5' || evtKey === '6') {
            if (evtKey === '5') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.MinimumElevation < b.MinimumElevation) return 1;
                    if (a.MinimumElevation > b.MinimumElevation) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '6') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.MinimumElevation > b.MinimumElevation) return 1;
                    if (a.MinimumElevation < b.MinimumElevation) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Bottom (m)"

        } else if (evtKey === '7' || evtKey === '8') {
            if (evtKey === '7') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.VerticalDifference < b.VerticalDifference) return 1;
                    if (a.VerticalDifference > b.VerticalDifference) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '8') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.VerticalDifference > b.VerticalDifference) return 1;
                    if (a.VerticalDifference < b.VerticalDifference) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Vertical (m)"

        } else if (evtKey === '9' || evtKey === '10') {
            if (evtKey === '9') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiLifts < b.TotalSkiLifts) return 1;
                    if (a.TotalSkiLifts > b.TotalSkiLifts) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '10') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiLifts > b.TotalSkiLifts) return 1;
                    if (a.TotalSkiLifts < b.TotalSkiLifts) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Lifts"

        } else if (evtKey === '11' || evtKey === '12') {
            if (evtKey === '11') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiCourses < b.TotalSkiCourses) return 1;
                    if (a.TotalSkiCourses > b.TotalSkiCourses) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '12') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.TotalSkiCourses > b.TotalSkiCourses) return 1;
                    if (a.TotalSkiCourses < b.TotalSkiCourses) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Courses"

        } else if (evtKey === '13' || evtKey === '14') {
            if (evtKey === '13') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.LongestCourse < b.LongestCourse) return 1;
                    if (a.LongestCourse > b.LongestCourse) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '14') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.LongestCourse > b.LongestCourse) return 1;
                    if (a.LongestCourse < b.LongestCourse) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Longest (m)"

        } else if (evtKey === '15' || evtKey === '16') {
            if (evtKey === '15') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.SteepestSlope < b.SteepestSlope) return 1;
                    if (a.SteepestSlope > b.SteepestSlope) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })
                sortOrder = "ASC"
            } else if (evtKey === '16') {
                sortedResult = searchedSkiAreaList.sort(function (a, b) {
                    if (a.SteepestSlope > b.SteepestSlope) return 1;
                    if (a.SteepestSlope < b.SteepestSlope) return -1;

                    if (a.Name > b.Name) return 1;
                    if (a.Name < b.Name) return -1;

                    return 0;
                })

                sortOrder = "DESC"
            }

            sortColumn = "Steepest (º)"
        }

        setSearchedSkiAreaList(sortedResult)
        setButtonTitle('Sort by: ' + evt.target.innerText)
        setSortColumn(sortColumn)
        setSortOrder(sortOrder)
    }

    if (searchedSkiAreaList.length > 0) {
        return (
            <div className='mt-4'>
                <div className='row sj-no-bg sj-row-no-margin-padding'>
                    <div className='col-md-6 sj-row-no-margin-padding'>
                        {searchFilterPrefecture !== '' &&
                            <div><strong>[Prefecture] </strong>{searchFilterPrefecture}</div>
                        }
                        {searchFilterArea !== '' &&
                            <div><strong>[Area] </strong>{searchFilterArea}</div>
                        }
                        {searchFilterTown !== '' &&
                            <div><strong>[Town] </strong>{searchFilterTown}</div>
                        }
                        {(searchFilterPrefecture === '' && searchFilterArea === '' && searchFilterTown === '') &&
                            <div>No filters selected</div>
                        }
                    </div>
                    <div className='col-md-6 right-aligned sj-row-no-margin-padding'>
                        <DropdownButton align="end" title={buttonTitle} onSelect={SortResult} id="dropdown-menu-align-end" className='sj-large-font-button'>
                            <Dropdown.Item eventKey="1">Ski area name [A-Z]</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Ski area name [Z-A]</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Maximum elevation [highest{'>'}lowest]</Dropdown.Item>
                            <Dropdown.Item eventKey="4">Maximum elevation [lowest{'>'}highest]</Dropdown.Item>
                            <Dropdown.Item eventKey="5">Minimum elevation [highest{'>'}lowest]</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Minimum elevation [lowest{'>'}highest]</Dropdown.Item>
                            <Dropdown.Item eventKey="7">Vertical [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="8">Vertical [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="9">Lifts [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="10">Lifts [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="11">Courses [most{'>'}least]</Dropdown.Item>
                            <Dropdown.Item eventKey="12">Courses [least{'>'}most]</Dropdown.Item>
                            <Dropdown.Item eventKey="13">Longest [longest{'>'}shortest]</Dropdown.Item>
                            <Dropdown.Item eventKey="14">Longest [shortest{'>'}longest]</Dropdown.Item>
                            <Dropdown.Item eventKey="15">Steepest [steepest{'>'}gentlest]</Dropdown.Item>
                            <Dropdown.Item eventKey="16">Steepest [gentlest{'>'}steepest]</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <SkiAreaListTable
                    tableData={searchedSkiAreaList}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    headingColumns={['', 'Top (m)', 'Bottom (m)', 'Vertical (m)', 'Lifts', 'Courses', 'Longest (m)', 'Steepest (º)']}
                    //headingColumns={['Name', 'Town', 'Top', 'Bottom', 'Vertical', 'Ski Lifts', 'Ski Courses', 'Longest Course', 'Steepest Run']}
                    dataMapping={['Name', 'MaximumElevation', 'MinimumElevation', 'VerticalDifference', 'TotalSkiLifts', 'TotalSkiCourses', 'LongestCourse', 'SteepestSlope']}
                    title={`Currently ${allSkiAreaList.length} ski areas selected`}
                />
            </div>
        )
    } else {
        return (
            <>
                <h3>No Result Found</h3>
            </>
        )
    }
};

export default SearchResult;