import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Towns = () => {


    const [townList, setTownList] = useState([]);

    const fetchTown = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/town/list`
        }).then(resTown => {
            setTownList(resTown.data)
        })
    };

    useEffect(() => {
        fetchTown();
    }, []);

    return (
        <div className='ski-area-listing-holder'>
            <ul className="sj-no-bullets-thin">
                {townList.map(town => (
                    <li key={town.Id}><Link to={`${window.$baseSkiAreaUrl}/town/${town.UniqueName}`}>{town.Name} {town.TownTypeStr} ({town.TotalResorts})</Link></li>
                ))}
            </ul>
        </div>
    )
};

export default Towns;