import axios from 'axios'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Loader from '../Common/Loader'
import { RenderBreak } from '../../helpers/Helper'

const GuideDetail = ({ params }) => {

  const guideUniqueName = params.guide_name
  const [guideList, setGuideList] = useState([])

  const fetchGuides = async (sectionUniqueName) => {
    axios({
      method: 'POST',
      url: `${window.$baseAPIUrl}/listingGuideSection/guides/list/${sectionUniqueName}`
    }).then(resGuides => {

      /*let guidePages = resGuides.data.filter(function (currentElement) {
        return (currentElement.UniqueName === guideUniqueName);
      });

      if (guidePages.length <= 0) {
        window.location.href = "https://www.snowjapan.com/404.html";
      }*/

      setGuideList(resGuides.data)
    })
  };

  useEffect(() => {
    fetchGuides(params.section_name)
  }, [params]);

  let guides = []

  if (guideUniqueName !== undefined && guideUniqueName !== '') {
    guides = guideList.filter(function (currentElement) {
      return (currentElement.UniqueName === guideUniqueName);
    })
  } else {
    guides = guideList.filter(function (currentElement) {
      return (currentElement.IsHomePage);
    })
  }

  let guideDetail = undefined

  if (guides.length > 0) {
    guideDetail = guides[0]
  }

  if (guideDetail !== undefined) {
    return (
      <>
        <Helmet>
          <title>{guideDetail.Title}</title>
          <meta name="description" content={`${guideDetail.Description}`} />
          <script src="https://public.flourish.studio/resources/embed.js"></script>
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: RenderBreak(guideDetail.HtmlContent) }} />
      </>
    )
  } else {
    return (
      <Loader />
    )
  }
}

export default GuideDetail