import { useState, useEffect } from 'react'
import styled from 'styled-components';

const CheckboxText = styled.span`
  ${props => props.Disabled && `
         color: #b7b7b7;
    `}
`;

const NearbySkiAreaCheckList = ({ selectedPrefectures, selectedSkiAreas, options, onChange }) => {
    const [skiAreaData, setSkiAreaData] = useState(options);

    const toggle = index => {
        const newData = [...skiAreaData];
        newData.splice(index, 1, {
            name: skiAreaData[index].name,
            uniqueName: skiAreaData[index].uniqueName,
            accommodationUniqueName: skiAreaData[index].accommodationUniqueName,
            checked: !skiAreaData[index].checked,
            disabled: skiAreaData[index].disabled
        });
        setSkiAreaData(newData);
        onChange(newData);
    };

    const refreshSkiAreaOpetions = (prefectures, allSkiAreas, selSkiAreas) => {

        let selectedSkiAreaList = selSkiAreas.filter(function (currentElement) {
            return (currentElement.checked);
        });

        var selecteSkidAreaUniqueNames = selectedSkiAreaList.map(function (obj) { return obj.uniqueName; });

        let selectedPrefectureList = prefectures.filter(function (currentElement) {
            return (currentElement.checked);
        });

        var prefectureUniqueNames = selectedPrefectureList.map(function (obj) { return obj.uniqueName; });

        allSkiAreas = allSkiAreas.map(item => {
            item.disabled = false;
            item.checked = selecteSkidAreaUniqueNames.includes(item.uniqueName);
            return item;
        })

        setSkiAreaData(allSkiAreas);
    }

    useEffect(() => {
        refreshSkiAreaOpetions(selectedPrefectures, options, selectedSkiAreas);
    }, [selectedPrefectures, options, selectedSkiAreas]);

    return (
        <>
            <h3>Nearby Ski Areas</h3>
            {skiAreaData.map((item, index) => (
                <div key={item.uniqueName}>
                    <label>
                        <input
                            readOnly
                            type="checkbox"
                            checked={item.checked || false}
                            onClick={() => toggle(index)}
                            disabled={item.disabled || false}
                        /> <CheckboxText Disabled={item.disabled}>{item.name}</CheckboxText>
                    </label><br />
                </div>
            ))}
        </>
    );
};

export default NearbySkiAreaCheckList;