import { useState, useEffect } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../../tabs.scss';

const SkiAreaDetailTabs = ({ params, resort, skiAreaDailyReport, skiAreaLatestWeather, hasListings }) => {

  const [buttonTitle, setButtonTitle] = useState('Basics')

  const baseUrlPath = window.$baseSkiAreaUrl + '/' + params.prefecture + '/' + params.town + '/' + params.resort

  const [subItemName, setSubItemName] = useState('')

  useEffect(() => {
    setSubItemName(params.type)

    let thisButtonTitle
    switch (params.type) {
      case 'daily-report':
        thisButtonTitle = 'Daily Report'
        break
      case 'spotlight':
        thisButtonTitle = 'Spotlight'
        break
      case 'location':
        thisButtonTitle = 'Location'
        break
      case 'snow-data':
        thisButtonTitle = 'Snow Data'
        break
      case 'weather':
        thisButtonTitle = 'Weather'
        break
      case 'history':
        thisButtonTitle = 'History'
        break
      case 'reviews':
        thisButtonTitle = 'Reviews'
        break
      case 'photos':
        thisButtonTitle = 'Photos'
        break
      case 'route-finder':
        thisButtonTitle = 'Route Finder'
        break
      case 'listing':
        thisButtonTitle = 'Listings'
        break
      default:
        thisButtonTitle = "Basics"
    }

    setButtonTitle(thisButtonTitle)

  }, [params]);

  return (
    <div>
      <div className="d-none d-lg-block bg-color-ski-area">
        <ul className="tabs">
          <li className={`${subItemName === undefined ? " active" : ""}`}>
            <Link to={`${baseUrlPath}`}>Basics</Link>
          </li>
          {(resort.ResortSpotlightUrl !== '' && resort.ResortSpotlightUrl !== undefined && resort.ResortSpotlightUrl !== null) &&
            <li className={`${subItemName === 'spotlight' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/spotlight`}>Spotlight</Link>
            </li>
          }
          <li className={`${subItemName === 'location' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/location`}>Location</Link>
          </li>
          {(skiAreaDailyReport !== undefined && skiAreaDailyReport !== null && skiAreaDailyReport.ReportId > 0) &&
            <li className={`${subItemName === 'daily-report' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/daily-report`}>Daily report</Link>
            </li>
          }
          {(skiAreaLatestWeather !== undefined && skiAreaLatestWeather !== null && Object.keys(skiAreaLatestWeather).length > 0) &&
            <li className={`${subItemName === 'snow-data' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/snow-data`}>Snow data</Link>
            </li>
          }
          <li className={`${subItemName === 'weather' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/weather`}>Weather</Link>
          </li>
          {/*<li className={`${subItemName === 'history' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/history`}>History</Link>
        </li>*/}
          {resort.NumberOfReviews > 0 &&
            <li className={`${subItemName === 'reviews' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/reviews`}>Reviews</Link>
            </li>
          }
          {resort.NumberOfPhotos > 0 &&
            <li className={`${subItemName === 'photos' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/photos`}>Photos</Link>
            </li>
          }
          {/*<li className={`${subItemName === 'route-finder' ? " active" : ""}`}>
            <Link to={`${baseUrlPath}/route-finder`}>Route Finder</Link>
          </li>*/}
          {hasListings &&
            <li className={`${subItemName === 'listing' ? " active" : ""}`}>
              <Link to={`${baseUrlPath}/listing`}>Listings</Link>
            </li>
          }
        </ul>
      </div>
      <div className="d-lg-none sj-row-100">
        <DropdownButton align="end" title={buttonTitle} id="dropdown-menu-align-end" className='sj-large-font-button ski-area-sub-menu'>
          <Dropdown.Item as={Link} to={`${baseUrlPath}`}>Basics</Dropdown.Item>
          {(resort.ResortSpotlightUrl !== '' && resort.ResortSpotlightUrl !== undefined && resort.ResortSpotlightUrl !== null) &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/spotlight`}>Spotlight</Dropdown.Item>
          }
          <Dropdown.Item as={Link} to={`${baseUrlPath}/location`}>Location</Dropdown.Item>
          {(skiAreaDailyReport !== undefined && skiAreaDailyReport !== null && skiAreaDailyReport.ReportId > 0) &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/daily-report`}>Daily report</Dropdown.Item>
          }
          {(skiAreaLatestWeather !== undefined && skiAreaLatestWeather !== null && Object.keys(skiAreaLatestWeather).length > 0) &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/snow-data`}>Snow data</Dropdown.Item>
          }
          <Dropdown.Item as={Link} to={`${baseUrlPath}/weather`}>Weather</Dropdown.Item>
          {/*<Dropdown.Item as={Link} to={`${baseUrlPath}/history`}>History</Dropdown.Item>*/}
          {resort.NumberOfReviews > 0 &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/reviews`}>Reviews</Dropdown.Item>
          }
          {resort.NumberOfPhotos > 0 &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/photos`}>Photos</Dropdown.Item>
          }
          {/*<Dropdown.Item as={Link} to={`${baseUrlPath}/route-finder`}>Route Finder</Dropdown.Item>*/}
          {hasListings &&
            <Dropdown.Item as={Link} to={`${baseUrlPath}/listing`}>Listings</Dropdown.Item>
          }
        </DropdownButton>
      </div>
    </div>
  )
}

export default SkiAreaDetailTabs