import { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Loader from "../../../Common/Loader"
import SkiAreaInfo from '../SkiAreaInfo'
import SnowChart from '../../../Common/SnowChart'
import SeasonSnowChart from '../../../Common/SeasonSnowChart'
import SnowTable from './SnowTable'

const SnowDataContainer = ({ resort, skiAreaDailyReport }) => {

    console.log(skiAreaDailyReport)

    const [snowData, setSnowData] = useState([]);

    const fetchSnowData = async (resortId) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/snowfall/${resortId}`
        }).then(dataSnowData => {
            if (dataSnowData.data.length > 0) {

                let thisAllSnowData = dataSnowData.data

                let thisSnowData = thisAllSnowData.filter(function (currentElement) {
                    return (currentElement.SnowDepth !== 999 && currentElement.Season !== '-');
                });

                setSnowData(thisSnowData)
            }
        })
    };

    useEffect(() => {
        fetchSnowData(resort.Id);
    }, [resort.Id]);

    const chartOptions = {
        chart: {
            type: 'area',
            zoomType: 'x',
            backgroundColor: "#efefef",
            borderWidth: 1,
            borderColor: "#dedede",
            style: {
                fontFamily: 'Lato'
            }
        },
        title: {
            text: 'Official snow depth data',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Montserrat'
            }
        },
        subtitle: {
            text: 'Drag a section of the graph to zoom in',
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                fontFamily: 'Lato'
            }
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: { // don't display the dummy year
                month: '%b %e ', year: '%b'
            },
            title: {
                text: '',
                enabled: false
            }
        },
        legend: {
            itemStyle: {
                fontWeight: 'bold',
                fontSize: '14px'
            }
        },
        yAxis: {
            title: {
                text: 'Official snow depth (cm)',
                style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            },
            min: 0,
            labels: {
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %e}: {point.y}cm'
        },
        exporting:
        {
            enabled: false
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 3,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                threshold: null
            }
        },
        credits:
        {
            enabled: false
        }
    };

    if (snowData.length <= 0) {
        return (
            <Loader />
        )
    }
    else {
        let seasons = [...new Set(snowData.map(item => item.Season))].sort().reverse();

        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Snow depth data for {resort.Name} ({resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan) | SnowJapan</title>
                    <meta name="description" content={`Official snow depth data (current and archive) for ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
                </Helmet>
                <div className='empty-row'>
                    <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Snow-Depth.svg" alt={`${resort.Name} snow data`} /></div>
                    <div>
                        <SkiAreaInfo resort={resort} />
                        <div className="sj-para">
                            <h3>Official snow depth data</h3>
                            <div className="sj-para">
                                The data published on this page is official snow depth data - as originally reported by the ski area. It is updated daily during the main winter season. Please note that each ski area has its own method of measuring snow depth, and SnowJapan does not control the data.
                            </div>
                            <div className="sj-para">
                                Click on each season's name to toggle on/off and compare the seasons on the top graph. Data is presented in the tables below the graphs.
                            </div>
                        </div>
                    </div>
                </div>
                <SnowChart resortName={resort.Name} snowData={snowData} />
                {seasons.map((season, index) => (
                    <div key={index}>
                        <SeasonSnowChart resortName={resort.Name} snowData={snowData} season={season} chartOptions={chartOptions} index={index} />
                    </div>
                ))}
                {skiAreaDailyReport !== undefined &&
                    <SnowTable resort={resort} snowData={snowData} skiAreaDailyReport={skiAreaDailyReport} />
                }
            </div>
        )
    }
};

export default SnowDataContainer;