import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Popup from "reactjs-popup";

const AllReportCalendar = ({ nowYear, nowMonth, dailySnowfallMonthlyList }) => {

  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  //const today = new Date();
  const [date, setDate] = useState(new Date(nowYear, nowMonth, 1));
  //const [day, setDay] = useState(date.getDate());
  const [month, setMonth] = useState(date.getMonth());
  //const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  useEffect(() => {
    //setDay(date.getDate());
    setMonth(date.getMonth());
    //setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [nowYear, nowMonth, date]);

  function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const getDayOrdinal = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  const getDailySnowfallReports = (calDay) => {

    let dailySnowfallData = dailySnowfallMonthlyList.filter(function (currentElement) {
      return (currentElement.NowReportDay === calDay);
    });

    dailySnowfallData.sort((a, b) => {
      let nameA = a.Name.toLowerCase(),
        nameB = b.Name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return dailySnowfallData
  }

  const containerId = "keepinside" + nowMonth + nowYear;
  const containerIdSelector = "#keepinside" + nowMonth + nowYear;

  const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;

  return (
    <div className='now-calendar-frame' id={containerId}>
      <div className='now-calendar-frame-header'>
        <div className='center-aligned'>
          <h4>{MONTHS[month]} {nowYear}</h4>
        </div>
      </div>
      <div className='now-calendar-frame-body'>
        {DAYS_OF_THE_WEEK.map((d) => (
          <div key={d} className='now-calendar-day-header'>
            <strong>{d}</strong>
          </div>
        ))}
        {Array(days[month] + (startDay - 1)).fill(null).map((_, index) => {

          const d = index - (startDay - 2);
          var dayClassName = ''

          var allDailyReports = []

          if (d <= 0) {
            dayClassName = ' empty-date'
          } else {
            dayClassName = ' with-date'
            allDailyReports = getDailySnowfallReports(d)

            if (allDailyReports.length > 0) {
              dayClassName = ' with-date with-report'
            }
          }

          return (
            <>
              {allDailyReports.length <= 0 &&
                <div className={dayClassName} key={index}>
                  {d > 0 ? d : ''}
                </div>
              }

              {allDailyReports.length > 0 &&
                <Popup
                  trigger={open => (
                    <div className={dayClassName} key={index}>
                      {d > 0 ? d : ''}
                    </div>
                  )}
                  position="top"
                  closeOnDocumentClick="true"
                  keepTooltipInside={containerIdSelector}
                >
                  <div className='popup-content-header'><h5>Daily Reports on {MONTHS[month]} {d}{getDayOrdinal(d)}, {nowYear}</h5></div>
                  <div className='popup-content-body'>
                    {(allDailyReports.length > 0) &&
                      <div>
                        {allDailyReports.map((dailyData, index) => (
                          <div key={index}>
                            <Link to={`${window.$baseDailyReportUrl}/${dailyData.UniqueName}/archives/${dailyData.NowReportDayTh}-${dailyData.NowReportMonthFull}-${dailyData.NowReportYear}`}>{dailyData.Name}</Link>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </Popup>
              }
            </>
          );
        })}
      </div>
    </div>
  );
}

export default AllReportCalendar;