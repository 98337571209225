import { Link } from 'react-router-dom'
import { RenderBreak } from '../../helpers/Helper'
import PropTypes from 'prop-types'

const SkiAreaReviewDetail = ({ review }) => {
    return (
        <div className='sj-row'>
            <div className="sj-box sj-box-blue sj-round-half left-aligned mt-3">
                <h4>{review.Headline}</h4>
            </div>
            <div className="row sj-row-small-margin-padding sj-no-bg">
                <div>
                    <Link to={`${window.$baseSkiAreaUrl}/${review.PrefectureUniqueName}/${review.TownUniqueName}/${review.ResortUniqueName}`}><h4>{review.ResortName}</h4></Link>
                </div>
                <div className='sj-large-font'>
                    <Link to={`${window.$baseSkiAreaUrl}/town/${review.TownUniqueName}`}>{review.TownName} {review.TownTypeStr}</Link>,&nbsp;
                    <Link to={`${window.$baseSkiAreaUrl}/prefecture/${review.PrefectureUniqueName}`}>{review.PrefectureName}</Link>
                </div>
            </div>
            {review.ReviewText !== '' &&
                <div className='row sj-row-small-margin-padding sj-no-bg'>
                    <div dangerouslySetInnerHTML={{ __html: RenderBreak(review.ReviewText) }} />
                </div>
            }
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Date visited:</strong>
                </div>
                <div className="col-lg-10">
                    {review.DateVisited}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Things I liked:</strong>
                </div>
                <div className="col-lg-10">
                    {review.ThingsILiked}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Things I didn’t like:</strong>
                </div>
                <div className="col-lg-10">
                    {review.ThingsIDidnotLike}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Review by:</strong>
                </div>
                <div className="col-lg-10">
                    {review.ReaderName}
                </div>
            </div>
            <div className="row sj-row-small-margin-padding">
                <div className="col-lg-2">
                    <strong>Posted:</strong>
                </div>
                <div className="col-lg-10">
                    {review.SubmittedDayTh} {review.SubmittedMonthFull} {review.SubmittedYear}
                </div>
            </div>
            <hr className='mt-5' />
        </div>
    )
};

SkiAreaReviewDetail.propTypes = {
    review: PropTypes.object.isRequired
}

export default SkiAreaReviewDetail;