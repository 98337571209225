const AccommodationInfo = ({ accommodation }) => {
  return (
    <div className='sj-para'>
      <div className="sj-para-large">
        <h3 className="sj-lighter-blue-text">{accommodation.TownName} {accommodation.TownTypeStr}, {accommodation.PrefectureName}</h3>
        {accommodation.PrefectureNameJapanese}{accommodation.TownNameJapanese}
      </div>
      {(accommodation.WebUrl !== '' && accommodation.WebUrl !== undefined && accommodation.WebUrl !== null) &&
        <div>
          <a className="sj-large-button-blue btn btn-primary" href={accommodation.WebUrl} target="_blank" rel='noreferrer'> <strong>VISIT WEBSITE</strong> </a>
        </div>
      }
      {/*{(accommodation.EmailAddress1 !== '' && accommodation.EmailAddress1 !== undefined && accommodation.EmailAddress1 !== null) &&
        <div className='sj-para'>
          <h4>Email: <a href={`mailto:${accommodation.EmailAddress1}`}>Send</a></h4>
        </div>
      }
      {(accommodation.PhoneNumber1 !== '' && accommodation.PhoneNumber1 !== undefined && accommodation.PhoneNumber1 !== null) &&
        <div className='sj-para'>
          <h4>Phone (in Japan): {accommodation.PhoneNumber1}</h4>
        </div>
      }*/}
    </div>
  );
}

export default AccommodationInfo;