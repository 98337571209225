import { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from "../../../Common/Loader"

const SeasonSummary = ({ dailyReport, seasonName }) => {

    const seasonNameParts = seasonName.split('-')

    const [dailyReportSeasonSummary, setDailyReportSeasonSummary] = useState([]);

    const fetchSeasonSummary = async (nowId, season) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/archive/${nowId}/${season}`
        }).then(resSeasonSummary => {
            console.log(resSeasonSummary.data)
            setDailyReportSeasonSummary(resSeasonSummary.data)
        })
    };

    useEffect(() => {
        if (seasonName > '2011-2012') {
            fetchSeasonSummary(dailyReport.Now_Id, seasonName)
        }
    }, [dailyReport.Now_Id, seasonName]);

    if (dailyReportSeasonSummary.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='empty-row'>
                <div className="sj-box sj-box-dark-gray sj-round-half center-aligned mt-2">
                    <h4>Observed snowfall during the {seasonName} winter season</h4>
                </div>
                {dailyReportSeasonSummary.TotalSnowfall > 0 &&
                    <div className="sj-table-container sj-full-width sj-no-margin">
                        <table className="sj-table sj-table-season-summary">
                            <tbody>
                                <tr>
                                    <td>Total snowfall over the season</td>
                                    <td colSpan={2}>{dailyReportSeasonSummary.TotalSnowfall}cm</td>
                                </tr>
                                <tr>
                                    <td>Number of days snowfall observed at base</td>
                                    <td colSpan={2}>{dailyReportSeasonSummary.TotalSnowfallDays} days</td>
                                </tr>
                                <tr>
                                    <td>Most observed snowfall in 1 day</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.MostDailySnowfall}cm</td>
                                    <td>[{dailyReportSeasonSummary.MostDailySnowfallDate}]</td>
                                </tr>
                                <tr>
                                    <td>November {seasonNameParts[0]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.NovemberSnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.NovemberSnowfallDays}</strong> days in November {seasonNameParts[0]}</td>
                                </tr>
                                <tr>
                                    <td>December {seasonNameParts[0]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.DecemberSnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.DecemberSnowfallDays}</strong> days in December {seasonNameParts[0]}</td>
                                </tr>
                                <tr>
                                    <td>January {seasonNameParts[1]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.JanuarySnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.JanuarySnowfallDays}</strong> days in January {seasonNameParts[1]}</td>
                                </tr>
                                <tr>
                                    <td>February {seasonNameParts[1]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.FebruarySnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.FebruarySnowfallDays}</strong> days in February {seasonNameParts[1]}</td>
                                </tr>
                                <tr>
                                    <td>March {seasonNameParts[1]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.MarchSnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.MarchSnowfallDays}</strong> days in March {seasonNameParts[1]}</td>
                                </tr>
                                <tr>
                                    <td>April {seasonNameParts[1]} snowfall</td>
                                    <td className='left-aligned'>{dailyReportSeasonSummary.AprilSnowfall}cm</td>
                                    <td>Reported on <strong>{dailyReportSeasonSummary.AprilSnowfallDays}</strong> days in April {seasonNameParts[1]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                {dailyReportSeasonSummary.TotalSnowfall == 0 &&
                    <div>
                        <h3 className='center-aligned space-above'>No data yet</h3>
                    </div>
                }
            </div>
        )
    }
};

export default SeasonSummary;