import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Navbar, Nav, Image, NavDropdown } from 'react-bootstrap'
import textlogo from '../../static-contents/Snow_Japan_Color_Logo.svg'

const MainNav = () => {

  const location = useLocation();
  //const history = useHistory()

  //const [showSnowData, setShowSnowData] = useState('');

  const [nowList, setNowList] = useState([]);

  const [selectedClassNameResort, setSelectedClassNameResort] = useState('')
  const [selectedClassNameNows, setSelectedClassNameNows] = useState('')
  const [selectedClassNameGuide, setSelectedClassNameGuide] = useState('')
  const [selectedClassNameListing, setSelectedClassNameListing] = useState('')
  const [selectedClassNameAbout, setSelectedClassNameAbout] = useState('')

  const [isSkiAreaShown, setIsSkiAreaShown] = useState(false);
  const [isDailyReportsShown, setIsDailyReportsShown] = useState(false);
  const [isGuideShown, setIsGuideShown] = useState(false);
  const [isListingShown, setIsListingShown] = useState(false);
  const [isAboutShown, setIsAboutShown] = useState(false);
  const [subPageURL, setSubPageURL] = useState('');

  const fetchNowList = async () => {
    axios({
      method: 'POST',
      url: `${window.$baseAPIUrl}/dailyreport/nows/all`
    }).then(resNowList => {
      setNowList(resNowList.data)
    })
  };

  useEffect(() => {
    fetchNowList()
  }, []);

  useEffect(() => {

    let thisPathName = location.pathname.toLowerCase()
    let classNameResort = ''
    let classNameNows = ''
    let classNameGuide = ''
    let classNameListing = ''
    let classNameAbout = ''
    let thisSubPageURL = ''

    if (thisPathName !== undefined) {
      if (thisPathName.includes('/japan-ski-resorts') || thisPathName.includes('/route-finder')) {
        classNameResort = 'sj-menu-link-Resort-section'
        if (thisPathName.includes('/discover')) {
          thisSubPageURL = 'ski-area-discover'
        }
        else if (thisPathName.includes('/japan-ski-resorts/listings') || thisPathName.includes('/japan-ski-resorts/prefecture') || thisPathName.includes('/japan-ski-resorts/town') || thisPathName.includes('/japan-ski-resorts/area')) {
          thisSubPageURL = 'ski-area-listings'
        }
        else if (thisPathName.includes('/japan-ski-resorts/reviews')) {
          thisSubPageURL = 'ski-area-reviews'
        }
        else if (thisPathName.includes('/japan-ski-resorts/photos')) {
          thisSubPageURL = 'ski-area-photos'
        }
        else if (thisPathName.includes('/route-finder')) {
          thisSubPageURL = 'route-finder'
        }
        else if (thisPathName.endsWith('/japan-ski-resorts') || thisPathName.endsWith('/japan-ski-resorts/')) {
          thisSubPageURL = 'ski-area-home'
        }
      } else if (thisPathName.includes('/guides/the-japan-skiing-experience/the-ski-season-in-japan')) {
        classNameResort = 'sj-menu-link-Resort-section'
        thisSubPageURL = 'ski-area-the-season'
      } else if (thisPathName.includes('/japan-daily-snow-weather-reports')) {
        classNameNows = 'sj-menu-link-Nows-section'
        if (thisPathName.includes('/japan-daily-snow-weather-reports/webcams')) {
          thisSubPageURL = 'daily-report-webcams'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/official-snow-depth')) {
          thisSubPageURL = 'daily-report-snow-depth'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/calendar')) {
          thisSubPageURL = 'daily-report-calendar'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/appi-kogen-Now')) {
          thisSubPageURL = 'appi-kogen-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/furano-Now')) {
          thisSubPageURL = 'furano-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/geto-kogen-Now')) {
          thisSubPageURL = 'geto-kogen-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/hakuba-now')) {
          thisSubPageURL = 'hakuba-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/kiroro-now')) {
          thisSubPageURL = 'kiroro-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/madarao-now')) {
          thisSubPageURL = 'madarao-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/myoko-now')) {
          thisSubPageURL = 'myoko-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/niseko-now')) {
          thisSubPageURL = 'niseko-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/nozawa-onsen-now')) {
          thisSubPageURL = 'nozawa-onsen-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/shiga-kogen-now')) {
          thisSubPageURL = 'shiga-kogen-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/yuzawa-now')) {
          thisSubPageURL = 'yuzawa-now'
        }
        else if (thisPathName.includes('/japan-daily-snow-weather-reports/zao-now')) {
          thisSubPageURL = 'zao-now'
        }
        else if (thisPathName.endsWith('/japan-daily-snow-weather-reports') || thisPathName.endsWith('/japan-daily-snow-weather-reports/')) {
          thisSubPageURL = 'daily-report-home'
        }
      } else if (thisPathName.includes('/guides')) {
        classNameGuide = 'sj-menu-link-Guide-section'
        if (thisPathName.includes('/guides/introduction-to-skiing-in-japan')) {
          thisSubPageURL = 'guides-introduction-to-skiing'
        }
        else if (thisPathName.includes('/guides/the-japan-skiing-experience')) {
          thisSubPageURL = 'guides-the-japan-skiing-experience'
        }
        else if (thisPathName.includes('/guides/graphs')) {
          thisSubPageURL = 'guides-graphs'
        }
        else if (thisPathName.includes('/guides/travel-guides')) {
          thisSubPageURL = 'guides-travel-guides'
        }
        else if (thisPathName.includes('/guides/the-prefectures-of-japan')) {
          thisSubPageURL = 'guides-prefectures-of-japan'
        }
        else if (thisPathName.includes('/guides/old-ski-areas-from-japans-past')) {
          thisSubPageURL = 'guides-old-ski-areas'
        }
        else if (thisPathName.endsWith('/guides') || thisPathName.endsWith('/guides/')) {
          thisSubPageURL = 'guides-home'
        }
      } else if (thisPathName.includes('/listings') || thisPathName.includes('/japan-places-to-stay')) {
        classNameListing = 'sj-menu-link-Listing-section'
        if (thisPathName.includes('/japan-places-to-stay')) {
          thisSubPageURL = 'listings-accommodation'
        }
        else if (thisPathName.includes('/listings/resort-spotlights')) {
          thisSubPageURL = 'listings-resort-spotlights'
        }
        else if (thisPathName.includes('/listings/lessons')) {
          thisSubPageURL = 'listings-lessons'
        }
        else if (thisPathName.includes('/listings/travel')) {
          thisSubPageURL = 'listings-travel'
        }
        else if (thisPathName.includes('/listings/job-listings')) {
          thisSubPageURL = 'listings-job-listings'
        }
        else if (thisPathName.endsWith('/listings') || thisPathName.endsWith('/listings/')) {
          thisSubPageURL = 'listings-home'
        }
      } else if (thisPathName.includes('/about-snowjapan') || thisPathName.includes('/about-snowjapan-japanese') || thisPathName.includes('/about-daily-reports') || thisPathName.includes('/about-information-and-updates') || thisPathName.includes('/contact')) {
        classNameAbout = 'sj-menu-link-About-section'
        if (thisPathName.includes('/about-snowjapan')) {
          thisSubPageURL = 'about-snowjapan'
        }
        else if (thisPathName.includes('/about-snowjapan-japanese')) {
          thisSubPageURL = 'about-snowjapan-japanese'
        }
        else if (thisPathName.includes('/about-daily-reports')) {
          thisSubPageURL = 'about-daily-reports'
        }
        else if (thisPathName.includes('/about-information-and-updates')) {
          thisSubPageURL = 'about-information-and-updates'
        }
        else if (thisPathName.includes('/about-members-area')) {
          thisSubPageURL = 'about-members-area'
        }
        else if (thisPathName.includes('/contact')) {
          thisSubPageURL = 'about-contact'
        }
      }
      setSubPageURL(thisSubPageURL)
    }

    setSelectedClassNameResort(classNameResort)
    setSelectedClassNameNows(classNameNows)
    setSelectedClassNameGuide(classNameGuide)
    setSelectedClassNameListing(classNameListing)
    setSelectedClassNameAbout(classNameAbout)

  }, [location]);

  // Ski Area Sub Menu
  const showSkiAreaSubMenu = (e) => {
    if (!isSkiAreaShown) {
      setIsSkiAreaShown(true);
    }
  }
  const hideSkiAreaSubMenu = (e) => {
    if (isSkiAreaShown) {
      setIsSkiAreaShown(false);
    }
  }

  const skiAreaMenuClicked = (e) => {
    if (!isSkiAreaShown) {
      setIsSkiAreaShown(true);
    }
  }

  // Daily Reports Sub Menu
  const showDailyReportsSubMenu = (e) => {
    if (!isDailyReportsShown) {
      setIsDailyReportsShown(true);
    }
  }

  const hideDailyReportsSubMenu = (e) => {
    if (isDailyReportsShown) {
      setIsDailyReportsShown(false);
    }
  }

  const dailyReportsMenuClicked = (e) => {
    if (!isDailyReportsShown) {
      setIsDailyReportsShown(true);
    }
  }

  // Guide Reports Sub Menu
  const showGuideSubMenu = (e) => {
    if (!isGuideShown) {
      setIsGuideShown(true);
    }
  }

  const hideGuideSubMenu = (e) => {
    if (isGuideShown) {
      setIsGuideShown(false);
    }
  }

  const guideMenuClicked = (e) => {
    if (!isGuideShown) {
      setIsGuideShown(true);
    }
  }

  // Listing Reports Sub Menu
  const showListingSubMenu = (e) => {
    if (!isListingShown) {
      setIsListingShown(true);
    }
  }

  const hideListingSubMenu = (e) => {
    if (isListingShown) {
      setIsListingShown(false);
    }
  }

  const listingMenuClicked = (e) => {
    if (!isListingShown) {
      setIsListingShown(true);
    }
  }

  // About Reports Sub Menu
  const showAboutSubMenu = (e) => {
    if (!isAboutShown) {
      setIsAboutShown(true);
    }
  }

  const hideAboutSubMenu = (e) => {
    if (isAboutShown) {
      setIsAboutShown(false);
    }
  }

  const aboutMenuClicked = (e) => {
    if (!isAboutShown) {
      setIsAboutShown(true);
    }
  }

  return (
    <div className="sticky-top">
      <div className="d-none d-lg-block">
        <Navbar bg="dark" variant="dark" expand="lg" className="justify-content-center sj-nav-bar" collapseOnSelect>
          <div className='sj-top-menu'>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav className="mr-auto justify-content-center">
                <NavDropdown title="Ski Areas" id="navbarSkiArea" show={isSkiAreaShown} onMouseEnter={showSkiAreaSubMenu} onClick={skiAreaMenuClicked} onMouseLeave={hideSkiAreaSubMenu} onSelect={hideSkiAreaSubMenu} className={`sj-menu-link sj-menu-link-Resort ${selectedClassNameResort}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}`} eventKey={1.1} className={`${subPageURL === 'ski-area-home' ? "active" : ""}`}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/discover`} eventKey={1.2} className={`${subPageURL === 'ski-area-discover' ? "active" : ""}`}>Discover</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/listings`} eventKey={1.3} className={`${subPageURL === 'ski-area-listings' ? "active" : ""}`}>Lists</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-japan-skiing-experience/the-ski-season-in-japan`} eventKey={1.4} className={`${subPageURL === 'ski-area-the-season' ? "active" : ""}`}>The season</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/reviews`} eventKey={1.5} className={`${subPageURL === 'ski-area-reviews' ? "active" : ""}`}>Reviews</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/photos`} eventKey={1.6} className={`${subPageURL === 'ski-area-photos' ? "active" : ""}`}>Photos</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseRouteFinderUrl}`} eventKey={1.7} className={`${subPageURL === 'route-finder' ? "active" : ""}`}>Route Finder</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Daily Reports" id="navbarDailyReports" show={isDailyReportsShown} onMouseEnter={showDailyReportsSubMenu} onClick={dailyReportsMenuClicked} onMouseLeave={hideDailyReportsSubMenu} onSelect={hideDailyReportsSubMenu} className={`sj-menu-link sj-menu-link-Nows ${selectedClassNameNows}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}`} eventKey={2.1} className={`${subPageURL === 'daily-report-home' ? "active" : ""}`}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/webcams`} eventKey={2.2} className={`${subPageURL === 'daily-report-webcams' ? "active" : ""}`}>Webcams</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/official-snow-depth`} eventKey={2.3} className={`${subPageURL === 'daily-report-snow-depth' ? "active" : ""}`}>Snow depth tables</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/calendar`} eventKey={2.4} className={`${subPageURL === 'daily-report-calendar' ? "active" : ""}`}>Reports calendar</NavDropdown.Item>
                  {nowList.map((now, index) => (
                    <NavDropdown.Item key={now.Id} as={Link} to={`${window.$baseDailyReportUrl}/${now.UniqueName}`} eventKey={now.UniqueName} className={`${subPageURL === now.UniqueName ? "active" : ""}`}>{now.Name}</NavDropdown.Item>
                  ))}
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Guides" id="navbarGuide" show={isGuideShown} onMouseEnter={showGuideSubMenu} onClick={guideMenuClicked} onMouseLeave={hideGuideSubMenu} onSelect={hideGuideSubMenu} className={`sj-menu-link sj-menu-link-Guide ${selectedClassNameGuide}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}`} eventKey={3.1} className={`${subPageURL === 'guides-home' ? "active" : ""}`}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/introduction-to-skiing-in-japan`} eventKey={3.2} className={`${subPageURL === 'guides-introduction-to-skiing' ? "active" : ""}`}>Our introduction to skiing in Japan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-japan-skiing-experience`} eventKey={3.3} className={`${subPageURL === 'guides-the-japan-skiing-experience' ? "active" : ""}`}>The Japan skiing experience</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/graphs`} eventKey={3.4} className={`${subPageURL === 'guides-graphs' ? "active" : ""}`}>Japan's ski areas in graphs</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/travel-guides`} eventKey={3.5} className={`${subPageURL === 'guides-travel-guides' ? "active" : ""}`}>Travel guides</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-prefectures-of-japan`} eventKey={3.6} className={`${subPageURL === 'guides-prefectures-of-japan' ? "active" : ""}`}>Introducing the prefectures</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/old-ski-areas-from-japans-past`} eventKey={3.7} className={`${subPageURL === 'guides-old-ski-areas' ? "active" : ""}`}>Old ski areas from Japan's past</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Listings" id="navbarListing" show={isListingShown} onMouseEnter={showListingSubMenu} onClick={listingMenuClicked} onMouseLeave={hideListingSubMenu} onSelect={hideListingSubMenu} className={`sj-menu-link sj-menu-link-Listing ${selectedClassNameListing}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}`} eventKey={4.1} className={`${subPageURL === 'listings-home' ? "active" : ""}`}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseAccommodationUrl}`} eventKey={4.2} className={`${subPageURL === 'listings-accommodation' ? "active" : ""}`}>Accommodation</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/resort-spotlights`} eventKey={4.3} className={`${subPageURL === 'listings-resort-spotlights' ? "active" : ""}`}>Resort Spotlights</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/lessons`} eventKey={4.4} className={`${subPageURL === 'listings-lessons' ? "active" : ""}`}>Lessons</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/travel`} eventKey={4.5} className={`${subPageURL === 'listings-travel' ? "active" : ""}`}>Travel</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/job-listings`} eventKey={4.6} className={`${subPageURL === 'listings-job-listings' ? "active" : ""}`}>Jobs Available</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="About" id="navbarAbout" show={isAboutShown} onMouseEnter={showAboutSubMenu} onClick={aboutMenuClicked} onMouseLeave={hideAboutSubMenu} onSelect={hideAboutSubMenu} className={`sj-menu-link sj-menu-link-About ${selectedClassNameAbout}`}>
                  <NavDropdown.Item as={Link} to='/about-snowjapan' eventKey={5.1} className={`${subPageURL === 'about-snowjapan' ? "active" : ""}`}>About SnowJapan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-snowjapan-japanese' eventKey={5.2} className={`${subPageURL === 'about-snowjapan-japanese' ? "active" : ""}`}>このサイトについて</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-daily-reports' eventKey={5.3} className={`${subPageURL === 'about-daily-reports' ? "active" : ""}`}>About daily reports</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-information-and-updates' eventKey={5.4} className={`${subPageURL === 'about-information-and-updates' ? "active" : ""}`}>About our information</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-members-area' eventKey={5.5} className={`${subPageURL === 'about-members-area' ? "active" : ""}`}>About member area</NavDropdown.Item>
                  <NavDropdown.Item href='https://www.snowjapan.com/community' eventKey={5.6}>News & updates</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/contact' eventKey={5.7} className={`${subPageURL === 'about-contact' ? "active" : ""}`}>Contact us</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
      <div className="d-lg-none">
        <Navbar bg="dark" variant="dark" expand="lg" className="sj-nav-bar-mobile" collapseOnSelect>
          <div className='sj-mobile-header'>
            <Navbar.Brand href="/" className="d-lg-none">
              <Image src={textlogo} className='sj-mobile-logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ float: 'right', marginTop: '10px' }} />
          </div>
          <div className='sj-top-menu'>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav className="mr-auto justify-content-center">
                <NavDropdown title="Ski Areas" id="navbarSkiArea" className={`sj-menu-link sj-menu-link-Resort ${selectedClassNameResort}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}`} eventKey={1.1}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/discover`} eventKey={1.2}>Discover</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/listings`} eventKey={1.3}>Lists</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-japan-skiing-experience/the-ski-season-in-japan`} eventKey={1.4}>The season</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/reviews`} eventKey={1.5}>Reviews</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseSkiAreaUrl}/photos`} eventKey={1.6}>Photos</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseRouteFinderUrl}`} eventKey={1.7}>Route Finder</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Daily Reports" id="navbarDailyReports" className={`sj-menu-link sj-menu-link-Nows ${selectedClassNameNows}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}`} eventKey={2.1}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/webcams`} eventKey={2.2}>Webcams</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/official-snow-depth`} eventKey={2.3}>Snow depth tables</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseDailyReportUrl}/calendar`} eventKey={2.4}>Reports calendar</NavDropdown.Item>
                  {nowList.map((now, index) => (
                    <NavDropdown.Item key={now.Id} as={Link} to={`${window.$baseDailyReportUrl}/${now.UniqueName}`} eventKey={now.UniqueName}>{now.Name}</NavDropdown.Item>
                  ))}
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Guides" id="navbarGuide" className={`sj-menu-link sj-menu-link-Guide ${selectedClassNameGuide}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}`} eventKey={3.1}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/introduction-to-skiing-in-japan`} eventKey={3.2}>Our introduction to skiing in Japan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-japan-skiing-experience`} eventKey={3.3}>The Japan skiing experience</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/graphs`} eventKey={3.4}>Japan's ski areas in graphs</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/travel-guides`} eventKey={3.5}>Travel guides</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/the-prefectures-of-japan`} eventKey={3.6}>Introducing the prefectures</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseGuideUrl}/old-ski-areas-from-japans-past`} eventKey={3.7}>Old ski areas from Japan's past</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="Listings" id="navbarListing" className={`sj-menu-link sj-menu-link-Listing ${selectedClassNameListing}`}>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}`} eventKey={4.1}>Index</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseAccommodationUrl}`} eventKey={4.2}>Accommodation</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/resort-spotlights`} eventKey={4.3}>Resort Spotlights</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/lessons`} eventKey={4.4}>Lessons</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/travel`} eventKey={4.5}>Travel</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`${window.$baseListingUrl}/job-listings`} eventKey={4.6}>Jobs Available</NavDropdown.Item>
                </NavDropdown>
                <span className="d-none d-lg-block nav-divider">|</span>
                <NavDropdown title="About" id="navbarAbout" className={`sj-menu-link sj-menu-link-About ${selectedClassNameAbout}`}>
                  <NavDropdown.Item as={Link} to='/about-snowjapan' eventKey={5.1}>About SnowJapan</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-snowjapan-japanese' eventKey={5.2}>このサイトについて</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-daily-reports' eventKey={5.3}>About daily reports</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-information-and-updates' eventKey={5.4}>About our information</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/about-members-area' eventKey={5.5}>About member area</NavDropdown.Item>
                  <NavDropdown.Item href='https://www.snowjapan.com/community' eventKey={5.6}>News & updates</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to='/contact' eventKey={5.7}>Contact us</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar >
      </div >
    </div>
  );
}

export default MainNav;