import axios from 'axios'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { RenderBreak } from '../../helpers/Helper'

const GuideHome = () => {

    const location = useLocation()

    const [guideHomePage, setGuideHomePage] = useState('');

    const fetchGuideHomePage = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/Guide`
        }).then(resGeneralMessages => {
            setGuideHomePage(resGeneralMessages.data.Message)
        })
    };

    useEffect(() => {
        fetchGuideHomePage()
    }, [location.path]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: RenderBreak(guideHomePage) }} />
        </>
    )
};

export default GuideHome;