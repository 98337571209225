import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import Loader from '../Common/Loader'
import { Link } from 'react-router-dom'
import useSkiAreaReviews from '../CustomHooks/useSkiAreaReviews'
import MemberReviewDetail from './MemberReviewDetail'

const MemberReviews = ({ user }) => {

    const queryReview = 0
    const userName = user.UserName
    const [pageNumberReview, setPaegNumberReview] = useState(1)

    const {
        loadingReview,
        errorReview,
        reviews,
        hasMoreReview
    } = useSkiAreaReviews(queryReview, userName, pageNumberReview)

    const observerReview = useRef()
    const lastReviewElementRef = useCallback(nodeReview => {
        if (loadingReview)
            return
        if (observerReview.current)
            observerReview.current.disconnect()
        observerReview.current = new IntersectionObserver(entriesReview => {
            if (entriesReview[0].isIntersecting && hasMoreReview) {
                setPaegNumberReview(prevPageNumberReview => prevPageNumberReview + 1)
            }
        })

        if (nodeReview)
            observerReview.current.observe(nodeReview)
    }, [loadingReview, hasMoreReview])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>{user.FullName}’s reviews | Member area | SnowJapan</title>
                <meta name="description" content="Member reviews | Member area | SnowJapan" />
            </Helmet>
            <div>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Reviews.svg" alt='review' /></div>
                <div>
                    <h3>My ski area reviews</h3>
                    <div className="sj-para">
                        Below are the ski area reviews you have posted on SnowJapan. Please note that reviews need to be manually approved before appearing on the site, so please allow some time after posting a review.
                    </div>
                    {/*<div className="col-md-6 sj-row-no-margin-padding padding-right-5">
                        <Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-1'><h6 className='mt-1 mb-1'>Upload new ski area photos</h6></Link>
                    </div>
                    <div className="col-md-6 sj-row-no-margin-padding padding-left-5">
                        <Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-1'><h6 className='mt-1 mb-1'>Write a new ski area review</h6></Link>
                    </div>*/}
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Write a new ski area review</Link></h6>
                    </div>
                    <div className="sj-para">
                        <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                    </div>
                </div>
            </div>
            <div className='empty-row'>
                <div className="sj-para">
                    <h3>Recent reviews</h3>
                </div>
                {reviews.map((review, index) => {
                    if (reviews.length === index + 1) {
                        return (
                            <div ref={lastReviewElementRef} key={review.Id}>
                                <MemberReviewDetail review={review} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={review.Id}>
                                <MemberReviewDetail review={review} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingReview &&
                        <Loader />
                    }
                </div>
                <div>{errorReview && 'Error'}</div>
            </div>
        </div>
    )
}

export default MemberReviews