/* eslint-disable */
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useSearchParams, useParams } from 'react-router-dom'
import { Form, Button, Row, Collapse } from 'react-bootstrap'
import axios from 'axios'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './../../AuthenticationForm.css'

//`${window.$baseSJAPIUrl}/api/Account/Register`
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/

const ResetPassword = () => {

    const params = useParams()

    const [errMsg, setErrMsg] = useState('')
    const [statusCode, setStatusCode] = useState('')
    const [success, setSuccess] = useState(false)
    const [nonSuccessMessage, setNonSuccessMessage] = useState('Please wait while we verify your email.')
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token') || ''
    const [formerrors, setFormErrors] = useState({})
    const [isVisible, setIsVisible] = useState(false)

    const [values, setValues] = useState({
        Token: "",
        Password: "",
        ConfirmPassword: ""
    })

    useEffect(() => {
        //HandleConfirmEmail(token);
        setValues((values) => ({
            ...values,
            "Token": token,
        }))
    }, [token])

    const handleChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name.slice(3)]: event.target.value,
        }))
    }

    const validate = () => {
        let errors = {}

        if (!values.Password) {
            errors.Password = "The password field cannot be blank."
        }
        else if (!PWD_REGEX.test(values.Password)) {
            errors.Password = "The password field is not valid."
        }

        if (!values.ConfirmPassword) {
            errors.ConfirmPassword = "The confirm password field cannot be blank."
        } else if (values.Password !== values.ConfirmPassword) {
            errors.ConfirmPassword = "The confirm password did not match with the password."
        }

        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            return false
        }
    }

    /*const HandleResetPassword = (thisToken) => {
        console.log(thisToken)
        try {
            axios({
                method: 'POST',
                url: `${window.$baseSJAPIUrl}/Account/ResetPassword`,
                data: {
                    Token: thisToken,
                    Password: ,
                    ConfirmPassword: 
                }
            }).then(resetPasswordResult => {
                console.log(resetPasswordResult);
                if (resetPasswordResult.data.ResultType === 'SUCCESS') {
                    setSuccess(true)
                    setErrMsg('')
                } else {
                    setErrMsg(resetPasswordResult.data.Message)
                    setStatusCode(resetPasswordResult.data.StatusCode)
                }
            })
        } catch (err) {
            console.log(err)
            if (!err?.response) {
                setErrMsg('No server response');
            }
        }
    }*/

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate(values)) {
            try {
                var thisData = {
                    Token: values.Token,
                    Password: values.Password,
                    ConfirmPassword: values.ConfirmPassword
                }

                console.log(thisData)

                axios({
                    method: 'POST',
                    url: `${window.$baseSJAPIUrl}/Account/ResetPassword`,
                    data: thisData
                }).then(resetPasswordResult => {

                    console.log(resetPasswordResult?.data);

                    if (resetPasswordResult.data.ResultType === 'SUCCESS') {
                        setSuccess(true)
                        setErrMsg('')
                    } else {
                        setErrMsg(resetPasswordResult.data.Message)
                        setStatusCode(resetPasswordResult.data.StatusCode)
                    }
                })
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response')
                }
            }
        }
    }

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Reset password  | Member area | SnowJapan</title>
                <meta name="description" content="Register a new account to upload photos and submit reviews on SnowJapan." />
            </Helmet>
            <div>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Icons-Member.svg" alt='member-login' /></div>
                <div>
                    <h3>SnowJapan member area</h3>
                    <div className="sj-para">
                        <p>Please enter a new password below and click Reset password to assign a new password.</p>
                    </div>
                </div>
                <div className="empty-row">
                    {success ? (
                        <section>
                            <h1>Success!</h1>
                            <p>
                                <div>Your password has been changed successfully. Please click the button below to login.</div>
                                <div className='sj-row mt-3 pt-3'>
                                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                        <div className="col-md-6 sj-row-no-margin-padding">
                                            <Link to={`${window.$baseAppUrl}/login`} className='btn btn-primary w-100 sj-box-dark-blue mt-2'><h6 className='mt-1 mb-1'>Login here</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </p>
                        </section>
                    ) : (
                        <>
                            {errMsg !== '' &&
                                <div>
                                    <h1 className="text-danger">Error!</h1>
                                    <div className="text-danger">{errMsg}</div>
                                </div>
                            }
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3 sj-no-margin">
                                    <Form.Group controlId="txtPassword" className="mb-4">
                                        <Form.Label>
                                            <h5>Password:</h5>
                                        </Form.Label>
                                        <Form.Control name="txtPassword" type="password" placeholder="Password" value={values.Password} onChange={handleChange} />
                                        {formerrors.Password && (
                                            <div className="text-danger">{formerrors.Password}
                                                {formerrors.Password === 'The password field is not valid.' &&
                                                    <>
                                                        <FontAwesomeIcon icon={faQuestionCircle} onClick={invokeCollapse} />
                                                        <Collapse in={isVisible}>
                                                            <div id="collapsePanel">
                                                                <div>
                                                                    8 to 24 characters.<br />
                                                                    Must include uppercase and lowercase letters, a number and a special character.<br />
                                                                    Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                                                </div>
                                                            </div>
                                                        </Collapse>
                                                    </>
                                                }
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="txtConfirmPassword" className="mb-4">
                                        <Form.Label><h5>Confirm password:</h5></Form.Label>
                                        <Form.Control name="txtConfirmPassword" type="password" placeholder="Confirm password" value={values.ConfirmPassword} onChange={handleChange} />
                                        {formerrors.ConfirmPassword && (

                                            <div className="text-danger">{formerrors.ConfirmPassword}</div>
                                        )}
                                    </Form.Group>
                                    <div className='center-aligned'>
                                        <Button type="submit" className='sj-large-font-button sj-red-bg sj-row-100'>Reset password</Button>
                                    </div>
                                </Row>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </div >
    )
}

export default ResetPassword