import { Link } from 'react-router-dom'
import { GetTotalMonthDays, GetCurrentSeasonName } from '../../../../helpers/Helper'

const SnowTable = ({ resort, snowData, skiAreaDailyReport }) => {

  const getMonthlySeasonSnowDepthValue = (month) => {

    let monthlySeasonSnowfallData = []

    let snowSeasons = [...new Set(snowData.map(item => item.Season))].sort().reverse();

    snowSeasons.forEach(function (snowSeason) {

      let dailySnowDepthData = []

      let dailySnowData = snowData.filter(function (currentElement) {
        return (currentElement.WeatherMonthFull === month && currentElement.Season === snowSeason);
      });

      dailySnowData.forEach(function (dataItem) {
        dailySnowDepthData.push({ 'day': dataItem.WeatherDay, 'dailySnowDepth': dataItem.SnowDepth })
      })

      monthlySeasonSnowfallData.push({ 'seasonName': snowSeason, 'seasonDailySnowDepth': dailySnowDepthData })
    })

    return monthlySeasonSnowfallData;
  }

  let dailySnowDepthDataList = [];
  //let months = ['November', 'December', 'January', 'February', 'March', 'April'];
  let months = ['December', 'January', 'February', 'March', 'April'];

  months.forEach(function (month) {
    dailySnowDepthDataList.push({
      'name': month,
      'seasonData': getMonthlySeasonSnowDepthValue(month)
    })
  })

  let totalDays = 0

  const runCallback = (cb) => {
    return cb();
  };

  const GetDailyData = (monthlyData, day) => {
    let snowData = monthlyData.filter(function (elm) {
      return (elm.day === day);
    });

    if (snowData.length > 0 && snowData[0].dailySnowDepth !== 999) {
      return snowData[0].dailySnowDepth;
    } else {
      return '';
    }
  };

  const GetDayTh = (day) => {
    let dayTh = ''

    switch (day) {
      case 1:
      case 21:
      case 31:
        dayTh = 'st'
        break;
      case 2:
      case 22:
        dayTh = 'nd'
        break;
      case 3:
      case 23:
        dayTh = 'rd'
        break;
      default:
        dayTh = 'th'
    }

    return day + dayTh
  };

  const currentSeason = GetCurrentSeasonName()

  if (snowData.length <= 0) {
    return (<>Loading...</>)
  }
  else {
    return (
      <div className='empty-row'>
        {
          dailySnowDepthDataList.map((monthlyData, index) => (
            <div key={index} className='empty-row' style={{ overflowX: 'auto' }}>
              <div className="sj-box sj-box-dark-gray sj-round-half center-aligned mt-5">
                <h4>{monthlyData.name}</h4>
              </div>
              <div className='table-container'>
                <table className='table-container__table sj-data-table'>
                  <thead>
                    <tr>
                      <th>Season</th>
                      {runCallback(() => {
                        totalDays = GetTotalMonthDays(monthlyData.name)
                        const row = [];
                        for (var i = 1; i < (totalDays + 1); i++) {
                          row.push(<th key={i}>{GetDayTh(i)}</th>);
                        }
                        return row;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {monthlyData.seasonData.map((seasonSnowData, key) => (
                      <tr key={key}>
                        <td className={`td-first-column ${seasonSnowData.seasonName === currentSeason ? "sj-box-red" : ""}`}><Link to={`${window.$baseDailyReportUrl}/${skiAreaDailyReport.NowUniqueName}/season/${seasonSnowData.seasonName}`}>{seasonSnowData.seasonName}</Link></td>
                        {runCallback(() => {
                          const row = [];
                          for (var i = 1; i < (totalDays + 1); i++) {
                            row.push(<td key={i} className={`${seasonSnowData.seasonName === currentSeason ? "sj-box-light-red" : ""}`}>{
                              GetDailyData(seasonSnowData.seasonDailySnowDepth, i)
                            }</td>);
                          }
                          return row;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))
        }
      </div >
    )
  }
}

export default SnowTable;