import { useState, useEffect, Fragment } from 'react'
import { Form } from 'react-bootstrap'

const WayPointAutocomplete = ({ skiAreaList, onRoutePointSelect, initialValue, slNo, controlName, readOnly }) => {

    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const [filteredSkiAreas, setFilteredSkiAreas] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [userInput, setUserInput] = useState('')

    const onChange = (e) => {
        let selectedSkiAreaNameParts = e.currentTarget.value.split('(')

        const userInput = selectedSkiAreaNameParts[0].trim();

        const thisFilteredSkiAreas = skiAreaList.filter(
            //skiArea => (skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.NameJapanese.indexOf(userInput) === 0 || skiArea.TownName.toLowerCase().indexOf(userInput.toLowerCase()) === 0 || skiArea.TownNameJapanese.indexOf(userInput) === 0 || (skiArea.AreaName !== null && skiArea.AreaName !== undefined && skiArea.AreaName.toLowerCase().indexOf(userInput.toLowerCase()) === 0) || (skiArea.AreaNameJapanese !== null && skiArea.AreaNameJapanese !== undefined && skiArea.AreaNameJapanese.indexOf(userInput) === 0))
            skiArea => (skiArea.Name.toLowerCase().indexOf(userInput.toLowerCase()) >= 0 || skiArea.NameJapanese.indexOf(userInput) >= 0 || skiArea.TownName.toLowerCase().indexOf(userInput.toLowerCase()) >= 0 || skiArea.TownNameJapanese.indexOf(userInput) >= 0 || (skiArea.AreaName !== null && skiArea.AreaName !== undefined && skiArea.AreaName.toLowerCase().indexOf(userInput.toLowerCase()) >= 0) || (skiArea.AreaNameJapanese !== null && skiArea.AreaNameJapanese !== undefined && skiArea.AreaNameJapanese.indexOf(userInput) >= 0))
        );

        setActiveSuggestion(0)
        setFilteredSkiAreas(thisFilteredSkiAreas)
        setShowSuggestions(true)
        setUserInput(e.currentTarget.value)
    };

    const onClick = e => {
        setActiveSuggestion(0)
        setFilteredSkiAreas([])
        setShowSuggestions(false)
        setUserInput(e.currentTarget.innerText)

        let selectedSkiAreaNameParts = e.currentTarget.innerText.split('(')

        const selectedSkiAreaList = skiAreaList.filter(
            skiArea => (skiArea.Name === selectedSkiAreaNameParts[0].trim())
        );

        let coords = e.currentTarget.innerText.split(',')

        if (selectedSkiAreaList.length > 0) {

            let selectedSkiArea = selectedSkiAreaList[0];

            let newRoutePoint = {
                name: selectedSkiArea.Name + ' (' + selectedSkiArea.PrefectureName + ')',
                location_latitude: selectedSkiArea.Location_Latitude,
                location_longitude: selectedSkiArea.Location_Longitude,
                index: slNo
            };

            onRoutePointSelect(newRoutePoint)
        }
        else if (coords.length === 2) {
            let newRoutePoint = {
                name: e.currentTarget.innerText,
                location_latitude: coords[0],
                location_longitude: coords[1],
                index: slNo
            };

            onRoutePointSelect(newRoutePoint)
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            setActiveSuggestion(0)
            setShowSuggestions(false)
            let selectedSkiArea = filteredSkiAreas[activeSuggestion]
            if (selectedSkiArea !== null && selectedSkiArea !== undefined) {
                setUserInput(selectedSkiArea.Name + ' (' + selectedSkiArea.PrefectureName + ')')
                let newRoutePoint = {
                    name: selectedSkiArea.Name + ' (' + selectedSkiArea.PrefectureName + ')',
                    location_latitude: selectedSkiArea.Location_Latitude,
                    location_longitude: selectedSkiArea.Location_Longitude,
                    index: slNo
                };

                onRoutePointSelect(newRoutePoint)
            }
        }
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setActiveSuggestion(activeSuggestion - 1)
        }
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSkiAreas.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    };

    const isCoordinate = (input) => {

        let coords = input.split(',')
        if (coords.length === 2) {
            return true
        }
        return false
    }

    const isValidCoordinate = (input) => {

        let coords = input.split(',')
        if (coords.length === 2) {
            if (coords[0].trim() >= 26.075 && coords[0].trim() <= 45.52722222222222 && coords[1].trim() >= 127.6363888888889 && coords[1].trim() <= 145.8175) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        setUserInput(initialValue)
    }, [initialValue]);

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
        if (filteredSkiAreas.length) {
            suggestionsListComponent = (
                <ul className="suggestions">
                    {filteredSkiAreas.map((skiArea, index) => {
                        let className;

                        if (index === activeSuggestion) {
                            className = "suggestion-active";
                        }

                        return (
                            <li className={className} key={index} onClick={onClick}>
                                {skiArea.Name} ({skiArea.PrefectureName})
                            </li>
                        );
                    })}
                </ul>
            );
        } else if (isCoordinate(userInput)) {
            if (isValidCoordinate(userInput)) {
                suggestionsListComponent = (<ul className="suggestions">
                    <li onClick={onClick}>
                        {userInput}
                    </li>
                </ul>
                );
            }
            else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>Only coordinates in Japan are valid</em>
                    </div>
                );
            }
        } else {
            suggestionsListComponent = (
                <div className="no-suggestions">
                    <em>No ski area found</em>
                </div>
            );
        }
    }

    return (
        <Fragment>
            <Form.Control name={controlName} type="text" placeholder="Type ski area or town name here" onChange={onChange} onKeyDown={onKeyDown} value={userInput} readOnly={readOnly} />
            {suggestionsListComponent}
        </Fragment>
    );
};

export default WayPointAutocomplete;