import { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import GuideHome from './Home'
import GuideDetail from './GuideDetail'

const GuideContainer = () => {

    const params = useParams()

    let requestType = undefined

    if (params.section_name !== '' && params.section_name !== undefined) {
        requestType = 'guide-detail'
    }

    return (
        <Fragment>
            <>
                {
                    {
                        undefined: <GuideHome />,
                        'guide-detail': <GuideDetail params={params} />
                    }[requestType]
                }
            </>
        </Fragment>
    )
}

export default GuideContainer