import { Link, useNavigate } from 'react-router-dom'
import { Form, FormControl, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useAuth } from '../CustomHooks/useAuth'
import axios from 'axios'

const SearchMemberBar = () => {

  const { setAuth, auth, setUser, user } = useAuth()

  const [loggedinUser, setLoggedinUser] = useState(user)
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('')

  const handleChange = (e) => {
    setKeyword(e.target.value)
  }

  const searchClicked = () => {
    let path = `/search/${keyword}`;
    //history.push(path);
    navigate(path);
  }

  const logout = async () => {
    try {
      axios({
        method: 'POST',
        url: `${window.$baseSJAPIUrl}/Account/Logout`
      }).then(logoutStatusResult => {

        //console.log('logged out')
        //console.log(logoutStatusResult?.data);

        let loggedoutUser = logoutStatusResult.data
        sessionStorage.setItem('sessionUser', null)
        setAuth(false)
        setUser(null)
        //history.push('/');;
        navigate('/')
      })

      //clear state and controlled inputs
      //need value attrib on inputs for this

      //setUserName('')
      //setPwd('')
    } catch (err) {
      //console.log(err)
    }
  }

  useEffect(() => {
    setLoggedinUser(user)
  }, [auth, user])

  return (
    <div className='sj-box-light-gray pt-2 pb-2'>
      <Form className="d-flex justify-content-center">
        <FormControl type="search" size="sm" placeholder="Search" style={{ width: 250 }} className="me-2" aria-label="Search" onBlur={handleChange} /> <Button variant="primary" onClick={searchClicked}>Search</Button>
      </Form>
      {(loggedinUser !== null && loggedinUser.UserName !== null && loggedinUser.UserName !== '') &&
        <div className='center-aligned'>Hello, {loggedinUser.FullName}: <Link to={`${window.$baseMemberUrl}`} className='normal-link'>member page</Link> [<a className='normal-link' onClick={logout}>Logout</a>]</div>
      }
    </div>
  );
}

export default SearchMemberBar;