import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { Button, Offcanvas } from 'react-bootstrap'
import Loader from "../Common/Loader"
import PrefectureCheckList from "../Common/PrefectureCheckList"
import TownCheckList from "../Common/TownCheckList"
import AreaCheckList from "../Common/AreaCheckList"
import NearbySkiAreaCheckList from "../Common/NearbySkiAreaCheckList"
import SearchResult from './SearchResult'
import { RenderBreak } from '../../helpers/Helper'

const AccommodationHome = ({ accommodationList, uniqueSkiAreas, allNearbySkyAreaList }) => {

    const params = useParams()

    const [accommodationHomePage, setAccommodationHomePage] = useState('')

    const [allAccommodationList, setAllAccommodationList] = useState([])

    // Prefectures
    const [prefectures, setPrefectures] = useState([])
    const [selectedPrefectures, setSelectedPrefectures] = useState([])

    // Areas
    const [allAreas, setAllAreas] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([])

    // Towns
    const [allTowns, setAllTowns] = useState([]);
    const [selectedTowns, setSelectedTowns] = useState([])

    // Ski Areas
    const [selectedSkiAreas, setSelectedSkiAreas] = useState([])

    const ClearSort = useRef(null)
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [noFilter, setNoFilter] = useState(true)

    const fetchAccommodationHomePage = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/Accommodation`
        }).then(resGeneralMessages => {
            setAccommodationHomePage(resGeneralMessages.data.Message)
        })
    };

    // Pre-Searched Prefecture, Area and Town List
    const getAccommodationPrefectures = (resultAccommodationList) => {
        if (resultAccommodationList.length > 0) {
            const prefectures = [...new Map(resultAccommodationList.map(item => [item['PrefectureUniqueName'], item])).values()]

            const uniquePrefectures = prefectures.map(p => ({ name: p.PrefectureName, uniqueName: p.PrefectureUniqueName, checked: false }))

            uniquePrefectures.sort(function (a, b) {
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0

            })

            setPrefectures(uniquePrefectures)
        }
    }

    const getAllAccommodationAreas = (allAccommodations) => {

        if (allAccommodations.length > 0) {
            const areas = [...new Map(allAccommodations.map(item => [item['AreaUniqueName'], item])).values()]

            const uniqueAreas = areas.map(a => ({ name: a.AreaName, uniqueName: a.AreaUniqueName, prefectureUniqueName: a.PrefectureUniqueName, checked: false, disabled: false }));

            uniqueAreas.sort(function (a, b) {
                if (a.name === null || b.name === null) {
                    return 0;
                }
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0
            })

            setAllAreas(uniqueAreas)
        }
    }

    const getAllAccommodationTowns = (allAccommodations) => {
        if (allAccommodations.length > 0) {
            const towns = [...new Map(allAccommodations.map(item => [item['TownUniqueName'], item])).values()];

            const uniqueTowns = towns.map(t => ({ name: t.TownName, uniqueName: t.TownUniqueName, prefectureUniqueName: t.PrefectureUniqueName, checked: false, disabled: false }));

            uniqueTowns.sort(function (a, b) {
                let nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();

                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                return 0

            })

            setAllTowns(uniqueTowns)
        }
    }

    const getSearchResult = (thisAccommodationList) => {
        let searchedAccommodation = thisAccommodationList

        var searchedAccommodationPrefecture = []
        var searchedAccommodationArea = []
        var searchedAccommodationTown = []
        var searchedAccommodationSkiArea = []

        // Prefecture Search
        let selectedPrefectureList = selectedPrefectures.filter(function (currentElement) {
            return (currentElement.checked)
        })

        if (selectedPrefectureList.length > 0) {

            var prefectureUniqueNames = selectedPrefectureList.map(function (obj) { return obj.uniqueName; })

            searchedAccommodationPrefecture = thisAccommodationList.filter(({ PrefectureUniqueName }) => prefectureUniqueNames.includes(PrefectureUniqueName))
        }

        // Area Search
        let selectedAreaList = selectedAreas.filter(function (currentElement) {
            return (currentElement.checked)
        });

        if (selectedAreaList.length > 0) {

            var areaUniqueNames = selectedAreaList.map(function (obj) { return obj.uniqueName; })

            searchedAccommodationArea = thisAccommodationList.filter(({ AreaUniqueName }) => areaUniqueNames.includes(AreaUniqueName))
        }

        // Town Search
        let selectedTownList = selectedTowns.filter(function (currentElement) {
            return (currentElement.checked)
        });

        if (selectedTownList.length > 0) {

            var townUniqueNames = selectedTownList.map(function (obj) { return obj.uniqueName; })

            searchedAccommodationTown = thisAccommodationList.filter(({ TownUniqueName }) => townUniqueNames.includes(TownUniqueName))
        }

        // Ski Area Search
        let selectedSkiAreaList = selectedSkiAreas.filter(function (currentElement) {
            return (currentElement.checked)
        })

        if (selectedSkiAreaList.length > 0) {

            var skiAreaUniqueNames = selectedSkiAreaList.map(function (obj) { return obj.uniqueName; })

            var selectedNearbySkiAreas = allNearbySkyAreaList.filter(({ SkiAreaUniqueName }) => skiAreaUniqueNames.includes(SkiAreaUniqueName))

            var accommodationUniqueNames = selectedNearbySkiAreas.map(function (obj) { return obj.AccommodationUniqueName })

            searchedAccommodationSkiArea = thisAccommodationList.filter(({ UniqueName }) => accommodationUniqueNames.includes(UniqueName))
        }

        if (selectedPrefectureList.length > 0 || selectedAreaList.length > 0 || selectedTownList.length > 0 || selectedSkiAreaList.length > 0) {
            searchedAccommodation = [...new Set([...searchedAccommodationPrefecture, ...searchedAccommodationTown, ...searchedAccommodationArea, ...searchedAccommodationSkiArea])];

            setNoFilter(false)
        }

        searchedAccommodation = searchedAccommodation.sort(function (a, b) {
            let nameA = a.Name.toLowerCase(), nameB = b.Name.toLowerCase()

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        })

        setAllAccommodationList(searchedAccommodation)
        getAccommodationPrefectures(searchedAccommodation)
        getAllAccommodationAreas(accommodationList)
        getAllAccommodationTowns(accommodationList)
    }

    useEffect(() => {
        fetchAccommodationHomePage()
    }, [params])

    useEffect(() => {
        getSearchResult(accommodationList)

    }, [params, accommodationList, uniqueSkiAreas, selectedPrefectures, selectedTowns, selectedAreas, selectedSkiAreas])

    const ClearSearch = () => {

        if (selectedPrefectures.length > 0) {
            const selPrefectures = selectedPrefectures.map(p => ({ name: p.name, uniqueName: p.uniqueName, checked: false }))
            setSelectedPrefectures(selPrefectures)
        }

        if (selectedAreas.length > 0) {
            const selAreas = selectedAreas.map(a => ({ name: a.AreaName, uniqueName: a.AreaUniqueName, prefectureUniqueName: a.PrefectureUniqueName, checked: false, disabled: false }))
            setSelectedAreas(selAreas)
        }

        if (selectedTowns.length > 0) {
            const selTowns = selectedTowns.map(t => ({ name: t.TownName, uniqueName: t.TownUniqueName, prefectureUniqueName: t.PrefectureUniqueName, checked: false, disabled: false }))
            setSelectedTowns(selTowns)
        }

        if (selectedSkiAreas.length > 0) {
            const selSkiAreas = uniqueSkiAreas.map(sa => ({ name: sa.name, uniqueName: sa.uniqueName, prefectureUniqueName: sa.prefectureUniqueName, accommodationUniqueName: sa.accommodationUniqueName, checked: false, disabled: false }))
            setSelectedSkiAreas(selSkiAreas)
        }

        getSearchResult(accommodationList)
        setNoFilter(true)

        ClearSort.current()
    }

    if (prefectures.length <= 0) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Accommodation near ski areas in Japan | SnowJapan</title>
                    <meta name="description" content="Accommodation and places to stay near popular ski areas throughout Japan" />
                </Helmet>
                <h3>Accommodation</h3>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Accommodations.svg" alt="Japan Ski Areas" /></div>
                <div>
                    <div className="sj-para">
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(accommodationHomePage) }} />
                    </div>
                </div>
                <div className='empty-row'>
                    <Button variant="primary" onClick={handleShow} className='sj-large-font-button sj-red-bg'>{'< Select filters'}</Button>&nbsp;
                    <Button variant="primary" onClick={ClearSearch} className='sj-large-font-button'>Clear all filters</Button>
                    <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <h3>Choose filter(s)</h3>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div>Choose any combination of filters from this list of Prefectures, Areas and Towns.</div>
                            <div className='search-bar-frame'>
                                <div className='search-bar-frame-body sj-box-lighter-blue'>
                                    <PrefectureCheckList
                                        options={prefectures}
                                        selectedPrefectures={selectedPrefectures}
                                        onChange={data => {
                                            setSelectedPrefectures(data)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='search-bar-frame'>
                                <div className='search-bar-frame-body sj-box-lighter-blue'>
                                    <AreaCheckList
                                        options={allAreas}
                                        selectedPrefectures={selectedPrefectures}
                                        selectedAreas={selectedAreas}
                                        onChange={data => {
                                            setSelectedAreas(data)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='search-bar-frame'>
                                <div className='search-bar-frame-body sj-box-lighter-blue'>
                                    <TownCheckList
                                        options={allTowns}
                                        selectedPrefectures={selectedPrefectures}
                                        selectedTowns={selectedTowns}
                                        onChange={data => {
                                            setSelectedTowns(data)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='search-bar-frame'>
                                <div className='search-bar-frame-body sj-box-lighter-blue'>
                                    <NearbySkiAreaCheckList
                                        options={uniqueSkiAreas}
                                        selectedPrefectures={selectedPrefectures}
                                        selectedSkiAreas={selectedSkiAreas}
                                        onChange={data => {
                                            setSelectedSkiAreas(data)
                                        }}
                                    />
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <div>
                        <SearchResult allAccommodationList={allAccommodationList} ClearSort={ClearSort} prefectures={selectedPrefectures} areas={selectedAreas} towns={selectedTowns} nearbySkiAreas={selectedSkiAreas} noFilter={noFilter} />
                    </div>
                </div>
            </div>
        )
    }
};

export default AccommodationHome