import { useState, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Loader from "../../../Common/Loader"
import SkiAreaInfo from '../SkiAreaInfo'
import useSkiAreaPhotos from '../../../CustomHooks/useSkiAreaPhotos'
import PhotoDetail from './PhotoDetail'

const PhotosContainer = ({ resort }) => {

    const queryPhoto = resort.Id
    const userName = ''
    const [pageNumberPhoto, setPaegNumberPhoto] = useState(1)

    const {
        loadingPhoto,
        errorPhoto,
        photos,
        hasMorePhoto
    } = useSkiAreaPhotos(queryPhoto, userName, pageNumberPhoto)

    const observerPhoto = useRef()
    const lastPhotoElementRef = useCallback(nodePhoto => {
        if (loadingPhoto)
            return
        if (observerPhoto.current)
            observerPhoto.current.disconnect()
        observerPhoto.current = new IntersectionObserver(entriesPhoto => {
            if (entriesPhoto[0].isIntersecting && hasMorePhoto) {
                setPaegNumberPhoto(prevPageNumberPhoto => prevPageNumberPhoto + 1)
            }
        })

        if (nodePhoto)
            observerPhoto.current.observe(nodePhoto)
    }, [loadingPhoto, hasMorePhoto])

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Photos of {resort.Name} ({resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan) | SnowJapan</title>
                <meta name="description" content={`Reader photos of ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
            </Helmet>
            <div className='empty-row'>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Photos.svg" alt={`${resort.Name}`} /></div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        <h3>Photos of {resort.Name}</h3>
                        <div className="sj-para">
                            Photos of <strong>{resort.Name}</strong> that have been posted by SnowJapan members.
                        </div>
                        <div className="sj-para">
                            <h6 className='mt-1 mb-1'><Link to={`${window.$baseMemberUrl}/upload-photo/${resort.UniqueName}`} className='btn btn-primary sj-large-font-button sj-red-bg sj-50-box'>Upload new ski area photos</Link></h6>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="sj-para">
                    <h3>Recent photos</h3>
                </div>
                {photos.map((photo, index) => {
                    if (photos.length === index + 1) {
                        return (
                            <div ref={lastPhotoElementRef} key={photo.Id}>
                                <PhotoDetail resort={resort} photo={photo} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={photo.Id}>
                                <PhotoDetail resort={resort} photo={photo} />
                            </div>
                        )
                    }
                })}
                <div>
                    {loadingPhoto &&
                        <Loader />
                    }
                </div>
                <div>{errorPhoto && 'Error'}</div>
            </div>
        </div>
    )
};

export default PhotosContainer;