import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import SkiAreaInfo from '../SkiAreaInfo'
import { RenderBreak } from '../../../../helpers/Helper'

const LocationContainer = ({ resort, nearbySkiAreaList }) => {
    const [location, setLocation] = useState([]);
    const [nearbySkiAreas, setNearbySkiAreas] = useState(nearbySkiAreaList);

    const fetchLocation = async (resortUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/skiarea/location/${resortUniqueName}`
        }).then(dataLocation => {
            setLocation(dataLocation.data)
        })
    };

    useEffect(() => {
        fetchLocation(resort.UniqueName);
        setNearbySkiAreas(nearbySkiAreaList)
    }, [resort.UniqueName, nearbySkiAreaList]);

    return (
        <div className='empty-row'>
            <Helmet>
                <title>Location of {resort.Name} | Ski area in {resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan  |  {resort.NameJapanese} | SnowJapan</title>
                <meta name="description" content={`Location and maps of ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
            </Helmet>
            <div className='empty-row'>
                <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Location.svg" alt={`${resort.Name} location`} /></div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        <h3>Location & access</h3>
                        <div className="sj-para">
                            {resort.Name} is a ski area located in {resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}.
                        </div>
                        <div className="sj-para">
                            <h5><Link to={`${window.$baseRouteFinderUrl}/${resort.UniqueName}`} className='btn btn-primary sj-large-button-blue sj-50-box'>Route Finder</Link></h5>
                        </div>
                        <div className='sj-para'>
                            Click on the above <strong>Route Finder</strong> link to discover how to get to <strong>{resort.Name}</strong> from your current location or any other ski area in Japan.
                        </div>
                    </div>
                </div>
            </div>
            <div className="sj-row">
                <div className='ratio ratio-16x9'>
                    <iframe title='Location Map' width='930' height='600' src={`https://www.google.com/maps/embed/v1/place?q=${resort.Location_Latitude},${resort.Location_Longitude}&key=AIzaSyAmyWUwcoNW504tXuRlQ_mZssrKEEhGn20&zoom=${resort.Location_MapZoomLevel}`} allowFullScreen></iframe>
                </div>
            </div>
            <div className="sj-box sj-box-dark-blue sj-round-full center-aligned mt-5">
                <h3>Getting to {resort.Name}</h3>
            </div>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-2">
                <h4>By public transport</h4>
            </div>
            <div className="sj-row">
                <div className="row sj-no-bg sj-row-no-margin-padding mb-3">
                    <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                        <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Shinkansen.svg" alt='Train' />
                    </div>
                    <div className="col-md-9 sj-list-text-holder">
                        <div>
                            {(location.ShinkansenStation_Id !== null && location.ShinkansenLine_Id !== null) &&
                                <div>
                                    {location.KMFromShinkansenStation}km from <strong>{location.ShinkansenStationName}</strong> on the <strong>{location.ShinkansenLineName}</strong>
                                </div>
                            }
                            {(location.TrainStation1_Id !== null && location.TrainLine1_Id !== null) &&
                                <div>
                                    {location.KMFromStation1}km from <strong>{location.TrainStation1Name}</strong> on the <strong>{location.TrainLine1Name}</strong>
                                </div>
                            }
                            {(location.TrainStation2_Id !== null && location.TrainLine2_Id !== null) &&
                                <div>
                                    {location.KMFromStation2}km from <strong>{location.TrainStation2Name}</strong> on the <strong>{location.TrainLine2Name}</strong>
                                </div>
                            }
                            <div className='mt-3'>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(location.TravelInformation) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row sj-no-bg sj-row-no-margin-padding">
                    <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                        <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Bus.svg" alt='Bus' />
                    </div>
                    <div className="col-md-9 sj-list-text-holder">
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: RenderBreak(location.ShuttleBusInformation) }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-4">
                <h4>By road</h4>
            </div>
            <div className="sj-row">
                <div className="row sj-no-bg sj-row-no-margin-padding mb-3">
                    <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                        <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Cars.svg" alt='Car' />
                    </div>
                    <div className="col-md-9 sj-list-text-holder">
                        <div>
                            {(location.Interchange1_Id !== null && location.Expressway1_Id !== null) &&
                                <div>
                                    {location.KMFromInterchange1}km from the <strong>{location.Interchange1Name}</strong> on the <strong>{location.Expressway1Name}</strong>
                                </div>
                            }
                            {(location.Interchange2_Id !== null && location.Expressway2_Id !== null) &&
                                <div>
                                    {location.KMFromInterchange2}km from the <strong>{location.Interchange2Name}</strong> on the <strong>{location.Expressway2Name}</strong>
                                </div>
                            }
                            {(location.Interchange3_Id !== null && location.Expressway3_Id !== null) &&
                                <div>
                                    {location.KMFromInterchange3}km from the <strong>{location.Interchange3Name}</strong> on the <strong>{location.Expressway3Name}</strong>
                                </div>
                            }
                            {(location.RouteNumber !== null && location.RouteNumber !== '') &&
                                <div>
                                    Nearest national route: <strong>Route {location.RouteNumber}</strong> (<strong>{location.KMFromRoute}km</strong> away)
                                </div>
                            }
                            {(location.OtherPrefecturalRoadNumber !== null && location.OtherPrefecturalRoadNumber !== '') &&
                                <div>
                                    Nearest prefecture road: <strong>Prefecture Road {location.OtherPrefecturalRoadNumber}</strong>
                                </div>
                            }
                            <div className='mt-3'>
                                <div dangerouslySetInnerHTML={{ __html: RenderBreak(location.Direction) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row sj-no-bg sj-row-no-margin-padding mb-3">
                    <div className="col-md-3 m-0 mt-2 p-0 sj-list-icon-holder">
                        <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Parking.svg" alt='Parking' />
                    </div>
                    <div className="col-md-9 sj-list-text-holder">
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: RenderBreak(location.Parking) }} />
                        </div>
                    </div>
                </div>
            </div>
            {
                (resort.StreetViewMap1 !== '' || resort.StreetViewMap2 !== '') &&
                <div className="sj-row">
                    {(resort.StreetViewMap1 !== null && resort.StreetViewMap1 !== '') &&
                        <div className='mb-2 mt-5'>
                            <div className='ratio ratio-16x9'>
                                <iframe title='Street View 1' width='930' height='600' src={resort.StreetViewMap1} allowFullScreen aria-hidden='false'></iframe>
                            </div>
                        </div>
                    }
                    {(resort.StreetViewMap2 !== null && resort.StreetViewMap2 !== '') &&
                        <div className='mb-2 mt-5'>
                            <div className='ratio ratio-16x9'>
                                <iframe title='Street View 2' width='930' height='600' src={resort.StreetViewMap2} allowFullScreen aria-hidden='false'></iframe>
                            </div>
                        </div>
                    }
                    <div className='center-aligned'>
                        The above image has been manually selected to try and provide the most suitable views and location information. Settings will be manually checked and updated every year.
                    </div>
                </div>
            }
            {
                nearbySkiAreas.length > 0 &&
                <div className='empty-row padding-top'>
                    <div className="sj-box sj-box-dark-blue sj-round-full center-aligned mb-4">
                        <h3>Other ski areas nearby</h3>
                    </div>
                    <div>
                        <div className='row sj-frame-header sj-row-no-margin-padding'>
                            <div className="col-7">
                                <h5>Ski Area</h5>
                            </div>
                            <div className="col-5">
                                <h5>Distance</h5>
                            </div>
                        </div>
                        <div className='sj-data-table-holder'>
                            {nearbySkiAreas.map(nearbySkiArea => (
                                <div className='row sj-frame-body sj-row-no-margin-padding' key={nearbySkiArea.Id}>
                                    <div className="col-7">
                                        <Link to={`${window.$baseSkiAreaUrl}/${nearbySkiArea.SkiAreaPrefectureName}/${nearbySkiArea.SkiAreaTownUniqueName}/${nearbySkiArea.SkiAreaUniqueName}`}>{nearbySkiArea.SkiAreaName}</Link>
                                    </div>
                                    <div className="col-5">
                                        {nearbySkiArea.Distance} km
                                        {nearbySkiArea.IsConnected &&
                                            <>&nbsp;&nbsp;(Physically Connected)</>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div >
    )
};

export default LocationContainer;