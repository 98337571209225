import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Loader from '../Common/Loader'

const MemberHome = ({ user }) => {

    const [memberHomePage, setMemberHomePage] = useState('')
    const location = useLocation()
    const [userFullName, setUserFullName] = useState('')

    const fetchData = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/MemberHome`
        }).then(resGeneralMessages => {
            setMemberHomePage(resGeneralMessages.data.Message)
        })
    };

    useEffect(() => {
        fetchData()
    }, [location.pathname])

    useEffect(() => {
        if (user === null) {
            setUserFullName('')
        } else {
            setUserFullName(user.FullName)
        }
    }, [user])

    if (memberHomePage === '') {
        return (
            <Loader />
        )

    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>{userFullName}’s member area | SnowJapan</title>
                    <meta name="description" content="Member area | SnowJapan" />
                </Helmet>
                <div>
                    <div className="sj-para-image"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Icons-Member.svg" alt='member-login' /></div>
                    <div>
                        <h3>{userFullName}’s member area</h3>
                        <div className="sj-para">
                            <p>Thank you for sharing your thoughts and/or photographs of Japanese ski areas with other SnowJapan readers.</p>
                            <p>Be assured that your email address is strictly only used for registration purposes. It is not shared with anyone or added to any mailing list, and you will not receive any emails from us.</p>
                            <p>Before submitting content to SnowJapan, <Link to={`${window.$baseAppUrl}/about-members-area`}>please read this</Link>.</p>
                        </div>
                        <div>
                            <div className='sj-row'>
                                <div className='sj-para'>
                                    <h3>Submit new content</h3>
                                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                        <div className="col-md-6 sj-row-no-margin-padding padding-right-5">
                                            <Link to={`${window.$baseMemberUrl}/upload-photo`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-1'><h6 className='mt-1 mb-1'>Upload new ski area photos</h6></Link>
                                        </div>
                                        <div className="col-md-6 sj-row-no-margin-padding padding-left-5">
                                            <Link to={`${window.$baseMemberUrl}/submit-review`} className='btn btn-primary sj-large-font-button sj-red-bg w-100 mt-1'><h6 className='mt-1 mb-1'>Write a new ski area review</h6></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='sj-para'>
                                    <h3>Your current content </h3>
                                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                        <div className="col-md-6 sj-row-no-margin-padding padding-right-5">
                                            <Link to={`${window.$baseMemberUrl}/photos`} className='btn btn-primary w-100 sj-box-dark-blue mt-1'><h6 className='mt-1 mb-1'>My photos</h6></Link>
                                        </div>
                                        <div className="col-md-6 sj-row-no-margin-padding padding-left-5">
                                            <Link to={`${window.$baseMemberUrl}/reviews`} className='btn btn-primary w-100 sj-box-dark-blue mt-1'><h6 className='mt-1 mb-1'>My reviews</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MemberHome