import axios from 'axios'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { Button, Collapse, Image } from 'react-bootstrap'
import Loader from '../Common/Loader'
import { RenderBreak } from '../../helpers/Helper'

const Home = ({ seasonName }) => {

    const params = useParams()

    const [dailyReportList, setDailyReportList] = useState([])
    const [generalMessageList, setSeneralMessageList] = useState([])
    const [dailyReportMessage, setDailyReportMessage] = useState('')
    const [dailyReportWeatherMapUrl, setDailyReportWeatherMapUrl] = useState('')
    const [showSnowData, setShowSnowData] = useState('')
    const [latestSkiAreaWeatherList, setLatestSkiAreaWeatherList] = useState([])
    const [seasonTotalSnowList, setSeasonTotalSnowList] = useState([])
    const [open, setOpen] = useState(false)

    let singleSkiAreaWeatherReport = undefined

    const fetchGeneralMessagesAndDailyReports = async (thisSeasonName) => {
        const generalMessageIds = ['Nows', 'NowWeatherUrl', 'ShowSnowData']
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/all`,
            data: { generalMessageIds: generalMessageIds }
        }).then(resGeneralMessages => {
            setSeneralMessageList(resGeneralMessages.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/dailyreport/latest/all`
            })
        }).then(resDailyReportLatest => {
            setDailyReportList(resDailyReportLatest.data)
            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/dailyreport/reports/season-snowfall/${thisSeasonName}/0`
            })
        }).then(resSeasonTotalSnow => {
            setSeasonTotalSnowList(resSeasonTotalSnow.data)

            return axios({
                method: 'POST',
                url: `${window.$baseAPIUrl}/skiarea/latestweather/list`,
                data: { filter_type: 'with-snow' }
            })
        }).then(resSkiAreaWeather => {
            if (resSkiAreaWeather.data) {
                const skiAreaLatestWeather = resSkiAreaWeather.data

                var maxCreated = skiAreaLatestWeather.sort((a, b) => b.CreatedAt - a.CreatedAt)[0].CreatedAt;

                const latestSkiAreaWeather = skiAreaLatestWeather.filter(function (currentElement) {
                    return (currentElement.CreatedAt === maxCreated);
                })

                latestSkiAreaWeather.sort(function (a, b) {
                    return a.SnowDepth - b.SnowDepth
                })

                setLatestSkiAreaWeatherList(latestSkiAreaWeather.reverse().slice(0, 25))
            }
        })
    }

    useEffect(() => {
        fetchGeneralMessagesAndDailyReports(seasonName)
    }, [params, seasonName])

    const GetSeasonTotal = (nowId) => {


        let seasonTotalSnow = seasonTotalSnowList.filter(function (currentElement) {
            return (currentElement.Now_Id === nowId);
        });

        let seasonSnowfall = '--'

        if (seasonTotalSnow.length > 0) {
            seasonSnowfall = seasonTotalSnow[0].TotalSnow + 'cm'
        }

        return seasonSnowfall
    }

    console.log(generalMessageList)

    if (generalMessageList.length > 0 && (dailyReportMessage === undefined || dailyReportMessage === '')) {
        setDailyReportMessage(generalMessageList[0].Message)
    }

    if (generalMessageList.length > 1 && (dailyReportWeatherMapUrl === undefined || dailyReportWeatherMapUrl === '')) {
        setDailyReportWeatherMapUrl(generalMessageList[1].Message)
    }

    if (generalMessageList.length > 2 && (showSnowData === undefined || showSnowData === '')) {
        setShowSnowData(generalMessageList[2].Title)
    }

    if (latestSkiAreaWeatherList.length > 0) {
        singleSkiAreaWeatherReport = latestSkiAreaWeatherList[0];
    }

    if (latestSkiAreaWeatherList.length <= 0) {
        return (<Loader />)
    } else {
        return (
            <div className='empty-row'>
                <Helmet>
                    <title>Independent daily snow and weather reporting from Japan’s ski areas | SnowJapan</title>
                    <meta name="description" content="SnowJapan publishes exclusive daily snow and weather reports from popular ski area regions of Japan, including detailed analysis and archive data." />
                </Helmet>
                <h3>Daily Reports</h3>
                <div className="sj-para-image mb-4"><img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Weather-HeavySnow.svg" alt="Japan as 'Snow Country'" /></div>
                <div>
                    <div className="sj-para"><div dangerouslySetInnerHTML={{ __html: RenderBreak(dailyReportMessage) }} /></div>
                </div>
                <div className="sj-row sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                    <h4>Now daily reports</h4>
                </div>
                <div className='center-aligned sj-row' style={{ overflow: 'none' }}>
                    <div className="row sj-row-no-margin-padding sj-no-bg">
                        {dailyReportList.map(dailyReport => (
                            <div key={dailyReport.Id} className="col-lg-4 col-md-6 sj-row-small-margin-padding">
                                <div className='daily-report-tile-container'>
                                    <div className='daily-report-tile'>
                                        <div className='daily-report-tile-body table-container'>
                                            <table className='table-container__table sj-table'>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan='2'>
                                                            <h4><Link to={`${window.$baseDailyReportUrl}/${dailyReport.UniqueName}`}>{dailyReport.Name}</Link></h4>
                                                            <div className='sj-red-text'>{dailyReport.NowReportDayTh} {dailyReport.NowReportMonthFull} {dailyReport.NowReportYear} ({dailyReport.NowReportHourMinute})</div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='sj-td-50'>Latest</td>
                                                        <td className='sj-td-50'>{dailyReport.NewSnowfallResortA}cm</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                    <h4>Webcams</h4>
                </div>
                <div className="sj-row">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-lg-3 col-md-5 p-0 sj-list-icon-holder">
                            <Image src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Webcam.svg" alt='snowjapan webcam' />
                        </div>
                        <div className="col-lg-9 col-md-7 sj-list-text-holder">
                            <div className="center-aligned mb-4">
                                <Link to={`${window.$baseDailyReportUrl}/webcams`}><h5>Webcam images and live video feeds from ski areas (and related places of interest) around Japan.</h5></Link>
                            </div>
                            <div className="row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-lg-4 center-aligned sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><Link to='guides/ski-webcams/northern-japan'>Northern regions</Link></h5>
                                        <h6>including Hokkaido, Tohoku<br />&nbsp;</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 center-aligned sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><Link to='guides/ski-webcams/central-japan'>Central regions</Link></h5>
                                        <h6>including Niigata, Nagano, Gunma</h6>
                                    </div>
                                </div>
                                <div className="col-lg-4 center-aligned sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><Link to='guides/ski-webcams/western-japan'>Western regions</Link></h5>
                                        <h6>including Gifu, Kansai and Western Japan</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                    <h4>Reports calendar</h4>
                </div>
                <div className="sj-row">
                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-lg-3 col-md-5 p-0 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Calendar.svg" alt='snowjapan calendar' />
                        </div>
                        <div className="col-lg-9 col-md-7 sj-list-text-holder">
                            <Link to={`${window.$baseDailyReportUrl}/calendar`}><h5>View the current season calendar with links to all the daily reports posted on SnowJapan.</h5></Link>
                        </div>
                    </div>
                </div>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                    <h4>Real-time snowfall (new window) </h4>
                </div>
                <div className="sj-row">
                    <div className="row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-lg-3 col-md-5 p-0 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Snow-Forecast.svg" alt='snowjapan forecast' />
                        </div>
                        <div className="col-lg-9 col-md-7 sj-list-text-holder">
                            <div className="row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_kaikotan/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/#zoom:6/elements:slmcs&rasrf' target='_blank' rel='noreferrer'>1-hour precipitation</a></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_kaikotan/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/#zoom:6/elements:slmcs&rasrf03h' target='_blank' rel='noreferrer'>3-hour precipitation</a></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 mt-0 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_kaikotan/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/#zoom:6/elements:slmcs&rasrf24h' target='_blank' rel='noreferrer'>24-hour precipitation</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_snow/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/elements:snowf03h' target='_blank' rel='noreferrer'>3-hour snowfall</a></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_snow/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/elements:snowf24h' target='_blank' rel='noreferrer'>24-hour snowfall</a></h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_snow/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/elements:snowf48h' target='_blank' rel='noreferrer'>48-hour snowfall</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-lg-4 sj-row-no-margin-padding">
                                    <div className='sj-box-lighter-gray sj-round-full m-2 p-2 pt-3 pb-3'>
                                        <h5><a href='https://www.jma.go.jp/bosai/en_snow/#zoom:6/lat:38.916682/lon:138.823242/colordepth:normal/elements:snowd' target='_blank' rel='noreferrer'>Snow depth</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                    <h4>Weather map</h4>
                </div>
                <div className="sj-row">
                    <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                        <div className="col-lg-3 col-md-5 p-0 sj-list-icon-holder">
                            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Weather-HeavySnow.svg" alt='snowjapan snowfall' />
                        </div>
                        <div className="col-lg-9 col-md-7 sj-list-text-holder">
                            <Button
                                onClick={() => setOpen(!open)}
                                aria-expanded={open} className='w-100 mb-1'><h5 className='mt-1'>{open ? 'Click to hide map -' : 'Click to view map +'}</h5></Button>
                            <Collapse in={open}>
                                <div className='ratio ratio-16x9'>
                                    {(open) &&
                                        <iframe title='Various animated forecasts' src={`${dailyReportWeatherMapUrl}`} allowFullScreen></iframe>
                                    }
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                {(showSnowData == "Y") &&
                    <>
                        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5">
                            <h4>Official ski area snow depth data</h4>
                        </div>
                        <div className="sj-row">
                            <div className="row sj-row sj-no-bg sj-row-no-margin-padding">
                                <div className="col-lg-3 col-md-5 p-0 sj-list-icon-holder">
                                    <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Snow-Depth.svg" alt='snowjapan snow depth' />
                                </div>
                                <div className="col-lg-9 col-md-7 sj-list-text-holder">
                                    <div>
                                        <h5>Updated: {singleSkiAreaWeatherReport.WeatherDayTh} {singleSkiAreaWeatherReport.WeatherMonthFull} {singleSkiAreaWeatherReport.WeatherYear}</h5>
                                    </div>
                                    <div>
                                        The data in the below table originates from ski areas and is updated once a day in the main winter season.
                                    </div>
                                    {(latestSkiAreaWeatherList.length > 0) &&
                                        <div className='sj-row'>
                                            <div className='table-container'>
                                                <table className='table-container__table sj-list-table sj-snow-table'>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Prefecture</th>
                                                            <th>Current snow depth</th>
                                                            <th>Change</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {latestSkiAreaWeatherList.map(skiAreaWeather => (
                                                            <tr key={skiAreaWeather.Id}>
                                                                <td>
                                                                    <Link to={`${window.$baseSkiAreaUrl}/${skiAreaWeather.PrefectureUniqueName}/${skiAreaWeather.TownUniqueName}/${skiAreaWeather.UniqueName}`}>{skiAreaWeather.Name}</Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={`${window.$baseSkiAreaUrl}/prefecture/${skiAreaWeather.PrefectureUniqueName}`}>{skiAreaWeather.PrefectureName}</Link>
                                                                </td>
                                                                <td>
                                                                    {skiAreaWeather.SnowDepth} cm
                                                                </td>
                                                                <td>
                                                                    {skiAreaWeather.SnowDepthCompareToYesterday} cm
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='center-aligned mt-4'>
                                                <h6><Link to={`${window.$baseDailyReportUrl}/official-snow-depth`} className="btn btn-primary sj-large-button-blue sj-full-width">View the full list</Link></h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className='sj-row sj-box sj-box-red sj-round-full m-0 mt-2'>
                    Weather maps, real-time snowfall data and weather information can be found within the individual Now daily report sections and on individual ski area information pages within SnowJapan.
                </div>
                <div className="banner-holder">
                    <div className="sj-row mb-0">
                        <div className="row sj-row-no-margin-padding sj-no-bg">
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-left mt-3'>
                                    <a href='https://en.nozawaski.com/' target='_blank' rel='noreferrer'><Image src='https://www.snowjapan.com/UploadedFiles/CMSImages/Listings/listing-nozawa-onsen.jpg' alt='listing-nozawa-onsen' /></a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sj-row-no-margin-padding">
                                <div className='home-listing-holder-center1 mt-3'>
                                    <Link to='/listings/resort-spotlights/maiko-snow-resort'><Image src='https://www.snowjapan.com/UploadedFiles/ListingImages/link-maiko-snow-resort.jpg' fluid='true' alt='listing-geto-kogen.jpg' /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home