import { useState, useEffect } from 'react'
import axios from 'axios'
import Loader from "../../../Common/Loader"
import { RenderBreak } from '../../../../helpers/Helper'

const LiftTicketCharges = ({ resort }) => {

  const [ticketChargeList, setTicketChargeList] = useState([]);

  const fetchLiftTicket = async (resortUniqueName) => {
    axios({
      method: 'POST',
      url: `${window.$baseAPIUrl}/skiarea/ticket/list/${resortUniqueName}`
    }).then(dataTicket => {
      setTicketChargeList(dataTicket.data)
    })
  };

  useEffect(() => {
    fetchLiftTicket(resort.UniqueName);
  }, [resort.UniqueName]);

  if (ticketChargeList.length <= 0) {
    return (
      <Loader />
    );
  }
  else {
    return (
      <div className='empty-row'>
        <div className="sj-box sj-box-dark-gray sj-round-full center-aligned mt-5 mb-3">
          <h4>{resort.Name} lift tickets</h4>
        </div>
        <div className="row sj-no-bg sj-row-no-margin-padding">
          <div className="col-md-3 m-0 mt-5 p-0 sj-list-icon-holder">
            <img src="https://www.snowjapan.com/UploadedFiles/CMSImages/Icons/SnowJapan-Lift-Ticket.svg" alt='Train' />
          </div>
          <div className="col-md-9 sj-list-text-holder">
            <div className='table-container'>
              <table className='table-container__table sj-list-table ticket-info'>
                <thead>
                  <tr>
                    <th>Type of ticket</th>
                    <th>Adults</th>
                    <th>
                      Child
                      {(resort.ChildTicketLabel !== '' && resort.ChildTicketLabel !== null) &&
                        <div>
                          {resort.ChildTicketLabel}
                        </div>
                      }
                    </th>
                    {(resort.OlderChildTicketLabel !== '' && resort.OlderChildTicketLabel !== null) &&
                      <th>Older Child
                        <div>
                          {resort.OlderChildTicketLabel}
                        </div>
                      </th>
                    }
                    <th>
                      Senior
                      {(resort.SeniorTicketLabel !== '' && resort.SeniorTicketLabel !== null) &&
                        <div>
                          {resort.SeniorTicketLabel}
                        </div>
                      }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ticketChargeList.map((ticketCharge, index) => (
                    <tr key={index}>
                      <td>
                        {ticketCharge.NameOfTicket &&
                          <div>{ticketCharge.NameOfTicket}</div>
                        }</td>
                      <td>
                        {ticketCharge.AdultTicket &&
                          <div>{ticketCharge.AdultTicket} yen</div>
                        }
                      </td>
                      <td>
                        {ticketCharge.ChildTicket &&
                          <div>{ticketCharge.ChildTicket} yen</div>
                        }
                      </td>
                      {(resort.OlderChildTicketLabel !== '' && resort.OlderChildTicketLabel !== null) &&
                        <td>
                          {ticketCharge.OlderChildTicket &&
                            <div>{ticketCharge.OlderChildTicket} yen</div>
                          }
                        </td>
                      }
                      <td>
                        {ticketCharge.SeniorTicket &&
                          <div>{ticketCharge.SeniorTicket} yen</div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {resort.InformationOnChildren !== '' &&
              <div className='pt-4'>
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.LiftTicketInformation) }} />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default LiftTicketCharges;