import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { RenderBreak } from '../../helpers/Helper'

const Home = ({ listingSectionList, listingList, accommodationList }) => {

    const location = useLocation()

    const [listingIntroductionText, setListingIntroductionText] = useState('')

    const fetchListingMessage = async () => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/generalmessage/Listing`
        }).then(resGeneralMessages => {
            setListingIntroductionText(resGeneralMessages.data.Message)
        })
    }

    useEffect(() => {
        fetchListingMessage()
    }, [location.pathname]);

    const getSectionListingList = (sectionUniqueName) => {
        const listings = listingList.filter(function (currentElement) {
            return (currentElement.SectionUniqueName === sectionUniqueName)
        })

        return listings
    }

    return (
        <div className='sj-row'>
            <Helmet>
                <title>Accommodation, ski lessons and jobs available near ski areas around Japan | SnowJapan</title>
                <meta name="description" content='Introducing various accommodations, ski schools and other businesses near ski areas in Japan' />
            </Helmet>
            {
                (listingIntroductionText !== null && listingIntroductionText !== undefined && listingIntroductionText !== '') &&
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(listingIntroductionText) }} />
            }
            <div className='sj-listing-frame-row'>
                {(accommodationList !== undefined && accommodationList.length > 0) &&
                    <div className='sj-listing-frame-holder'>
                        <div className='sj-listing-frame'>
                            <div className='sj-listing-frame-body'>
                                <div>
                                    <h4>Accommodation</h4>
                                </div>
                                <div>
                                    <DropdownButton id="accommodation-list-button" title="Please Select" drop='down'>
                                        {accommodationList.map((item, index) => (
                                            <Dropdown.Item key={index} as={Link} to={`${window.$baseAccommodationUrl}/${item.PrefectureUniqueName}/${item.TownUniqueName}/${item.UniqueName}`}>{item.Name}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </div>
                                <div className='mt-3'>
                                    <Link to={window.$baseAccommodationUrl}>Full accommodation listings</Link>
                                </div>
                                <div>
                                    <Link to={window.$baseAccommodationUrl}><img src={`https://www.snowjapan.com/UploadedFiles/SectionIcons/SnowJapan-Icons_Accommodation.svg`} alt='accommodation' /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {listingSectionList.map(listingSection => (
                    <div key={listingSection.Id} className='sj-listing-frame-holder'>
                        <div className='sj-listing-frame'>
                            <div className='sj-listing-frame-body'>
                                <div>
                                    <h4>{listingSection.Name}</h4>
                                </div>
                                <div>
                                    <DropdownButton id={listingSection.UniqueName} title="Please Select" drop='down'>
                                        {getSectionListingList(listingSection.UniqueName).map((item, index) => (
                                            <Dropdown.Item key={index} as={Link} to={`${window.$baseListingUrl}/${listingSection.UniqueName}/${item.UniqueName}`}>{item.Name}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </div>
                                <div className='mt-3'>
                                    <Link to={`${window.$baseListingUrl}/${listingSection.UniqueName}`}>Full {listingSection.Name} listings</Link>
                                </div>
                                <div>
                                    <Link to={`${window.$baseListingUrl}/${listingSection.UniqueName}`}><img src={`https://www.snowjapan.com/UploadedFiles/SectionIcons/${listingSection.SectionIcon}`} alt={`${listingSection.Name}`} /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Home