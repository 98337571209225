/* eslint no-eval: 0 */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { RenderBreak } from '../../helpers/Helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'

const ListingListTable = ({ tableData, sortColumn, sortOrder, headingColumns, dataMapping, title, breakOn = 'medium' }) => {
  let tableClass = 'table-container__table';

  if (breakOn === 'small') {
    tableClass += ' table-container__table--break-sm';
  } else if (breakOn === 'medium') {
    tableClass += ' table-container__table--break-md';
  } else if (breakOn === 'large') {
    tableClass += ' table-container__table--break-lg';
  }

  const data = tableData.map((row, index) => {
    let rowData = [];
    let i = 0;

    for (const key in row) {

      if (dataMapping[i] === undefined) {
        continue;
      }

      var objName = 'row.' + dataMapping[i]

      rowData.push({
        key: headingColumns[i],
        val: eval(objName),
        UniqueName: row.UniqueName,
        SectionUniqueName: row.SectionUniqueName,
        IntroductionText: row.IntroductionText,
        ImageName: row.ImageName
      });
      i++;
    }

    return <tr key={index}>
      {rowData.map((data, index) => {
        if (index === 0) {
          return <td key={index}>
            <Link to={`${window.$baseListingUrl}/${data.SectionUniqueName}/${data.UniqueName}`}><img src={`https://www.snowjapan.com/UploadedFiles/ListingImages/${data.ImageName}`} alt={`${data.Name}`} /></Link>
          </td>
        } else if (index === 1) {
          if (sortColumn === 'Listing name') {
            return <td key={index} className='sorted-column' data-heading={data.key}>
              <Link to={`${window.$baseListingUrl}/${data.SectionUniqueName}/${data.UniqueName}`}><h5>{data.val}</h5></Link>
              {(data.IntroductionText !== null && data.IntroductionText !== undefined && data.IntroductionText !== '') &&
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(data.IntroductionText) }} />
              }
            </td>
          } else {
            return <td key={index} data-heading={data.key}>
              <Link to={`${window.$baseListingUrl}/${data.SectionUniqueName}/${data.UniqueName}`}><h5>{data.val}</h5></Link>
              {(data.IntroductionText !== null && data.IntroductionText !== undefined && data.IntroductionText !== '') &&
                <div dangerouslySetInnerHTML={{ __html: RenderBreak(data.IntroductionText) }} />
              }
            </td>
          }
        }
        else {
          if (data.key === sortColumn) {
            return <td key={index} className='sorted-column' data-heading={data.key}>
              {data.val}
            </td>
          } else {
            return <td key={index} data-heading={data.key}>
              {data.val}
            </td>
          }
        }
      }
      )}
    </tr>
  });

  return (
    <div className="table-container">
      <table className={`sj-list-table sj-accommodation-table ${tableClass}`}>
        <thead>
          <tr>
            {headingColumns.map((col, index) => {
              if (col !== '' && col === sortColumn) {
                if (sortOrder === 'ASC') {
                  return <th key={index} className='sorted-column-header'>{col}&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDoubleDown} /></th>
                } else if (sortOrder === 'DESC') {
                  return <th key={index} className='sorted-column-header'>{col}&nbsp;&nbsp;<FontAwesomeIcon icon={faAngleDoubleUp} /></th>
                } else {
                  return <th key={index} className='sorted-column-header'>{col}</th>
                }
              } else {
                return <th key={index}>{col}</th>
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data}
        </tbody>
      </table>
    </div>
  );
}

ListingListTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  breakOn: PropTypes.oneOf(['small', 'medium', 'large'])
}

export default ListingListTable;