import { Image } from 'react-bootstrap'

const CourseMap = ({ resort }) => {

  let courseMapSmall = ''
  if (resort.CourseMapSmall !== '' && resort.CourseMapSmall !== undefined) {
    courseMapSmall = String(resort.CourseMapSmall).replace('~/', '/')
  }

  let courseMapLarge = ''
  if (resort.CourseMapLarge !== '' && resort.CourseMapLarge !== undefined) {
    courseMapLarge = String(resort.CourseMapLarge).replace('~/', '/')
  }

  return (
    <div className='empty-row'>
      {courseMapSmall !== '' &&
        <div className='empty-row center-aligned'>
          <div className='sj-image-holder sj-row-no-margin-padding'>
            <Image src={`https://www.snowjapan.com/${courseMapSmall}`} fluid='true' alt={`${resort.Name} course map small`} className="mt-5" />
          </div>
          <div className='center-aligned'>
            The above image has been created by SnowJapan to show the position of ski lifts at {resort.Name}.
          </div>
        </div>
      }
      {courseMapLarge !== '' &&
        <div className='empty-row center-aligned'>
          <div className='sj-image-holder sj-row-no-margin-padding'>
            <Image src={`https://www.snowjapan.com${courseMapLarge}`} fluid='true' alt={`${resort.Name} course map large`} className="mt-5" />
          </div>
        </div>
      }
    </div>
  );
}

export default CourseMap;