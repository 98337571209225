import { useState, useEffect } from 'react'
import axios from 'axios'
import MonthlyReportsListing from './MonthlyReportsListing'
import { GetCurrentSeasonName } from '../../../../helpers/Helper'

const MonthlyReportsListingContainer = ({ dailyReport }) => {

    const [dailySnowfallList, setDailySnowfallList] = useState([]);
    const [seasonName, setSeasonName] = useState(GetCurrentSeasonName());
    const [years, setYears] = useState(seasonName.split('-'));

    const fetchDailySnowfall = async (nowUniqueName) => {
        axios({
            method: 'POST',
            url: `${window.$baseAPIUrl}/dailyreport/reports/${nowUniqueName}/All`
        }).then(resDailySnowfall => {
            setDailySnowfallList(resDailySnowfall.data)
        })
    };

    useEffect(() => {
        fetchDailySnowfall(dailyReport.UniqueName)
        setYears(seasonName.split('-'))
    }, [dailyReport.UniqueName, seasonName]);

    let seasons = [...new Set(dailySnowfallList.map(item => item.Season))].sort().reverse();

    const handleChange = (event) => {
        setSeasonName(event.target.value)
    };

    return (
        <div>
            <h1>Daily Report Archives</h1>
            <div>
                <div>
                    <select onChange={handleChange}>
                        {seasons.map((item, index) => (
                            <option key={index} value={item}>
                                {item} Season
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='April' nowYear={years[1]} dailySnowfallList={dailySnowfallList} />
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='March' nowYear={years[1]} dailySnowfallList={dailySnowfallList} />
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='February' nowYear={years[1]} dailySnowfallList={dailySnowfallList} />
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='January' nowYear={years[1]} dailySnowfallList={dailySnowfallList} />
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='December' nowYear={years[0]} dailySnowfallList={dailySnowfallList} />
                    <MonthlyReportsListing dailyReport={dailyReport} monthName='November' nowYear={years[0]} dailySnowfallList={dailySnowfallList} />
                </div>
            </div>
        </div>
    )
};

export default MonthlyReportsListingContainer;