import { Image } from 'react-bootstrap'
import SkiAreaInfo from '../SkiAreaInfo'
import { Helmet } from 'react-helmet'
import CourseMap from './CourseMap'
import SkiMountain from './SkiMountain'
import SkiSnowBoard from './SkiSnowBoard'
import Facilities from './Facilities'
import SeasonOpeningTime from './SeasonOpeningTime'
import LiftTicketCharges from './LiftTicketCharges'
import ContactInformation from './ContactInformation'
import { RenderBreak } from '../../../../helpers/Helper'

const BasicContainer = ({ requestUrl, resort, nearbySkiAreaList, skiAreaLatestWeather, showSnowData }) => {

    return (
        <div className='empty-row'>
            <Helmet>
                <title>{resort.Name} | Ski area in {resort.TownName} {resort.TownTypeStr}, {resort.PrefectureName}, Japan  |  {resort.NameJapanese} | SnowJapan</title>
                <meta name="description" content={`Introducing ${resort.Name}, a ski area located in ${resort.TownName} ${resort.TownTypeStr}, ${resort.PrefectureName}, Japan.`} />
            </Helmet>
            <div className='empty-row'>
                <div className="sj-para-map">
                    <Image src={`https://www.snowjapan.com/UploadedFiles/MapImages/Ski-Area/${resort.MapImage}`} alt={`${resort.Name} map`} fluid='true' />
                </div>
                <div>
                    <SkiAreaInfo resort={resort} />
                    <div className="sj-para">
                        {/*<h4>Planned season</h4>
                        <h5>{resort.PlannedSeasonDates}</h5>*/}
                        <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.PlannedSeasonDates) }} />
                        {(skiAreaLatestWeather !== undefined && Object.keys(skiAreaLatestWeather).length > 0 && skiAreaLatestWeather.SnowDepth !== null && showSnowData !== undefined && showSnowData === "Y") &&
                            <div className='sj-para sj-box sj-box-red sj-round-full sj-50-box'>
                                <h5>Official snow depth:</h5>
                                <div>
                                    {skiAreaLatestWeather.SnowDepth}cm ({skiAreaLatestWeather.SnowDepthCompareToYesterday}cm change since last update)<br />
                                    Updated on {skiAreaLatestWeather.WeatherDayTh} {skiAreaLatestWeather.WeatherMonthFull} {skiAreaLatestWeather.WeatherYear}
                                </div>
                            </div>
                        }
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: RenderBreak(resort.IntroductoryText) }} />
                </div>
            </div>
            <div className="sj-para">
                {(resort.RegionName !== null && resort.RegionName !== '' && resort.RegionUrl !== null && resort.RegionUrl !== '') &&
                    <a className='btn btn-primary sj-large-button-blue sj-full-width' href={resort.RegionUrl}>{resort.RegionName}</a>
                }
            </div>
            <SkiMountain resort={resort} />
            <SkiSnowBoard resort={resort} nearbySkiAreaList={nearbySkiAreaList} />
            {resort.CourseMapSmall !== '' &&
                <CourseMap resort={resort} />
            }
            <Facilities resort={resort} />
            <LiftTicketCharges resort={resort} />
            <SeasonOpeningTime resort={resort} />
            <ContactInformation resort={resort} />
        </div>
    )
};

export default BasicContainer;