import { Link } from 'react-router-dom'
import Loader from "../../../Common/Loader"

const MonthlySnowfallTable = ({ monthlySnowfall, nowUniqueName }) => {

    const getSnowfallValue = (season) => {
        let monthlySnowfallData = [];

        let monthlyData = monthlySnowfall.filter(function (currentElement) {
            return (currentElement.Season === season);
        });

        monthlyData.forEach(function (dataItem) {
            let totalSeasonSnowfall = dataItem.TotalSnow + dataItem.PrevMonthTotalSnow1 + dataItem.PrevMonthTotalSnow2 + dataItem.PrevMonthTotalSnow3 + dataItem.PrevMonthTotalSnow4 + dataItem.PrevMonthTotalSnow5 + dataItem.PrevMonthTotalSnow6

            monthlySnowfallData.push({ 'month': dataItem.NowReportMonthFull, 'newSnowfall': dataItem.TotalSnow, 'seasonSnowfall': totalSeasonSnowfall })
        })

        return monthlySnowfallData;
    }

    let monthlySnowfallData = [];
    let seasons = [...new Set(monthlySnowfall.map(item => item.Season))].sort().reverse();

    seasons.forEach(function (item) {
        if (item > '2011-2012') {
            monthlySnowfallData.push({
                'name': item,
                'data': getSnowfallValue(item)
            })
        }
    })

    const GetNovemberBlankCells = (monthlyData) => {
        let novemberData = monthlyData.filter(function (currentElement) {
            return (currentElement.month === 'November');
        });

        if (novemberData.length <= 0) {
            return (
                <td className="center-aligned">-</td>
            )
        }
        else {
            return (<></>)
        }
    }

    const GetBlankCells = (monthlyData) => {
        let novemberData = monthlyData.filter(function (currentElement) {
            return (currentElement.month === 'November');
        });

        let blankCells = monthlyData.length

        if (novemberData.length <= 0) {
            blankCells = blankCells + 1
        }

        return (
            <>
                {(() => {
                    const rows = [];
                    for (let i = 0; i < (6 - blankCells); i++) {
                        rows.push(<td className="center-aligned" key={i}>-</td>);
                    }
                    return rows;
                })()}
            </>
        )
    }

    if (monthlySnowfall.length <= 0) {
        return (<Loader />)
    }
    else {
        return (
            <div className='table-container overflow-x'>
                <table className='table-container__table sj-list-table monthly-snow-fall'>
                    <thead>
                        <tr>
                            <th className="td-table-header center-aligned">Season</th>
                            <th className="td-table-header"><span className="d-none d-lg-block">November</span><span className="d-lg-none">NOV</span></th>
                            <th className="td-table-header"><span className="d-none d-lg-block">December</span><span className="d-lg-none">DEC</span></th>
                            <th className="td-table-header"><span className="d-none d-lg-block">January</span><span className="d-lg-none">JAN</span></th>
                            <th className="td-table-header"><span className="d-none d-lg-block">February</span><span className="d-lg-none">FEB</span></th>
                            <th className="td-table-header"><span className="d-none d-lg-block">March</span><span className="d-lg-none">MAR</span></th>
                            <th className="td-table-header"><span className="d-none d-lg-block">April</span><span className="d-lg-none">APR</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {monthlySnowfallData.map(season => (
                            <tr key={season.name}>
                                <td className='td-first-column'><Link to={`${window.$baseDailyReportUrl}/${nowUniqueName}/season/${season.name}`}>{season.name}</Link></td>
                                {GetNovemberBlankCells(season.data)}
                                {season.data.map((snowdata, index) => (
                                    <td key={index} className="center-aligned">
                                        <div className='td-monthly-snowfall'>{snowdata.newSnowfall}cm</div>
                                        <div className='td-season-snowfall'>[{snowdata.seasonSnowfall}cm]</div>
                                    </td>
                                ))}
                                {GetBlankCells(season.data)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
};

export default MonthlySnowfallTable;